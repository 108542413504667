import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router, RouterEvent, NavigationEnd } from '@angular/router';
import { Subscription } from 'rxjs';
import { environment } from '@environments/leap/environment';

/** Constants */
import { CURRENT_YEAR, DOT, WHITESPACE, WELCOME_TEXT } from '@leap-common/constants/common';
import { COMMA_DELIMITER } from '@leap-common/constants/delimiters';
import { TERMS_OF_USE_TEXT, PRIVACY_POLICY_TEXT } from '../../../../core/constants/auth';
import {
    EXTERNAL_POLICY_DISCLAIMER_TEXT,
    EXTERNAL_POLICY_LINK,
    EXTERNAL_POLICY_TEXT,
} from '@apps/leap/src/app/shared/constants/external';

/** Services - Facades */
import { FeatureFlagsService } from '@leap-libs/feature-flags/src/public-api';
import { AlertsFacade } from '@leap-store/core/src/lib/ui/alerts/alerts.facade';

@Component({
    selector: 'app-auth-layout',
    templateUrl: 'auth-layout.container.component.html',
    styleUrls: ['auth-layout.container.component.scss'],
})
export class AuthLayoutContainerComponent implements OnInit, OnDestroy {
    // organization
    organization: string = environment.organization;

    // text color
    isTextWhite: boolean;

    // display
    isWelcomeTextDisplayed: boolean;
    isAgreementTextDisplayed: boolean;

    // route
    route: string;

    // subscriptions
    routerSubscription: Subscription;

    // feature flags
    areExternalLinksEnabled: boolean;

    readonly welcomeText: string = WELCOME_TEXT;
    readonly currentYear: number = CURRENT_YEAR;

    readonly termsOfUseTitle: string = TERMS_OF_USE_TEXT;
    readonly privacyPolicyTitle: string = PRIVACY_POLICY_TEXT;
    readonly externalPolicyTitle: string = EXTERNAL_POLICY_TEXT;
    readonly externalPolicyDisclaimerText: string = EXTERNAL_POLICY_DISCLAIMER_TEXT;

    readonly dot: string = DOT;
    readonly comma: string = COMMA_DELIMITER;

    constructor(
        private router: Router,
        private featureFlagsService: FeatureFlagsService,
        private alertsFacade: AlertsFacade,
    ) {
        this.subscribeToRouterEvents();
    }

    ngOnInit(): void {
        this.initializeEnabledFeatures();
        this.initializeIsTextWhite();
    }

    ngOnDestroy(): void {
        if (this.routerSubscription) {
            this.routerSubscription.unsubscribe();
        }
    }

    subscribeToRouterEvents(): void {
        this.routerSubscription = this.router.events.subscribe((event: RouterEvent) => {
            if (event instanceof NavigationEnd) {
                const urlParts: string[] = event.urlAfterRedirects
                    .split('?')[0]
                    .split('/')
                    .filter((urlPart: string) => urlPart);
                this.route = urlParts[1];

                // update text display
                this.updateTextDisplay();

                // clear active alerts
                this.alertsFacade.clearAlertEvents();
            }
        });
    }

    initializeEnabledFeatures(): void {
        this.areExternalLinksEnabled =
            this.featureFlagsService.isFeatureEnabled('externalPrivacyLinks');
    }

    initializeIsTextWhite(): void {
        this.isTextWhite = this.organization !== 'dmi';
    }

    updateTextDisplay(): void {
        this.isWelcomeTextDisplayed =
            this.organization !== 'pp' &&
            (this.route === 'login' || this.route === 'forgot-password');

        this.isAgreementTextDisplayed =
            this.route === 'login' ||
            this.route === 'forgot-password' ||
            this.route === 'reset-password';
    }

    /** Sets the background image dynamically and fixes its position. */
    setBackground(): Record<string, string> {
        return {
            'background-image': `url('assets/graphics/${this.organization}/full-background.png')`,
            'background-repeat': 'no-repeat',
            'background-size': 'cover',
            'background-position': 'right',
        };
    }

    setLogo(): Record<string, string> {
        const logo: Record<string, string> = {
            'background-image': `url('assets/graphics/${this.organization}/login-logo.svg')`,
            'background-repeat': 'no-repeat',
            'background-size': 'contain',
            'background-position': 'center',
            height:
                this.organization === 'mr' || this.organization === 'mr-plant'
                    ? '70px'
                    : this.organization === 'pp'
                    ? '75px'
                    : this.organization === 'dmi'
                    ? '95px'
                    : this.organization === 'dg-ingredient-analyzer'
                    ? '60px'
                    : '90px',
        };

        return logo;
    }

    onLinkClick(id: string, shouldOpenInNewTab: boolean = true): void {
        const url: string =
            id === EXTERNAL_POLICY_TEXT.toLocaleLowerCase().split(WHITESPACE).join('-')
                ? EXTERNAL_POLICY_LINK
                : `/legal/${id}`;

        if (shouldOpenInNewTab) {
            window.open(url, '_blank');
        } else {
            this.router.navigate([url]);
        }
    }
}
