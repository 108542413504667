/** third-party imports */
import { createReducer, on, Action, ActionReducer } from '@ngrx/store';

/** custom imports */
import * as layoutActions from './layout.actions';
import { LayoutState } from './layout-state.interface';

export const initialState: LayoutState = {
    isFooterCopyrightDisplayed: true,
    isTermsOfUseAcceptanceEnabled: false,
};

const layoutReducer: ActionReducer<LayoutState, Action> = createReducer(
    initialState,
    on(
        layoutActions.updateFooterCopyrightDisplay,
        (state: LayoutState, { isDisplayed }: { isDisplayed: boolean }) => ({
            ...state,
            isFooterCopyrightDisplayed: isDisplayed,
        }),
    ),
    on(
        layoutActions.updateTermsOfUseAcceptanceStatus,
        (state: LayoutState, { isEnabled }: { isEnabled: boolean }) => ({
            ...state,
            isTermsOfUseAcceptanceEnabled: isEnabled,
        }),
    ),
);

export const reducer = (state: LayoutState | undefined, action: Action): LayoutState =>
    layoutReducer(state, action);

// selectors
export const getFooterCopyrightDisplay: (state: LayoutState) => boolean = (state: LayoutState) =>
    state.isFooterCopyrightDisplayed;
export const getTermsOfUseAcceptanceStatus: (state: LayoutState) => boolean = (
    state: LayoutState,
) => state.isTermsOfUseAcceptanceEnabled;
