import { Injectable } from '@angular/core';
import { OpenDiscoveryParser } from '../../discovery/open/parsers/open-discovery.parser';
import { ClosedDiscoveryParser } from '../../discovery/closed/parsers/closed-discovery.parser';
import { CombinatorialDiscoveryParser } from '../../discovery/combinatorial/parsers/combinatorial-discovery.parser';

/** Interfaces */
import MessageRestApi from '../rest-api-interfaces/message.rest.interface';
import Message from '../interfaces/message.interface';
import MessageDataRestApi from '../rest-api-interfaces/message-data.rest.interface';
import MessageData from '../interfaces/message-data.interface';
import Discovery from '@apps/leap/src/app/shared/enums/discovery.enum';
import OpenDiscoveryInsightRestApi from '@leap-store/core/src/lib/data/discovery/open/rest-api-interfaces/insight.rest.interface';
import OpenDiscoveryInsight from '@leap-store/core/src/lib/data/discovery/open/interfaces/insight.interface';
import ClosedDiscoveryInsightRestApi from '@leap-store/core/src/lib/data/discovery/closed/rest-api-interfaces/insight.rest.interface';
import ClosedDiscoveryInsight from '@leap-store/core/src/lib/data/discovery/closed/interfaces/insight.interface';
import CombinatorialDiscoveryInsightRestApi from '@leap-store/core/src/lib/data/discovery/combinatorial/rest-api-interfaces/insight.rest.interface';
import CombinatorialDiscoveryInsight from '@leap-store/core/src/lib/data/discovery/combinatorial/interfaces/insight.interface';
import FeedbackArticleOrigin from '@apps/leap/src/app/shared/modules/feedback/enums/feedback-article-origin.enum';

@Injectable()
export class NotificationsParser {
    constructor(
        private openDiscoveryInsightsParser: OpenDiscoveryParser,
        private closedDiscoveryInsightsParser: ClosedDiscoveryParser,
        private combinatorialDiscoveryInsightsParser: CombinatorialDiscoveryParser,
    ) {}

    parseMessage(message: MessageRestApi): Message {
        return {
            id: message.uuid,
            userId: message.user_email,
            data: message.data ? this.parseMessageData(message.data) : undefined,
            notificationName: message.notification_name,
            sentAt: message.sent_at,
            extraRecipients: message.extra_recipients,
        };
    }

    parseMessageData(data: MessageDataRestApi): MessageData {
        return {
            message: data.additional_info,
            consent: data.user_agrees_on_feedback === 'Yes',
        };
    }

    serializeMessageData(data: MessageData): MessageDataRestApi {
        let serializedInsight:
            | OpenDiscoveryInsightRestApi
            | ClosedDiscoveryInsightRestApi
            | CombinatorialDiscoveryInsightRestApi;

        if (data.discovery?.insight) {
            if (data.discovery?.type === Discovery.open) {
                serializedInsight = this.openDiscoveryInsightsParser.serializeInsight(
                    data.discovery?.insight as OpenDiscoveryInsight,
                );
            }

            if (data.discovery?.type === Discovery.closed) {
                serializedInsight = this.closedDiscoveryInsightsParser.serializeInsight(
                    data.discovery?.insight as ClosedDiscoveryInsight,
                );
            }

            if (data.discovery?.type === Discovery.combinatorial) {
                serializedInsight = this.combinatorialDiscoveryInsightsParser.serializeInsight(
                    data.discovery?.insight as CombinatorialDiscoveryInsight,
                );
            }
        }

        return {
            additional_info: data.message,
            user_agrees_on_feedback: data.consent ? 'Yes' : 'No',
            analytics_mode:
                data.discovery?.type === Discovery.open
                    ? 'Open Discovery'
                    : data.discovery?.type === Discovery.closed
                    ? 'Closed Discovery'
                    : data.discovery?.type === Discovery.combinatorial
                    ? 'Combinatorial Discovery'
                    : data.article?.origin === FeedbackArticleOrigin.search
                    ? 'Articles Search'
                    : undefined,
            dropdown_selection: data.feedbackTheme ? data.feedbackTheme : undefined,
            source: data.discovery?.searchSource ? data.discovery?.searchSource : undefined,
            intermediate: data.article
                ? data.discovery?.type === Discovery.open
                    ? (data.discovery?.insight as OpenDiscoveryInsight)?.targetName
                    : data.discovery?.type === Discovery.closed
                    ? (data.discovery?.insight as ClosedDiscoveryInsight)?.intermediateName
                    : undefined
                : undefined,
            target: data.discovery?.searchTarget
                ? data.discovery?.searchTarget2
                    ? `${data.discovery?.searchTarget}||${data.discovery?.searchTarget2}`
                    : data.discovery?.searchTarget
                : undefined,
            insight: data.discovery?.insight && !data.article ? serializedInsight : undefined,
            article_title: data.article?.title || undefined,
            PMID: data.article?.externalId || undefined,
            query: data.article?.query || undefined,
        };
    }
}
