export enum CompoundReportActionTypes {
    GET_OVERVIEW_REQUEST = '[COMPOUND REPORT][OVERVIEW] Get Overview Request',
    GET_OVERVIEW_SUCCESS = '[COMPOUND REPORT][OVERVIEW] Get Overview Success',
    GET_OVERVIEW_FAILURE = '[COMPOUND REPORT][OVERVIEW] Get Overview Failure',
    GET_EFFECT_SENTENCES_REQUEST = '[COMPOUND REPORT][EFFECTS] Get Effect Sentences Request',
    GET_EFFECT_SENTENCES_SUCCESS = '[COMPOUND REPORT][EFFECTS] Get Effect Sentences Success',
    GET_EFFECT_SENTENCES_FAILURE = '[COMPOUND REPORT][EFFECTS] Get Effect Sentences Failure',
    GET_HEALTH_LABELS_REQUEST = '[COMPOUND REPORT][HEALTH LABELS] Get Health Labels Request',
    GET_HEALTH_LABELS_SUCCESS = '[COMPOUND REPORT][HEALTH LABELS] Get Health Labels Success',
    GET_HEALTH_LABELS_FAILURE = '[COMPOUND REPORT][HEALTH LABELS] Get Health Labels Failure',
    GET_HEALTH_LABEL_SENTENCES_REQUEST = '[COMPOUND REPORT][HEALTH LABELS] Get Health Label Sentences Request',
    GET_HEALTH_LABEL_SENTENCES_SUCCESS = '[COMPOUND REPORT][HEALTH LABELS] Get Health Label Sentences Success',
    GET_HEALTH_LABEL_SENTENCES_FAILURE = '[COMPOUND REPORT][HEALTH LABELS] Get Health Label Sentences Failure',
    GET_STUDY_TYPES_REQUEST = '[COMPOUND REPORT][STUDY TYPES] Get Study Types Request',
    GET_STUDY_TYPES_SUCCESS = '[COMPOUND REPORT][STUDY TYPES] Get Study Types Success',
    GET_STUDY_TYPES_FAILURE = '[COMPOUND REPORT][STUDY TYPES] Get Study Types Failure',
    GET_STUDY_TYPE_REQUEST = '[COMPOUND REPORT][STUDY TYPES] Get Study Type Request',
    GET_STUDY_TYPE_SUCCESS = '[COMPOUND REPORT][STUDY TYPES] Get Study Type Success',
    GET_STUDY_TYPE_FAILURE = '[COMPOUND REPORT][STUDY TYPES] Get Study Type Failure',
    DOWNLOAD_REQUEST = '[COMPOUND REPORT][DOWNLOAD] Download Report Request',
    DOWNLOAD_SUCCESS = '[COMPOUND REPORT][DOWNLOAD] Download Report Success',
    DOWNLOAD_FAILURE = '[COMPOUND REPORT][DOWNLOAD] Download Report Failure',
    RESET_REPORT = '[COMPOUND REPORT] Reset Report',
    CLEAR_NEXT_ERROR = '[COMPOUND REPORT] Clear Next Error',
}
