import { createSelector } from '@ngrx/store';
import { getUIState } from '../index';
import { UIState } from '../ui-state.interface';
import * as fromIngredientAnalyzer from './ingredient-analyzer.reducer';

export const getIngredientAnalyzerState = createSelector(
    getUIState,
    (state: UIState) => state.ingredientAnalyzer,
);

export const getSelectedIngredient = createSelector(
    getIngredientAnalyzerState,
    fromIngredientAnalyzer.getSelectedIngredient,
);

export const getBaselineIngredient = createSelector(
    getIngredientAnalyzerState,
    fromIngredientAnalyzer.getBaselineIngredient,
);

export const getSelectedIngredients = createSelector(
    getIngredientAnalyzerState,
    fromIngredientAnalyzer.getSelectedIngredients,
);
