import { Injectable } from '@angular/core';
import { mapKeys } from 'lodash';

/** Constants */
import { SERIALIZED_UNCLASSIFIED_EFFECT } from '@leap-store/core/src/lib/data/compound-report/constants/compound-report';

/** Interfaces - Enums */
import FilterCounts from '../modules/filters/interfaces/filter-counts.interface';
import Effect from '@leap-store/core/src/lib/data/ingredient-profiler/enums/effect.enum';

@Injectable()
export class EffectsParser {
    constructor() {}

    parseEffects(effects?: string[]): Effect[] {
        return effects?.map((effect: string) => this.parseEffect(effect)) || [];
    }

    parseEffect(effect: string): Effect {
        return effect === SERIALIZED_UNCLASSIFIED_EFFECT ? Effect.unclassified : (effect as Effect);
    }

    parseEffectsCounts(effectsCounts: Record<string, FilterCounts>): Record<string, FilterCounts> {
        return mapKeys(effectsCounts, (counts: FilterCounts, effect: string) =>
            this.parseEffect(effect),
        );
    }

    serializeEffects(effects: Effect[]): string[] {
        return effects?.map((effect: Effect) => this.serializeEffect(effect)) || [];
    }

    serializeEffect(effect: Effect): string {
        return effect === Effect.unclassified ? SERIALIZED_UNCLASSIFIED_EFFECT : effect;
    }
}
