import { getComputedPropertyValue } from '@leap-common/utilities/helpers';

export const COLOR_GREY: Record<string, string> = {
    50: getComputedPropertyValue('--grey-50'),
    100: getComputedPropertyValue('--grey-100'),
    200: getComputedPropertyValue('--grey-200'),
    300: getComputedPropertyValue('--grey-300'),
    400: getComputedPropertyValue('--grey-400'),
    500: getComputedPropertyValue('--grey-500'),
    600: getComputedPropertyValue('--grey-600'),
    700: getComputedPropertyValue('--grey-700'),
    800: getComputedPropertyValue('--grey-800'),
    900: getComputedPropertyValue('--grey-900'),
};

export const COLOR_TEXT_PRIMARY: string = COLOR_GREY[900];
export const COLOR_TEXT_SECONDARY: string = COLOR_GREY[600];

export const COLOR_INFORMATIVE_INFO: string = getComputedPropertyValue('--informative-info');
export const COLOR_INFORMATIVE_SUCCESS: string = getComputedPropertyValue('--informative-success');
export const COLOR_INFORMATIVE_WARNING: string = getComputedPropertyValue('--informative-warning');
export const COLOR_INFORMATIVE_DANGER: string = getComputedPropertyValue('--informative-danger');
