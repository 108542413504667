import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { RoleService } from '../services/role.service';

@Injectable()
export class RoleGuard implements CanActivate {
    constructor(private roleService: RoleService, private router: Router) {}

    /**
     * Activates the protected route based on the roleService.hasUserAdministratorPrivileges().
     * If the user has not administrator privileges re-route them to the /discovery route.
     */
    canActivate(): Observable<boolean> {
        return this.roleService.hasUserAdministratorPrivileges().pipe(
            map((hasUserAdministratorPrivileges: boolean) => {
                if (hasUserAdministratorPrivileges) {
                    return true;
                }

                this.router.navigate(['/discovery']);
                return false;
            }),
        );
    }
}
