import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

/** custom imports */
import { ClosedDiscoveryInsightsService } from './service';
import Insight from '@leap-store/core/src/lib/data/discovery/closed/interfaces/insight.interface';
import InsightsPerCategory from '@leap-store/core/src/lib/data/discovery/closed/types/insights-per-category.type';
import Metadata from '@leap-store/core/src/lib/data/discovery/closed/interfaces/metadata.interface';
import SortingOptions from '@leap-common/interfaces/sorting-options.interface';
import Filter from '@apps/leap/src/app/shared/modules/filters/interfaces/filter.interface';
import InsightRelationshipType from '@apps/leap/src/app/shared/types/discovery-insight-relationship-type.type';
import InsightsPerAssociation from '@apps/leap/src/app/shared/types/insights-per-association.type';
import InsightsPerAssociationType from '@apps/leap/src/app/shared/types/insights-per-association-type.type';
import InsightsPerDatabase from '@apps/leap/src/app/shared/types/insights-per-database.type';
import InsightsPerTag from '@apps/leap/src/app/shared/types/insights-per-tag.type';
import InsightsPerHealthLabel from '@apps/leap/src/app/shared/types/insights-per-health-label.type';
import InsightsPerStudyType from '@apps/leap/src/app/shared/types/insights-per-study-type.type';
import InsightsPerJournal from '@apps/leap/src/app/shared/types/insights-per-journal.type';
import InsightsPerMolecule from '@apps/leap/src/app/shared/types/insights-per-molecule.type';
import InsightsPerLab from '@apps/leap/src/app/shared/types/insights-per-lab.type';
import InsightsPerRelationshipType from '@apps/leap/src/app/shared/types/insights-per-relationship-type.type';
import InsightsPerRelationshipTypeOrigin from '@apps/leap/src/app/shared/types/insights-per-relationship-type-origin.type';

@Injectable()
export class ClosedDiscoveryInsightsFacade {
    subject: BehaviorSubject<Insight[] | null> = new BehaviorSubject(null);
    filteredSubject: BehaviorSubject<PouchDB.Core.ExistingDocument<Insight>[] | null> =
        new BehaviorSubject(null);
    displayingSubject: BehaviorSubject<number> = new BehaviorSubject(null);
    totalSubject: BehaviorSubject<number> = new BehaviorSubject(null);
    countPerCategorySubject: BehaviorSubject<InsightsPerCategory> = new BehaviorSubject(null);
    countPerCategoryNewSubject: BehaviorSubject<Record<string, number>> = new BehaviorSubject(null);
    countPerSubcategorySubject: BehaviorSubject<Record<string, number>> = new BehaviorSubject(null);
    countPerAssociationSubject: BehaviorSubject<InsightsPerAssociation> = new BehaviorSubject(null);
    countPerAssociationTypeSubject: BehaviorSubject<InsightsPerAssociationType> =
        new BehaviorSubject(null);
    countPerDatabaseSubject: BehaviorSubject<InsightsPerDatabase> = new BehaviorSubject(null);
    countPerTagSubject: BehaviorSubject<InsightsPerTag> = new BehaviorSubject(null);
    countPerHealthLabelSubject: BehaviorSubject<InsightsPerHealthLabel> = new BehaviorSubject(null);
    countPerStudyTypeSubject: BehaviorSubject<InsightsPerStudyType> = new BehaviorSubject(null);
    countPerJournalSubject: BehaviorSubject<InsightsPerJournal> = new BehaviorSubject(null);
    countPerMoleculeSubject: BehaviorSubject<InsightsPerMolecule> = new BehaviorSubject(null);
    countPerLabSubject: BehaviorSubject<InsightsPerLab> = new BehaviorSubject(null);
    countPerRelationshipTypeSubject: BehaviorSubject<InsightsPerRelationshipType> =
        new BehaviorSubject(null);
    countPerRelationshipTypeOriginSubject: BehaviorSubject<InsightsPerRelationshipTypeOrigin> =
        new BehaviorSubject(null);
    oldestOccurrenceSubject: BehaviorSubject<number> = new BehaviorSubject(null);
    newestOccurrenceSubject: BehaviorSubject<number> = new BehaviorSubject(null);
    totalCategoriesSubject: BehaviorSubject<string[]> = new BehaviorSubject([]);
    totalSubcategoriesSubject: BehaviorSubject<string[]> = new BehaviorSubject([]);
    totalRelationshipsSubject: BehaviorSubject<InsightRelationshipType[]> = new BehaviorSubject([]);
    totalDatabasesSubject: BehaviorSubject<string[]> = new BehaviorSubject([]);
    totalTagsSubject: BehaviorSubject<string[]> = new BehaviorSubject([]);
    totalHealthLabelsSubject: BehaviorSubject<string[]> = new BehaviorSubject([]);
    totalJournalsSubject: BehaviorSubject<string[]> = new BehaviorSubject([]);
    totalMoleculesSubject: BehaviorSubject<string[]> = new BehaviorSubject([]);
    groupedCategoriesSubject: BehaviorSubject<Record<string, string[]>> = new BehaviorSubject(null);
    isDiscoveryExecutionCompleteSubject: BehaviorSubject<boolean> = new BehaviorSubject(false);

    constructor(private service: ClosedDiscoveryInsightsService) {}

    /** Database */
    getOrCreatePouchDB(sourceId: string, targetId: string): Promise<boolean> {
        return this.service.getOrCreateDB(sourceId, targetId);
    }

    createPouchDB(sourceId: string, targetId: string): Promise<void> {
        return this.service.createDB(sourceId, targetId);
    }

    doesPouchDBExist(sourceId: string, targetId: string): Promise<boolean> {
        return this.service.doesDBExist(sourceId, targetId);
    }

    destroyPouchDB(sourceId: string, targetId: string): Promise<void> {
        return this.service.destroyDB(sourceId, targetId);
    }

    destroyPouchDBs(): Promise<void[]> {
        return this.service.destroyDBs();
    }

    resetDiscovery(): void {
        this.resetInsights();
        this.resetMetadata();
    }

    /** Insights */
    resetInsights(): void {
        this.subject.next(null);
        this.filteredSubject.next(null);
        this.isDiscoveryExecutionCompleteSubject.next(false);
    }

    async getInsights({
        limit,
        sortingOptions,
        filters,
    }: {
        limit?: number;
        sortingOptions?: SortingOptions;
        filters?: Record<string, Filter[] | [number, number]>;
    }): Promise<PouchDB.Find.FindResponse<unknown>> {
        const response: PouchDB.Find.FindResponse<any> = await this.service.retrieve({
            limit,
            sortingOptions,
            filters,
        });

        if (limit) {
            this.filteredSubject.next(response.docs);
        } else {
            this.subject.next(response.docs);
        }

        this.isDiscoveryExecutionCompleteSubject.next(true);

        return response;
    }

    storeInsights({
        insights,
        fields,
    }: {
        insights: Insight[];
        fields?: string[];
    }): Promise<(PouchDB.Core.Response | PouchDB.Core.Error)[]> {
        return this.service.populate({ insights, fields });
    }

    /** Metadata */
    addMetadata(metadata: Metadata): Promise<PouchDB.Core.Response> {
        return this.service.createMetadata(metadata);
    }

    async getMetadata(): Promise<void> {
        const response: PouchDB.Core.IdMeta & PouchDB.Core.GetMeta & Metadata =
            await this.service.retrieveMetadata();

        this.displayingSubject.next(response.displaying);
        this.totalSubject.next(response.total);
        this.countPerCategorySubject.next(response.countPerCategory);
        this.countPerCategoryNewSubject.next(response.countPerCategoryNew);
        this.countPerSubcategorySubject.next(response.countPerSubcategory);
        this.countPerAssociationSubject.next(response.countPerAssociation);
        this.countPerAssociationTypeSubject.next(response.countPerAssociationType);
        this.countPerDatabaseSubject.next(response.countPerDatabase);
        this.countPerTagSubject.next(response.countPerTag);
        this.countPerHealthLabelSubject.next(response.countPerHealthLabel);
        this.countPerStudyTypeSubject.next(response.countPerStudyType);
        this.countPerJournalSubject.next(response.countPerJournal);
        this.countPerMoleculeSubject.next(response.countPerMolecule);
        this.countPerLabSubject.next(response.countPerLab);
        this.countPerRelationshipTypeSubject.next(response.countPerRelationshipType);
        this.countPerRelationshipTypeOriginSubject.next(response.countPerRelationshipTypeOrigin);
        this.oldestOccurrenceSubject.next(response.oldestOccurrence);
        this.newestOccurrenceSubject.next(response.newestOccurrence);
        this.totalCategoriesSubject.next(response.totalCategories);
        this.totalSubcategoriesSubject.next(response.totalSubcategories);
        this.totalRelationshipsSubject.next(response.totalRelationships);
        this.totalDatabasesSubject.next(response.totalDatabases);
        this.totalTagsSubject.next(response.totalTags);
        this.totalHealthLabelsSubject.next(response.totalHealthLabels);
        this.totalJournalsSubject.next(response.totalJournals);
        this.totalMoleculesSubject.next(response.totalMolecules);
        this.groupedCategoriesSubject.next(response.groupedCategories);
    }

    resetMetadata(): void {
        this.displayingSubject.next(null);
        this.totalSubject.next(null);
        this.countPerCategorySubject.next(null);
        this.countPerCategoryNewSubject.next(null);
        this.countPerSubcategorySubject.next(null);
        this.countPerAssociationSubject.next(null);
        this.countPerAssociationTypeSubject.next(null);
        this.countPerDatabaseSubject.next(null);
        this.countPerTagSubject.next(null);
        this.countPerHealthLabelSubject.next(null);
        this.countPerStudyTypeSubject.next(null);
        this.countPerJournalSubject.next(null);
        this.countPerMoleculeSubject.next(null);
        this.countPerLabSubject.next(null);
        this.countPerRelationshipTypeSubject.next(null);
        this.countPerRelationshipTypeOriginSubject.next(null);
        this.oldestOccurrenceSubject.next(null);
        this.newestOccurrenceSubject.next(null);
        this.totalCategoriesSubject.next([]);
        this.totalSubcategoriesSubject.next([]);
        this.totalRelationshipsSubject.next([]);
        this.totalDatabasesSubject.next([]);
        this.totalTagsSubject.next([]);
        this.totalHealthLabelsSubject.next([]);
        this.totalJournalsSubject.next([]);
        this.totalMoleculesSubject.next([]);
        this.groupedCategoriesSubject.next(null);
    }
}
