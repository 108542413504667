import { createAction, props } from '@ngrx/store';
import { IngredientAnalyzerActionTypes } from './ingredient-analyzer-action-types.enum';
import Ingredient from '../../data/ingredient-viewer/interfaces/ingredient.interface';

export const updateSelectedIngredient = createAction(
    IngredientAnalyzerActionTypes.UPDATE_SELECTED_INGREDIENT,
    props<{ selectedIngredient: Ingredient }>(),
);

export const updateBaselineIngredient = createAction(
    IngredientAnalyzerActionTypes.UPDATE_BASELINE_INGREDIENT,
    props<{ baselineIngredient: Ingredient }>(),
);

export const updateSelectedIngredients = createAction(
    IngredientAnalyzerActionTypes.UPDATE_SELECTED_INGREDIENTS,
    props<{ selectedIngredients: Ingredient[] }>(),
);
