/** third-party imports */
import { createReducer, on, Action, ActionReducer } from '@ngrx/store';
import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { uniqueId, forIn, isEqual } from 'lodash';

/** custom imports */
import { AlertsState } from './alerts-state.interface';
import AlertEvent from './interfaces/alert-event.interface';
import * as alertActions from './alerts.actions';

export const adapter: EntityAdapter<AlertEvent> = createEntityAdapter<AlertEvent>();

export const initialState: AlertsState = adapter.getInitialState();

const alertsReducer: ActionReducer<AlertsState, Action> = createReducer(
    initialState,
    on(
        alertActions.pushAlertEvent,
        (state: AlertsState, { alertEvent }: { alertEvent: AlertEvent }) => {
            const alert: AlertEvent = {
                ...alertEvent,
                id: uniqueId('alert_'),
                timeout: alertEvent.timeout || 15000,
            };

            const areEqual: boolean = Object.values(state.entities).some((value: AlertEvent) =>
                isEqual(value.content, alertEvent.content),
            );

            // if a new alert is the same with an existing one, skip it
            return areEqual ? state : adapter.addOne(alert, state);
        },
    ),
    on(
        alertActions.removeAlertEvent,
        (
            state: AlertsState,
            { id, shouldRemovePersistent }: { id: string; shouldRemovePersistent: boolean },
        ) => {
            if (
                state.entities[id] &&
                (!state.entities[id].isPersistent || shouldRemovePersistent) &&
                state.entities[id].onClose
            ) {
                state.entities[id].onClose();
            }
            return !state.entities[id].isPersistent || shouldRemovePersistent
                ? adapter.removeOne(id, state)
                : state;
        },
    ),
    on(
        alertActions.clearAlertEvents,
        (state: AlertsState, { shouldRemovePersistent }: { shouldRemovePersistent: boolean }) => {
            forIn(
                state.entities,
                (alertEvent: AlertEvent) =>
                    (!alertEvent.isPersistent || shouldRemovePersistent) &&
                    alertEvent.onClose &&
                    alertEvent.onClose(),
            );
            return adapter.removeMany(
                (state.ids as string[]).filter(
                    (id: string) => !state.entities[id].isPersistent || shouldRemovePersistent,
                ),
                state,
            );
        },
    ),
);

export const reducer = (state: AlertsState | undefined, action: Action): AlertsState =>
    alertsReducer(state, action);

export const getEntities: (state: AlertsState) => AlertEvent[] = adapter.getSelectors().selectAll;
