import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { EMPTY_STRING } from '@leap-common/constants/common';

@Injectable()
export class HttpParamsService {
    constructor() {}

    /**
     * It is used to avoid sending undefined (optional) parameters through HTTP requests.
     * It gets a set of parameters and checks whether their values exist or not.
     * - If the value exists and is not an array, it appends it to the object to be returned.
     * - If the value is an array, it appends each element of the array as a separate key-value pair in the resulting object.
     * - If the value does not exist (is undefined, null or an empty string), it skips it (does not return it).
     */
    createHttpParams(
        params: Record<string, string | number | boolean | string[]> = {},
    ): HttpParams {
        let httpParams: HttpParams = new HttpParams();
        Object.keys(params).forEach((param: string) => {
            const value: string | number | boolean | string[] = params[param];
            if (Array.isArray(value)) {
                // append each item in the array as a separate query parameter
                value.forEach((item: string | number | boolean) => {
                    httpParams = httpParams.append(param, item);
                });
            } else if (value !== undefined && value !== null && value !== EMPTY_STRING) {
                // append single non-array values
                httpParams = httpParams.set(param, value.toString());
            }
        });

        return httpParams;
    }
}
