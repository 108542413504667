import { createSelector } from '@ngrx/store';
import { DataState } from '../../data-state.interface';
import { getDataState } from '../../index';
import * as fromPatents from './patents.reducer';

export const getPatentsState = createSelector(
    getDataState,
    (state: DataState) => state.discovery.patents,
);

export const getPatents = createSelector(getPatentsState, fromPatents.getPatents);
export const getDateRange = createSelector(getPatentsState, fromPatents.getDateRange);
export const getFilteredTotal = createSelector(getPatentsState, fromPatents.getFilteredTotal);
export const getErrors = createSelector(getPatentsState, fromPatents.getErrors);
export const getLoading = createSelector(getPatentsState, fromPatents.getLoading);
export const getLoaded = createSelector(getPatentsState, fromPatents.getLoaded);
export const getBlob = createSelector(getPatentsState, fromPatents.getBlob);
