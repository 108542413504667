import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';

/** custom imports */
import { reducers } from '..';
import { MetadataEffects } from './metadata.effects';
import { MetadataFacade } from './metadata.facade';
import { MetadataParser } from './parsers/metadata.parser';
import { MetadataService } from './services/metadata.service';

@NgModule({
    imports: [
        CommonModule,
        HttpClientModule,
        StoreModule.forRoot(reducers),
        StoreDevtoolsModule.instrument({ maxAge: 10 }),
        EffectsModule.forRoot([MetadataEffects]),
    ],
    declarations: [],
    providers: [MetadataFacade, MetadataParser, MetadataService],
})
export class MetadataStoreModule {}
