/** third-party imports */
import { createSelector } from '@ngrx/store';

/** custom imports */
import { DataState } from '../data-state.interface';
import { getDataState } from '../index';
import * as fromIngredientComparison from './ingredient-comparison.reducer';

export const getIngredientComparisonState = createSelector(
    getDataState,
    (state: DataState) => state.ingredientComparison,
);

export const getErrors = createSelector(
    getIngredientComparisonState,
    fromIngredientComparison.getErrors,
);
export const getIngredients = createSelector(
    getIngredientComparisonState,
    fromIngredientComparison.getIngredients,
);
export const getIngredientsLoading = createSelector(
    getIngredientComparisonState,
    fromIngredientComparison.getIngredientsLoading,
);
export const getIngredientsLoaded = createSelector(
    getIngredientComparisonState,
    fromIngredientComparison.getIngredientsLoaded,
);
export const getBioactivity = createSelector(
    getIngredientComparisonState,
    fromIngredientComparison.getBioactivity,
);
export const getBioactivityLoading = createSelector(
    getIngredientComparisonState,
    fromIngredientComparison.getBioactivityLoading,
);
export const getBioactivityLoaded = createSelector(
    getIngredientComparisonState,
    fromIngredientComparison.getBioactivityLoaded,
);

export const getAminoAcids = createSelector(
    getIngredientComparisonState,
    fromIngredientComparison.getAminoAcids,
);
export const getAminoAcidsLoading = createSelector(
    getIngredientComparisonState,
    fromIngredientComparison.getAminoAcidsLoading,
);
export const getAminoAcidsLoaded = createSelector(
    getIngredientComparisonState,
    fromIngredientComparison.getAminoAcidsLoaded,
);
export const getMolecularWeights = createSelector(
    getIngredientComparisonState,
    fromIngredientComparison.getMolecularWeights,
);
export const getMolecularWeightsLoading = createSelector(
    getIngredientComparisonState,
    fromIngredientComparison.getMolecularWeightsLoading,
);
export const getMolecularWeightsLoaded = createSelector(
    getIngredientComparisonState,
    fromIngredientComparison.getMolecularWeightsLoaded,
);
