/** third-party imports */
import { createReducer, on, Action, ActionReducer } from '@ngrx/store';
import { HttpErrorResponse } from '@angular/common/http';
import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';

/** custom imports */
import * as filesActions from './files.actions';
import { FilesState } from './files-state.interface';
import EnhancedFile from './interfaces/enhanced-file.interface';
import FileStatus from './enums/file-status.enum';
import PaginatedResults from '@leap-common/interfaces/paginated-results.interface';
import File from '@leap-store/core/src/lib/data/files/interfaces/file.interface';
import FileMessages from './enums/file-messages.enum';
import AbortSettings from './interfaces/abort-settings.interface';
import ErrorResponse from '@leap-common/interfaces/error-response.interface';
import SortingOrder from '@leap-common/enums/sorting-order.enum';

const initialFileToUpload: EnhancedFile = { file: null };

export const adapter: EntityAdapter<File> = createEntityAdapter<File>();

export const initialState: FilesState = adapter.getInitialState({
    fileToUpload: initialFileToUpload,
    errors: [],
    loading: false,
    loaded: false,
    pageIndex: 0,
    pageSize: 0,
    total: 0,
    sortDirection: SortingOrder.descending,
    sortColumn: 'creationDate',
    shouldFetchFiles: false,
    deletePendingItems: [],
});

const filesReducer: ActionReducer<FilesState, Action> = createReducer(
    initialState,
    on(filesActions.stageFileToUpload, (state: FilesState, { file }: { file: File }) => ({
        ...state,
        fileToUpload: {
            ...state.fileToUpload,
            file,
            status: FileStatus.staged,
            uploadProgress: 0,
        },
    })),
    on(filesActions.unstageFileToUpload, (state: FilesState) => ({
        ...state,
        fileToUpload: { ...initialFileToUpload },
    })),
    on(
        filesActions.createFilePathRequest,
        // Additional parameters: file, onProgress
        (state: FilesState) => ({
            ...state,
            fileToUpload: {
                ...state.fileToUpload,
                status: FileStatus.initializing,
                messages: [FileMessages.UPLOAD_INITIALIZATION],
            },
        }),
    ),
    on(
        filesActions.createFilePathSuccess,
        (state: FilesState, { sas, id }: { sas: string; id: string }) => ({
            ...state,
            fileToUpload: {
                ...state.fileToUpload,
                sas,
                id,
                status: '',
                messages: [''],
            },
        }),
    ),
    on(
        filesActions.createFilePathFailure,
        (state: FilesState, { errorResponse }: { errorResponse: HttpErrorResponse }) => ({
            ...state,
            fileToUpload: {
                ...state.fileToUpload,
                status: FileStatus.error,
                messages: [errorResponse.error.errors[0].message],
            },
        }),
    ),
    on(
        filesActions.uploadFileRequest,
        // additional params are: sas, id, file, onProgress
        (state: FilesState, { abortSettings }: { abortSettings: AbortSettings }) => ({
            ...state,
            fileToUpload: {
                ...state.fileToUpload,
                status: FileStatus.uploading,
                messages: [''],
                abortSettings,
            },
        }),
    ),
    on(filesActions.uploadFileSuccess, (state: FilesState) => ({
        ...state,
        fileToUpload: {
            ...state.fileToUpload,
            status: FileStatus.success,
            messages: [''],
            abortSettings: null as AbortSettings,
        },
    })),
    on(filesActions.uploadFileFailure, (state: FilesState) => ({
        ...state,
        fileToUpload: {
            ...state.fileToUpload,
            status: FileStatus.retry,
            messages: [FileMessages.UPLOAD_FAILURE],
            abortController: null as AbortSettings,
        },
    })),
    on(
        filesActions.updateUploadProgress,
        (state: FilesState, { loadedBytes }: { loadedBytes: number }) => {
            const percentage: number =
                state.fileToUpload.file && (loadedBytes / state.fileToUpload.file.size) * 100;
            const roundedPercentage: string = (Math.round(percentage * 100) / 100).toFixed(0);
            return {
                ...state,
                fileToUpload: {
                    ...state.fileToUpload,
                    uploadProgress: parseInt(roundedPercentage, 10),
                },
            };
        },
    ),
    on(filesActions.cancelFileUpload, (state: FilesState) => {
        state.fileToUpload.abortSettings.controller.abort();
        return {
            ...state,
            fileToUpload: { ...state.fileToUpload, uploadProgress: 0 },
        };
    }),
    on(filesActions.getFilesRequest, (state: FilesState) => ({
        ...state,
        loading: true,
        loaded: false,
    })),
    on(
        filesActions.getFilesSuccess,
        (
            state: FilesState,
            {
                paginatedFiles,
                sortDirection,
                sortColumn,
            }: {
                paginatedFiles: PaginatedResults<File>;
                sortDirection: SortingOrder;
                sortColumn: string;
            },
        ) =>
            adapter.setAll(paginatedFiles.results, {
                ...state,
                pageIndex: paginatedFiles.pageIndex,
                pageSize: paginatedFiles.pageSize,
                total: paginatedFiles.total,
                sortDirection,
                sortColumn,
                loading: false,
                loaded: true,
            }),
    ),
    on(
        filesActions.getFilesFailure,
        (state: FilesState, { errorResponse }: { errorResponse: HttpErrorResponse }) => ({
            ...state,
            errors: [...state.errors, errorResponse.error],
            loading: false,
            loaded: false,
        }),
    ),
    on(filesActions.deleteFilePathRequest, (state: FilesState) => ({ ...state })),
    on(filesActions.deleteFilePathSuccess, (state: FilesState) => ({ ...state })),
    on(
        filesActions.deleteFilePathFailure,
        // additional parameters: errorResponse
        (state: FilesState) => ({
            ...state,
        }),
    ),
    on(filesActions.deleteFileRequest, (state: FilesState, { id }: { id: string }) => ({
        ...state,
        deletePendingItems: [...state.deletePendingItems, id],
    })),
    on(filesActions.deleteFileSuccess, (state: FilesState, { id }: { id: string }) =>
        adapter.removeOne(id, {
            ...state,
            shouldFetchFiles: true,
            deletePendingItems: state.deletePendingItems.filter(
                (pendingItem: string) => pendingItem !== id,
            ),
            fileToUpload:
                state.fileToUpload.id === id
                    ? {
                          ...initialFileToUpload,
                          uploadProgress: 0,
                      }
                    : state.fileToUpload,
        }),
    ),
    on(
        filesActions.deleteFileFailure,
        (
            state: FilesState,
            { id, errorResponse }: { id: string; errorResponse: HttpErrorResponse },
        ) => ({
            ...state,
            errors: [...state.errors, errorResponse.error],
            shouldFetchFiles: false,
            deletePendingItems: state.deletePendingItems.filter(
                (pendingItem: string) => pendingItem !== id,
            ),
        }),
    ),
    on(filesActions.clearNextError, (state: FilesState) => ({
        ...state,
        errors: state.errors.slice(1),
    })),
    on(filesActions.clearShouldFetchFiles, (state: FilesState) => ({
        ...state,
        shouldFetchFiles: false,
    })),
);

export const reducer = (state: FilesState | undefined, action: Action): FilesState =>
    filesReducer(state, action);

// selectors
export const getFileToUpload: (state: FilesState) => EnhancedFile = (state: FilesState) =>
    state.fileToUpload;
export const getFileEntities: (state: FilesState) => File[] = adapter.getSelectors().selectAll;
export const getErrors: (state: FilesState) => ErrorResponse[] = (state: FilesState) =>
    state.errors;
export const getLoading: (state: FilesState) => boolean = (state: FilesState) => state.loading;
export const getLoaded: (state: FilesState) => boolean = (state: FilesState) => state.loaded;
export const getPageIndex: (state: FilesState) => number = (state: FilesState) => state.pageIndex;
export const getPageSize: (state: FilesState) => number = (state: FilesState) => state.pageSize;
export const getTotal: (state: FilesState) => number = (state: FilesState) => state.total;
export const getSortDirection: (state: FilesState) => SortingOrder = (state: FilesState) =>
    state.sortDirection;
export const getSortColumn: (state: FilesState) => string = (state: FilesState) => state.sortColumn;
export const getShouldFetchFiles: (state: FilesState) => boolean = (state: FilesState) =>
    state.shouldFetchFiles;
export const getDeletePendingItems: (state: FilesState) => string[] = (state: FilesState) =>
    state.deletePendingItems;
