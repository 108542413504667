/** third-party imports */
import { Injectable, TemplateRef } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { BehaviorSubject, Observable } from 'rxjs';

/** custom imports */
import { LayoutState } from './layout-state.interface';
import { updateFooterCopyrightDisplay, updateTermsOfUseAcceptanceStatus } from './layout.actions';
import { getFooterCopyrightDisplay, getTermsOfUseAcceptanceStatus } from './layout.selectors';

@Injectable()
export class LayoutFacade {
    isFooterCopyrightDisplayed$: Observable<boolean> = this.store.pipe(
        select(getFooterCopyrightDisplay),
    );
    isTermsOfUseAcceptanceEnabled$: Observable<boolean> = this.store.pipe(
        select(getTermsOfUseAcceptanceStatus),
    );

    // The mainMenu/footerCustomContent$ are added as BehaviorSubjects to prevent performance issues.
    // This is similar to what we do for example with the insights$ in the open-discovery facade.
    mainMenuCustomContent$: BehaviorSubject<TemplateRef<unknown>> = new BehaviorSubject<
        TemplateRef<unknown>
    >(null);
    footerCustomContent$: BehaviorSubject<TemplateRef<unknown>> = new BehaviorSubject<
        TemplateRef<unknown>
    >(null);

    constructor(private store: Store<LayoutState>) {}

    updateFooterCopyrightDisplay(isDisplayed: boolean): void {
        this.store.dispatch(updateFooterCopyrightDisplay({ isDisplayed }));
    }

    updateTermsOfUseAcceptanceStatus(isEnabled: boolean): void {
        this.store.dispatch(updateTermsOfUseAcceptanceStatus({ isEnabled }));
    }

    updateMainMenuCustomContent(content: TemplateRef<unknown>): void {
        this.mainMenuCustomContent$.next(content);
    }

    resetMainMenuCustomContent(): void {
        this.mainMenuCustomContent$.next(null);
    }

    updateFooterCustomContent(content: TemplateRef<unknown>): void {
        this.footerCustomContent$.next(content);
    }

    resetFooterCustomContent(): void {
        this.footerCustomContent$.next(null);
    }
}
