/** third-party imports */
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

/** custom imports */
import { environment } from '@environments/leap/environment';
import { PathsParser } from '../parsers/paths.parser';

/** Interfaces - Enums */
import PaginatedResultsRestApi from '@leap-common/rest-api-interfaces/paginated-results.rest.interface';
import PaginatedResults from '@leap-common/interfaces/paginated-results.interface';
import PathRestApi from '../rest-api-interfaces/path.rest.interface';
import ExplorationPath from '../interfaces/exploration-path.interface';
import MetapathBaseExplorationPath from '../../metapaths/interfaces/metapath-base-exploration-path.interface';
import CategoriesRestApi from '@leap-store/core/src/lib/data/metapaths/rest-api-interfaces/categories.rest.interface';
import SubcategoriesRestApi from '@leap-store/core/src/lib/data/metapaths/rest-api-interfaces/subcategories.rest.interface';
import RelationsRestApi from '@leap-store/core/src/lib/data/metapaths/rest-api-interfaces/relations.rest.interface';
import SortingOrder from '@leap-common/enums/sorting-order.enum';

@Injectable()
export class PathsService {
    constructor(private http: HttpClient, private pathsParser: PathsParser) {}

    /**
     * Gets paths, parses them into the desired format and
     * returns an Observable of PaginatedResults<ExplorationPath>.
     */
    getPaths(
        sourceId: string,
        targetId: string,
        metapathBaseExplorationPath: MetapathBaseExplorationPath,
        pageSize: number,
        pageIndex: number,
        sortingOrder: SortingOrder,
    ): Observable<PaginatedResults<ExplorationPath>> {
        const categories: CategoriesRestApi = this.pathsParser.serializeCategories(
            metapathBaseExplorationPath,
        );
        const subcategories: SubcategoriesRestApi = this.pathsParser.serializeSubcategories(
            metapathBaseExplorationPath,
        );
        const relations: RelationsRestApi = this.pathsParser.serializeRelations(
            metapathBaseExplorationPath,
        );

        return this.http
            .post(`${environment.metapathsServerUrl}/graphmeta/paths/`, {
                sourceUid: sourceId,
                targetUid: targetId,
                categories,
                subcategories,
                relations,
                pageSize,
                pageIndex: pageIndex + 1,
                sortingOrder,
            })
            .pipe(
                map((paginatedResults: PaginatedResultsRestApi<PathRestApi>) =>
                    this.pathsParser.parsePaginatedPaths(paginatedResults),
                ),
            );
    }

    downloadPaths(
        sourceId: string,
        targetId: string,
        metapathBaseExplorationPath: MetapathBaseExplorationPath,
        sortingOrder: SortingOrder,
    ): Observable<Blob> {
        const categories: CategoriesRestApi = this.pathsParser.serializeCategories(
            metapathBaseExplorationPath,
        );
        const subcategories: SubcategoriesRestApi = this.pathsParser.serializeSubcategories(
            metapathBaseExplorationPath,
        );
        const relations: RelationsRestApi = this.pathsParser.serializeRelations(
            metapathBaseExplorationPath,
        );

        return this.http.post(
            `${environment.metapathsServerUrl}/graphmeta/paths/download/`,
            {
                sourceUid: sourceId,
                targetUid: targetId,
                categories,
                subcategories,
                relations,
                sortingOrder,
            },
            {
                headers: new HttpHeaders({
                    Accept: 'text/csv',
                }),
                responseType: 'blob',
            },
        );
    }
}
