/** third-party imports */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

/** custom imports */
import { environment } from '@environments/leap/environment';
import { PipelineExecutionsParser } from '../parsers/pipeline-executions.parser';
import { FilesParser } from '@leap-store/core/src/lib/data/files/parsers/files.parser';

/** Interfaces */
import PaginatedResultsRestApi from '@leap-common/rest-api-interfaces/paginated-results.rest.interface';
import PaginatedResults from '@leap-common/interfaces/paginated-results.interface';
import PipelineExecutionRestApi from '../rest-api-interfaces/pipeline-execution.rest.interface';
import PipelineExecution from '@leap-store/core/src/lib/data/pipeline-executions/interfaces/pipeline-execution.interface';
import File from '@leap-store/core/src/lib/data/files/interfaces/file.interface';
import FileRestApi from '@leap-store/core/src/lib/data/files/rest-api-interfaces/file.rest.interface';
import SortingOrder from '@leap-common/enums/sorting-order.enum';

@Injectable()
export class PipelineExecutionsService {
    constructor(
        private http: HttpClient,
        private pipelineExecutionsParser: PipelineExecutionsParser,
        private fileParser: FilesParser,
    ) {}

    /**
     * Gets user pipeline executions, parses them into the desired format and
     * returns an Observable of PaginatedResults<PipelineExecution>.
     */
    getPipelineExecutions(
        pageIndex: number,
        pageSize: number,
        sortDirection: SortingOrder,
        sortColumn: string,
    ): Observable<PaginatedResults<PipelineExecution>> {
        const serializedOrder: string =
            this.pipelineExecutionsParser.serializePipelineExecutionsOrderByParameter(
                sortDirection,
                sortColumn,
            );

        return this.http
            .get(`${environment.pipelineExecutionsServerUrl}/workflow-executions/`, {
                params: {
                    pageIndex: (pageIndex + 1).toString(),
                    pageSize: pageSize.toString(),
                    orderBy: serializedOrder,
                },
            })
            .pipe(
                map((paginatedResults: PaginatedResultsRestApi<PipelineExecutionRestApi>) =>
                    this.pipelineExecutionsParser.parsePaginatedResults(paginatedResults),
                ),
            );
    }

    /**
     * Gets available upload from server, then parses it and returns an observable with the
     * download url.
     */
    getPipelineResult(id: string): Observable<File> {
        return this.http
            .get(`${environment.uploadServerUrl}/uploads/${id}/?sas_type=download`)
            .pipe(map((file: FileRestApi) => this.fileParser.parseFile(file)));
    }
}
