import { Injectable } from '@angular/core';

/** Constants */
import { FOOD_CATEGORY } from '@apps/leap/src/app/shared/constants/discovery';

/** Services - Parsers */
import { ArrayHandlerService } from '@leap-common/services/array-handler.service';
import { InsightsService } from '@apps/leap/src/app/shared/services/insights.service';
import { InsightParser } from '@apps/leap/src/app/shared/parsers/insight.parser';

/** Interfaces */
import SuggestionRestApi from '@apps/leap/src/app/shared/rest-api-interfaces/suggestion.rest.interface';
import Suggestion from '@apps/leap/src/app/shared/interfaces/suggestion.interface';

@Injectable()
export class SuggestionsParser {
    constructor(
        private arrayHandlerService: ArrayHandlerService,
        private insightsService: InsightsService,
        private insightParser: InsightParser,
    ) {}

    /** Parses BE Suggestions to integrate them on the FE */
    parseSuggestions(suggestions: SuggestionRestApi[]): Suggestion[] {
        return suggestions.map((suggestion: SuggestionRestApi) => this.parseSuggestion(suggestion));
    }

    /** Parses SuggestionRestApi to Suggestion */
    parseSuggestion(suggestion: SuggestionRestApi): Suggestion {
        return {
            id: suggestion.uid,
            term: suggestion.term,
            categories: suggestion.categories
                ? this.insightParser
                      .parseCategories(suggestion.categories)
                      .sort(this.arrayHandlerService.getSortOrder([FOOD_CATEGORY]))
                : null,
            subcategories: suggestion.categories
                ? this.insightParser.parseSubcategories(suggestion.categories)
                : null,
            groupedCategories: suggestion.categories
                ? this.insightsService.getGroupedCategories([suggestion.categories])
                : null,
            synonyms: suggestion.synonyms || null,
        };
    }
}
