import { Component, Input } from '@angular/core';

/** Constants */
import { CHECKBOX_DEFAULT_FONT_SIZE } from '../../constants/checkbox';

@Component({
    selector: 'lib-checkbox',
    templateUrl: 'checkbox.component.html',
    styleUrls: ['checkbox.component.scss'],
})
export class CheckboxComponent {
    @Input() isActive: boolean;
    @Input() isIndeterminate: boolean;
    @Input() isDisabled: boolean;
    @Input() isPrimary: boolean;
    @Input() fontSize: number = CHECKBOX_DEFAULT_FONT_SIZE;

    constructor() {}

    onClick(event: Event): void {
        if (!this.isDisabled) {
            return;
        }

        event.stopPropagation();
    }
}
