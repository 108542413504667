/** Modules */
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { LoginRoutingModule } from './login-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbAlertModule } from '@ng-bootstrap/ng-bootstrap';
import { FormModule } from '@leap-libs/form/src/public-api';
import { PipesModule } from '@leap-libs/pipes/src/public-api';
import { DecoratedInputModule } from '@leap-libs/decorated-input/src/public-api';
import { InputModule } from '@leap-libs/input/src/public-api';
import { LegalModule } from '../../legal/legal.module';

/** Services */
import { ErrorHandlerService } from '@leap-common/services/error-handler.service';

/** Components */
import { LoginContainerComponent } from './components/containers/login/login.container.component';
import { LoginFormViewComponent } from './components/views/login-form/login-form.view.component';

@NgModule({
    imports: [
        CommonModule,
        LoginRoutingModule,
        HttpClientModule,
        NgbAlertModule,
        FormsModule,
        ReactiveFormsModule,
        FormModule,
        PipesModule,
        DecoratedInputModule,
        InputModule,
        LegalModule,
    ],
    declarations: [LoginContainerComponent, LoginFormViewComponent],
    providers: [ErrorHandlerService],
})
export class LoginModule {}
