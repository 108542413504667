import { createAction, props, ActionCreator } from '@ngrx/store';
import { TypedAction } from '@ngrx/store/src/models';
import { HttpErrorResponse } from '@angular/common/http';
import { RolesActionTypes } from './roles-action-types.enum';
import PaginatedResults from '@leap-common/interfaces/paginated-results.interface';
import UserRole from '@leap-store/core/src/lib/data/auth/interfaces/user-role.interface';

export const getRolesRequest: ActionCreator<
    RolesActionTypes.GET_ROLES_REQUEST,
    () => TypedAction<RolesActionTypes.GET_ROLES_REQUEST>
> = createAction(RolesActionTypes.GET_ROLES_REQUEST);

export const getRolesSuccess: ActionCreator<
    RolesActionTypes.GET_ROLES_SUCCESS,
    (props: { paginatedRoles: PaginatedResults<UserRole> }) => {
        paginatedRoles: PaginatedResults<UserRole>;
    } & TypedAction<RolesActionTypes.GET_ROLES_SUCCESS>
> = createAction(
    RolesActionTypes.GET_ROLES_SUCCESS,
    props<{ paginatedRoles: PaginatedResults<UserRole> }>(),
);

export const getRolesFailure: ActionCreator<
    RolesActionTypes.GET_ROLES_FAILURE,
    (props: { errorResponse: HttpErrorResponse }) => {
        errorResponse: HttpErrorResponse;
    } & TypedAction<RolesActionTypes.GET_ROLES_FAILURE>
> = createAction(RolesActionTypes.GET_ROLES_FAILURE, props<{ errorResponse: HttpErrorResponse }>());

export const clearNextError: ActionCreator<
    RolesActionTypes.CLEAR_NEXT_ERROR,
    () => TypedAction<RolesActionTypes.CLEAR_NEXT_ERROR>
> = createAction(RolesActionTypes.CLEAR_NEXT_ERROR);
