/** third-party imports */
import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';

/** custom imports */
import ErrorResponse from '@leap-common/interfaces/error-response.interface';
import { TermsState } from './terms-state.interface';
import { getOverviewRequest, clearOverview, clearNextError } from './terms.actions';
import { getOverview, getLoading, getLoaded, getErrors } from './terms.selectors';
import TermOverview from './interfaces/term-overview.interface';
import BookmarkIds from '@leap-store/core/src/lib/data/bookmarks/interfaces/bookmark-ids.interface';

@Injectable()
export class TermsFacade {
    termsOverview$: Observable<TermOverview[]> = this.store.pipe(select(getOverview));
    loading$: Observable<boolean> = this.store.pipe(select(getLoading));
    loaded$: Observable<boolean> = this.store.pipe(select(getLoaded));
    errors$: Observable<ErrorResponse[]> = this.store.pipe(select(getErrors));

    constructor(private store: Store<TermsState>) {}

    getTermsOverview(ids: string[], bookmarkIds?: BookmarkIds): void {
        this.store.dispatch(getOverviewRequest({ ids, bookmarkIds }));
    }

    clearTermsOverview(): void {
        this.store.dispatch(clearOverview());
    }

    clearNextError(): void {
        this.store.dispatch(clearNextError());
    }
}
