/** third-party imports */
import { Injectable } from '@angular/core';

/** custom imports */
import {
    INVALID_SEARCH_QUERY_ANSWER,
    INVALID_SEARCH_QUERY_STATUS,
    NO_DATA_SEARCH_QUERY_ANSWER,
    NO_DATA_SEARCH_QUERY_STATUS,
} from '../constants/conversational-search';
import { InsightParser } from '@apps/leap/src/app/shared/parsers/insight.parser';
import { DiscoveryParser } from '@apps/leap/src/app/shared/parsers/discovery.parser';
import { ArticlesParser } from '../../articles/parsers/articles.parser';
import SearchQueryRestApi from '../rest-api-interfaces/search-query.rest.interface';
import SearchQuery from '../interfaces/search-query.interface';
import SearchDiscoveryRestApi from '../rest-api-interfaces/search-discovery.rest.interface';
import SearchDiscovery from '../interfaces/search-discovery.interface';

@Injectable()
export class ConversationalSearchParser {
    constructor(
        private insightParser: InsightParser,
        private discoveryParser: DiscoveryParser,
        private articlesParser: ArticlesParser,
    ) {}

    parseSearchQuery(query: SearchQueryRestApi): SearchQuery {
        return {
            question: query.question,
            answer: this.parseAnswer(query.answer, query.finish_status),
            id: query.uuid,
            sessionId: query.session_uuid,
            confidence: this.insightParser.parseScoreClassification(query.confidence_score),
            articles: this.articlesParser.parseArticles(query.references),
            discovery: this.parseSearchDiscovery(query.discovery),
        };
    }

    parseAnswer(answer: string, status: string): string {
        return status === NO_DATA_SEARCH_QUERY_STATUS
            ? NO_DATA_SEARCH_QUERY_ANSWER
            : status === INVALID_SEARCH_QUERY_STATUS
            ? INVALID_SEARCH_QUERY_ANSWER
            : answer;
    }

    parseSearchDiscovery(searchDiscovery: SearchDiscoveryRestApi): SearchDiscovery {
        return searchDiscovery
            ? {
                  type: this.discoveryParser.parseDiscovery(searchDiscovery.discovery_type),
                  parameters: this.discoveryParser.parseDiscoveryExecutionParameters(
                      searchDiscovery.parameters,
                  ),
                  filters: searchDiscovery.parameters.filters,
              }
            : null;
    }
}
