export enum NotebookServersActionTypes {
    GET_NOTEBOOK_SERVERS_REQUEST = '[NOTEBOOK SERVERS] Get Notebook Servers Request',
    GET_NOTEBOOK_SERVERS_SUCCESS = '[NOTEBOOK SERVERS] Get Notebook Servers Success',
    GET_NOTEBOOK_SERVERS_FAILURE = '[NOTEBOOK SERVERS] Get Notebook Servers Failure',
    CREATE_NOTEBOOK_SERVER_REQUEST = '[NOTEBOOK SERVERS] Create Notebook Server Request',
    CREATE_NOTEBOOK_SERVER_SUCCESS = '[NOTEBOOK SERVERS] Create Notebook Server Success',
    CREATE_NOTEBOOK_SERVER_FAILURE = '[NOTEBOOK SERVERS] Create Notebook Server Failure',
    DELETE_NOTEBOOK_SERVER_REQUEST = '[NOTEBOOK SERVERS] Delete Notebook Server Request',
    DELETE_NOTEBOOK_SERVER_SUCCESS = '[NOTEBOOK SERVERS] Delete Notebook Server Success',
    DELETE_NOTEBOOK_SERVER_FAILURE = '[NOTEBOOK SERVERS] Delete Notebook Server Failure',
    LOGOUT_JUPYTER_HUB_REQUEST = '[NOTEBOOK SERVERS] Logout Jupyter Hub Request',
    LOGOUT_JUPYTER_HUB_SUCCESS = '[NOTEBOOK SERVERS] Logout Jupyter Hub Success',
    LOGOUT_JUPYTER_HUB_FAILURE = '[NOTEBOOK SERVERS] Logout Jupyter Hub Failure',
    CLEAR_NEXT_ERROR = '[NOTEBOOK SERVERS] Clear Next Error',
}
