import { getComputedPropertyValue } from '@leap-common/utilities/helpers';

export const COLOR_PRIMARY: Record<string, string> = {
    50: getComputedPropertyValue('--branding-primary-50'),
    100: getComputedPropertyValue('--branding-primary-100'),
    200: getComputedPropertyValue('--branding-primary-200'),
    300: getComputedPropertyValue('--branding-primary-300'),
    400: getComputedPropertyValue('--branding-primary-400'),
    500: getComputedPropertyValue('--branding-primary-500'),
    600: getComputedPropertyValue('--branding-primary-600'),
    700: getComputedPropertyValue('--branding-primary-700'),
    800: getComputedPropertyValue('--branding-primary-800'),
    900: getComputedPropertyValue('--branding-primary-900'),
};

export const COLOR_SECONDARY: Record<string, string> = {
    50: getComputedPropertyValue('--branding-secondary-50'),
    100: getComputedPropertyValue('--branding-secondary-100'),
    200: getComputedPropertyValue('--branding-secondary-200'),
    300: getComputedPropertyValue('--branding-secondary-300'),
    400: getComputedPropertyValue('--branding-secondary-400'),
    500: getComputedPropertyValue('--branding-secondary-500'),
    600: getComputedPropertyValue('--branding-secondary-600'),
    700: getComputedPropertyValue('--branding-secondary-700'),
    800: getComputedPropertyValue('--branding-secondary-800'),
    900: getComputedPropertyValue('--branding-secondary-900'),
};
