import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { PERMISSIONS_TEXT, BACK_TO_LOGIN_TEXT } from '../../../../core/constants/auth';

@Component({
    selector: 'app-permissions',
    templateUrl: 'permissions.container.component.html',
    styleUrls: ['permissions.container.component.scss'],
})
export class PermissionsContainerComponent {
    readonly text: string = PERMISSIONS_TEXT;
    readonly backToLoginText: string = BACK_TO_LOGIN_TEXT;

    constructor(private router: Router) {}

    onBackClicked(): void {
        this.router.navigate(['auth/login']);
    }
}
