/** third-party imports */
import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';

/** custom imports */
import ErrorResponse from '@leap-common/interfaces/error-response.interface';
import Notebook from './interfaces/notebook.interface';
import { NotebooksState } from './notebooks-state.interface';
import {
    getNotebooksRequest,
    getNotebookRequest,
    createNotebookRequest,
    cloneNotebookRequest,
    updateNotebookRequest,
    deleteNotebookRequest,
    clearNextError,
    clearShouldFetchNotebooks,
} from './notebooks.actions';
import {
    getNotebooks,
    getNotebook,
    getNotebookLoading,
    getNotebookLoaded,
    getTemplates,
    getError,
    getLoading,
    getLoaded,
    getPageIndex,
    getPageSize,
    getTotal,
    getSortColumn,
    getSortDirection,
    getDeletePendingItems,
    getShouldFetchNotebooks,
} from './notebooks.selectors';
import OwnershipType from './enums/ownership-type.enum';
import SortingOrder from '@leap-common/enums/sorting-order.enum';

@Injectable()
export class NotebooksFacade {
    notebooks$: Observable<Notebook[]> = this.store.pipe(select(getNotebooks));
    notebook$: Observable<Notebook> = this.store.pipe(select(getNotebook));
    notebookLoading$: Observable<boolean> = this.store.pipe(select(getNotebookLoading));
    notebookLoaded$: Observable<boolean> = this.store.pipe(select(getNotebookLoaded));
    templates$: Observable<Notebook[]> = this.store.pipe(select(getTemplates));
    error$: Observable<ErrorResponse[]> = this.store.pipe(select(getError));
    loading$: Observable<boolean> = this.store.pipe(select(getLoading));
    loaded$: Observable<boolean> = this.store.pipe(select(getLoaded));
    pageIndex$: Observable<number> = this.store.pipe(select(getPageIndex));
    pageSize$: Observable<number> = this.store.pipe(select(getPageSize));
    total$: Observable<number> = this.store.pipe(select(getTotal));
    sortDirection$: Observable<SortingOrder> = this.store.pipe(select(getSortDirection));
    sortColumn$: Observable<string> = this.store.pipe(select(getSortColumn));
    deletePendingItems$: Observable<string[]> = this.store.pipe(select(getDeletePendingItems));
    shouldFetchNotebooks$: Observable<boolean> = this.store.pipe(select(getShouldFetchNotebooks));

    constructor(private store: Store<NotebooksState>) {}

    getNotebooks(
        pageIndex: number,
        pageSize: number,
        ownerType: string = OwnershipType.owned,
        sortDirection?: SortingOrder,
        sortColumn?: string,
        suppress?: boolean,
    ): void {
        this.store.dispatch(
            getNotebooksRequest({
                pageIndex,
                pageSize,
                sortDirection,
                sortColumn,
                ownerType,
                suppress,
            }),
        );
    }

    getNotebook(id: string): void {
        this.store.dispatch(getNotebookRequest({ id }));
    }

    createNotebook(name?: string): void {
        this.store.dispatch(createNotebookRequest({ name }));
    }

    cloneNotebook(id: string): void {
        this.store.dispatch(cloneNotebookRequest({ id }));
    }

    updateNotebook(id: string, name: string): void {
        this.store.dispatch(updateNotebookRequest({ id, name }));
    }

    deleteNotebook(id: string): void {
        this.store.dispatch(deleteNotebookRequest({ id }));
    }

    clearNextError(): void {
        this.store.dispatch(clearNextError());
    }

    clearShouldFetchNotebooks(): void {
        this.store.dispatch(clearShouldFetchNotebooks());
    }
}
