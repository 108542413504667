import {
    ASSOCIATIONS_LABEL,
    BIOMARKERS_LABEL,
    DATE_RANGE_LABEL,
    JOURNAL_LABEL,
    ORIGINS_LABEL,
    RELATIONSHIP_TYPES_LABEL,
    STUDY_TYPE_LABEL,
} from '../../../constants/labels';
import ArticleFilter from '../enums/filter.enum';

export const FILTERS_TITLE: string = 'Articles filters';

export const APPLY_FILTERS_TEXT: string = 'Apply selected filters';
export const CLEAR_FILTERS_TEXT: string = 'Clear selected filters';

export const TITLE_PER_FILTER: Partial<Record<ArticleFilter, string>> = {
    [ArticleFilter.studyType]: STUDY_TYPE_LABEL,
    [ArticleFilter.journal]: JOURNAL_LABEL,
    [ArticleFilter.origin]: ORIGINS_LABEL,
    [ArticleFilter.relationship]: RELATIONSHIP_TYPES_LABEL,
    [ArticleFilter.association]: ASSOCIATIONS_LABEL,
    [ArticleFilter.dateRange]: DATE_RANGE_LABEL,
    [ArticleFilter.biomarkers]: BIOMARKERS_LABEL,
};

export const SELECTED_STUDY_TYPE_TOOLTIP_TEXT: string = `The option '%s' cannot be unselected since it's the one chosen through the report.`;
export const ORIGIN_INFO_TOOLTIP_TEXT: string =
    'All articles that are available both in PubMed and in PubAg, are displayed as PubMed articles only';
export const DATE_RANGE_OLDEST_TOOLTIP_TEXT: string = 'Filter insights per oldest publication date';
export const DATE_RANGE_NEWEST_TOOLTIP_TEXT: string = 'Filter insights per newest publication date';

export const DISABLED_STUDY_TYPE_TOOLTIP_TEXT: string =
    'No articles are available with this specific type of study';
export const DISABLED_STUDY_TYPES_TOOLTIP_TEXT: string =
    'No articles found with the available types of studies.';
export const DISABLED_JOURNALS_TOOLTIP_TEXT: string =
    'No articles found with the available journal information.';
export const DISABLED_ORIGIN_TOOLTIP_TEXT: string = 'No articles are available from this source';
export const DISABLED_ORIGINS_TOOLTIP_TEXT: string = 'No sources information is available';
export const DISABLED_RELATIONSHIP_TYPES_TOOLTIP_TEXT: string =
    'No articles found with the available relationship types';
export const DISABLED_EFFECTS_TOOLTIP_TEXT: string = 'No articles found with the available effects';

export const BIOMARKERS_FILTER_TEXT: string = 'Exclude biomarkers';
export const BIOMARKERS_FILTER_ACTIVE_ITEM_TEXT: string = 'Biomarkers excluded';
export const BIOMARKERS_SWITCH_ID: string = 'biomarkers-switch';
export const BIOMARKERS_TAG_NAME: string = 'Endogenous Metabolite';

export const FULL_TEXT_ARTICLES_FILTER_TEXT: string = 'View full articles only';
export const FULL_TEXT_ARTICLES_FILTER_ACTIVE_ITEM_TEXT: string = 'Full articles only';
export const NO_FULL_TEXT_ARTICLES_TOOLTIP_TEXT: string = 'No full articles are available';

export const CO_OCCURRENCES_ARTICLES_FILTER_TEXT: string = 'View co-occurrences only';
export const CO_OCCURRENCES_ARTICLES_FILTER_ACTIVE_ITEM_TEXT: string = 'Co-occurrences only';
export const NO_CO_OCCURRENCES_ARTICLES_TOOLTIP_TEXT: string =
    'No articles with co-occurrences are available';
export const ANIMAL_MILK_FILTER_TEXT: string = "Exclude other animals' milk";
export const ANIMAL_MILK_FILTER_ACTIVE_ITEM_TEXT: string = "Other animals' milk excluded";
export const ANIMAL_MILK_TAG_NAME: string = "Other animal's milk";

export const SORTING_CATEGORY_FILTER_CHIP_TYPE: string = 'sorting-category';

export const START_YEAR_LABEL: string = 'Start year: ';
export const END_YEAR_LABEL: string = 'End year: ';
