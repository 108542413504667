export enum MetadataActionTypes {
    GET_LABS_REQUEST = '[METADATA] Get Labs Request',
    GET_LABS_SUCCESS = '[METADATA] Get Labs Success',
    GET_LABS_FAILURE = '[METADATA] Get Labs Failure',
    GET_HEALTH_LABELS_REQUEST = '[METADATA] Get Health Labels Request',
    GET_HEALTH_LABELS_SUCCESS = '[METADATA] Get Health Labels Success',
    GET_HEALTH_LABELS_FAILURE = '[METADATA] Get Health Labels Failure',
    GET_PREVALENCES_REQUEST = '[METADATA] Get Prevalences Request',
    GET_PREVALENCES_SUCCESS = '[METADATA] Get Prevalences Success',
    GET_PREVALENCES_FAILURE = '[METADATA] Get Prevalences Failure',
    CLEAR_PREVALENCES = '[METADATA] Clear Prevalences',
    GET_IDENTIFIERS_REQUEST = '[METADATA] Get Identifiers Request',
    GET_IDENTIFIERS_SUCCESS = '[METADATA] Get Identifiers Success',
    GET_IDENTIFIERS_FAILURE = '[METADATA] Get Identifiers Failure',
    CLEAR_IDENTIFIERS = '[METADATA] Clear Identifiers',
    GET_PROTEIN_ORIGINS_REQUEST = '[METADATA] Get Protein Origins Request',
    GET_PROTEIN_ORIGINS_SUCCESS = '[METADATA] Get Protein Origins Success',
    GET_PROTEIN_ORIGINS_FAILURE = '[METADATA] Get Protein Origins Failure',
    CLEAR_PROTEIN_ORIGINS = '[METADATA] Clear Protein Origins',
    GET_PATENTS_COUNTS_REQUEST = '[METADATA] Get Patents Counts Request',
    GET_PATENTS_COUNTS_SUCCESS = '[METADATA] Get Patents Counts Success',
    GET_PATENTS_COUNTS_FAILURE = '[METADATA] Get Patents Counts Failure',
    CLEAR_PATENTS_COUNTS = '[METADATA] Clear Patents Counts',
    GET_CONTAINING_INGREDIENTS_REQUEST = '[METADATA] Get Containing Ingredients Request',
    GET_CONTAINING_INGREDIENTS_SUCCESS = '[METADATA] Get Containing Ingredients Success',
    GET_CONTAINING_INGREDIENTS_FAILURE = '[METADATA] Get Containing Ingredients Failure',
    CLEAR_CONTAINING_INGREDIENTS = '[METADATA] Clear Containing Ingredients',
    GET_RELATIONSHIPS_INFO_REQUEST = '[METADATA] Get Relationships Info Request',
    GET_RELATIONSHIPS_INFO_SUCCESS = '[METADATA] Get Relationships Info Success',
    GET_RELATIONSHIPS_INFO_FAILURE = '[METADATA] Get Relationships Info Failure',
    CLEAR_RELATIONSHIPS_INFO = '[METADATA] Clear Relationships Info',
    GET_STUDY_TYPES_REQUEST = '[METADATA] Get Types Of Study Request',
    GET_STUDY_TYPES_SUCCESS = '[METADATA] Get Types Of Study Success',
    GET_STUDY_TYPES_FAILURE = '[METADATA] Get Types Of Study Failure',
    GET_JOURNALS_REQUEST = '[METADATA] Get Journals Request',
    GET_JOURNALS_SUCCESS = '[METADATA] Get Journals Success',
    GET_JOURNALS_FAILURE = '[METADATA] Get Journals Failure',
    GET_UNIT_CONVERSIONS_REQUEST = '[METADATA] Get Unit Conversions Request',
    GET_UNIT_CONVERSIONS_SUCCESS = '[METADATA] Get Unit Conversions Success',
    GET_UNIT_CONVERSIONS_FAILURE = '[METADATA] Get Unit Conversions Failure',
    GET_ARTICLES_INGESTION_DATE_REQUEST = '[METADATA] Get Articles Ingestion Date Request',
    GET_ARTICLES_INGESTION_DATE_SUCCESS = '[METADATA] Get Articles Ingestion Date Success',
    GET_ARTICLES_INGESTION_DATE_FAILURE = '[METADATA] Get Articles Ingestion Date Failure',
    CLEAR_NEXT_ERROR = '[METADATA] Clear Next Error',
}
