import { Component, HostBinding, Inject, OnDestroy, OnInit } from '@angular/core';
import OktaAuth from '@okta/okta-auth-js';
import { OKTA_AUTH } from '@okta/okta-angular';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { FormBuilder, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { Observable, Subscription, of } from 'rxjs';
import { map, pairwise, switchMap, filter } from 'rxjs/operators';
import { isEmpty } from 'lodash';
import { environment } from '@environments/leap/environment';

/** Constants */
import { EMPTY_STRING, WHITESPACE } from '@leap-common/constants/common';
import { EMAIL_VALIDATION_REGEX } from '@leap-common/constants/regexes';
import {
    EMAIL_ICON,
    PASSWORD_ICON,
    INPUT_SHOWN_ICON,
    INPUT_HIDDEN_ICON,
    EMAIL_LABEL,
    PASSWORD_LABEL,
    EMAIL_PLACEHOLDER,
    PASSWORD_PLACEHOLDER,
    LOGIN_INTRODUCTION_TEXT,
    CUSTOM_LOGIN_INTRODUCTION_TEXT,
    FORGOT_PASSWORD_TEXT,
} from '../../../../core/constants/auth';
import {
    EXTERNAL_POLICY_TEXT,
    EXTERNAL_POLICY_LINK,
} from '@apps/leap/src/app/shared/constants/external';

/** Helpers */
import { isTruthy, wait } from '@leap-common/utilities/helpers';
import { generateCodeVerifier, generateCodeChallenge } from '../../../../core/utilities/helpers';

/** Services - Facades */
import { ErrorHandlerService } from '@leap-common/services/error-handler.service';
import { FeatureFlagsService } from '@leap-libs/feature-flags/src/public-api';
import { FormValidationService } from '@leap-libs/form/src/lib/form-validation.service';
import { TokenService } from '@leap-store/core/src/lib/data/auth/services/token.service';
import { GPTService } from '@leap-store/core/src/lib/data/gpt/services/gpt.service';
import { RedirectService } from '../../../../../layout/services/redirect.service';
import { AuthService } from '../../../../core/services/auth.service';
import { AuthFacade } from '@leap-store/core/src/lib/data/auth/auth.facade';
import { NotebookServersFacade } from '@leap-store/core/src/lib/data/notebook-servers/notebook-servers.facade';
import { AlertsFacade } from '@leap-store/core/src/lib/ui/alerts/alerts.facade';

/** Interfaces - Enums */
import Jwt from '@leap-store/core/src/lib/data/auth/interfaces/jwt.interface';
import Alert from '@leap-store/core/src/lib/ui/alerts/interfaces/alert.interface';
import ErrorResponse from '@leap-common/interfaces/error-response.interface';
import LoginUser from '@leap-store/core/src/lib/data/auth/interfaces/login-user.interface';
import TokenTypes from '@leap-store/core/src/lib/data/auth/enums/token-types.enum';
import DecoratedInputOptions from '@leap-libs/decorated-input/src/lib/decorated-input-options.interface';
import InputOptions from '@leap-libs/input/src/lib/interfaces/input-options.interface';

@Component({
    selector: 'app-login',
    templateUrl: 'login.container.component.html',
    styleUrls: ['login.container.component.scss'],
})
export class LoginContainerComponent implements OnInit, OnDestroy {
    @HostBinding('class') classes = 'full-width';

    // text color
    isTextWhite: boolean;

    // introduction
    introductionText: string;

    // form
    form: FormGroup;
    formEmailOptions: DecoratedInputOptions;
    formPasswordOptions: DecoratedInputOptions;
    formAgreedToTermsOptions: InputOptions;
    formSubmitButtonTitle: string;
    emailValidators: ValidatorFn;
    passwordValidators: ValidatorFn;

    // login$
    jwt$: Observable<Jwt> = this.authFacade.jwt$;
    resetPasswordKey$: Observable<string> = this.authFacade.resetPasswordKey$;
    errors$: Observable<ErrorResponse[]> = this.authFacade.error$;

    // subscriptions
    queryParamsSubscription: Subscription;
    jwtSubscription: Subscription;
    resetPasswordKeySubscription: Subscription;
    errorsSubscription: Subscription;

    // feature flags
    areNotebooksEnabled: boolean;
    isChatAssistantEnabled: boolean;
    areExternalLinksEnabled: boolean;
    isExternalLoginEnabled: boolean;

    readonly forgotPasswordText: string = FORGOT_PASSWORD_TEXT;

    constructor(
        @Inject(OKTA_AUTH) private oktaAuth: OktaAuth,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private formBuilder: FormBuilder,
        private errorHandlerService: ErrorHandlerService,
        private featureFlagsService: FeatureFlagsService,
        private formValidationService: FormValidationService,
        private tokenService: TokenService,
        private gptService: GPTService,
        private redirectService: RedirectService,
        private authService: AuthService,
        private authFacade: AuthFacade,
        private notebookServersFacade: NotebookServersFacade,
        private alertsFacade: AlertsFacade,
    ) {}

    ngOnInit(): void {
        this.subscribeToQueryParams();
        this.initializeFormValidators();
        this.initializeForm();
        this.initializeEnabledFeatures();
        this.initializeIsTextWhite();
        this.initializeIntroductionText();
        this.handleSuccess();
        this.handleResetPasswordKey();
        this.handleErrors();
        this.handleChromeAutofill();
    }

    ngOnDestroy(): void {
        this.queryParamsSubscription?.unsubscribe();
        this.jwtSubscription?.unsubscribe();
        this.resetPasswordKeySubscription?.unsubscribe();
        this.errorsSubscription?.unsubscribe();

        // clear reset password key
        this.clearResetPasswordKey();
    }

    subscribeToQueryParams(): void {
        this.queryParamsSubscription = this.activatedRoute.queryParams
            .pipe(filter((params: Params) => !isEmpty(params)))
            .subscribe((params: Params) => {
                this.tokenService.setToken(TokenTypes.access, params.access);
                this.tokenService.setToken(TokenTypes.refresh, params.refresh);
                this.redirectToLanding();
            });
    }

    /** Initializes the form validators. */
    initializeFormValidators(): void {
        // initialize email validators
        this.emailValidators = Validators.compose([
            this.formValidationService.requiredValidator({ required: 'Email is required' }),
            this.formValidationService.patternValidator(EMAIL_VALIDATION_REGEX, {
                pattern: 'Please type in a valid email',
            }),
        ]);

        // initialize password validators
        this.passwordValidators = this.formValidationService.requiredValidator({
            required: 'Password is required',
        });
    }

    /** Initializes the form */
    initializeForm(): void {
        this.form = this.formBuilder.group({
            email: [null, this.emailValidators],
            password: [null, this.passwordValidators],
            agreedToTerms: [false, Validators.requiredTrue],
        });

        this.formEmailOptions = {
            type: 'text',
            id: 'email',
            label: EMAIL_LABEL,
            placeholder: EMAIL_PLACEHOLDER,
            iconsBegin: [{ class: EMAIL_ICON }],
            iconsEnd: [],
        };

        this.formPasswordOptions = {
            type: 'password',
            id: 'password',
            label: PASSWORD_LABEL,
            placeholder: PASSWORD_PLACEHOLDER,
            iconsBegin: [{ class: PASSWORD_ICON }],
            iconsEnd: [{ class: INPUT_HIDDEN_ICON, shouldToggleVisibility: true }],
        };

        this.formAgreedToTermsOptions = {
            id: 'agreedToTerms',
        };

        this.formSubmitButtonTitle = 'Login';
    }

    initializeEnabledFeatures(): void {
        this.areNotebooksEnabled = this.featureFlagsService.isFeatureEnabled('notebooks');
        this.isChatAssistantEnabled = this.featureFlagsService.isFeatureEnabled('chatAssistant');
        this.areExternalLinksEnabled =
            this.featureFlagsService.isFeatureEnabled('externalPrivacyLinks');
        this.isExternalLoginEnabled = this.featureFlagsService.isFeatureEnabled('externalLogin');
    }

    initializeIsTextWhite(): void {
        this.isTextWhite = environment.organization !== 'dmi';
    }

    initializeIntroductionText(): void {
        this.introductionText =
            environment.organization === 'mr' ||
            environment.organization === 'mr-plant' ||
            environment.organization === 'pp'
                ? CUSTOM_LOGIN_INTRODUCTION_TEXT
                : environment.organization === 'dg-ingredient-analyzer'
                ? EMPTY_STRING
                : LOGIN_INTRODUCTION_TEXT;
    }

    /**
     * Subscribes to the jwt$ and if the conditions fulfilled, redirects to the landing page.
     * deleteAssistantQueries, deleteNotebookServer and redirection should happen only on login, so we check if previous
     * Jwt value was null.
     */
    handleSuccess(): void {
        this.jwtSubscription = this.jwt$
            .pipe(
                pairwise(),
                filter(([previousJwt]) => !previousJwt),
                switchMap(([, currentJwt]) => {
                    if (currentJwt?.accessToken) {
                        return this.isChatAssistantEnabled
                            ? this.gptService.deleteAssistantQueries() // call directly the service and avoid passing this through the store to keep the flow simplified
                            : of(currentJwt?.accessToken);
                    }
                }),
            )
            .subscribe(
                () => this.redirectToLanding(),
                () => this.redirectToLanding(), // redirect to the landing page even if the deleteAssistantQueries() fails, to avoid app issues if OpenAI is down
            );
    }

    /**
     * Verifies if the user has permission to access the app.
     * If not, the user's tokens are deleted, and they are redirected to the permissions page.
     * If access is granted, the user is further checked for acceptance of the terms of use
     * and enabled features, and then redirected accordingly.
     */
    redirectToLanding(): void {
        const hasUserAppPermissions: boolean = this.authService.hasUserAppPermissions();

        if (!hasUserAppPermissions) {
            this.authFacade.logout([TokenTypes.access, TokenTypes.refresh]);
            this.router.navigate(['auth/permissions']);
            return;
        }

        /*
         * On login we delete the notebook server to fix an issue
         * where the user cannot access notebooks once his token has
         * expired and was logged out automatically.
         * The deletion of the notebook server takes place only if the notebooks feature flag is enabled.
         */
        if (this.areNotebooksEnabled) {
            this.notebookServersFacade.deleteNotebookServer();
        }

        this.redirectService.redirectBasedOnTermsOfUseAcceptance();
    }

    /**
     * Subscribes to the resetPasswordKey$ and redirects to the reset-password page with the provided key as a query parameter.
     */
    handleResetPasswordKey(): void {
        this.resetPasswordKeySubscription = this.resetPasswordKey$
            .pipe(filter(isTruthy))
            .subscribe((key: string) => {
                // navigate to reset password page
                this.router.navigate(['auth/reset-password'], { queryParams: { key } });
            });
    }

    clearResetPasswordKey(): void {
        this.authFacade.clearResetPasswordKey();
    }

    /**
     * Subscribes to the error$ and displays the proper error alert.
     */
    handleErrors(): void {
        this.errorsSubscription = this.errors$
            .pipe(
                filter((errors: ErrorResponse[]) => errors.length > 0 && errors[0] !== undefined),
                map((errors: ErrorResponse[]) => errors[0]),
            )
            .subscribe((error: ErrorResponse) => {
                const alert: Alert = this.errorHandlerService.handleErrorResponse(error);

                // push error to ui state
                this.alertsFacade.pushAlertEvent({
                    content: alert,
                });

                // pop error from data state
                this.authFacade.clearNextError();
            });
    }

    /**
     * Prevents a bug in Chrome where an autofilled
     * by Chrome form stays in disabled status.
     */
    async handleChromeAutofill(): Promise<void> {
        // Current browser is chrome
        const isChrome: boolean =
            /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);

        // If Chrome proceed
        if (isChrome) {
            // Wait to ensure that login form is mounted
            await wait(500);

            // Select elements that are autofilled
            const autoFilledInputs: NodeListOf<Element> = document.querySelectorAll(
                'input:-internal-autofill-selected',
            );

            // Autofilled inputs exist and are exactly 2 (email, password)
            if (autoFilledInputs?.length === 2) {
                const submitButton: HTMLElement = document.querySelector(
                    'app-login button[type=submit]',
                );

                // Remove disabled attribute from submit button
                submitButton.removeAttribute('disabled');
            }
        }
    }

    /**
     * Calls the authFacade.login() with the value of the form and resets it.
     */
    onLogin(user: LoginUser): void {
        this.authFacade.login(user);
    }

    onExternalLogin(isClicked: boolean): void {
        if (!isClicked) {
            return;
        }

        this.loginWithOkta();
    }

    async loginWithOkta(): Promise<void> {
        // generate and store a code verifier (random string) for PKCE
        const codeVerifier: string = generateCodeVerifier();
        this.tokenService.setToken('codeVerifier', codeVerifier);

        // generate a code challenge (SHA-256 hash of the code verifier) for PKCE
        const codeChallenge: string = await generateCodeChallenge(codeVerifier);

        // redirect the user to Okta for authentication with the code challenge and method
        await this.oktaAuth.signInWithRedirect({
            codeChallenge,
            codeChallengeMethod: 'S256', // specifies SHA-256 as the hashing algorithm
        });
    }

    onLinkClick(id: string, shouldOpenInNewTab: boolean = true): void {
        const url: string =
            id === EXTERNAL_POLICY_TEXT.toLocaleLowerCase().split(WHITESPACE).join('-')
                ? EXTERNAL_POLICY_LINK
                : `/legal/${id}`;

        if (shouldOpenInNewTab) {
            window.open(url, '_blank');
        } else {
            this.router.navigate([url]);
        }
    }

    onInputVisibilityToggled(inputId: string): void {
        this.toggleInputVisibility(inputId);
    }

    toggleInputVisibility(inputId: string): void {
        if (inputId === 'password') {
            this.formPasswordOptions.type =
                this.formPasswordOptions.type === 'password' ? 'text' : 'password';

            this.formPasswordOptions.iconsEnd =
                this.formPasswordOptions.type === 'password'
                    ? [{ class: INPUT_HIDDEN_ICON, shouldToggleVisibility: true }]
                    : [
                          {
                              class: INPUT_SHOWN_ICON,
                              shouldToggleVisibility: true,
                          },
                      ];
        }
    }
}
