/** third-party imports */
import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';

/** custom imports */
import ErrorResponse from '@leap-common/interfaces/error-response.interface';
import { ExplainabilityState } from './explainability-state.interface';
import { getPathsRequest, clearNextError } from './explainability.actions';
import {
    getPaths,
    getEnhancedPaths,
    getParentInsightId,
    getErrors,
    getLoading,
    getLoaded,
    getSortingOrder,
} from './explainability.selectors';
import ExplorationPath from '../paths/interfaces/exploration-path.interface';
import EnhancedPaths from './interfaces/enhanced-paths';
import SortingOrder from '@leap-common/enums/sorting-order.enum';
import { createDistinctUntilObjectChanged } from '@leap-common/utilities/helpers';

@Injectable()
export class ExplainabilityFacade {
    paths$: Observable<ExplorationPath[]> = this.store.pipe(select(getPaths));
    enhancedPaths$: Observable<EnhancedPaths> = this.store.pipe(
        select(getEnhancedPaths),
        createDistinctUntilObjectChanged<EnhancedPaths>(),
    );
    parentInsightId$: Observable<string> = this.store.pipe(select(getParentInsightId));
    errors$: Observable<ErrorResponse[]> = this.store.pipe(select(getErrors));
    loading$: Observable<boolean> = this.store.pipe(select(getLoading));
    loaded$: Observable<boolean> = this.store.pipe(select(getLoaded));
    sortingOrder$: Observable<SortingOrder> = this.store.pipe(select(getSortingOrder));

    constructor(private store: Store<ExplainabilityState>) {}

    getPaths(
        parentInsightId: string,
        sourceId: string,
        targetId: string,
        sourceCategories: string[],
        targetCategories: string[],
        sourceSubcategories: string[],
        targetSubcategories: string[],
        relations: string[],
        sortingOrder: SortingOrder,
    ): void {
        this.store.dispatch(
            getPathsRequest({
                parentInsightId,
                sourceId,
                targetId,
                sourceCategories,
                targetCategories,
                sourceSubcategories,
                targetSubcategories,
                relations,
                sortingOrder,
            }),
        );
    }

    clearNextError(): void {
        this.store.dispatch(clearNextError());
    }
}
