/** third-party libraries */
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

/** custom imports */
import { environment } from '@environments/leap/environment';
import { ContentWriterParser } from '../parsers/content-writer.parser';

/** Interfaces - Types - Enums */
import FileExtension from '@leap-common/enums/file-extension.enum';
import ContentWriterFormatId from '../enums/format-id.enum';
import TopicsRestApi from '../rest-api-interfaces/topics.rest.interface';
import GeneratedContentRestApi from '../rest-api-interfaces/generated-content.rest.interface';
import GeneratedContent from '../interfaces/generated-content.interface';
import Configuration from '../interfaces/configuration.interface';

@Injectable()
export class ContentWriterService {
    constructor(private http: HttpClient, private contentWriterParser: ContentWriterParser) {}

    /**
     * Returns an array (up to four) of LLM generated proposed topics.
     */
    getTopics(externalIds: string[]): Observable<string[]> {
        return this.http
            .post(`${environment.projectsReportServerUrl}/content-writer/topics`, {
                external_ids: externalIds,
            })
            .pipe(map(({ topics }: TopicsRestApi) => topics));
    }

    /**
     * Returns content based on the provided format (long or short) and the LLM-specific parameters.
     *
     * @param externalIds - Used in long format content generation
     * @param longContent - Used in short format content generation
     */
    getContent({
        format,
        configuration,
        externalIds,
        longContentText,
    }: {
        format: ContentWriterFormatId;
        configuration: Configuration;
        externalIds?: string[];
        longContentText?: string;
    }): Observable<GeneratedContent> {
        return this.http
            .post(`${environment.projectsReportServerUrl}/content-writer/posts`, {
                configuration: this.contentWriterParser.serializeConfiguration(configuration),
                type: this.contentWriterParser.serializeFormat(format),
                topic: configuration.topic,
                ...(format === ContentWriterFormatId.longArticle
                    ? { external_ids: externalIds }
                    : format === ContentWriterFormatId.shortPost && { article: longContentText }),
            })
            .pipe(
                map((content: GeneratedContentRestApi) =>
                    this.contentWriterParser.parseGeneratedContent(content, longContentText),
                ),
            );
    }

    /**
     * Returns a blob with compressed data of all the formats' content in the specified extension.
     */
    downloadContent({
        extension,
        longContentText,
        shortContentText,
    }: {
        extension: FileExtension;
        longContentText: string;
        shortContentText: string;
    }): Observable<Blob> {
        return this.http.post(
            `${environment.projectsReportServerUrl}/content-writer/posts/download`,
            { format: extension, blog_post: longContentText, linkedin_post: shortContentText },
            { headers: new HttpHeaders({ Accept: `text/${extension}` }), responseType: 'blob' },
        );
    }
}
