import { createAction, props } from '@ngrx/store';
import { HttpErrorResponse } from '@angular/common/http';
import { CombinatorialDiscoveryActionTypes } from './combinatorial-discovery-action-types.enum';
import Filter from '@apps/leap/src/app/shared/modules/filters/interfaces/filter.interface';
import SortingOptions from '@leap-common/interfaces/sorting-options.interface';

export const performDiscoveryRequest = createAction(
    CombinatorialDiscoveryActionTypes.PERFORM_DISCOVERY_REQUEST,
    props<{
        categories: string[];
        term: string;
        indexFields: string[];
        sessionId: string;
    }>(),
);

export const performDiscoverySuccess = createAction(
    CombinatorialDiscoveryActionTypes.PERFORM_DISCOVERY_SUCCESS,
);

export const performDiscoveryFailure = createAction(
    CombinatorialDiscoveryActionTypes.PERFORM_DISCOVERY_FAILURE,
    props<{ errorResponse: HttpErrorResponse }>(),
);

export const downloadDiscoveryRequest = createAction(
    CombinatorialDiscoveryActionTypes.DOWNLOAD_DISCOVERY_REQUEST,
    props<{
        categories: string[];
        term: string;
        isGrouped: boolean;
    }>(),
);

export const downloadDiscoverySuccess = createAction(
    CombinatorialDiscoveryActionTypes.DOWNLOAD_DISCOVERY_SUCCESS,
    props<{ blob: Blob }>(),
);

export const downloadDiscoveryFailure = createAction(
    CombinatorialDiscoveryActionTypes.DOWNLOAD_DISCOVERY_FAILURE,
    props<{ errorResponse: HttpErrorResponse }>(),
);

export const fetchLocalDiscoveryRequest = createAction(
    CombinatorialDiscoveryActionTypes.FETCH_LOCAL_DISCOVERY_REQUEST,
    props<{
        limit?: number;
        sortingOptions?: SortingOptions;
        filters?: Record<string, Filter[] | [number, number]>;
        fetchMetadata?: boolean;
    }>(),
);

export const fetchLocalDiscoverySuccess = createAction(
    CombinatorialDiscoveryActionTypes.FETCH_LOCAL_DISCOVERY_SUCCESS,
);

export const fetchLocalDiscoveryFailure = createAction(
    CombinatorialDiscoveryActionTypes.FETCH_LOCAL_DISCOVERY_FAILURE,
);

export const getSearchSuggestionsRequest = createAction(
    CombinatorialDiscoveryActionTypes.GET_SEARCH_SUGGESTIONS_REQUEST,
    props<{
        source: string;
        target: string;
        target2: string;
        query: string;
    }>(),
);

export const getSearchSuggestionsSuccess = createAction(
    CombinatorialDiscoveryActionTypes.GET_SEARCH_SUGGESTIONS_SUCCESS,
    props<{ suggestionIds: string[] }>(),
);

export const getSearchSuggestionsFailure = createAction(
    CombinatorialDiscoveryActionTypes.GET_SEARCH_SUGGESTIONS_FAILURE,
    props<{ errorResponse: HttpErrorResponse }>(),
);

export const clearSearchSuggestions = createAction(
    CombinatorialDiscoveryActionTypes.CLEAR_SEARCH_SUGGESTIONS,
);

export const resetDiscovery = createAction(CombinatorialDiscoveryActionTypes.RESET_DISCOVERY);

export const clearNextError = createAction(CombinatorialDiscoveryActionTypes.CLEAR_NEXT_ERROR);

export const saveDBId = createAction(
    CombinatorialDiscoveryActionTypes.SAVE_DB_ID,
    props<{ id: string }>(),
);

export const removeDBId = createAction(
    CombinatorialDiscoveryActionTypes.REMOVE_DB_ID,
    props<{ id: string }>(),
);

export const saveSessionId = createAction(
    CombinatorialDiscoveryActionTypes.SAVE_SESSION_ID,
    props<{ id: string }>(),
);

export const exitDiscovery = createAction(CombinatorialDiscoveryActionTypes.EXIT_DISCOVERY);
