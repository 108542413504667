import { createAction, props } from '@ngrx/store';
import { HttpErrorResponse } from '@angular/common/http';
import { ExplainabilityActionTypes } from './explainability-action-types.enum';
import PaginatedResults from '@leap-common/interfaces/paginated-results.interface';
import ExplorationPath from '../paths/interfaces/exploration-path.interface';
import SortingOrder from '@leap-common/enums/sorting-order.enum';

export const getPathsRequest = createAction(
    ExplainabilityActionTypes.GET_PATHS_REQUEST,
    props<{
        parentInsightId: string;
        sourceId: string;
        targetId: string;
        sourceCategories: string[];
        targetCategories: string[];
        sourceSubcategories: string[];
        targetSubcategories: string[];
        relations: string[];
        sortingOrder: SortingOrder;
    }>(),
);

export const getPathsSuccess = createAction(
    ExplainabilityActionTypes.GET_PATHS_SUCCESS,
    props<{
        parentInsightId: string;
        paginatedPaths: PaginatedResults<ExplorationPath>;
        sortingOrder: SortingOrder;
    }>(),
);

export const getPathsFailure = createAction(
    ExplainabilityActionTypes.GET_PATHS_FAILURE,
    props<{ errorResponse: HttpErrorResponse }>(),
);

export const clearNextError = createAction(ExplainabilityActionTypes.CLEAR_NEXT_ERROR);
