import { Injectable } from '@angular/core';
import DatabaseRestApi from '../rest-api-interfaces/database.rest.interface';
import Database from '../interfaces/database.interface';

@Injectable()
export class DatabasesParser {
    constructor() {}

    parseDatabases(databases: Record<string, DatabaseRestApi>): Record<string, Database> {
        return Object.entries(databases).reduce(
            (accumulator: Record<string, Database>, [key, value]) => {
                accumulator[key] = this.parseDatabase(value);
                return accumulator;
            },
            {},
        );
    }

    parseDatabase(database: DatabaseRestApi): Database {
        return {
            url: database.url,
            fullName: database.full_text,
            description: database.description,
        };
    }
}
