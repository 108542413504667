import { Injectable } from '@angular/core';

/** Constants */
import { UNDERSCORE_DELIMITER } from '@leap-common/constants/delimiters';

/** Services */
import { ArrayHandlerService } from '@leap-common/services/array-handler.service';

/** Interfaces - Enums */
import SortingOrder from '@leap-common/enums/sorting-order.enum';
import PaginatedResultsRestApi from '@leap-common/rest-api-interfaces/paginated-results.rest.interface';
import PaginatedResults from '@leap-common/interfaces/paginated-results.interface';
import IngredientRestApi from '../rest-api-interfaces/ingredient.rest.interface';
import Ingredient from '../interfaces/ingredient.interface';
import StatisticsItem from '../../report/interfaces/statistics-item.interface';
import StatisticsRestApi from '../rest-api-interfaces/statistics.rest.interface';
import Statistics from '../interfaces/statistics.interface';
import MolecularWeightRestApi from '../rest-api-interfaces/molecular-weight.rest.interface';
import AminoAcidsRestApi from '../rest-api-interfaces/amino-acids.rest.interface';

@Injectable()
export class IngredientViewerParser {
    constructor(private arrayHandlerService: ArrayHandlerService) {}

    parsePaginatedIngredients(
        paginatedIngredients: PaginatedResultsRestApi<IngredientRestApi>,
        ids?: string[],
    ): PaginatedResults<Ingredient> {
        return {
            results: this.parseIngredients(paginatedIngredients.results, ids),
            pageIndex: paginatedIngredients.pageIndex,
            pageSize: paginatedIngredients.pageSize,
            total: paginatedIngredients.total,
        };
    }

    /**
     * Parses an array of ingredients. Optionally sorts the ingredients based on a given order of ids.
     * The `ids` array defines the desired order for sorting.
     *  - If provided, the returned array of ingredients will be sorted according to this order.
     *  - If not provided, the ingredients will not be sorted.
     *  - If an id from `ids` is not found in the parsed ingredients, it will not appear in the output.
     */
    parseIngredients(ingredients: IngredientRestApi[], ids?: string[]): Ingredient[] {
        const unorderedIngredients: Ingredient[] =
            ingredients?.map((ingredient: IngredientRestApi) => this.parseIngredient(ingredient)) ||
            [];

        return ids
            ? unorderedIngredients.sort(
                  this.arrayHandlerService.getSortWithResolver(
                      (ingredient: { id: string }) => ids.indexOf(ingredient.id),
                      SortingOrder.ascending,
                  ),
              )
            : unorderedIngredients;
    }

    parseIngredient(ingredient: IngredientRestApi): Ingredient {
        return ingredient
            ? {
                  id: ingredient.uuid,
                  name: this.parseIngredientName(ingredient.name, ingredient.version),
                  uploadedBy: ingredient.created_by,
                  uploadedAt: ingredient.created_at,
                  notes: ingredient.note,
                  moleculesCount: ingredient.metadata?.molecules,
                  aminoAcidsCount: ingredient.metadata?.amino_acids,
                  hydrolysisDegree: this.parseHydrolysisDegree(
                      ingredient.metadata?.hydrolysis_degree,
                  ),
              }
            : null;
    }

    parseHydrolysisDegree(hydrolysisDegree: number): number {
        return Number(hydrolysisDegree.toFixed(2)) || 0;
    }

    parseIngredientName(name: string, version: number): string {
        return version ? `${name}${UNDERSCORE_DELIMITER}${version}` : name;
    }

    parseStatistics(statistics: StatisticsRestApi): Statistics {
        return statistics
            ? {
                  aminoAcids: this.parseAminoAcids(statistics.aminoAcids),
                  bioactivities: this.parseBioactivities(statistics.bioactivities),
                  molecularWeights: this.parseMolecularWeights(statistics.molecularWeights),
              }
            : null;
    }

    parseAminoAcids(aminoAcids: AminoAcidsRestApi): Record<string, StatisticsItem[]> {
        return aminoAcids
            ? {
                  [aminoAcids.ingredient]: Object.entries(aminoAcids.concentrations).map(
                      ([key, value]: [string, number]) => ({
                          name: key,
                          value,
                      }),
                  ),
              }
            : null;
    }

    parseBioactivities(bioactivities: Record<string, number>): Record<string, StatisticsItem[]> {
        return bioactivities
            ? {
                  [bioactivities.ingredient]: Object.entries(bioactivities).map(
                      ([key, value]: [string, number]) => ({
                          name: key,
                          value,
                      }),
                  ),
              }
            : null;
    }

    parseMolecularWeights(molecularWeights: MolecularWeightRestApi[]): StatisticsItem[] {
        return molecularWeights
            ? molecularWeights.map((molecularWeight: MolecularWeightRestApi) => ({
                  name: molecularWeight.range,
                  percentage: molecularWeight.percentage,
              }))
            : null;
    }
}
