export const AMPERSAND_DELIMITER: string = ' & ';
export const COMMA_DELIMITER: string = ', ';
export const UNDERSCORE_DELIMITER = '_';
export const BLACK_CIRCLE_DELIMITER: string = ' \u25cf ';
export const BULLET_DELIMITER: string = ' \u2022 ';
export const DOUBLE_PIPE_DELIMITER: string = '||';
export const NDASH_DELIMITER: string = ' \u2013 ';
export const PLUS_DELIMITER: string = ' + ';
export const COLON_DELIMITER: string = ': ';
export const OR_DELIMITER: string = ' or ';
export const AND_DELIMITER: string = ' and ';
