/** third-party libraries */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { from, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { BlockBlobClient, AnonymousCredential } from '@azure/storage-blob';

/** custom imports */
import { FilesParser } from '../parsers/files.parser';
import { environment } from '@environments/leap/environment';
import PaginatedResultsRestApi from '@leap-common/rest-api-interfaces/paginated-results.rest.interface';
import PaginatedResults from '@leap-common/interfaces/paginated-results.interface';
import FileRestApi from '@leap-store/core/src/lib/data/files/rest-api-interfaces/file.rest.interface';
import File from '@leap-store/core/src/lib/data/files/interfaces/file.interface';
import FileUploadEnvelopeRestApi from '../rest-api-interfaces/file-upload-envelope.rest.interface';
import FileUploadResponse from '../interfaces/file-upload-response.interface';
import SortingOrder from '@leap-common/enums/sorting-order.enum';

@Injectable()
export class FilesService {
    constructor(private http: HttpClient, private filesParser: FilesParser) {}

    /** The first request to the server, to retrieve SAS URI */
    createFilePath(path: string): Observable<File> {
        return this.http
            .post(`${environment.uploadServerUrl}/uploads/`, { path })
            .pipe(map((file: FileRestApi) => this.filesParser.parseFile(file)));
    }

    /** Upload file */
    /** TODO:
     * In localhost we face CORS issues.
     * If we need to actively work on file upload,
     * we need to ask from the DevOps team to allow localhost environment in CORS rules.
     */
    uploadFile(
        sas: string,
        file: any,
        onProgress: (progress: any) => void,
        abortSignal: AbortSignal,
    ): Observable<FileUploadResponse> {
        const credentials: AnonymousCredential = new AnonymousCredential();
        const client: BlockBlobClient = new BlockBlobClient(sas, credentials);

        return from(
            client
                .uploadData(file, { onProgress, abortSignal })
                .then((response: FileUploadEnvelopeRestApi<FileUploadResponse>) =>
                    this.filesParser.parseFileUploadEnvelope(response),
                ),
        );
    }

    /**
     * Gets user file uploads, parses them into the desired format and
     * returns an Observable of PaginatedResults<File>.
     */
    getFiles(
        pageIndex: number,
        pageSize: number,
        sortDirection: SortingOrder,
        sortColumn: string,
    ): Observable<PaginatedResults<File>> {
        const serializedOrder: string = this.filesParser.serializeFilesOrderByParameter(
            sortDirection,
            sortColumn,
        );
        return this.http
            .get(`${environment.uploadServerUrl}/uploads/`, {
                params: {
                    pageIndex: (pageIndex + 1).toString(),
                    pageSize: pageSize.toString(),
                    orderBy: serializedOrder,
                },
            })
            .pipe(
                map((paginatedResults: PaginatedResultsRestApi<FileRestApi>) =>
                    this.filesParser.parsePaginatedResults(paginatedResults),
                ),
            );
    }

    /**
     * Delete file request.
     */
    deleteFile(id: string): Observable<void> {
        return this.http
            .delete(`${environment.uploadServerUrl}/uploads/${id}/`)
            .pipe(map(() => undefined));
    }
}
