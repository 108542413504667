/** third-party imports */
import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { of, from } from 'rxjs';
import { switchMap, map, catchError, withLatestFrom } from 'rxjs/operators';

/** custom imports */
import { CombinatorialDiscoveryService } from './services/combinatorial-discovery.service';
import * as DiscoveryActions from './combinatorial-discovery.actions';
import PaginatedInsights from './interfaces/paginated-insights.interface';
import { CombinatorialDiscoveryFacade } from './combinatorial-discovery.facade';
import { CombinatorialDiscoveryInsightsFacade } from '@leap-store/pouchdb/combinatorial-discovery-insights/facade';
import Metadata from './interfaces/metadata.interface';

@Injectable()
export class CombinatorialDiscoveryEffects {
    constructor(
        private actions$: Actions,
        private combinatorialDiscoveryService: CombinatorialDiscoveryService,
        private pouchDBFacade: CombinatorialDiscoveryInsightsFacade,
        private combinatorialDiscoveryFacade: CombinatorialDiscoveryFacade,
    ) {}

    performDiscovery$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DiscoveryActions.performDiscoveryRequest),
            switchMap(({ categories, term, indexFields, sessionId }) =>
                this.combinatorialDiscoveryService.performDiscovery(categories, term).pipe(
                    withLatestFrom(this.combinatorialDiscoveryFacade.sessionId$),
                    map(
                        async ([paginatedInsights, storedSessionId]: [
                            PaginatedInsights,
                            string,
                        ]) => {
                            // If stored session id is different than current session id
                            // it means that discovery was interrupted and we need to break the
                            // flow.
                            if (storedSessionId !== sessionId) {
                                return DiscoveryActions.exitDiscovery();
                            }

                            const metadata: Metadata = {
                                displaying: paginatedInsights.displayingInsights,
                                total: paginatedInsights.total,
                                countPerCategory: paginatedInsights.insightsPerCategory,
                                oldestOccurrence: paginatedInsights.oldestOccurrence,
                                newestOccurrence: paginatedInsights.newestOccurrence,
                                totalRelationshipsAB: paginatedInsights.totalRelationshipsAB,
                                totalRelationshipsBC: paginatedInsights.totalRelationshipsBC,
                                totalRelationshipsAC: paginatedInsights.totalRelationshipsAC,
                                countPerAssociation: paginatedInsights.countPerAssociation,
                                countPerAssociationType: paginatedInsights.countPerAssociationType,
                                countPerRelationshipTypeAB:
                                    paginatedInsights.countPerRelationshipTypeAB,
                                countPerRelationshipTypeBC:
                                    paginatedInsights.countPerRelationshipTypeBC,
                                countPerRelationshipTypeAC:
                                    paginatedInsights.countPerRelationshipTypeAC,
                                countPerRelationshipTypeOriginAB:
                                    paginatedInsights.countPerRelationshipTypeOriginAB,
                                countPerRelationshipTypeOriginBC:
                                    paginatedInsights.countPerRelationshipTypeOriginBC,
                                countPerRelationshipTypeOriginAC:
                                    paginatedInsights.countPerRelationshipTypeOriginAC,
                            };

                            await this.pouchDBFacade.createPouchDB(term, categories);
                            await this.pouchDBFacade.addMetadata(metadata);
                            await this.pouchDBFacade.storeInsights({
                                insights: paginatedInsights.results,
                                fields: indexFields,
                            });
                            await this.pouchDBFacade.getMetadata();
                            await this.pouchDBFacade.getInsights({});
                            return DiscoveryActions.performDiscoverySuccess();
                        },
                    ),
                    switchMap((promise) => from(promise)),
                    catchError(async (errorResponse: HttpErrorResponse) => {
                        if (errorResponse.status === 404) {
                            const metadata: Metadata = {
                                displaying: null,
                                total: null,
                                countPerCategory: null,
                                oldestOccurrence: null,
                                newestOccurrence: null,
                                totalRelationshipsAB: null,
                                totalRelationshipsAC: null,
                                totalRelationshipsBC: null,
                                countPerAssociation: null,
                                countPerAssociationType: null,
                                countPerRelationshipTypeAB: null,
                                countPerRelationshipTypeBC: null,
                                countPerRelationshipTypeAC: null,
                                countPerRelationshipTypeOriginAB: null,
                                countPerRelationshipTypeOriginBC: null,
                                countPerRelationshipTypeOriginAC: null,
                                errorMessage: errorResponse.error.errors[0].message,
                            };

                            await this.pouchDBFacade.createPouchDB(term, categories);
                            await this.pouchDBFacade.addMetadata(metadata);
                            await this.pouchDBFacade.getMetadata();
                        } else {
                            await this.pouchDBFacade.destroyPouchDB(term, categories);
                        }

                        return DiscoveryActions.performDiscoveryFailure({ errorResponse });
                    }),
                ),
            ),
        ),
    );

    fetchDiscovery$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DiscoveryActions.fetchLocalDiscoveryRequest),
            switchMap(async ({ limit, sortingOptions, filters, fetchMetadata }) => {
                await this.pouchDBFacade.getInsights({ limit, sortingOptions, filters });

                if (fetchMetadata) {
                    this.pouchDBFacade.getMetadata();
                }

                return DiscoveryActions.fetchLocalDiscoverySuccess();
            }),
        ),
    );

    downloadDiscovery$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DiscoveryActions.downloadDiscoveryRequest),
            switchMap(({ term, categories, isGrouped }) =>
                this.combinatorialDiscoveryService.download(term, categories, isGrouped).pipe(
                    map((blob: Blob) => DiscoveryActions.downloadDiscoverySuccess({ blob })),
                    catchError((errorResponse: HttpErrorResponse) =>
                        of(DiscoveryActions.downloadDiscoveryFailure({ errorResponse })),
                    ),
                ),
            ),
        ),
    );

    getSuggestions$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DiscoveryActions.getSearchSuggestionsRequest),
            switchMap(({ source, target, target2, query }) =>
                this.combinatorialDiscoveryService
                    .getSearchSuggestions(source, target, target2, query)
                    .pipe(
                        map((suggestionIds: string[]) =>
                            DiscoveryActions.getSearchSuggestionsSuccess({
                                suggestionIds,
                            }),
                        ),
                        catchError((errorResponse: HttpErrorResponse) =>
                            of(
                                DiscoveryActions.getSearchSuggestionsFailure({
                                    errorResponse,
                                }),
                            ),
                        ),
                    ),
            ),
        ),
    );
}
