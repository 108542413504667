import { createAction, props, ActionCreator } from '@ngrx/store';
import { TypedAction } from '@ngrx/store/src/models';
import { HttpErrorResponse } from '@angular/common/http';
import User from '@leap-store/core/src/lib/data/users/interfaces/user.interface';
import UserProperties from '@leap-store/core/src/lib/data/users/interfaces/user-properties.interface';
import { UsersActionTypes } from './users-action-types.enum';
import PaginatedResults from '@leap-common/interfaces/paginated-results.interface';
import SortingOrder from '@leap-common/enums/sorting-order.enum';

export const createUserRequest: ActionCreator<
    UsersActionTypes.CREATE_USER_REQUEST,
    (props: { user: User; app: string }) => {
        user: User;
        app: string;
    } & TypedAction<UsersActionTypes.CREATE_USER_REQUEST>
> = createAction(UsersActionTypes.CREATE_USER_REQUEST, props<{ user: User; app: string }>());

export const createUserSuccess: ActionCreator<
    UsersActionTypes.CREATE_USER_SUCCESS,
    (props: { createdUser: User }) => {
        createdUser: User;
    } & TypedAction<UsersActionTypes.CREATE_USER_SUCCESS>
> = createAction(UsersActionTypes.CREATE_USER_SUCCESS, props<{ createdUser: User }>());

export const createUserFailure: ActionCreator<
    UsersActionTypes.CREATE_USER_FAILURE,
    (props: { errorResponse: HttpErrorResponse }) => {
        errorResponse: HttpErrorResponse;
    } & TypedAction<UsersActionTypes.CREATE_USER_FAILURE>
> = createAction(
    UsersActionTypes.CREATE_USER_FAILURE,
    props<{ errorResponse: HttpErrorResponse }>(),
);

export const getUserRequest: ActionCreator<
    UsersActionTypes.GET_USER_REQUEST,
    (props: { id: string }) => {
        id: string;
    } & TypedAction<UsersActionTypes.GET_USER_REQUEST>
> = createAction(UsersActionTypes.GET_USER_REQUEST, props<{ id: string }>());

export const getUserSuccess: ActionCreator<
    UsersActionTypes.GET_USER_SUCCESS,
    (props: { user: User }) => {
        user: User;
    } & TypedAction<UsersActionTypes.GET_USER_SUCCESS>
> = createAction(UsersActionTypes.GET_USER_SUCCESS, props<{ user: User }>());

export const getUserFailure: ActionCreator<
    UsersActionTypes.GET_USER_FAILURE,
    (props: { errorResponse: HttpErrorResponse }) => {
        errorResponse: HttpErrorResponse;
    } & TypedAction<UsersActionTypes.GET_USER_FAILURE>
> = createAction(UsersActionTypes.GET_USER_FAILURE, props<{ errorResponse: HttpErrorResponse }>());

export const updateUserRequest: ActionCreator<
    UsersActionTypes.UPDATE_USER_REQUEST,
    (props: { id: string; properties: UserProperties }) => {
        id: string;
        properties: UserProperties;
    } & TypedAction<UsersActionTypes.UPDATE_USER_REQUEST>
> = createAction(
    UsersActionTypes.UPDATE_USER_REQUEST,
    props<{ id: string; properties: UserProperties }>(),
);

export const updateUserSuccess: ActionCreator<
    UsersActionTypes.UPDATE_USER_SUCCESS,
    (props: { user: User }) => {
        user: User;
    } & TypedAction<UsersActionTypes.UPDATE_USER_SUCCESS>
> = createAction(UsersActionTypes.UPDATE_USER_SUCCESS, props<{ user: User }>());

export const updateUserFailure: ActionCreator<
    UsersActionTypes.UPDATE_USER_FAILURE,
    (props: { id: string; errorResponse: HttpErrorResponse }) => {
        id: string;
        errorResponse: HttpErrorResponse;
    } & TypedAction<UsersActionTypes.UPDATE_USER_FAILURE>
> = createAction(
    UsersActionTypes.UPDATE_USER_FAILURE,
    props<{ id: string; errorResponse: HttpErrorResponse }>(),
);

export const deleteUserRequest: ActionCreator<
    UsersActionTypes.DELETE_USER_REQUEST,
    (props: { id: string }) => {
        id: string;
    } & TypedAction<UsersActionTypes.DELETE_USER_REQUEST>
> = createAction(UsersActionTypes.DELETE_USER_REQUEST, props<{ id: string }>());

export const deleteUserSuccess: ActionCreator<
    UsersActionTypes.DELETE_USER_SUCCESS,
    (props: { user: User }) => {
        user: User;
    } & TypedAction<UsersActionTypes.DELETE_USER_SUCCESS>
> = createAction(UsersActionTypes.DELETE_USER_SUCCESS, props<{ user: User }>());

export const deleteUserFailure: ActionCreator<
    UsersActionTypes.DELETE_USER_FAILURE,
    (props: { id: string; errorResponse: HttpErrorResponse }) => {
        id: string;
        errorResponse: HttpErrorResponse;
    } & TypedAction<UsersActionTypes.DELETE_USER_FAILURE>
> = createAction(
    UsersActionTypes.DELETE_USER_FAILURE,
    props<{ id: string; errorResponse: HttpErrorResponse }>(),
);

export const deleteUsersRequest: ActionCreator<
    UsersActionTypes.DELETE_USERS_REQUEST,
    (props: { ids: string[] }) => {
        ids: string[];
    } & TypedAction<UsersActionTypes.DELETE_USERS_REQUEST>
> = createAction(UsersActionTypes.DELETE_USERS_REQUEST, props<{ ids: string[] }>());

export const deleteUsersSuccess: ActionCreator<
    UsersActionTypes.DELETE_USERS_SUCCESS,
    (props: { paginatedUsers: PaginatedResults<User> }) => {
        paginatedUsers: PaginatedResults<User>;
    } & TypedAction<UsersActionTypes.DELETE_USERS_SUCCESS>
> = createAction(
    UsersActionTypes.DELETE_USERS_SUCCESS,
    props<{ paginatedUsers: PaginatedResults<User> }>(),
);

export const deleteUsersFailure: ActionCreator<
    UsersActionTypes.DELETE_USERS_FAILURE,
    (props: { ids: string[]; errorResponse: HttpErrorResponse }) => {
        ids: string[];
        errorResponse: HttpErrorResponse;
    } & TypedAction<UsersActionTypes.DELETE_USERS_FAILURE>
> = createAction(
    UsersActionTypes.DELETE_USERS_FAILURE,
    props<{ ids: string[]; errorResponse: HttpErrorResponse }>(),
);

export const getUsersRequest: ActionCreator<
    UsersActionTypes.GET_USERS_REQUEST,
    (props: {
        currentUserEmail: string;
        pageIndex: number;
        pageSize: number;
        sortDirection: SortingOrder;
        sortColumn: string;
        app: string;
        department: string;
        search: string;
    }) => {
        currentUserEmail: string;
        pageIndex: number;
        pageSize: number;
        sortDirection: SortingOrder;
        sortColumn: string;
        app: string;
        department: string;
        search: string;
    } & TypedAction<UsersActionTypes.GET_USERS_REQUEST>
> = createAction(
    UsersActionTypes.GET_USERS_REQUEST,
    props<{
        currentUserEmail: string;
        pageIndex: number;
        pageSize: number;
        sortDirection: SortingOrder;
        sortColumn: string;
        app: string;
        department: string;
        search: string;
    }>(),
);

export const getUsersSuccess: ActionCreator<
    UsersActionTypes.GET_USERS_SUCCESS,
    (props: {
        paginatedUsers: PaginatedResults<User>;
        sortDirection: SortingOrder;
        sortColumn: string;
    }) => {
        paginatedUsers: PaginatedResults<User>;
        sortDirection: SortingOrder;
        sortColumn: string;
    } & TypedAction<UsersActionTypes.GET_USERS_SUCCESS>
> = createAction(
    UsersActionTypes.GET_USERS_SUCCESS,
    props<{
        paginatedUsers: PaginatedResults<User>;
        sortDirection: SortingOrder;
        sortColumn: string;
    }>(),
);

export const getUsersFailure: ActionCreator<
    UsersActionTypes.GET_USERS_FAILURE,
    (props: { errorResponse: HttpErrorResponse }) => {
        errorResponse: HttpErrorResponse;
    } & TypedAction<UsersActionTypes.GET_USERS_FAILURE>
> = createAction(UsersActionTypes.GET_USERS_FAILURE, props<{ errorResponse: HttpErrorResponse }>());

export const clearNextSuccess: ActionCreator<
    UsersActionTypes.CLEAR_NEXT_SUCCESS,
    () => TypedAction<UsersActionTypes.CLEAR_NEXT_SUCCESS>
> = createAction(UsersActionTypes.CLEAR_NEXT_SUCCESS);

export const clearNextError: ActionCreator<
    UsersActionTypes.CLEAR_NEXT_ERROR,
    () => TypedAction<UsersActionTypes.CLEAR_NEXT_ERROR>
> = createAction(UsersActionTypes.CLEAR_NEXT_ERROR);

export const clearShouldFetchUsers = createAction(UsersActionTypes.CLEAR_SHOULD_FETCH_USERS);
