/** third-party imports */
import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';

/**  custom imports */
import { IngredientComparisonState } from './ingredient-comparison-state.interface';
import {
    getIngredientsRequest,
    getBioactivityRequest,
    getAminoAcidsRequest,
    getMolecularWeightsRequest,
    clearNextError,
} from './ingredient-comparison.actions';
import {
    getIngredients,
    getIngredientsLoading,
    getIngredientsLoaded,
    getBioactivity,
    getBioactivityLoading,
    getBioactivityLoaded,
    getAminoAcids,
    getAminoAcidsLoading,
    getAminoAcidsLoaded,
    getMolecularWeights,
    getMolecularWeightsLoading,
    getMolecularWeightsLoaded,
    getErrors,
} from './ingredient-comparison.selectors';
import ErrorResponse from '@leap-common/interfaces/error-response.interface';
import Ingredient from '../ingredient-viewer/interfaces/ingredient.interface';
import Bioactivity from './interfaces/bioactivity.interface';
import MultiLineChartDataItem from '@leap-libs/charts/src/lib/interfaces/multi-line-chart-data-item.interface';
import StatisticsItem from '../report/interfaces/statistics-item.interface';

@Injectable()
export class IngredientComparisonFacade {
    errors$: Observable<ErrorResponse[]> = this.store.pipe(select(getErrors));
    ingredients$: Observable<Ingredient[]> = this.store.pipe(select(getIngredients));
    ingredientsLoading$: Observable<boolean> = this.store.pipe(select(getIngredientsLoading));
    ingredientsLoaded$: Observable<boolean> = this.store.pipe(select(getIngredientsLoaded));
    bioactivity$: Observable<Bioactivity[]> = this.store.pipe(select(getBioactivity));
    bioactivityLoading$: Observable<boolean> = this.store.pipe(select(getBioactivityLoading));
    bioactivityLoaded$: Observable<boolean> = this.store.pipe(select(getBioactivityLoaded));
    aminoAcids$: Observable<Record<string, StatisticsItem[]>> = this.store.pipe(
        select(getAminoAcids),
    );
    aminoAcidsLoading$: Observable<boolean> = this.store.pipe(select(getAminoAcidsLoading));
    aminoAcidsLoaded$: Observable<boolean> = this.store.pipe(select(getAminoAcidsLoaded));
    molecularWeights$: Observable<MultiLineChartDataItem[]> = this.store.pipe(
        select(getMolecularWeights),
    );
    molecularWeightsLoading$: Observable<boolean> = this.store.pipe(
        select(getMolecularWeightsLoading),
    );
    molecularWeightsLoaded$: Observable<boolean> = this.store.pipe(
        select(getMolecularWeightsLoaded),
    );

    constructor(private store: Store<IngredientComparisonState>) {}

    getIngredients(ids: string[]): void {
        this.store.dispatch(getIngredientsRequest({ ids }));
    }

    getBioactivity(ingredientIds: string[], baselineIngredient: string): void {
        this.store.dispatch(
            getBioactivityRequest({ ingredientIds, baselineId: baselineIngredient }),
        );
    }

    getAminoAcids(ingredientIds: string[]): void {
        this.store.dispatch(getAminoAcidsRequest({ ingredientIds }));
    }

    getMolecularWeights(ingredientIds: string[]): void {
        this.store.dispatch(getMolecularWeightsRequest({ ingredientIds }));
    }

    clearNextError(): void {
        this.store.dispatch(clearNextError());
    }
}
