import { createAction, props } from '@ngrx/store';
import { AlertsActionTypes } from './alerts-action-types.enum';
import AlertEvent from './interfaces/alert-event.interface';

export const pushAlertEvent = createAction(
    AlertsActionTypes.PUSH_ALERT_EVENT,
    props<{ alertEvent: AlertEvent }>(),
);

export const removeAlertEvent = createAction(
    AlertsActionTypes.REMOVE_ALERT_EVENT,
    props<{ id: string; shouldRemovePersistent: boolean }>(),
);

export const clearAlertEvents = createAction(
    AlertsActionTypes.CLEAR_ALERT_EVENTS,
    props<{ shouldRemovePersistent: boolean }>(),
);
