import { createSelector } from '@ngrx/store';
import { getUIState } from '../index';
import { UIState } from '../ui-state.interface';
import * as fromProjects from './projects.reducer';

export const getProjectsState = createSelector(getUIState, (state: UIState) => state.projects);

export const getSearchFilter = createSelector(getProjectsState, fromProjects.getSearchFilter);
export const getStatusFilters = createSelector(getProjectsState, fromProjects.getStatusFilters);
export const getTypeFilters = createSelector(getProjectsState, fromProjects.getTypeFilters);
export const getSortDirection = createSelector(getProjectsState, fromProjects.getSortDirection);
export const getSortColumn = createSelector(getProjectsState, fromProjects.getSortColumn);
