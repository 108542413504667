import { createAction, props } from '@ngrx/store';
import { HttpErrorResponse } from '@angular/common/http';
import { PipelineExecutionsActionTypes } from './pipeline-executions-action-types.enum';
import PaginatedResults from '@leap-common/interfaces/paginated-results.interface';
import PipelineExecution from './interfaces/pipeline-execution.interface';
import File from '@leap-store/core/src/lib/data/files/interfaces/file.interface';
import SortingOrder from '@leap-common/enums/sorting-order.enum';

export const getPipelineExecutionsRequest = createAction(
    PipelineExecutionsActionTypes.GET_PIPELINE_EXECUTIONS_REQUEST,
    props<{
        pageIndex: number;
        pageSize: number;
        sortDirection: SortingOrder;
        sortColumn: string;
    }>(),
);

export const getPipelineExecutionsSuccess = createAction(
    PipelineExecutionsActionTypes.GET_PIPELINE_EXECUTIONS_SUCCESS,
    props<{
        paginatedPipelineExecutions: PaginatedResults<PipelineExecution>;
        sortDirection: SortingOrder;
        sortColumn: string;
    }>(),
);

export const getPipelineExecutionsFailure = createAction(
    PipelineExecutionsActionTypes.GET_PIPELINE_EXECUTIONS_FAILURE,
    props<{ errorResponse: HttpErrorResponse }>(),
);

export const getPipelineResultRequest = createAction(
    PipelineExecutionsActionTypes.GET_PIPELINE_RESULT_REQUEST,
    props<{
        id: string;
    }>(),
);

export const getPipelineResultSuccess = createAction(
    PipelineExecutionsActionTypes.GET_PIPELINE_RESULT_SUCCESS,
    props<{
        pipelineResult: File;
    }>(),
);

export const getPipelineResultFailure = createAction(
    PipelineExecutionsActionTypes.GET_PIPELINE_RESULT_FAILURE,
    props<{ errorResponse: HttpErrorResponse }>(),
);

export const clearDownloadableResult = createAction(
    PipelineExecutionsActionTypes.CLEAR_DOWNLOADABLE_RESULT,
);

export const clearNextError = createAction(PipelineExecutionsActionTypes.CLEAR_NEXT_ERROR);
