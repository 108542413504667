/** third-party imports */
import { createReducer, on, Action, ActionReducer } from '@ngrx/store';
import { EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { HttpErrorResponse } from '@angular/common/http';

/** custom imports */
import * as notebooksActions from './notebooks.actions';
import ErrorResponse from '@leap-common/interfaces/error-response.interface';
import { NotebooksState } from './notebooks-state.interface';
import PaginatedResults from '@leap-common/interfaces/paginated-results.interface';
import Notebook from './interfaces/notebook.interface';
import OwnershipType from './enums/ownership-type.enum';
import SortingOrder from '@leap-common/enums/sorting-order.enum';

export const adapter: EntityAdapter<Notebook> = createEntityAdapter<Notebook>();

export const initialState: NotebooksState = adapter.getInitialState({
    notebook: undefined,
    notebookLoading: false,
    notebookLoaded: false,
    templates: [],
    error: [],
    loading: false,
    loaded: false,
    pageIndex: 0,
    pageSize: 0,
    total: 0,
    sortDirection: SortingOrder.descending,
    sortColumn: 'creationDate',
    deletePendingItems: [],
    shouldFetchNotebooks: false,
});

const notebooksReducer: ActionReducer<NotebooksState, Action> = createReducer(
    initialState,
    on(notebooksActions.getNotebooksRequest, (state: NotebooksState) => ({
        ...state,
        loading: true,
        loaded: false,
    })),
    on(
        notebooksActions.getNotebooksSuccess,
        (
            state: NotebooksState,
            {
                paginatedNotebooks,
                sortDirection,
                sortColumn,
                ownerType,
            }: {
                paginatedNotebooks: PaginatedResults<Notebook>;
                sortDirection: SortingOrder;
                sortColumn: string;
                ownerType: string;
            },
        ) =>
            ownerType === OwnershipType.templates
                ? { ...state, templates: paginatedNotebooks.results }
                : adapter.setAll(paginatedNotebooks.results, {
                      ...state,
                      loading: false,
                      loaded: true,
                      pageIndex: paginatedNotebooks.pageIndex,
                      pageSize: paginatedNotebooks.pageSize,
                      total: paginatedNotebooks.total,
                      sortDirection,
                      sortColumn,
                  }),
    ),
    on(
        notebooksActions.getNotebooksFailure,
        (
            state: NotebooksState,
            { errorResponse, suppress }: { errorResponse: HttpErrorResponse; suppress: boolean },
        ) => ({
            ...state,
            error: suppress ? [...state.error] : [...state.error, errorResponse.error],
            loading: suppress,
            loaded: false,
        }),
    ),
    on(notebooksActions.getNotebookRequest, (state: NotebooksState) => ({
        ...state,
        notebook: null as Notebook,
        notebookLoading: true,
        notebookLoaded: false,
    })),
    on(
        notebooksActions.getNotebookSuccess,
        (state: NotebooksState, { notebook }: { notebook: Notebook }) => ({
            ...state,
            notebook,
            notebookLoading: false,
            notebookLoaded: true,
        }),
    ),
    on(
        notebooksActions.getNotebookFailure,
        (state: NotebooksState, { errorResponse }: { errorResponse: HttpErrorResponse }) => ({
            ...state,
            error: [...state.error, errorResponse.error],
            notebookLoading: false,
            notebookLoaded: false,
        }),
    ),
    on(notebooksActions.createNotebookRequest, (state: NotebooksState) => ({
        ...state,
        notebook: null as Notebook,
    })),
    on(
        notebooksActions.createNotebookSuccess,
        (state: NotebooksState, { notebook }: { notebook: Notebook }) => ({
            ...state,
            entities: { ...state.entities, [notebook.id]: notebook },
            notebook,
        }),
    ),
    on(
        notebooksActions.createNotebookFailure,
        (state: NotebooksState, { errorResponse }: { errorResponse: HttpErrorResponse }) => ({
            ...state,
            error: [...state.error, errorResponse.error],
        }),
    ),
    on(notebooksActions.cloneNotebookRequest, (state: NotebooksState) => ({
        ...state,
        notebook: null as Notebook,
    })),
    on(
        notebooksActions.cloneNotebookSuccess,
        (state: NotebooksState, { notebook }: { notebook: Notebook }) => ({
            ...state,
            entities: { ...state.entities, [notebook.id]: notebook },
            notebook,
        }),
    ),
    on(
        notebooksActions.cloneNotebookFailure,
        (state: NotebooksState, { errorResponse }: { errorResponse: HttpErrorResponse }) => ({
            ...state,
            error: [...state.error, errorResponse.error],
        }),
    ),
    on(
        notebooksActions.updateNotebookSuccess,
        (state: NotebooksState, { notebook }: { notebook: Notebook }) =>
            adapter.upsertOne(notebook, {
                ...state,
                shouldFetchNotebooks: true,
            }),
    ),
    on(
        notebooksActions.updateNotebookFailure,
        (state: NotebooksState, { errorResponse }: { errorResponse: HttpErrorResponse }) => ({
            ...state,
            error: [...state.error, errorResponse.error],
        }),
    ),
    on(notebooksActions.deleteNotebookRequest, (state: NotebooksState, { id }: { id: string }) => ({
        ...state,
        deletePendingItems: [...state.deletePendingItems, id],
    })),
    on(notebooksActions.deleteNotebookSuccess, (state: NotebooksState, { id }: { id: string }) =>
        adapter.removeOne(id, {
            ...state,
            shouldFetchNotebooks: true,
            deletePendingItems: state.deletePendingItems.filter(
                (pendingItem: string) => pendingItem !== id,
            ),
        }),
    ),
    on(
        notebooksActions.deleteNotebookFailure,
        (
            state: NotebooksState,
            { id, errorResponse }: { id: string; errorResponse: HttpErrorResponse },
        ) => ({
            ...state,
            error: [...state.error, errorResponse.error],
            deletePendingItems: state.deletePendingItems.filter(
                (pendingItem: string) => pendingItem !== id,
            ),
        }),
    ),
    on(notebooksActions.clearNextError, (state: NotebooksState) => ({
        ...state,
        error: state.error.slice(1),
    })),
    on(notebooksActions.clearShouldFetchNotebooks, (state: NotebooksState) => ({
        ...state,
        shouldFetchNotebooks: false,
    })),
);

export const reducer = (state: NotebooksState | undefined, action: Action): NotebooksState =>
    notebooksReducer(state, action);

// selectors
export const getNotebooksEntities: (state: NotebooksState) => Notebook[] =
    adapter.getSelectors().selectAll;
export const getNotebook: (state: NotebooksState) => Notebook = (state: NotebooksState) =>
    state.notebook;
export const getNotebookLoading: (state: NotebooksState) => boolean = (state: NotebooksState) =>
    state.notebookLoading;
export const getNotebookLoaded: (state: NotebooksState) => boolean = (state: NotebooksState) =>
    state.notebookLoaded;
export const getTemplates: (state: NotebooksState) => Notebook[] = (state: NotebooksState) =>
    state.templates;
export const getError: (state: NotebooksState) => ErrorResponse[] = (state: NotebooksState) =>
    state.error;
export const getLoading: (state: NotebooksState) => boolean = (state: NotebooksState) =>
    state.loading;
export const getLoaded: (state: NotebooksState) => boolean = (state: NotebooksState) =>
    state.loaded;
export const getPageIndex: (state: NotebooksState) => number = (state: NotebooksState) =>
    state.pageIndex;
export const getPageSize: (state: NotebooksState) => number = (state: NotebooksState) =>
    state.pageSize;
export const getTotal: (state: NotebooksState) => number = (state: NotebooksState) => state.total;
export const getSortDirection: (state: NotebooksState) => SortingOrder = (state: NotebooksState) =>
    state.sortDirection;
export const getSortColumn: (state: NotebooksState) => string = (state: NotebooksState) =>
    state.sortColumn;
export const getDeletePendingItems: (state: NotebooksState) => string[] = (state: NotebooksState) =>
    state.deletePendingItems;
export const getShouldFetchNotebooks: (state: NotebooksState) => boolean = (
    state: NotebooksState,
) => state.shouldFetchNotebooks;
