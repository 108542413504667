import { createAction, props } from '@ngrx/store';
import { DiscoveryActionTypes } from './discovery-action-types.enum';
import TabId from '@apps/leap/src/app/shared/enums/tab-id.enum';
import NetworkView from './enums/network-view.enum';
import DiscoveryExecution from './interfaces/discovery-execution.interface';
import Discovery from '@apps/leap/src/app/shared/enums/discovery.enum';
import DiscoverySummary from './interfaces/discovery-summary.interface';

export const updateActiveTabId = createAction(
    DiscoveryActionTypes.UPDATE_ACTIVE_TAB_ID,
    props<{ id: TabId }>(),
);

export const updateActiveNetworkView = createAction(
    DiscoveryActionTypes.UPDATE_ACTIVE_NETWORK_VIEW,
    props<{ view: NetworkView }>(),
);

export const updateExecution = createAction(
    DiscoveryActionTypes.UPDATE_EXECUTION,
    props<{ execution: DiscoveryExecution }>(),
);

export const updateDiscovery = createAction(
    DiscoveryActionTypes.UPDATE_DISCOVERY,
    props<{ discovery: Discovery }>(),
);

export const updateSummary = createAction(
    DiscoveryActionTypes.UPDATE_SUMMARY,
    props<{ summary: DiscoverySummary }>(),
);
