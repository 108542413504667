export enum OpenDiscoveryActionTypes {
    PERFORM_DISCOVERY_REQUEST = '[DISCOVERY][OPEN] Perform Discovery Request',
    PERFORM_DISCOVERY_SUCCESS = '[DISCOVERY][OPEN] Perform Discovery Success',
    PERFORM_DISCOVERY_FAILURE = '[DISCOVERY][OPEN] Perform Discovery Failure',
    DOWNLOAD_DISCOVERY_REQUEST = '[DISCOVERY][OPEN] Download Discovery Request',
    DOWNLOAD_DISCOVERY_SUCCESS = '[DISCOVERY][OPEN] Download Discovery Success',
    DOWNLOAD_DISCOVERY_FAILURE = '[DISCOVERY][OPEN] Download Discovery Failure',
    GET_SEARCH_SUGGESTIONS_REQUEST = '[DISCOVERY][OPEN] Get Search Suggestions Request',
    GET_SEARCH_SUGGESTIONS_SUCCESS = '[DISCOVERY][OPEN] Get Search Suggestions Success',
    GET_SEARCH_SUGGESTIONS_FAILURE = '[DISCOVERY][OPEN] Get Search Suggestions Failure',
    CLEAR_SEARCH_SUGGESTIONS = '[DISCOVERY][OPEN] Clear Search Suggestions',
    RESET_DISCOVERY = '[DISCOVERY][OPEN] Reset Discovery',
    CLEAR_NEXT_ERROR = '[DISCOVERY][OPEN] Clear Next Error',
    SAVE_SESSION_ID = '[DISCOVERY][OPEN] Save Session Id',
}
