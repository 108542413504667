/** third-party imports */
import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';

/**  custom imports */
import { ConversationalSearchState } from './conversational-search-state.interface';
import {
    performQueryRequest,
    clearNextError,
    initializeSession,
    deleteSession,
} from './conversational-search.actions';
import {
    getQueries,
    getTabIds,
    getLoading,
    getLoaded,
    getErrors,
} from './conversational-search.selectors';
import ErrorResponse from '@leap-common/interfaces/error-response.interface';
import SearchQuery from './interfaces/search-query.interface';

@Injectable()
export class ConversationalSearchFacade {
    queries$: Observable<Record<string, SearchQuery[]>> = this.store.pipe(select(getQueries));
    tabIds$: Observable<string[]> = this.store.pipe(select(getTabIds));
    loading$: Observable<Record<string, boolean>> = this.store.pipe(select(getLoading));
    loaded$: Observable<Record<string, boolean>> = this.store.pipe(select(getLoaded));
    errors$: Observable<ErrorResponse[]> = this.store.pipe(select(getErrors));

    constructor(private store: Store<ConversationalSearchState>) {}

    performQuery(query: string, tabId: string, sessionId?: string): void {
        this.store.dispatch(
            performQueryRequest({
                query,
                tabId,
                sessionId,
            }),
        );
    }

    initializeSession(tabId: string, hasInitialText?: boolean): void {
        this.store.dispatch(initializeSession({ tabId, hasInitialText }));
    }

    deleteSession(tabId: string): void {
        this.store.dispatch(deleteSession({ tabId }));
    }

    clearNextError(): void {
        this.store.dispatch(clearNextError());
    }
}
