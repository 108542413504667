/** third-party imports */
import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';

/**  custom imports */
import { ArticlesReportState } from './articles-report-state.interface';
import {
    getOverviewRequest,
    getAssociationsRequest,
    getKeyFindingsRequest,
    getTermSummariesRequest,
    getCriticalAnalysisRequest,
    getTheoreticalFrameworkRequest,
    getReferencesRequest,
    downloadReportRequest,
    resetReport,
    clearNextError,
} from './articles-report.actions';
import {
    getErrors,
    getOverview,
    getOverviewLoading,
    getOverviewLoaded,
    getAssociations,
    getAssociationsLoading,
    getAssociationsLoaded,
    getKeyFindings,
    getKeyFindingsLoading,
    getKeyFindingsLoaded,
    getTermSummaries,
    getTermSummariesLoading,
    getTermSummariesLoaded,
    getCriticalAnalysis,
    getCriticalAnalysisLoading,
    getCriticalAnalysisLoaded,
    getTheoreticalFrameworkItems,
    getTheoreticalFrameworkLoading,
    getTheoreticalFrameworkLoaded,
    getReferences,
    getReferencesLoading,
    getReferencesLoaded,
    getBlob,
} from './articles-report.selectors';
import ErrorResponse from '@leap-common/interfaces/error-response.interface';
import Associations from './interfaces/associations.interface';
import KeyFinding from './interfaces/key-finding.interface';
import TheoreticalFrameworkItem from './interfaces/theoretical-framework-item.interface';
import BookmarkArticle from '../bookmarks/types/article.type';
import Reference from '../report/interfaces/reference.interface';
import Summary from '../report/interfaces/summary.interface';

@Injectable()
export class ArticlesReportFacade {
    errors$: Observable<ErrorResponse[]> = this.store.pipe(select(getErrors));
    overview$: Observable<string> = this.store.pipe(select(getOverview));
    overviewLoading$: Observable<boolean> = this.store.pipe(select(getOverviewLoading));
    overviewLoaded$: Observable<boolean> = this.store.pipe(select(getOverviewLoaded));
    associations$: Observable<Associations> = this.store.pipe(select(getAssociations));
    associationsLoading$: Observable<boolean> = this.store.pipe(select(getAssociationsLoading));
    associationsLoaded$: Observable<boolean> = this.store.pipe(select(getAssociationsLoaded));
    keyFindings$: Observable<KeyFinding[]> = this.store.pipe(select(getKeyFindings));
    keyFindingsLoading$: Observable<boolean> = this.store.pipe(select(getKeyFindingsLoading));
    keyFindingsLoaded$: Observable<boolean> = this.store.pipe(select(getKeyFindingsLoaded));
    termSummaries$: Observable<Summary[]> = this.store.pipe(select(getTermSummaries));
    termSummariesLoading$: Observable<boolean> = this.store.pipe(select(getTermSummariesLoading));
    termSummariesLoaded$: Observable<boolean> = this.store.pipe(select(getTermSummariesLoaded));
    criticalAnalysis$: Observable<string> = this.store.pipe(select(getCriticalAnalysis));
    criticalAnalysisLoading$: Observable<boolean> = this.store.pipe(
        select(getCriticalAnalysisLoading),
    );
    criticalAnalysisLoaded$: Observable<boolean> = this.store.pipe(
        select(getCriticalAnalysisLoaded),
    );
    theoreticalFrameworkItems$: Observable<TheoreticalFrameworkItem[]> = this.store.pipe(
        select(getTheoreticalFrameworkItems),
    );
    theoreticalFrameworkLoading$: Observable<boolean> = this.store.pipe(
        select(getTheoreticalFrameworkLoading),
    );
    theoreticalFrameworkLoaded$: Observable<boolean> = this.store.pipe(
        select(getTheoreticalFrameworkLoaded),
    );
    references$: Observable<Reference[]> = this.store.pipe(select(getReferences));
    referencesLoading$: Observable<boolean> = this.store.pipe(select(getReferencesLoading));
    referencesLoaded$: Observable<boolean> = this.store.pipe(select(getReferencesLoaded));
    blob$: Observable<Blob> = this.store.pipe(select(getBlob));

    constructor(private store: Store<ArticlesReportState>) {}

    getOverview({
        projectId,
        bookmarkIds,
        shouldRegenerate,
    }: {
        projectId: string;
        bookmarkIds: string[];
        shouldRegenerate?: boolean;
    }): void {
        this.store.dispatch(getOverviewRequest({ projectId, bookmarkIds, shouldRegenerate }));
    }

    getAssociations({
        projectId,
        bookmarkIds,
    }: {
        projectId: string;
        bookmarkIds: string[];
    }): void {
        this.store.dispatch(getAssociationsRequest({ projectId, bookmarkIds }));
    }

    getKeyFindings({
        projectId,
        bookmarks,
        shouldRegenerate,
    }: {
        projectId: string;
        bookmarks: BookmarkArticle[];
        shouldRegenerate?: boolean;
    }): void {
        this.store.dispatch(getKeyFindingsRequest({ projectId, bookmarks, shouldRegenerate }));
    }

    getTermSummaries({
        projectId,
        bookmarkIds,
        shouldRegenerate,
    }: {
        projectId: string;
        bookmarkIds: string[];
        shouldRegenerate: boolean;
    }): void {
        this.store.dispatch(getTermSummariesRequest({ projectId, bookmarkIds, shouldRegenerate }));
    }

    getCriticalAnalysis({
        projectId,
        bookmarkIds,
        shouldRegenerate,
    }: {
        projectId: string;
        bookmarkIds: string[];
        shouldRegenerate?: boolean;
    }): void {
        this.store.dispatch(
            getCriticalAnalysisRequest({ projectId, bookmarkIds, shouldRegenerate }),
        );
    }

    getTheoreticalFramework({
        projectId,
        bookmarks,
        shouldRegenerate,
    }: {
        projectId: string;
        bookmarks: BookmarkArticle[];
        shouldRegenerate?: boolean;
    }): void {
        this.store.dispatch(
            getTheoreticalFrameworkRequest({ projectId, bookmarks, shouldRegenerate }),
        );
    }

    getReferences({ projectId, bookmarkIds }: { projectId: string; bookmarkIds: string[] }): void {
        this.store.dispatch(getReferencesRequest({ projectId, bookmarkIds }));
    }

    downloadReport({ projectId, bookmarkIds }: { projectId: string; bookmarkIds: string[] }): void {
        this.store.dispatch(downloadReportRequest({ projectId, bookmarkIds }));
    }

    resetReport(): void {
        this.store.dispatch(resetReport());
    }

    clearNextError(): void {
        this.store.dispatch(clearNextError());
    }
}
