/** third-party imports */
import { createAction, props } from '@ngrx/store';

/** custom imports */
import { IngredientManagementActionTypes } from './ingredient-management-action-types.enum';
import { HttpErrorResponse } from '@angular/common/http';
import SortingOptions from '@leap-common/interfaces/sorting-options.interface';
import PaginatedResults from '@leap-common/interfaces/paginated-results.interface';
import File from '@leap-store/core/src/lib/data/files/interfaces/file.interface';
import Ingredient from '@leap-store/core/src/lib/data/ingredient-viewer/interfaces/ingredient.interface';
import FileUploadResult from './interfaces/file-upload-result.interface';

export const uploadFileRequest = createAction(
    IngredientManagementActionTypes.UPLOAD_FILE_REQUEST,
    props<{ file: File }>(),
);

export const uploadFileSuccess = createAction(
    IngredientManagementActionTypes.UPLOAD_FILE_SUCCESS,
    props<{ result: FileUploadResult }>(),
);

export const uploadFileFailure = createAction(
    IngredientManagementActionTypes.UPLOAD_FILE_FAILURE,
    props<{ errorResponse: HttpErrorResponse; result: FileUploadResult }>(),
);

export const getIngredientsRequest = createAction(
    IngredientManagementActionTypes.GET_INGREDIENTS_REQUEST,
    props<{
        pageSize: number;
        pageIndex: number;
        searchQuery: string;
        sortingOptions: SortingOptions;
        suppressLoading: boolean;
        ids: string[];
    }>(),
);

export const getIngredientsSuccess = createAction(
    IngredientManagementActionTypes.GET_INGREDIENTS_SUCCESS,
    props<{ paginatedIngredients: PaginatedResults<Ingredient> }>(),
);

export const getIngredientsFailure = createAction(
    IngredientManagementActionTypes.GET_INGREDIENTS_FAILURE,
    props<{ errorResponse: HttpErrorResponse }>(),
);

export const updateNameRequest = createAction(
    IngredientManagementActionTypes.UPDATE_NAME_REQUEST,
    props<{ id: string; name: string }>(),
);

export const updateNameSuccess = createAction(
    IngredientManagementActionTypes.UPDATE_NAME_SUCCESS,
    props<{ ingredient: Ingredient }>(),
);

export const updateNameFailure = createAction(
    IngredientManagementActionTypes.UPDATE_NAME_FAILURE,
    props<{ errorResponse: HttpErrorResponse }>(),
);

export const updateNotesRequest = createAction(
    IngredientManagementActionTypes.UPDATE_NOTES_REQUEST,
    props<{ id: string; notes: string }>(),
);

export const updateNotesSuccess = createAction(
    IngredientManagementActionTypes.UPDATE_NOTES_SUCCESS,
);

export const updateNotesFailure = createAction(
    IngredientManagementActionTypes.UPDATE_NOTES_FAILURE,
    props<{ errorResponse: HttpErrorResponse }>(),
);

export const downloadIngredientRequest = createAction(
    IngredientManagementActionTypes.DOWNLOAD_INGREDIENT_REQUEST,
    props<{ id: string }>(),
);

export const downloadIngredientSuccess = createAction(
    IngredientManagementActionTypes.DOWNLOAD_INGREDIENT_SUCCESS,
    props<{ blob: Blob }>(),
);

export const downloadIngredientFailure = createAction(
    IngredientManagementActionTypes.DOWNLOAD_INGREDIENT_FAILURE,
    props<{ errorResponse: HttpErrorResponse }>(),
);

export const deleteIngredientRequest = createAction(
    IngredientManagementActionTypes.DELETE_INGREDIENT_REQUEST,
    props<{ id: string }>(),
);

export const deleteIngredientSuccess = createAction(
    IngredientManagementActionTypes.DELETE_INGREDIENT_SUCCESS,
    props<{ id: string }>(),
);

export const deleteIngredientFailure = createAction(
    IngredientManagementActionTypes.DELETE_INGREDIENT_FAILURE,
    props<{ id: string; errorResponse: HttpErrorResponse }>(),
);

export const downloadTemplateRequest = createAction(
    IngredientManagementActionTypes.DOWNLOAD_TEMPLATE_REQUEST,
);

export const downloadTemplateSuccess = createAction(
    IngredientManagementActionTypes.DOWNLOAD_TEMPLATE_SUCCESS,
    props<{ blob: Blob }>(),
);

export const downloadTemplateFailure = createAction(
    IngredientManagementActionTypes.DOWNLOAD_TEMPLATE_FAILURE,
    props<{ errorResponse: HttpErrorResponse }>(),
);

export const downloadInstructionsRequest = createAction(
    IngredientManagementActionTypes.DOWNLOAD_INSTRUCTIONS_REQUEST,
);

export const downloadInstructionsSuccess = createAction(
    IngredientManagementActionTypes.DOWNLOAD_INSTRUCTIONS_SUCCESS,
    props<{ blob: Blob }>(),
);

export const downloadInstructionsFailure = createAction(
    IngredientManagementActionTypes.DOWNLOAD_INSTRUCTIONS_FAILURE,
    props<{ errorResponse: HttpErrorResponse }>(),
);

export const clearNextSuccess = createAction(IngredientManagementActionTypes.CLEAR_NEXT_SUCCESS);

export const clearNextError = createAction(IngredientManagementActionTypes.CLEAR_NEXT_ERROR);

export const resetIngredientManagement = createAction(
    IngredientManagementActionTypes.RESET_INGREDIENT_MANAGEMENT,
);
