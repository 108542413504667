/** third-party imports */
import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';

/**  custom imports */
import { ContentWriterState } from './content-writer-state.interface';
import {
    getTopicsRequest,
    getAllContentRequest,
    getLongContentRequest,
    getShortContentRequest,
    downloadContentRequest,
} from './content-writer.actions';
import {
    getErrors,
    getTopics,
    getTopicsLoaded,
    getTopicsLoading,
    getLongContent,
    getLongContentLoaded,
    getLongContentLoading,
    getShortContent,
    getShortContentLoaded,
    getShortContentLoading,
    getContentBlob,
} from './content-writer.selectors';

/** Interfaces - Enums */
import FileExtension from '@leap-common/enums/file-extension.enum';
import ErrorResponse from '@leap-common/interfaces/error-response.interface';
import GeneratedContent from './interfaces/generated-content.interface';
import ContentWriterFormatId from './enums/format-id.enum';
import Configuration from './interfaces/configuration.interface';

@Injectable()
export class ContentWriterFacade {
    errors$: Observable<ErrorResponse[]> = this.store.pipe(select(getErrors));
    topics$: Observable<string[]> = this.store.pipe(select(getTopics));
    topicsLoading$: Observable<boolean> = this.store.pipe(select(getTopicsLoading));
    topicsLoaded$: Observable<boolean> = this.store.pipe(select(getTopicsLoaded));
    longContent$: Observable<GeneratedContent> = this.store.pipe(select(getLongContent));
    longContentLoading$: Observable<boolean> = this.store.pipe(select(getLongContentLoading));
    longContentLoaded$: Observable<boolean> = this.store.pipe(select(getLongContentLoaded));
    shortContent$: Observable<GeneratedContent> = this.store.pipe(select(getShortContent));
    shortContentLoading$: Observable<boolean> = this.store.pipe(select(getShortContentLoading));
    shortContentLoaded$: Observable<boolean> = this.store.pipe(select(getShortContentLoaded));
    contentBlob$: Observable<Blob> = this.store.pipe(select(getContentBlob));

    constructor(private store: Store<ContentWriterState>) {}

    getTopics({ externalIds }: { externalIds: string[] }): void {
        this.store.dispatch(getTopicsRequest({ externalIds }));
    }

    getAllContent({
        externalIds,
        configurationPerFormat,
    }: {
        externalIds: string[];
        configurationPerFormat: Record<ContentWriterFormatId, Configuration>;
    }): void {
        this.store.dispatch(getAllContentRequest({ externalIds, configurationPerFormat }));
    }

    getLongContent({
        externalIds,
        configuration,
    }: {
        externalIds: string[];
        configuration: Configuration;
    }): void {
        this.store.dispatch(getLongContentRequest({ externalIds, configuration }));
    }

    getShortContent({
        longContentText,
        configuration,
    }: {
        longContentText: string;
        configuration: Configuration;
    }): void {
        this.store.dispatch(getShortContentRequest({ longContentText, configuration }));
    }

    downloadContent({
        extension,
        longContentText,
        shortContentText,
    }: {
        extension: FileExtension;
        longContentText: string;
        shortContentText: string;
    }): void {
        this.store.dispatch(
            downloadContentRequest({ extension, longContentText, shortContentText }),
        );
    }
}
