/** third-party imports */
import { createReducer, on, Action, ActionReducer } from '@ngrx/store';
import { HttpErrorResponse } from '@angular/common/http';

/** custom imports */
import * as actions from './databases.actions';
import ErrorResponse from '@leap-common/interfaces/error-response.interface';
import { DatabasesState } from './databases-state.interface';
import Database from './interfaces/database.interface';

export const initialState: DatabasesState = {
    databases: {},
    errors: [],
    loading: false,
    loaded: false,
};

const databasesReducer: ActionReducer<DatabasesState, Action> = createReducer(
    initialState,
    on(actions.getDatabasesRequest, (state: DatabasesState) => ({
        ...state,
        databases: {},
        loading: true,
        loaded: false,
    })),
    on(
        actions.getDatabasesSuccess,
        (state: DatabasesState, { databases }: { databases: Record<string, Database> }) => ({
            ...state,
            databases,
            loading: false,
            loaded: true,
        }),
    ),
    on(
        actions.getDatabasesFailure,
        (state: DatabasesState, { errorResponse }: { errorResponse: HttpErrorResponse }) => ({
            ...state,
            errors: [...state.errors, errorResponse.error],
            loading: false,
            loaded: false,
        }),
    ),
    on(actions.clearNextError, (state: DatabasesState) => ({
        ...state,
        errors: state.errors.slice(1),
    })),
);

export const reducer = (state: DatabasesState | undefined, action: Action): DatabasesState =>
    databasesReducer(state, action);

// selectors
export const getDatabases: (state: DatabasesState) => Record<string, Database> = (
    state: DatabasesState,
) => state.databases;
export const getErrors: (state: DatabasesState) => ErrorResponse[] = (state: DatabasesState) =>
    state.errors;
export const getLoading: (state: DatabasesState) => boolean = (state: DatabasesState) =>
    state.loading;
export const getLoaded: (state: DatabasesState) => boolean = (state: DatabasesState) =>
    state.loaded;
