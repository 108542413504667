import { Pipe, PipeTransform } from '@angular/core';
import { EMPTY_STRING } from '@leap-common/constants/common';

@Pipe({
    name: 'trim',
})
export class TrimPipe implements PipeTransform {
    constructor() {}

    transform(value: string): string {
        if (!value) {
            return EMPTY_STRING;
        }
        return value.trim();
    }
}
