export enum PipelineExecutionsActionTypes {
    GET_PIPELINE_EXECUTIONS_REQUEST = '[PIPELINE EXECUTIONS] Get Pipeline Executions Request',
    GET_PIPELINE_EXECUTIONS_SUCCESS = '[PIPELINE EXECUTIONS] Get Pipeline Executions Success',
    GET_PIPELINE_EXECUTIONS_FAILURE = '[PIPELINE EXECUTIONS] Get Pipeline Executions Failure',
    GET_PIPELINE_RESULT_REQUEST = '[PIPELINE EXECUTIONS] Get Pipeline Result Request',
    GET_PIPELINE_RESULT_SUCCESS = '[PIPELINE EXECUTIONS] Get Pipeline Result Success',
    GET_PIPELINE_RESULT_FAILURE = '[PIPELINE EXECUTIONS] Get Pipeline Result Failure',
    CLEAR_DOWNLOADABLE_RESULT = '[PIPELINE EXECUTIONS] Clear Downloadable Result',
    CLEAR_NEXT_ERROR = '[PIPELINE EXECUTIONS] Clear Next Error',
}
