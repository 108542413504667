/** third-party imports */
import { createSelector } from '@ngrx/store';

/** custom imports */
import { DataState } from '../data-state.interface';
import { getDataState } from '../index';
import * as fromIngredientViewer from './ingredient-viewer.reducer';

export const getIngredientViewerState = createSelector(
    getDataState,
    (state: DataState) => state.ingredientViewer,
);

export const getErrors = createSelector(getIngredientViewerState, fromIngredientViewer.getErrors);
export const getIngredients = createSelector(
    getIngredientViewerState,
    fromIngredientViewer.getIngredients,
);
export const getIngredientsLoading = createSelector(
    getIngredientViewerState,
    fromIngredientViewer.getIngredientsLoading,
);
export const getIngredientsLoaded = createSelector(
    getIngredientViewerState,
    fromIngredientViewer.getIngredientsLoaded,
);
export const getIngredient = createSelector(
    getIngredientViewerState,
    fromIngredientViewer.getIngredient,
);
export const getIngredientLoading = createSelector(
    getIngredientViewerState,
    fromIngredientViewer.getIngredientLoading,
);
export const getIngredientLoaded = createSelector(
    getIngredientViewerState,
    fromIngredientViewer.getIngredientLoaded,
);
export const getStatistics = createSelector(
    getIngredientViewerState,
    fromIngredientViewer.getStatistics,
);
export const getStatisticsLoading = createSelector(
    getIngredientViewerState,
    fromIngredientViewer.getStatisticsLoading,
);
export const getStatisticsLoaded = createSelector(
    getIngredientViewerState,
    fromIngredientViewer.getStatisticsLoaded,
);
