import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { reducers } from '..';
import { RolesEffects } from './roles.effects';
import { RolesService } from './services/roles.service';
import { RolesParser } from './parsers/roles.parser';
import { RolesFacade } from './roles.facade';

@NgModule({
    imports: [
        CommonModule,
        HttpClientModule,
        StoreModule.forRoot(reducers),
        StoreDevtoolsModule.instrument({ maxAge: 10 }),
        EffectsModule.forRoot([RolesEffects]),
    ],
    declarations: [],
    providers: [RolesService, RolesParser, RolesFacade],
})
export class RolesStoreModule {}
