/** third-party imports */
import { createReducer, on, Action, ActionReducer } from '@ngrx/store';

/** custom imports */
import * as profilerActions from './profiler.actions';
import { ProfilerState } from './profiler-state.interface';
import TabId from '@apps/leap/src/app/shared/enums/tab-id.enum';

export const initialState: ProfilerState = {
    activeTabId: null,
};

const profilerReducer: ActionReducer<ProfilerState, Action> = createReducer(
    initialState,
    on(profilerActions.updateActiveTabId, (state: ProfilerState, { id }: { id: TabId }) => ({
        ...state,
        activeTabId: id,
    })),
    on(profilerActions.reset, () => initialState),
);

export const reducer = (state: ProfilerState | undefined, action: Action): ProfilerState =>
    profilerReducer(state, action);

// selectors
export const getActiveTabId: (state: ProfilerState) => TabId = (state: ProfilerState) =>
    state.activeTabId;
