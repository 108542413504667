/** third-party imports */
import { createSelector } from '@ngrx/store';

/** custom imports */
import { DataState } from '../data-state.interface';
import { getDataState } from '../index';
import * as fromArticlesReport from './articles-report.reducer';

export const getArticlesReportState = createSelector(
    getDataState,
    (state: DataState) => state.articlesReport,
);

export const getErrors = createSelector(getArticlesReportState, fromArticlesReport.getErrors);
export const getOverview = createSelector(getArticlesReportState, fromArticlesReport.getOverview);
export const getOverviewLoading = createSelector(
    getArticlesReportState,
    fromArticlesReport.getOverviewLoading,
);
export const getOverviewLoaded = createSelector(
    getArticlesReportState,
    fromArticlesReport.getOverviewLoaded,
);
export const getAssociations = createSelector(
    getArticlesReportState,
    fromArticlesReport.getAssociations,
);
export const getAssociationsLoading = createSelector(
    getArticlesReportState,
    fromArticlesReport.getAssociationsLoading,
);
export const getAssociationsLoaded = createSelector(
    getArticlesReportState,
    fromArticlesReport.getAssociationsLoaded,
);
export const getKeyFindings = createSelector(
    getArticlesReportState,
    fromArticlesReport.getKeyFindings,
);
export const getKeyFindingsLoading = createSelector(
    getArticlesReportState,
    fromArticlesReport.getKeyFindingsLoading,
);
export const getKeyFindingsLoaded = createSelector(
    getArticlesReportState,
    fromArticlesReport.getKeyFindingsLoaded,
);
export const getTermSummaries = createSelector(
    getArticlesReportState,
    fromArticlesReport.getTermSummaries,
);
export const getTermSummariesLoading = createSelector(
    getArticlesReportState,
    fromArticlesReport.getTermSummariesLoading,
);
export const getTermSummariesLoaded = createSelector(
    getArticlesReportState,
    fromArticlesReport.getTermSummariesLoaded,
);
export const getCriticalAnalysis = createSelector(
    getArticlesReportState,
    fromArticlesReport.getCriticalAnalysis,
);
export const getCriticalAnalysisLoading = createSelector(
    getArticlesReportState,
    fromArticlesReport.getCriticalAnalysisLoading,
);
export const getCriticalAnalysisLoaded = createSelector(
    getArticlesReportState,
    fromArticlesReport.getCriticalAnalysisLoaded,
);
export const getTheoreticalFrameworkItems = createSelector(
    getArticlesReportState,
    fromArticlesReport.getTheoreticalFrameworkItems,
);
export const getTheoreticalFrameworkLoading = createSelector(
    getArticlesReportState,
    fromArticlesReport.getTheoreticalFrameworkLoading,
);
export const getTheoreticalFrameworkLoaded = createSelector(
    getArticlesReportState,
    fromArticlesReport.getTheoreticalFrameworkLoaded,
);
export const getReferences = createSelector(
    getArticlesReportState,
    fromArticlesReport.getReferences,
);
export const getReferencesLoading = createSelector(
    getArticlesReportState,
    fromArticlesReport.getReferencesLoading,
);
export const getReferencesLoaded = createSelector(
    getArticlesReportState,
    fromArticlesReport.getReferencesLoaded,
);
export const getBlob = createSelector(getArticlesReportState, fromArticlesReport.getBlob);
