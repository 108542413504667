/** third-party imports */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

/** custom imports */
import { environment } from '@environments/leap/environment';
import { JwtParser } from '../parsers/jwt.parser';

/** Interfaces */
import LoginUser from '../interfaces/login-user.interface';
import JwtRestApi from '../rest-api-interfaces/jwt.rest.interface';
import Jwt from '../interfaces/jwt.interface';
import AuthKeyRestApi from '../rest-api-interfaces/auth-key.rest.interface';

@Injectable()
export class LoginService {
    constructor(private http: HttpClient, private jwtParser: JwtParser) {}

    /**
     * Posts the login user to the server.
     * Depending on the response, returns either a JWT token or an authentication key (string).
     */
    login(user: LoginUser): Observable<Jwt | string> {
        const app: string = environment.app;

        return this.http
            .post(
                `${environment.serverUrl}/authentication/login/?refreshToken=True&app=${app}`,
                user,
            )
            .pipe(
                map((response: JwtRestApi | AuthKeyRestApi) => {
                    if ('key' in response) {
                        return response.key;
                    }

                    return this.jwtParser.parseJwt(response as JwtRestApi);
                }),
            );
    }

    externalLogin(
        redirectUri: string,
        clientId: string,
        code: string,
        codeVerifier: string,
    ): Observable<Jwt> {
        return this.http
            .get(`${environment.serverUrl}/authentication/delegate/`, {
                params: {
                    redirect_uri: redirectUri,
                    client_id: clientId,
                    code,
                    code_verifier: codeVerifier,
                },
            })
            .pipe(map((jwt: JwtRestApi) => this.jwtParser.parseJwt(jwt)));
    }

    /**
     *  Posts the refreshToken and returns an Observable of Jwt
     */
    refreshLogin(refreshToken: string): Observable<Jwt> {
        const app: string = environment.app;

        return this.http
            .post(`${environment.serverUrl}/authentication/refresh/?app=${app}`, {
                refreshToken,
            })
            .pipe(map((jwt: JwtRestApi) => this.jwtParser.parseJwt(jwt)));
    }

    acceptTermsOfUse(): Observable<Jwt> {
        const app: string = environment.app;

        return this.http
            .post(`${environment.serverUrl}/terms-of-service/accept/?app=${app}`, {})
            .pipe(map((jwt: JwtRestApi) => this.jwtParser.parseJwt(jwt)));
    }
}
