export enum IngredientViewerActionTypes {
    GET_INGREDIENTS_REQUEST = '[INGREDIENT VIEWER] Get Ingredients Request',
    GET_INGREDIENTS_SUCCESS = '[INGREDIENT VIEWER] Get Ingredients Success',
    GET_INGREDIENTS_FAILURE = '[INGREDIENT VIEWER] Get Ingredients Failure',
    GET_INGREDIENT_REQUEST = '[INGREDIENT VIEWER] Get Ingredient Request',
    GET_INGREDIENT_SUCCESS = '[INGREDIENT VIEWER] Get Ingredient Success',
    GET_INGREDIENT_FAILURE = '[INGREDIENT VIEWER] Get Ingredient Failure',
    GET_STATISTICS_REQUEST = '[INGREDIENT VIEWER][STATISTICS] Get Statistics Request',
    GET_STATISTICS_SUCCESS = '[INGREDIENT VIEWER][STATISTICS] Get Statistics Success',
    GET_STATISTICS_FAILURE = '[INGREDIENT VIEWER][STATISTICS] Get Statistics Failure',
    CLEAR_INGREDIENTS = '[INGREDIENT VIEWER] Clear Ingredients',
    CLEAR_NEXT_ERROR = '[INGREDIENT VIEWER] Clear Next Error',
}
