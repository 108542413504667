import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

/** custom imports */
import { environment } from '@environments/leap/environment';

@Injectable()
export class ResetPasswordService {
    constructor(private http: HttpClient) {}

    resetPassword(newPassword: string, resetPasswordKey: string): Observable<void> {
        return this.http
            .post(`${environment.serverUrl}/authentication/confirm-reset-password/`, {
                password: newPassword,
                token: resetPasswordKey,
            })
            .pipe(map(() => undefined));
    }
}
