import { Component, Output, EventEmitter } from '@angular/core';

/** Constants */
import { CANCEL_TEXT } from '@leap-common/constants/common';
import { CONFIRMATION_DIALOG_TEXT, DELETE_TEXT } from '../../../constants/chat';

@Component({
    selector: 'app-chat-confirmation-dialog',
    templateUrl: 'chat-confirmation-dialog.view.component.html',
    styleUrls: ['chat-confirmation-dialog.view.component.scss'],
})
export class ChatConfirmationDialogViewComponent {
    @Output() cancelClicked: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() deleteClicked: EventEmitter<boolean> = new EventEmitter<boolean>();

    readonly text: string = CONFIRMATION_DIALOG_TEXT;
    readonly cancelText: string = CANCEL_TEXT;
    readonly deleteText: string = DELETE_TEXT;

    constructor() {}

    onCancel(): void {
        this.cancelClicked.emit(true);
    }

    onDelete(): void {
        this.deleteClicked.emit(true);
    }
}
