import { createAction, props } from '@ngrx/store';
import { HttpErrorResponse } from '@angular/common/http';
import { CategoriesActionTypes } from './categories-action-types.enum';

export const getCategoriesRequest = createAction(CategoriesActionTypes.GET_CATEGORIES_REQUEST);

export const getCategoriesSuccess = createAction(
    CategoriesActionTypes.GET_CATEGORIES_SUCCESS,
    props<{ categories: Record<string, string[]> }>(),
);

export const getCategoriesFailure = createAction(
    CategoriesActionTypes.GET_CATEGORIES_FAILURE,
    props<{ errorResponse: HttpErrorResponse }>(),
);

export const clearNextError = createAction(CategoriesActionTypes.CLEAR_NEXT_ERROR);
