/** third-party imports */
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { Actions, createEffect, ofType } from '@ngrx/effects';

/** custom imports */
import { FilesService } from './services/files.service';
import * as filesActions from './files.actions';
import { HttpErrorResponse } from '@angular/common/http';
import { FilesUtilitiesService } from './services/files-utilities.service';
import PaginatedResults from '@leap-common/interfaces/paginated-results.interface';
import File from '@leap-store/core/src/lib/data/files/interfaces/file.interface';
import FileUploadResponse from './interfaces/file-upload-response.interface';

@Injectable()
export class FilesEffects {
    constructor(
        private actions$: Actions,
        private fileService: FilesService,
        private filesUtilitiesService: FilesUtilitiesService,
    ) {}

    createPath$ = createEffect(() =>
        this.actions$.pipe(
            ofType(filesActions.createFilePathRequest),
            switchMap(({ file, onProgress }: { file: File; onProgress: (progress: any) => void }) =>
                this.fileService.createFilePath(file.name).pipe(
                    // on success we need to dispatch both
                    // createPathSuccess and uploadFileRequest because
                    // they are chained requests.
                    switchMap((responseFile: File) => [
                        filesActions.createFilePathSuccess({
                            sas: responseFile.sas,
                            id: responseFile.id,
                        }),
                        filesActions.uploadFileRequest({
                            sas: responseFile.sas,
                            id: responseFile.id,
                            file,
                            onProgress,
                            abortSettings: this.filesUtilitiesService.getAbortSettingsInstance(),
                        }),
                    ]),
                    catchError((errorResponse: HttpErrorResponse) =>
                        of(
                            filesActions.createFilePathFailure({
                                errorResponse,
                            }),
                        ),
                    ),
                ),
            ),
        ),
    );

    uploadFile$ = createEffect(() =>
        this.actions$.pipe(
            ofType(filesActions.uploadFileRequest),
            switchMap(({ sas, id, file, onProgress, abortSettings }) =>
                this.fileService.uploadFile(sas, file, onProgress, abortSettings.signal).pipe(
                    map((res: FileUploadResponse) =>
                        res.status === 201
                            ? filesActions.uploadFileSuccess()
                            : filesActions.uploadFileFailure(),
                    ),
                    catchError(() =>
                        // on error we dispatch the default failure action
                        // and also a deleteFileRequest action to delete the path
                        // created on the server
                        of(
                            filesActions.uploadFileFailure(),
                            filesActions.deleteFilePathRequest({ id }),
                        ),
                    ),
                ),
            ),
        ),
    );

    getFiles$ = createEffect(() =>
        this.actions$.pipe(
            ofType(filesActions.getFilesRequest),
            switchMap(({ pageIndex, pageSize, sortDirection, sortColumn }) =>
                this.fileService.getFiles(pageIndex, pageSize, sortDirection, sortColumn).pipe(
                    map((paginatedFiles: PaginatedResults<File>) =>
                        filesActions.getFilesSuccess({
                            paginatedFiles,
                            sortDirection,
                            sortColumn,
                        }),
                    ),
                    catchError((errorResponse: HttpErrorResponse) =>
                        of(filesActions.getFilesFailure({ errorResponse })),
                    ),
                ),
            ),
        ),
    );

    deleteFilePath$ = createEffect(() =>
        this.actions$.pipe(
            ofType(filesActions.deleteFilePathRequest),
            switchMap(({ id }: { id: string }) =>
                this.fileService.deleteFile(id).pipe(
                    map(() => filesActions.deleteFilePathSuccess()),
                    catchError((errorResponse: HttpErrorResponse) =>
                        of(filesActions.deleteFilePathFailure({ errorResponse })),
                    ),
                ),
            ),
        ),
    );

    deleteFile$ = createEffect(() =>
        this.actions$.pipe(
            ofType(filesActions.deleteFileRequest),
            switchMap(({ id }: { id: string }) =>
                this.fileService.deleteFile(id).pipe(
                    map(() => filesActions.deleteFileSuccess({ id })),
                    catchError((errorResponse: HttpErrorResponse) =>
                        of(filesActions.deleteFileFailure({ id, errorResponse })),
                    ),
                ),
            ),
        ),
    );
}
