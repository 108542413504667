/** third-party imports */
import { createSelector } from '@ngrx/store';

/** custom imports */
import { DataState } from '../data-state.interface';
import { getDataState } from '../index';
import * as fromCompoundReport from './compound-report.reducer';

export const getCompoundReportState = createSelector(
    getDataState,
    (state: DataState) => state.compoundReport,
);

export const getErrors = createSelector(getCompoundReportState, fromCompoundReport.getErrors);
export const getOverview = createSelector(getCompoundReportState, fromCompoundReport.getOverview);
export const getOverviewLoading = createSelector(
    getCompoundReportState,
    fromCompoundReport.getOverviewLoading,
);
export const getOverviewLoaded = createSelector(
    getCompoundReportState,
    fromCompoundReport.getOverviewLoaded,
);
export const getEffectSentences = createSelector(
    getCompoundReportState,
    fromCompoundReport.getEffectSentences,
);
export const getHealthLabels = createSelector(
    getCompoundReportState,
    fromCompoundReport.getHealthLabels,
);
export const getHealthLabelsLoading = createSelector(
    getCompoundReportState,
    fromCompoundReport.getHealthLabelsLoading,
);
export const getHealthLabelsLoaded = createSelector(
    getCompoundReportState,
    fromCompoundReport.getHealthLabelsLoaded,
);
export const getHealthLabelSentences = createSelector(
    getCompoundReportState,
    fromCompoundReport.getHealthLabelSentences,
);
export const getStudyTypes = createSelector(
    getCompoundReportState,
    fromCompoundReport.getStudyTypes,
);
export const getStudyTypesLoading = createSelector(
    getCompoundReportState,
    fromCompoundReport.getStudyTypesLoading,
);
export const getStudyTypesLoaded = createSelector(
    getCompoundReportState,
    fromCompoundReport.getStudyTypesLoaded,
);
export const getStudyType = createSelector(getCompoundReportState, fromCompoundReport.getStudyType);
export const getTotal = createSelector(getCompoundReportState, fromCompoundReport.getTotal);
export const getBlob = createSelector(getCompoundReportState, fromCompoundReport.getBlob);
