import { Injectable } from '@angular/core';
import { flatten, uniq } from 'lodash';

/** Helpers */
import { replaceDelimiter } from '@leap-common/utilities/helpers';

/** Services */
import { ArrayHandlerService } from '@leap-common/services/array-handler.service';
import { TypeGuardService } from './type-guard.service';

/** Constants */
import { EMPTY_STRING } from '@leap-common/constants/common';
import { AMPERSAND_DELIMITER } from '@leap-common/constants/delimiters';
import {
    RELATIONSHIP_GENERIC_LABEL,
    UNKNOWN_CATEGORY_LABEL,
} from '@apps/leap/src/app/shared/constants/discovery';

/** Interfaces - Types - Enums */
import InsightRelationshipType from '@apps/leap/src/app/shared/types/discovery-insight-relationship-type.type';
import InsightsPerRelationshipTypeOrigin from '@apps/leap/src/app/shared/types/insights-per-relationship-type-origin.type';
import RelationshipOrigin from '@apps/leap/src/app/shared/enums/relationship-origin.enum';
import InsightCategories from '@apps/leap/src/app/shared/types/insight-categories.type';
import InsightGroupedCategories from '@apps/leap/src/app/shared/types/insight-grouped-categories.type';
import AssociationType from '@apps/leap/src/app/shared/enums/association-type.enum';
import AssociationOrigin from '@apps/leap/src/app/shared/enums/association-origin.enum';
import Relation from '@apps/leap/src/app/shared/types/discovery-relation.type';
import ClosedDiscoveryRelation from '@leap-store/core/src/lib/ui/discovery/enums/closed-discovery-relation.enum';
import CombinatorialDiscoveryRelation from '@leap-store/core/src/lib/ui/discovery/enums/combinatorial-discovery-relation.enum';
import Discovery from '@apps/leap/src/app/shared/enums/discovery.enum';
import Compound from '@leap-store/core/src/lib/data/ingredient-profiler/interfaces/compound.interface';
import Insight from '@apps/leap/src/app/shared/types/discovery-insight.type';
import OpenDiscoveryInsight from '@leap-store/core/src/lib/data/discovery/open/interfaces/insight.interface';
import ProfilerInsight from '@leap-store/core/src/lib/data/ingredient-profiler/interfaces/insight.interface';
import Entity from '@leap-store/core/src/lib/data/bookmarks/interfaces/entity.interface';
import Bookmark from '@leap-store/core/src/lib/data/bookmarks/enums/bookmark.enum';

@Injectable()
export class InsightsService {
    constructor(
        private typeGuardService: TypeGuardService,
        private arrayHandlerService: ArrayHandlerService,
    ) {}

    /**
     * Extracts as a string tuple the appropriate ids from an insight based on its discovery type.
     */
    getInsightIds({
        insight,
        raw,
        relation,
    }: Partial<{
        insight: Insight;
        raw: boolean;
        relation: Relation;
    }> = {}): [string, string, string?] | undefined {
        if (!insight) {
            return;
        }
        if (
            this.typeGuardService.isOpenDiscoveryInsight(insight) ||
            (this.typeGuardService.isClosedDiscoveryInsight(insight) &&
                relation === ClosedDiscoveryRelation.relation0)
        ) {
            return [insight.sourceId, insight.targetId];
        }
        if (this.typeGuardService.isClosedDiscoveryInsight(insight)) {
            if (raw) {
                return [insight.sourceId, insight.intermediateId, insight.targetId];
            }
            if (relation === ClosedDiscoveryRelation.relation1) {
                return [insight.sourceId, insight.intermediateId];
            }
            if (relation === ClosedDiscoveryRelation.relation2) {
                return [insight.targetId, insight.intermediateId];
            }
        }
        if (this.typeGuardService.isCombinatorialDiscoveryInsight(insight)) {
            if (relation === CombinatorialDiscoveryRelation.AB) {
                return [insight.idA, insight.idB];
            }
            if (relation === CombinatorialDiscoveryRelation.BC) {
                return [insight.idB, insight.idC];
            }
            if (relation === CombinatorialDiscoveryRelation.AC) {
                return [insight.idA, insight.idC];
            }
        }
    }

    /**
     * Gets an InsightRelationshipType[] and returns a string[] with the relationships values
     */
    getRelationshipsValues(relationships: InsightRelationshipType[]): string[] {
        return flatten(
            relationships.map((relationship: InsightRelationshipType) =>
                relationship ? uniq(Object.values(relationship)) : [RELATIONSHIP_GENERIC_LABEL],
            ),
        );
    }

    /**
     * Gets an object that includes the keys 'true' and 'false' and returns an InsightsPerRelationshipTypeOrigin.
     * It creates a new object that consists of RelationshipOrigin keys.
     * It assigns the contents of the [false] key into [RelationshipOrigin.known] and
     * the contents of the [true] key into [RelationshipOrigin.predicted]
     * and returns the new object
     */
    mapCountToCountPerRelationshipTypeOrigin(
        originalCountPerRelationshipTypeOrigin: Record<'true' | 'false', number>,
        relation: CombinatorialDiscoveryRelation,
    ): InsightsPerRelationshipTypeOrigin {
        return {
            [`${RelationshipOrigin.known}-${relation}`]:
                originalCountPerRelationshipTypeOrigin.false,
            [`${RelationshipOrigin.predicted}-${relation}`]:
                originalCountPerRelationshipTypeOrigin.true,
        };
    }

    /**
     * Gets an InsightCategories[] and returns a string[] with the categories keys
     */
    getCategoriesKeys(categories: InsightCategories[]): string[] {
        return flatten(
            categories?.map((insightCategories: InsightCategories) =>
                insightCategories ? uniq(Object.keys(insightCategories)) : [UNKNOWN_CATEGORY_LABEL],
            ),
        );
    }

    /**
     * Gets an InsightCategories[] and returns a string[] with the categories values
     */
    getCategoriesValues(categories: InsightCategories[]): string[] {
        return flatten(
            categories?.map((insightCategories: InsightCategories) =>
                insightCategories
                    ? uniq(Object.values(insightCategories))
                    : [UNKNOWN_CATEGORY_LABEL],
            ),
        );
    }

    getGroupedCategories(totalCategories: InsightCategories[]): InsightGroupedCategories {
        let groupedCategories: InsightGroupedCategories = {};

        totalCategories?.forEach((insightCategories: InsightCategories) => {
            for (const [subcategory, category] of Object.entries(insightCategories)) {
                groupedCategories = {
                    ...groupedCategories,
                    [category]:
                        category in groupedCategories
                            ? uniq([...groupedCategories[category], subcategory])
                            : [subcategory],
                };
            }
        });

        return groupedCategories;
    }

    generateAssociationId(origin: AssociationOrigin, isNovel: boolean): string {
        return replaceDelimiter(
            `${isNovel ? AssociationType.novel : AssociationType.known}-${origin}`,
            ' ',
            '-',
        ).toLowerCase();
    }

    getAssociationsText(origins: AssociationOrigin[], isNovel?: boolean): string {
        if (!origins?.length) {
            return EMPTY_STRING;
        }

        return `${isNovel ? AssociationType.novel : AssociationType.known} (${origins.join(
            AMPERSAND_DELIMITER,
        )})`;
    }

    mapProfilerInsightToOpenDiscoveryInsight(
        insight: ProfilerInsight,
        compound?: Compound,
    ): OpenDiscoveryInsight {
        return {
            type: Discovery.open,
            id: insight.id,
            sourceCategories: insight.sourceCategories,
            sourceId: insight.sourceId,
            sourceName: insight.sourceName,
            sourcePrior: undefined,
            targetCategories: insight.targetCategories,
            targetId: insight.targetId,
            targetName: insight.targetName,
            targetPrior: undefined,
            totalScore: insight.totalScore,
            weightRaw: insight.weightRaw,
            weightScore: undefined,
            rankingIndex: undefined,
            isNovel: insight.isNovel,
            associationOrigins: insight.associationOrigins,
            associationIds: undefined,
            strength: insight.strength,
            relationshipType: insight.relationshipType,
            relationshipTypeValues: insight.relationshipType
                ? Object.values(insight.relationshipType)
                : [],
            isRelationshipTypePredicted: insight.isRelationshipTypePredicted,
            predictedRelationshipTypeProbability: insight.relationshipTypeProbability,
            linkProbability: undefined,
            targetArticlesCount: insight.articlesCount,
            patentsCount: undefined,
            oldestOccurrence: undefined,
            newestOccurrence: undefined,
            synonyms: insight.synonyms,
            koOriginDatabases: insight.koOriginDatabases,
            sourceTags: insight?.sourceTags || [],
            targetTags: insight?.targetTags || [],
            sourceHealthLabels: compound?.healthLabels || [],
            targetHealthLabels: insight.healthLabels,
            studyTypes: insight?.studyTypes || [],
            journals: insight?.journals || [],
            sourceMolecules: compound?.molecules || [],
            targetMolecules: insight?.targetMolecules || [],
            sourceLabs: compound?.labs || [],
            targetLabs: insight?.targetLabs || [],
            sourceProfileCategory: compound?.profileCategory,
            targetProfileCategory: undefined,
            subcategories: [],
            categories: uniq(Object.values(insight.targetCategories || {})),
            moleculeWeight: compound?.molecularWeight || undefined,
            cowMilkConcentration: compound?.cowMilkConcentration || undefined,
            prevalence: compound?.prevalence || undefined,
            articlesCount: undefined,
            proteinOrigins: compound?.proteinOrigins || [],
            sourceConcentration: undefined,
            ingredientConnections: insight.ingredientConnections,
            isLinkDirect: insight.isLinkDirect,
        };
    }

    mapCompoundToEntity(compound: Compound): Entity {
        return {
            type: Bookmark.entity,
            id: compound.id,
            name: compound.name,
            categories: compound.categoryPerSubcategory,
            subcategories: compound.subcategories,
            synonyms: compound.synonyms,
            definitions: undefined,
            tags: compound.tags,
            healthLabels: compound.healthLabels,
            molecules: compound.molecules,
            profileCategory: compound.profileCategory,
            prevalence: compound.prevalence,
            labs: compound.labs,
            patentsCount: undefined,
            cowMilkConcentration: compound.cowMilkConcentration,
            moleculeWeight: compound.molecularWeight,
        };
    }

    /**
     * Swaps source and target properties (id, name, categories) to ensure the network graph works as expected.
     * In the Discovery data, the `sourceId` is consistent among all insights, whereas in the Profiler data,
     * the `targetId` remains the same while the `sourceId` varies for each Insight.
     * To make the existing network-slim function properly, we performed the necessary swap of source and target properties.
     */
    reverseInsightProperties(insight: ProfilerInsight): ProfilerInsight {
        return {
            ...insight,
            id: `${insight.targetId}_${insight.sourceId}`,
            sourceId: insight.targetId,
            sourceName: insight.targetName,
            sourceCategories: insight.targetCategories,
            sourceTags: insight.targetTags,
            targetId: insight.sourceId,
            targetName: insight.sourceName,
            targetCategories: insight.sourceCategories,
            targetTags: insight.sourceTags,
        };
    }
}
