import { Component, Input, Output, EventEmitter } from '@angular/core';

/** Constants */
import { EMPTY_STRING } from '@leap-common/constants/common';
import {
    INPUT_MAX_LENGTH,
    INPUT_PLACEHOLDER,
    INPUT_SEND_ICON,
    INPUT_COUNT_TEXT,
} from '../../../constants/chat';

@Component({
    selector: 'app-chat-input',
    templateUrl: 'chat-input.view.component.html',
    styleUrls: ['chat-input.view.component.scss'],
})
export class ChatInputViewComponent {
    @Input() isButtonDisabled: boolean;
    @Output() valueToBeSent: EventEmitter<string> = new EventEmitter<string>();

    value: string = EMPTY_STRING;

    readonly maxLength: number = INPUT_MAX_LENGTH;
    readonly placeholder: string = INPUT_PLACEHOLDER;
    readonly icon: string = INPUT_SEND_ICON;
    readonly countText: string = INPUT_COUNT_TEXT;

    constructor() {}

    onValueToBeSent(inputValue: string): void {
        if (!inputValue?.trim() || this.isButtonDisabled) {
            return;
        }

        this.valueToBeSent.emit(inputValue);
        this.clearValue();
    }

    clearValue(): void {
        this.value = EMPTY_STRING;
    }
}
