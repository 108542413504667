import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';

/** pouchDB */
import PouchDB from 'pouchdb';
import PouchDBFind from 'pouchdb-find';
import PouchDBMemoryAdapter from 'pouchdb-adapter-memory';

/** custom imports */
import { DatabaseService } from '../common/database.service';
import { LocalStorageService } from '../common/local-storage.service';
import { ClosedDiscoveryFacade } from '@leap-store/core/src/lib/data/discovery/closed/closed-discovery.facade';
import Database from '../database.enum';
import Insight from '@leap-store/core/src/lib/data/discovery/closed/interfaces/insight.interface';
import Metadata from '@leap-store/core/src/lib/data/discovery/closed/interfaces/metadata.interface';
import SortingOptions from '@leap-common/interfaces/sorting-options.interface';
import Filter from '@apps/leap/src/app/shared/modules/filters/interfaces/filter.interface';
import RelationshipOrigin from '@apps/leap/src/app/shared/enums/relationship-origin.enum';
import SortingOrder from '@leap-common/enums/sorting-order.enum';

const TEST_DB_NAME: string = 'test-database';

PouchDB.plugin(PouchDBFind);
PouchDB.plugin(PouchDBMemoryAdapter);
@Injectable()
export class ClosedDiscoveryInsightsService {
    MASK_LENGTH: number = 16;

    pouchDB: PouchDB.Database;

    dbsIds$: Observable<string[]>; // = this.facade.dbsIds$;

    constructor(
        private databaseService: DatabaseService,
        private localStorageService: LocalStorageService,
        private facade: ClosedDiscoveryFacade,
    ) {}

    getActiveDBs(): string[] {
        return this.localStorageService.splitValueInDelimiter(
            this.localStorageService.getItem(Database.discoveryDBs),
            Database.delimiter,
        );
    }

    updateActiveDBs(activeDBs: string[]): void {
        this.localStorageService.setItem(Database.discoveryDBs, activeDBs.join(Database.delimiter));
    }

    appendToActiveDBs(dbName: string): void {
        let activeDBs: string[] = this.getActiveDBs();
        activeDBs = activeDBs.concat(dbName);
        this.updateActiveDBs(activeDBs);
    }

    async isIndexedDBSupported(): Promise<boolean> {
        const testDB: PouchDB.Database = new PouchDB(TEST_DB_NAME);
        const isNotSupported: boolean = await this.databaseService.checkDB(testDB);

        if (isNotSupported) {
            return false;
        }

        await this.databaseService.destroyDB(TEST_DB_NAME);
        return true;
    }

    /** Database */

    /**
     * Returns true when database is created and false when it already exists
     */
    async getOrCreateDB(sourceId: string, targetId: string): Promise<boolean> {
        const dbName: string = `${Database.closedDiscoveryInsights}-${sourceId}-${targetId}`;

        // Side effect: Creates empty DB which may never be used.
        this.pouchDB = this.databaseService.switchDB(dbName);

        const isIndexedDBUnknown: boolean = await this.databaseService.checkDB(this.pouchDB);

        // Firefox private mode doesn't support indexed db, so we need
        // to fall back to in-memory db.
        if (isIndexedDBUnknown) {
            const existingDBs: string[] = await this.dbsIds$.pipe(take(1)).toPromise();

            if (existingDBs.includes(`${sourceId}-${targetId}`)) {
                // Side effect: Creates empty DB which may never be used.
                this.pouchDB = this.databaseService.switchDB(dbName, isIndexedDBUnknown);
                return false;
            }

            // save DB id
            // this.facade.saveDBId(`${sourceId}-${targetId}`);

            // create in-memory DB
            this.pouchDB = this.databaseService.createDB(dbName, isIndexedDBUnknown);

            return true;
        }

        let activeDBs: string[] = this.localStorageService.splitValueInDelimiter(
            this.localStorageService.getItem(Database.discoveryDBs),
            Database.delimiter,
        );

        if (activeDBs.includes(dbName)) {
            // switch DB
            this.pouchDB = this.databaseService.switchDB(dbName);
            return false;
        }

        // create DB
        this.pouchDB = this.databaseService.createDB(dbName);

        // update localStorage / active DBs
        activeDBs = activeDBs.concat(dbName);
        this.localStorageService.setItem(Database.discoveryDBs, activeDBs.join(Database.delimiter));
        return true;
    }

    async createDB(sourceId: string, targetId: string): Promise<void> {
        const dbName: string = `${Database.closedDiscoveryInsights}-${sourceId}-${targetId}`;
        const isIndexedDBSupported: boolean = await this.isIndexedDBSupported();

        this.pouchDB = this.databaseService.switchDB(dbName);

        // Firefox private mode doesn't support indexed db, so we need
        // to fall back to in-memory db.
        if (!isIndexedDBSupported) {
            // save DB id
            // this.facade.saveDBId(`${sourceId}-${targetId}`);

            // create in-memory DB
            this.pouchDB = this.databaseService.createDB(dbName, true);

            return;
        }

        // create DB
        this.pouchDB = this.databaseService.createDB(dbName);

        // update active DBs
        this.appendToActiveDBs(dbName);
    }

    async doesDBExist(sourceId: string, targetId: string): Promise<boolean> {
        const dbName: string = `${Database.closedDiscoveryInsights}-${sourceId}-${targetId}`;
        const isIndexedDBSupported: boolean = await this.isIndexedDBSupported();

        // Firefox private mode doesn't support indexed db, so we need
        // to fall back to in-memory db.
        if (!isIndexedDBSupported) {
            const existingDBs: string[] = await this.dbsIds$.pipe(take(1)).toPromise();

            if (existingDBs.includes(`${sourceId}-${targetId}`)) {
                // Side effect: Creates empty DB which may never be used.
                this.pouchDB = this.databaseService.switchDB(dbName, true);
                return true;
            }

            return false;
        }

        const activeDBs: string[] = this.getActiveDBs();

        if (activeDBs.includes(dbName)) {
            // Side effect: Creates empty DB which may never be used.
            this.pouchDB = this.databaseService.switchDB(dbName);

            return true;
        }

        return false;
    }

    async destroyDB(sourceId: string, targetId: string): Promise<void> {
        const dbName: string = `${Database.closedDiscoveryInsights}-${sourceId}-${targetId}`;
        const isIndexedDBSupported: boolean = await this.isIndexedDBSupported();

        this.pouchDB = this.databaseService.switchDB(dbName);

        if (!isIndexedDBSupported) {
            // remove DB id
            // this.facade.removeDBId(`${sourceId}-${targetId}`);
            return;
        }

        // reset localStorage
        this.localStorageService.removeElementFromItemList(
            dbName,
            Database.discoveryDBs,
            Database.delimiter,
        );

        return this.databaseService.destroyDB(dbName);
    }

    async destroyDBs(): Promise<void[]> {
        const isIndexedDBSupported: boolean = await this.isIndexedDBSupported();
        if (!isIndexedDBSupported) {
            return;
        }
        return this.databaseService.destroyDBs(Database.discoveryDBs, Database.delimiter);
    }

    /** Insights */
    async populate({
        insights,
    }: {
        insights: Insight[];
        fields?: string[];
    }): Promise<(PouchDB.Core.Response | PouchDB.Core.Error)[]> {
        const documents: Insight[] = insights.map((insight: Insight) => ({
            ...insight,
            _id: insight.rankingIndex.toString().padStart(this.MASK_LENGTH, '0'),
        }));

        // await this.databaseService.createIndex(this.pouchDB, { fields });

        const response: (PouchDB.Core.Response | PouchDB.Core.Error)[] =
            await this.pouchDB.bulkDocs(documents);

        return response;
    }

    async retrieve({
        limit,
        sortingOptions,
        filters,
    }: {
        limit?: number;
        sortingOptions?: SortingOptions;
        filters?: Record<string, Filter[] | [number, number]>;
    }): Promise<PouchDB.Find.FindResponse<unknown>> {
        let selector: PouchDB.Find.Selector = {
            _id: { $gt: null },
        };
        const sort: (string | Record<string, SortingOrder>)[] = [];

        if (sortingOptions?.field) {
            selector[sortingOptions.field] = { $gt: null };
            sort.push({ [sortingOptions.field]: sortingOptions.order });
        }

        if (filters) {
            for (const [key, value] of Object.entries(filters)) {
                if (key === 'categories') {
                    const categories: string[] = (value as Filter[])
                        .filter((category: Filter) => category.isActive)
                        .map((category: Filter) => category.text);
                    if (categories?.length > 0) {
                        selector[key] = { $in: categories };
                    }
                }

                if (key === 'subcategories') {
                    const activeSubcategories: string[] = (value as Filter[])
                        .filter((subcategory: Filter) => subcategory.isActive)
                        .map((subcategory: Filter) => subcategory.text);
                    if (activeSubcategories?.length > 0) {
                        selector[key] = { $in: activeSubcategories };
                    }
                }

                if (key === 'koOriginDatabases') {
                    const activeDatabases: string[] = (value as Filter[])
                        .filter((database: Filter) => database.isActive)
                        .map((database: Filter) => database.text);
                    const excludedDatabases: string[] = (value as Filter[])
                        .filter((database: Filter) => database.isExcluded)
                        .map((database: Filter) => database.text);

                    if (activeDatabases?.length > 0) {
                        selector[key] = { ...(selector[key] || {}), $in: activeDatabases };
                    }
                    if (excludedDatabases?.length > 0) {
                        selector[key] = { ...(selector[key] || {}), $nin: excludedDatabases };
                    }
                }

                if (key === 'intermediateTags') {
                    const activeTags: string[] = (value as Filter[])
                        .filter((tag: Filter) => tag.isActive)
                        .map((tag: Filter) => tag.text);
                    const excludedTags: string[] = (value as Filter[])
                        .filter((tag: Filter) => tag.isExcluded)
                        .map((tag: Filter) => tag.text);

                    if (activeTags?.length > 0) {
                        selector[key] = { ...(selector[key] || {}), $in: activeTags };
                    }
                    if (excludedTags?.length > 0) {
                        selector[key] = { ...(selector[key] || {}), $nin: excludedTags };
                    }
                }

                if (key === 'intermediateHealthLabels') {
                    const activeHealthLabels: string[] = (value as Filter[])
                        .filter((healthLabel: Filter) => healthLabel.isActive)
                        .map((healthLabel: Filter) => healthLabel.text);

                    if (activeHealthLabels?.length > 0) {
                        selector[key] = { $in: activeHealthLabels };
                    }
                }

                if (key === 'studyTypes') {
                    const activeStudyTypes: string[] = (value as Filter[])
                        .filter((studyType: Filter) => studyType.isActive)
                        .map((studyType: Filter) => studyType.text);

                    if (activeStudyTypes?.length > 0) {
                        selector[key] = { $in: activeStudyTypes };
                    }
                }

                if (key === 'journals') {
                    const activeJournals: string[] = (value as Filter[])
                        .filter((journal: Filter) => journal.isActive)
                        .map((journal: Filter) => journal.text);

                    if (activeJournals?.length > 0) {
                        selector[key] = { $in: activeJournals };
                    }
                }

                if (key === 'intermediateMolecules') {
                    const activeMolecules: string[] = (value as Filter[])
                        .filter((molecule: Filter) => molecule.isActive)
                        .map((molecule: Filter) => molecule.text);

                    if (activeMolecules?.length > 0) {
                        selector[key] = { $in: activeMolecules };
                    }
                }

                if (key === 'intermediateLabs') {
                    const activeLabs: string[] = (value as Filter[])
                        .filter((lab: Filter) => lab.isActive)
                        .map((lab: Filter) => lab.text);

                    if (activeLabs?.length > 0) {
                        selector[key] = { $in: activeLabs };
                    }
                }

                if (key === 'dates' && value?.length) {
                    const oldestOccurrenceE0 = {
                        $and: [
                            { oldestOccurrenceE0: { $gte: value[0] } },
                            { oldestOccurrenceE0: { $lte: value[1] } },
                        ],
                    };

                    const newestOccurrenceE0 = {
                        $and: [
                            { newestOccurrenceE0: { $gte: value[0] } },
                            { newestOccurrenceE0: { $lte: value[1] } },
                        ],
                    };

                    const oldestOccurrenceE1 = {
                        $and: [
                            { oldestOccurrenceE1: { $gte: value[0] } },
                            { oldestOccurrenceE1: { $lte: value[1] } },
                        ],
                    };

                    const newestOccurrenceE1 = {
                        $and: [
                            { newestOccurrenceE1: { $gte: value[0] } },
                            { newestOccurrenceE1: { $lte: value[1] } },
                        ],
                    };

                    const query = {
                        $or: [
                            { ...oldestOccurrenceE0 },
                            { ...newestOccurrenceE0 },
                            { ...oldestOccurrenceE1 },
                            { ...newestOccurrenceE1 },
                            { oldestOccurrenceE0: { $eq: 0 } },
                            { newestOccurrenceE0: { $eq: 0 } },
                            { oldestOccurrenceE1: { $eq: 0 } },
                            { newestOccurrenceE1: { $eq: 0 } },
                        ],
                    };

                    selector = { ...selector, ...query };
                }

                if (key === 'associationIds') {
                    const activeAssociations: string[] = (value as Filter[])
                        .filter((association: Filter) => association.isActive)
                        .map((association: Filter) => association.id);

                    if (activeAssociations?.length > 0) {
                        selector[key] = { $in: activeAssociations };
                    }
                }

                if (key === 'relationshipTypeValues') {
                    const activeRelationships: string[] = (value as Filter[])
                        .filter((relationship: Filter) => relationship.isActive)
                        .map((relationship: Filter) => relationship.text);

                    if (activeRelationships?.length > 0) {
                        selector[key] = { $in: activeRelationships };
                    }
                }

                if (key === 'areRelationshipTypesPredicted') {
                    const activeRelationshipOrigins: boolean[] = (value as Filter[])
                        .filter((relationshipOrigin: Filter) => relationshipOrigin.isActive)
                        .map(
                            (relationshipOrigin: Filter) =>
                                relationshipOrigin.id === RelationshipOrigin.predicted,
                        );

                    if (activeRelationshipOrigins?.length > 0) {
                        selector[key] = { $in: activeRelationshipOrigins };
                    }
                }
            }
        }

        const result: PouchDB.Find.FindResponse<unknown> = await this.pouchDB.find({
            selector,
            sort: sort.length === 0 ? undefined : sort,
            limit,
        });

        return result;
    }

    /** Metadata */
    async createMetadata(metadata: Metadata): Promise<PouchDB.Core.Response> {
        const {
            countPerCategory,
            countPerCategoryNew,
            countPerSubcategory,
            countPerAssociation,
            countPerAssociationType,
            countPerDatabase,
            countPerTag,
            countPerHealthLabel,
            countPerStudyType,
            countPerJournal,
            countPerMolecule,
            countPerLab,
            countPerRelationshipType,
            countPerRelationshipTypeOrigin,
            displaying,
            total,
            newestOccurrence,
            oldestOccurrence,
            totalCategories,
            totalSubcategories,
            totalRelationships,
            totalDatabases,
            totalTags,
            totalHealthLabels,
            totalJournals,
            totalMolecules,
            groupedCategories,
        } = metadata;

        const response: PouchDB.Core.Response = await this.pouchDB.put({
            _id: '_local/metadata',
            countPerCategory,
            countPerCategoryNew,
            countPerSubcategory,
            countPerAssociation,
            countPerAssociationType,
            countPerDatabase,
            countPerTag,
            countPerHealthLabel,
            countPerStudyType,
            countPerJournal,
            countPerMolecule,
            countPerLab,
            countPerRelationshipType,
            countPerRelationshipTypeOrigin,
            displaying,
            total,
            newestOccurrence,
            oldestOccurrence,
            totalCategories,
            totalSubcategories,
            totalRelationships,
            totalDatabases,
            totalTags,
            totalHealthLabels,
            totalJournals,
            totalMolecules,
            groupedCategories,
        });

        return response;
    }

    retrieveMetadata(): Promise<PouchDB.Core.IdMeta & PouchDB.Core.GetMeta & Metadata> {
        return this.pouchDB.get('_local/metadata');
    }
}
