/** third-party imports */
import { createAction, props } from '@ngrx/store';
import { HttpErrorResponse } from '@angular/common/http';

/** custom imports */
import { GPTActionTypes } from './gpt-action-types.enum';
import QueryResponse from './interfaces/query-response.interface';
import QAPair from './interfaces/qa-pair.interface';

export const performStructuredOpenDiscoveryRequest = createAction(
    GPTActionTypes.PERFORM_STRUCTURED_OPEN_DISCOVERY_REQUEST,
    props<{
        source: string;
        sourceId: string;
        sourceCategories: string[];
        targetCategories: string[];
    }>(),
);

export const performStructuredOpenDiscoverySuccess = createAction(
    GPTActionTypes.PERFORM_STRUCTURED_OPEN_DISCOVERY_SUCCESS,
    props<{ structuredQuery: QueryResponse }>(),
);

export const performStructuredOpenDiscoveryFailure = createAction(
    GPTActionTypes.PERFORM_STRUCTURED_OPEN_DISCOVERY_FAILURE,
    props<{ errorResponse: HttpErrorResponse }>(),
);

export const performStructuredClosedDiscoveryRequest = createAction(
    GPTActionTypes.PERFORM_STRUCTURED_CLOSED_DISCOVERY_REQUEST,
    props<{
        source: string;
        sourceId: string;
        sourceCategories: string[];
        target: string;
        targetId: string;
        targetCategories: string[];
    }>(),
);

export const performStructuredClosedDiscoverySuccess = createAction(
    GPTActionTypes.PERFORM_STRUCTURED_CLOSED_DISCOVERY_SUCCESS,
    props<{ structuredQuery: QueryResponse }>(),
);

export const performStructuredClosedDiscoveryFailure = createAction(
    GPTActionTypes.PERFORM_STRUCTURED_CLOSED_DISCOVERY_FAILURE,
    props<{ errorResponse: HttpErrorResponse }>(),
);

export const performFreeQueryRequest = createAction(
    GPTActionTypes.PERFORM_FREE_QUERY_REQUEST,
    props<{
        query: string;
        sessionId: string;
    }>(),
);

export const performFreeQuerySuccess = createAction(
    GPTActionTypes.PERFORM_FREE_QUERY_SUCCESS,
    props<{ freeQuery: QueryResponse }>(),
);

export const performFreeQueryFailure = createAction(
    GPTActionTypes.PERFORM_FREE_QUERY_FAILURE,
    props<{ errorResponse: HttpErrorResponse }>(),
);

export const regenerateQueryRequest = createAction(
    GPTActionTypes.REGENERATE_QUERY_REQUEST,
    props<{
        id: string;
    }>(),
);

export const regenerateQuerySuccess = createAction(
    GPTActionTypes.REGENERATE_QUERY_SUCCESS,
    props<{ query: QueryResponse }>(),
);

export const regenerateQueryFailure = createAction(
    GPTActionTypes.REGENERATE_QUERY_FAILURE,
    props<{ errorResponse: HttpErrorResponse }>(),
);

export const downloadSessionHistoryRequest = createAction(
    GPTActionTypes.DOWNLOAD_SESSION_HISTORY_REQUEST,
    props<{
        id: string;
        extension: string;
    }>(),
);

export const downloadSessionHistorySuccess = createAction(
    GPTActionTypes.DOWNLOAD_SESSION_HISTORY_SUCCESS,
    props<{ blob: Blob }>(),
);

export const downloadSessionHistoryFailure = createAction(
    GPTActionTypes.DOWNLOAD_SESSION_HISTORY_FAILURE,
    props<{ errorResponse: HttpErrorResponse }>(),
);

export const resetSession = createAction(GPTActionTypes.RESET_SESSION);

export const performAssistantQueryRequest = createAction(
    GPTActionTypes.PERFORM_ASSISTANT_QUERY_REQUEST,
    props<{ query: string }>(),
);

export const performAssistantQuerySuccess = createAction(
    GPTActionTypes.PERFORM_ASSISTANT_QUERY_SUCCESS,
    props<{ assistantQuery: QAPair }>(),
);

export const performAssistantQueryFailure = createAction(
    GPTActionTypes.PERFORM_ASSISTANT_QUERY_FAILURE,
    props<{ errorResponse: HttpErrorResponse }>(),
);

export const getAssistantQueriesRequest = createAction(
    GPTActionTypes.GET_ASSISTANT_QUERIES_REQUEST,
);

export const getAssistantQueriesSuccess = createAction(
    GPTActionTypes.GET_ASSISTANT_QUERIES_SUCCESS,
    props<{ assistantQueries: QAPair[] }>(),
);

export const getAssistantQueriesFailure = createAction(
    GPTActionTypes.GET_ASSISTANT_QUERIES_FAILURE,
    props<{ errorResponse: HttpErrorResponse }>(),
);

export const deleteAssistantQueriesRequest = createAction(
    GPTActionTypes.DELETE_ASSISTANT_QUERIES_REQUEST,
);

export const deleteAssistantQueriesSuccess = createAction(
    GPTActionTypes.DELETE_ASSISTANT_QUERIES_SUCCESS,
    props<{ assistantQueries: QAPair[] }>(),
);

export const deleteAssistantQueriesFailure = createAction(
    GPTActionTypes.DELETE_ASSISTANT_QUERIES_FAILURE,
    props<{ errorResponse: HttpErrorResponse }>(),
);

export const clearNextError = createAction(GPTActionTypes.CLEAR_NEXT_ERROR);
