/** third party modules */
import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

/** custom imports */
import { environment } from '@environments/leap/environment';

/** Services- Facades */
import { CurrentUserService } from '@leap-store/core/src/lib/data/auth/services/current-user.service';
import { TokenService } from '@leap-store/core/src/lib/data/auth/services/token.service';
import { AuthFacade } from '@leap-store/core/src/lib/data/auth/auth.facade';

/** Interfaces - Enums */
import Jwt from '@leap-store/core/src/lib/data/auth/interfaces/jwt.interface';
import CurrentUser from '@leap-store/core/src/lib/data/auth/interfaces/current-user.interface';
import UserApp from '@leap-store/core/src/lib/data/auth/interfaces/user-app.interface';
import TokenTypes from '@leap-store/core/src/lib/data/auth/enums/token-types.enum';

@Injectable()
export class AuthService {
    jwt$: Observable<Jwt> = this.authFacade.jwt$;

    constructor(
        private jwtHelper: JwtHelperService,
        private authFacade: AuthFacade,
        private currentUserService: CurrentUserService,
        private tokenService: TokenService,
    ) {}

    /**
     * Maps the jwt$ and checks whether a user is authenticated or not.
     * If the accessToken does not exist in the store rehydrates it`.
     * Returns an Observable<boolean> based on the above condition.
     */
    isUserAuthenticated(): Observable<boolean> {
        return this.jwt$.pipe(
            map((jwt: Jwt) => {
                if (!jwt || !jwt.accessToken) {
                    this.authFacade.rehydrateToken(TokenTypes.access);
                }
                if (!jwt || !jwt.refreshToken) {
                    this.authFacade.rehydrateToken(TokenTypes.refresh);
                }
                if (jwt?.accessToken) {
                    return !this.jwtHelper.isTokenExpired(jwt.accessToken);
                }
                return false;
            }),
        );
    }

    hasUserAppPermissions(): boolean {
        const currentUser: CurrentUser = this.currentUserService.getUserIdentity();

        if (currentUser) {
            const apps: string[] = currentUser.apps.map((app: UserApp) => app.name.toLowerCase());
            const runningApp: string = environment.app;

            if (apps.includes(runningApp)) {
                return true;
            }

            this.tokenService.removeToken(TokenTypes.access);
            this.authFacade.rehydrateToken(TokenTypes.access);
            this.tokenService.removeToken(TokenTypes.refresh);
            this.authFacade.rehydrateToken(TokenTypes.refresh);
            return false;
        }
        return false;
    }
}
