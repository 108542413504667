import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';

/** custom imports */
import { reducers } from '..';
import { CowMilkProfilerEffects } from './cow-milk-profiler.effects';
import { CowMilkProfilerFacade } from './cow-milk-profiler.facade';
import { CowMilkProfilerService } from './services/cow-milk-profiler.service';

@NgModule({
    imports: [
        CommonModule,
        HttpClientModule,
        StoreModule.forRoot(reducers),
        StoreDevtoolsModule.instrument({ maxAge: 10 }),
        EffectsModule.forRoot([CowMilkProfilerEffects]),
    ],
    declarations: [],
    providers: [CowMilkProfilerService, CowMilkProfilerFacade],
})
export class CowMilkProfilerStoreModule {}
