import { createSelector } from '@ngrx/store';
import { DataState } from '../../data-state.interface';
import { getDataState } from '../../index';
import * as fromDiscovery from './closed-discovery.reducer';

export const getClosedDiscoveryState = createSelector(
    getDataState,
    (state: DataState) => state.discovery.closed,
);

export const getFilterCounts = createSelector(
    getClosedDiscoveryState,
    fromDiscovery.getFilterCounts,
);
export const getDisplaying = createSelector(getClosedDiscoveryState, fromDiscovery.getDisplaying);
export const getTotal = createSelector(getClosedDiscoveryState, fromDiscovery.getTotal);
export const getOldestOccurrence = createSelector(
    getClosedDiscoveryState,
    fromDiscovery.getOldestOccurrence,
);
export const getNewestOccurrence = createSelector(
    getClosedDiscoveryState,
    fromDiscovery.getNewestOccurrence,
);
export const getMinCowMilkConcentration = createSelector(
    getClosedDiscoveryState,
    fromDiscovery.getMinCowMilkConcentration,
);
export const getMaxCowMilkConcentration = createSelector(
    getClosedDiscoveryState,
    fromDiscovery.getMaxCowMilkConcentration,
);
export const getMinSourceConcentration = createSelector(
    getClosedDiscoveryState,
    fromDiscovery.getMinSourceConcentration,
);
export const getMaxSourceConcentration = createSelector(
    getClosedDiscoveryState,
    fromDiscovery.getMaxSourceConcentration,
);
export const getMinTargetConcentration = createSelector(
    getClosedDiscoveryState,
    fromDiscovery.getMinTargetConcentration,
);
export const getMaxTargetConcentration = createSelector(
    getClosedDiscoveryState,
    fromDiscovery.getMaxTargetConcentration,
);
export const getMinMoleculeWeight = createSelector(
    getClosedDiscoveryState,
    fromDiscovery.getMinMoleculeWeight,
);
export const getMaxMoleculeWeight = createSelector(
    getClosedDiscoveryState,
    fromDiscovery.getMaxMoleculeWeight,
);
export const getInsightsPerCategory = createSelector(
    getClosedDiscoveryState,
    fromDiscovery.getInsightsPerCategory,
);
export const getGroupedCategories = createSelector(
    getClosedDiscoveryState,
    fromDiscovery.getGroupedCategories,
);
export const getShouldShowSourceConcentration = createSelector(
    getClosedDiscoveryState,
    fromDiscovery.getShouldShowSourceConcentration,
);
export const getShouldShowTargetConcentration = createSelector(
    getClosedDiscoveryState,
    fromDiscovery.getShouldShowTargetConcentration,
);
export const getPreferences = createSelector(getClosedDiscoveryState, fromDiscovery.getPreferences);
export const getSearchSuggestions = createSelector(
    getClosedDiscoveryState,
    fromDiscovery.getSearchSuggestions,
);
export const getSearchSuggestionsLoading = createSelector(
    getClosedDiscoveryState,
    fromDiscovery.getSearchSuggestionsLoading,
);
export const getSearchSuggestionsLoaded = createSelector(
    getClosedDiscoveryState,
    fromDiscovery.getSearchSuggestionsLoaded,
);
export const getErrors = createSelector(getClosedDiscoveryState, fromDiscovery.getErrors);
export const getLoading = createSelector(getClosedDiscoveryState, fromDiscovery.getLoading);
export const getLoaded = createSelector(getClosedDiscoveryState, fromDiscovery.getLoaded);
export const getBlob = createSelector(getClosedDiscoveryState, fromDiscovery.getBlob);
export const getSessionId = createSelector(getClosedDiscoveryState, fromDiscovery.getSessionId);
