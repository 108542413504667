/** third-party imports */
import { Injectable } from '@angular/core';
import { flatten } from 'lodash';

/** Constants */
import { EFFECTS } from '@apps/leap/src/app/shared/modules/profiler-report/constants/report';

/** Services - Parsers */
import { ArrayHandlerService } from '@leap-common/services/array-handler.service';
import { ReportParser } from '@leap-store/core/src/lib/data/report/parsers/report.parser';
import { CompoundReportParser } from '@leap-store/core/src/lib/data/compound-report/parsers/compound-report.parser';
import { EffectsParser } from '@apps/leap/src/app/shared/parsers/effects.parser';

/** Interfaces - Enums */
import Overview from '../interfaces/overview.interface';
import RelationshipGroup from '../enums/relationship-group.enum';
import RelationshipGroupStatisticsItemRestApi from '../rest-api-interfaces/relationship-group-statistics-item.rest.interface';
import HealthLabelInfoRestApi from '../rest-api-interfaces/health-label-info.rest.interface';
import TopCompound from '../interfaces/top-compound.interface';
import TopCompoundRestApi from '../rest-api-interfaces/top-compound.rest.interface';
import HealthLabelStatisticsRestApi from '../rest-api-interfaces/health-label-statistics.rest.interface';
import HealthLabelStatistics from '../interfaces/health-label-statistics.interface';
import HealthLabelSummariesRestApi from '../rest-api-types/health-label-summaries.rest.type';
import HealthLabelSummaries from '../interfaces/health-label-summaries.interface';
import Summary from '../../report/interfaces/summary.interface';
import SummaryRestApi from '../../compound-report/rest-api-interfaces/summary.rest.interface';
import HealthLabelTopCompounds from '../interfaces/health-label-top-compounds.interface';
import HealthLabelTopCompoundsRestApi from '../rest-api-types/health-label-top-compounds.rest.type';
import TopTargetRestApi from '../rest-api-interfaces/top-target.rest.interface';
import StatisticsItem from '../../report/interfaces/statistics-item.interface';
import EffectStatisticsItemRestApi from '../../compound-report/rest-api-interfaces/effect-statistics-item.rest.interface';
import Effect from '../enums/effect.enum';

@Injectable()
export class ProfilerReportParser {
    constructor(
        private arrayHandlerService: ArrayHandlerService,
        private reportParser: ReportParser,
        private compoundReportParser: CompoundReportParser,
        private effectsParser: EffectsParser,
    ) {}

    parseOverview(healthLabelsInfo: HealthLabelInfoRestApi[]): Overview {
        return healthLabelsInfo.reduce(
            (accumulator: Overview, healthLabelInfo: HealthLabelInfoRestApi) => {
                accumulator.statistics.push({
                    healthLabel: healthLabelInfo.healthArea,
                    statistics: {
                        [RelationshipGroup.prevents]: healthLabelInfo[RelationshipGroup.prevents],
                        [RelationshipGroup.causes]: healthLabelInfo[RelationshipGroup.causes],
                        [Effect.beneficial]: healthLabelInfo[Effect.beneficial],
                        [Effect.adverse]: healthLabelInfo[Effect.adverse],
                    },
                });

                return accumulator;
            },
            { statistics: [] },
        );
    }

    parseHealthLabelsStatistics(
        healthLabelsStatistics: HealthLabelStatisticsRestApi[],
        areEffectsEnabled?: boolean,
    ): HealthLabelStatistics[] {
        return (
            healthLabelsStatistics?.map((healthLabelStatistics: HealthLabelStatisticsRestApi) =>
                this.parseHealthLabelStatistics(healthLabelStatistics, areEffectsEnabled),
            ) || []
        ).filter(({ filteredTargetsCount }: HealthLabelStatistics) => filteredTargetsCount > 0);
    }

    parseHealthLabelStatistics(
        healthLabelStatistics: HealthLabelStatisticsRestApi,
        areEffectsEnabled: boolean,
    ): HealthLabelStatistics {
        return {
            name: healthLabelStatistics.healthArea,
            targetsCount: healthLabelStatistics.noOfAssociations,
            filteredTargetsCount: healthLabelStatistics.noOfFilteredAssociations,
            relationshipGroups: !areEffectsEnabled
                ? this.parseHealthLabelRelationshipGroupStatistics(
                      healthLabelStatistics.counts as RelationshipGroupStatisticsItemRestApi[],
                  )
                : null,
            otherRelationships: !areEffectsEnabled
                ? this.parseHealthLabelOtherRelationshipStatistics(
                      healthLabelStatistics.counts as RelationshipGroupStatisticsItemRestApi[],
                  )
                : null,
            effects: areEffectsEnabled
                ? this.parseHealthLabelEffectStatistics(
                      healthLabelStatistics.counts as EffectStatisticsItemRestApi[],
                  )
                : null,
        };
    }

    parseHealthLabelRelationshipGroupStatistics(
        relationshipGroups: RelationshipGroupStatisticsItemRestApi[],
    ): StatisticsItem[] {
        return relationshipGroups
            .filter(
                ({ relationshipTypeGroup }: RelationshipGroupStatisticsItemRestApi) =>
                    !relationshipTypeGroup.includes(RelationshipGroup.other),
            )
            .map(({ relationshipTypeGroup, count }: RelationshipGroupStatisticsItemRestApi) => ({
                name: relationshipTypeGroup,
                value: count,
            }));
    }

    parseHealthLabelOtherRelationshipStatistics(
        relationshipGroups: RelationshipGroupStatisticsItemRestApi[],
    ): StatisticsItem[] {
        return relationshipGroups
            .filter(({ relationshipTypeGroup }: RelationshipGroupStatisticsItemRestApi) =>
                relationshipTypeGroup.includes(RelationshipGroup.other),
            )
            .map(({ relationshipTypeGroup, count }: RelationshipGroupStatisticsItemRestApi) => ({
                name:
                    relationshipTypeGroup === RelationshipGroup.other
                        ? relationshipTypeGroup
                        : relationshipTypeGroup.split(' ').slice(1).join(' '),
                value: count,
            }));
    }

    parseHealthLabelEffectStatistics(effects: EffectStatisticsItemRestApi[]): StatisticsItem[] {
        return effects
            .map(({ effect, count }: EffectStatisticsItemRestApi) => ({
                name: this.effectsParser.parseEffect(effect),
                value: count,
            }))
            .sort(
                this.arrayHandlerService.getSortOrder(
                    EFFECTS,
                    ({ name }: StatisticsItem) => name as Effect,
                ),
            );
    }

    parseHealthLabelSummaries(
        healthLabel: string,
        healthLabelSummaries: HealthLabelSummariesRestApi,
        areEffectsEnabled?: boolean,
    ): HealthLabelSummaries {
        return {
            healthLabel,
            summaries: healthLabelSummaries
                ? Object.entries(healthLabelSummaries).reduce(
                      (
                          accumulator: Record<RelationshipGroup | Effect, Summary>,
                          [type, summary]: [RelationshipGroup | Effect, SummaryRestApi],
                      ) => {
                          const parsedType: RelationshipGroup | Effect = areEffectsEnabled
                              ? this.effectsParser.parseEffect(type)
                              : type;

                          accumulator[parsedType] = this.compoundReportParser.parseSummary(summary);
                          return accumulator;
                      },
                      {} as Record<RelationshipGroup | Effect, Summary>,
                  )
                : null,
        };
    }

    parseHealthLabelTopCompounds(
        healthLabel: string,
        healthLabelTopCompounds: HealthLabelTopCompoundsRestApi,
        areEffectsEnabled?: boolean,
    ): HealthLabelTopCompounds {
        return {
            healthLabel,
            topCompounds: healthLabelTopCompounds
                ? Object.entries(healthLabelTopCompounds).reduce(
                      (
                          accumulator: Record<RelationshipGroup | Effect, TopCompound[]>,
                          [type, topCompounds]: [RelationshipGroup | Effect, TopCompoundRestApi[]],
                      ) => {
                          const parsedType: RelationshipGroup | Effect = areEffectsEnabled
                              ? this.effectsParser.parseEffect(type)
                              : type;

                          accumulator[parsedType] = this.parseTopCompounds(topCompounds);
                          return accumulator;
                      },
                      {} as Record<RelationshipGroup | Effect, TopCompound[]>,
                  )
                : null,
        };
    }

    parseTopCompounds(topCompounds: TopCompoundRestApi[]): TopCompound[] {
        return topCompounds.map((topCompound: TopCompoundRestApi) =>
            this.parseTopCompound(topCompound),
        );
    }

    parseTopCompound(topCompound: TopCompoundRestApi): TopCompound {
        return {
            name: topCompound.moleculeName,
            rankingIndex: topCompound.index,
            cowMilkConcentration: topCompound.cowMilkConcentration,
            sourceConcentration: topCompound.sourceConcentrations,
            relationships: topCompound.relationshipTypes,
            healthLabels: topCompound.totalHealthAreas,
            references: this.reportParser.parseReferences(
                flatten(
                    topCompound.topMedicalTerms.map(
                        ({ references }: TopTargetRestApi) => references,
                    ),
                ),
            ),
            topTargets: topCompound.topMedicalTerms.map(({ name }: TopTargetRestApi) => name),
        };
    }
}
