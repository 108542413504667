/** third-party imports */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

/** custom imports */
import { environment } from '@environments/leap/environment';
import { TermsParser } from '../parsers/terms.parser';

/** Interfaces */
import ResultsRestApi from '@leap-common/rest-api-interfaces/results.rest.interface';
import TermOverviewRestApi from '../rest-api-interfaces/term-overview.rest.interface';
import TermOverview from '../interfaces/term-overview.interface';

@Injectable()
export class TermsService {
    constructor(private http: HttpClient, private termsParser: TermsParser) {}

    getTermsOverview(ids: string[]): Observable<TermOverview[]> {
        return this.http
            .post(`${environment.discoveryServerUrl}/concepts/details`, {
                cuis: ids,
            })
            .pipe(
                map((terms: ResultsRestApi<TermOverviewRestApi>) =>
                    this.termsParser.parseTermsOverview(terms.results),
                ),
            );
    }
}
