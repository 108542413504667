import { createAction, props } from '@ngrx/store';
import { ProjectsActionTypes } from './projects-action-types.enum';
import StatusFilterOption from './interfaces/status-filter-option.interface';
import TypeFilterOption from './interfaces/type-filter-option.interface';
import SortingOrder from '@leap-common/enums/sorting-order.enum';

export const updateSearchFilter = createAction(
    ProjectsActionTypes.UPDATE_SEARCH_FILTER,
    props<{ filter: string }>(),
);

export const updateStatusFilters = createAction(
    ProjectsActionTypes.UPDATE_STATUS_FILTERS,
    props<{ filters: StatusFilterOption[] }>(),
);

export const updateTypeFilters = createAction(
    ProjectsActionTypes.UPDATE_TYPE_FILTERS,
    props<{ filters: TypeFilterOption[] }>(),
);

export const updateSortDirection = createAction(
    ProjectsActionTypes.UPDATE_SORT_DIRECTION,
    props<{ sortDirection: SortingOrder }>(),
);

export const updateSortColumn = createAction(
    ProjectsActionTypes.UPDATE_SORT_COLUMN,
    props<{ sortColumn: string }>(),
);
