import { Component, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';

/** Constants */
import { ELLIPSIS, EMPTY_STRING } from '@leap-common/constants/common';
import {
    CHAT_ICON,
    CHAT_CLOSE_ICON,
    HEADER_TITLE,
    HEADER_SUBTITLE,
    LINKS_INFO,
} from '../../../constants/chat';

/** Services - Facades */
import { FormatterService } from '@leap-common/services/formatter.service';
import { GPTFacade } from '@leap-store/core/src/lib/data/gpt/gpt.facade';

/** Interfaces */
import Header from '@leap-common/interfaces/header.interface';
import AssistantQuery from '@leap-store/core/src/lib/data/gpt/interfaces/assistant-query.interface';

@Component({
    selector: 'app-layout-chat',
    templateUrl: 'chat.container.component.html',
    styleUrls: ['chat.container.component.scss'],
})
export class ChatContainerComponent implements OnInit, OnDestroy {
    /**
     * This function runs every time the window gains focus.
     * It fetches the queries to ensure that if multiple instances of the app are open,
     * when the user writes in one instance and then switches to another,
     * the chat history will be updated without requiring the user to refresh manually
     */
    @HostListener('window:focus', ['$event'])
    onFocus(): void {
        this.getQueries();
    }

    // header
    header: Header;

    // queries
    queries: AssistantQuery[];
    queries$: Observable<AssistantQuery[]> = this.gptFacade.assistantQueries$;
    loading$: Observable<boolean> = this.gptFacade.assistantQueryLoading$;
    loaded$: Observable<boolean> = this.gptFacade.assistantQueryLoaded$;

    // popover
    @ViewChild('popover', { static: false, read: NgbPopover })
    popover: NgbPopover;
    isPopoverOpen: boolean;

    // delete
    isValidAnswerIncludedInLastQuery: boolean;

    // confirmation dialog
    isConfirmationDialogDisplayed: boolean;

    // subscriptions
    queriesSubscription: Subscription;

    readonly chatIcon: string = CHAT_ICON;
    readonly chatCloseIcon: string = CHAT_CLOSE_ICON;

    constructor(private formatterService: FormatterService, private gptFacade: GPTFacade) {}

    ngOnInit(): void {
        this.initializeHeader();
        this.getQueries();
        this.handleQueries();
    }

    ngOnDestroy(): void {
        this.queriesSubscription?.unsubscribe();
    }

    initializeHeader(): void {
        this.header = {
            icon: CHAT_ICON,
            title: HEADER_TITLE,
            subtitle: HEADER_SUBTITLE,
        };
    }

    onButtonClick(): void {
        this.popover.isOpen() ? this.closePopover() : this.openPopover();
    }

    onCloseClick(): void {
        this.closePopover();
    }

    updateIsPopoverOpen(): void {
        this.isPopoverOpen = this.popover.isOpen();
    }

    openPopover(): void {
        this.popover.open();
        this.updateIsPopoverOpen();
    }

    closePopover(): void {
        this.popover.close();
        this.updateIsPopoverOpen();
    }

    onQueryToBePerformed(query: string): void {
        this.performQuery(query);
    }

    performQuery(query: string): void {
        this.gptFacade.performAssistantQuery(query);
    }

    getQueries(): void {
        this.gptFacade.getAssistantQueries();
    }

    handleQueries(): void {
        this.queriesSubscription = this.queries$.subscribe((queries: AssistantQuery[]) => {
            this.queries = queries.map((query: AssistantQuery) => ({
                ...query,
                answer: this.formatterService.addLinksToText(query.answer, LINKS_INFO),
            }));

            const lastQuery: AssistantQuery = this.queries[this.queries?.length - 1];

            this.isValidAnswerIncludedInLastQuery =
                lastQuery && lastQuery?.answer !== ELLIPSIS && lastQuery?.answer !== EMPTY_STRING;
        });
    }

    onDeleteButtonClicked(isClicked: boolean): void {
        if (!isClicked) {
            return;
        }

        this.updateIsConfirmationDialogDisplayed(true);
    }

    onConfirmationDialogCancelClicked(isClicked: boolean): void {
        if (!isClicked) {
            return;
        }

        this.updateIsConfirmationDialogDisplayed(false);
    }

    onConfirmationDialogDeleteClicked(isClicked: boolean): void {
        if (!isClicked) {
            return;
        }

        this.gptFacade.deleteAssistantQueries();
        this.updateIsConfirmationDialogDisplayed(false);
    }

    updateIsConfirmationDialogDisplayed(isDisplayed: boolean): void {
        this.isConfirmationDialogDisplayed = isDisplayed;
    }
}
