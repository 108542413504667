import { Injectable } from '@angular/core';

/** custom imports */
import { TagsParser } from '../../tags/parsers/tags.parser';

/** Helpers */
import { mapEmailToUsername } from '@leap-common/utilities/helpers';

/** Interfaces - Types - Enums */
import PaginatedResultsRestApi from '@leap-common/rest-api-interfaces/paginated-results.rest.interface';
import PaginatedResults from '@leap-common/interfaces/paginated-results.interface';
import ProjectRestApi from '../rest-api-interfaces/project.rest.interface';
import Project from '../interfaces/project.interface';
import ProjectPropertiesRestApi from '../rest-api-interfaces/project-properties.rest.interface';
import ProjectProperties from '../interfaces/project-properties.interface';
import Bookmark from '../../bookmarks/enums/bookmark.enum';
import Privacy from '../enums/privacy.enum';
import Status from '../enums/status.enum';
import SortingOrder from '@leap-common/enums/sorting-order.enum';

@Injectable()
export class ProjectsParser {
    constructor(private tagsParser: TagsParser) {}

    /** Parses Paginated Projects */
    parsePaginatedResults(
        paginatedProjects: PaginatedResultsRestApi<ProjectRestApi>,
    ): PaginatedResults<Project> {
        return {
            results: paginatedProjects.results ? this.parseProjects(paginatedProjects.results) : [],
            pageIndex: paginatedProjects.pageIndex - 1,
            pageSize: paginatedProjects.pageSize,
            total: paginatedProjects.total,
        };
    }

    /** Parses BE Projects to integrate them on the FE */
    parseProjects(projects: ProjectRestApi[]): Project[] {
        return projects.map((project: ProjectRestApi) => this.parseProject(project));
    }

    /** Parses ProjectRestApi to Project */
    parseProject(project: ProjectRestApi): Project {
        return {
            id: project.uuid,
            name: project.name,
            description: project.description,
            tags: project.tags ? this.tagsParser.parseTags(project.tags) : [],
            favorite: project.favourite,
            archived: project.archived,
            createdBy: project.creator_mail ? mapEmailToUsername(project.creator_mail) : undefined,
            createdAt: project.created_at,
            updatedAt: project.updated_at,
            privacy: project.privacy ? this.parsePrivacy(project.privacy) : undefined,
            status: project.status ? this.parseStatus(project.status) : undefined,
            bookmarksCount: project.bookmarks_count
                ? this.parseBookmarksCount(project.bookmarks_count)
                : undefined,
            isArticlesReportEnabled: project.reportAllowedVersion,
        };
    }

    /** Parses string to Privacy */
    parsePrivacy(privacy: string): Privacy {
        return privacy === 'private'
            ? Privacy.private
            : privacy === 'public'
            ? Privacy.public
            : privacy === 'shared'
            ? Privacy.shared
            : undefined;
    }

    /** Parses string to Status */
    parseStatus(status: string): Status {
        return status === 'In progress'
            ? Status.inProgress
            : status === 'On hold'
            ? Status.onHold
            : status === 'Done'
            ? Status.done
            : undefined;
    }

    /** Parses Record<string, number> to Record<Bookmark, number> */
    parseBookmarksCount(bookmarksCount: Record<string, number>): Record<Bookmark, number> {
        return {
            [Bookmark.entity]: bookmarksCount.entities,
            [Bookmark.associationOpen]: bookmarksCount.od_associations,
            [Bookmark.associationClosed]: bookmarksCount.cd_associations,
            [Bookmark.article]: bookmarksCount.articles,
        };
    }

    /** Serializes projects sorting parameter */
    serializeProjectsOrderByParameter(sortDirection: SortingOrder, sortColumn: string): string {
        const direction: string = sortDirection === SortingOrder.descending ? '-' : '';
        const column: string =
            sortColumn === 'name'
                ? 'name'
                : sortColumn === 'createdAt'
                ? 'created_at'
                : sortColumn === 'updatedAt'
                ? 'updated_at'
                : undefined;
        return `${direction}${column}`;
    }

    /** Serializes status filter parameter */
    serializeStatusFilterParameter(statusFilter: string[]): string {
        return statusFilter.join(',');
    }

    /**
     * Parses FE Project to be sent to BE. Gets the properties of a project are to be updated and
     * converts them to ProjectPropertiesRestApi. Properties that do not exist in the initial
     * object they do not get included in the final object.
     */
    serializeProjectProperties(projectProperties: ProjectProperties): ProjectPropertiesRestApi {
        return {
            ...(projectProperties.archived !== undefined && {
                archived: projectProperties.archived,
            }),
            ...(projectProperties.name !== undefined && { name: projectProperties.name }),
            ...(projectProperties.description !== undefined && {
                description: projectProperties.description,
            }),
            ...(projectProperties.privacy && {
                privacy: this.serializePrivacy(projectProperties.privacy),
            }),
            ...(projectProperties.status && {
                status: this.serializeStatus(projectProperties.status),
            }),
            ...(projectProperties.tags && {
                tags: this.tagsParser.serializeTags(projectProperties.tags),
            }),
        };
    }

    /** Serializes privacy */
    serializePrivacy(privacy: Privacy): string {
        return privacy === Privacy.private
            ? 'private'
            : privacy === Privacy.public
            ? 'public'
            : privacy === Privacy.shared
            ? 'shared'
            : undefined;
    }

    /** Serializes status */
    serializeStatus(status: Status): string {
        return status === Status.inProgress
            ? 'In progress'
            : status === Status.onHold
            ? 'On hold'
            : status === Status.done
            ? 'Done'
            : undefined;
    }
}
