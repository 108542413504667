/** third-party imports */
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { Actions, createEffect, ofType } from '@ngrx/effects';

/** custom imports */
import { GPTService } from './services/gpt.service';
import { HttpErrorResponse } from '@angular/common/http';
import * as actions from './gpt.actions';
import QueryResponse from './interfaces/query-response.interface';
import QAPair from './interfaces/qa-pair.interface';

@Injectable()
export class GPTEffects {
    constructor(private actions$: Actions, private gptService: GPTService) {}

    performStructuredOpenDiscovery$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.performStructuredOpenDiscoveryRequest),
            switchMap(({ source, sourceId, sourceCategories, targetCategories }) =>
                this.gptService
                    .performStructuredOpenDiscovery(
                        source,
                        sourceId,
                        sourceCategories,
                        targetCategories,
                    )
                    .pipe(
                        map((structuredQuery: QueryResponse) =>
                            actions.performStructuredOpenDiscoverySuccess({ structuredQuery }),
                        ),
                        catchError((errorResponse: HttpErrorResponse) =>
                            of(actions.performStructuredOpenDiscoveryFailure({ errorResponse })),
                        ),
                    ),
            ),
        ),
    );

    performStructuredClosedDiscovery$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.performStructuredClosedDiscoveryRequest),
            switchMap(
                ({ source, sourceId, sourceCategories, target, targetId, targetCategories }) =>
                    this.gptService
                        .performStructuredClosedDiscovery(
                            source,
                            sourceId,
                            sourceCategories,
                            target,
                            targetId,
                            targetCategories,
                        )
                        .pipe(
                            map((structuredQuery: QueryResponse) =>
                                actions.performStructuredClosedDiscoverySuccess({
                                    structuredQuery,
                                }),
                            ),
                            catchError((errorResponse: HttpErrorResponse) =>
                                of(
                                    actions.performStructuredClosedDiscoveryFailure({
                                        errorResponse,
                                    }),
                                ),
                            ),
                        ),
            ),
        ),
    );

    performFreeQuery$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.performFreeQueryRequest),
            switchMap(({ query, sessionId }) =>
                this.gptService.performFreeQuery(query, sessionId).pipe(
                    map((freeQuery: QueryResponse) =>
                        actions.performFreeQuerySuccess({ freeQuery }),
                    ),
                    catchError((errorResponse: HttpErrorResponse) =>
                        of(actions.performFreeQueryFailure({ errorResponse })),
                    ),
                ),
            ),
        ),
    );

    regenerateQuery$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.regenerateQueryRequest),
            switchMap(({ id }) =>
                this.gptService.regenerateQuery(id).pipe(
                    map((query: QueryResponse) => actions.regenerateQuerySuccess({ query })),
                    catchError((errorResponse: HttpErrorResponse) =>
                        of(actions.regenerateQueryFailure({ errorResponse })),
                    ),
                ),
            ),
        ),
    );

    downloadSessionHistory$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.downloadSessionHistoryRequest),
            switchMap(({ id, extension }) =>
                this.gptService.downloadSessionHistory(id, extension).pipe(
                    map((blob: Blob) => actions.downloadSessionHistorySuccess({ blob })),
                    catchError((errorResponse: HttpErrorResponse) =>
                        of(actions.downloadSessionHistoryFailure({ errorResponse })),
                    ),
                ),
            ),
        ),
    );

    performAssistantQuery$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.performAssistantQueryRequest),
            switchMap(({ query }) =>
                this.gptService.performAssistantQuery(query).pipe(
                    map((assistantQuery: QAPair) =>
                        actions.performAssistantQuerySuccess({ assistantQuery }),
                    ),
                    catchError((errorResponse: HttpErrorResponse) =>
                        of(actions.performAssistantQueryFailure({ errorResponse })),
                    ),
                ),
            ),
        ),
    );

    getAssistantQueries$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.getAssistantQueriesRequest),
            switchMap(() =>
                this.gptService.getAssistantQueries().pipe(
                    map((assistantQueries: QAPair[]) =>
                        actions.getAssistantQueriesSuccess({ assistantQueries }),
                    ),
                    catchError((errorResponse: HttpErrorResponse) =>
                        of(actions.getAssistantQueriesFailure({ errorResponse })),
                    ),
                ),
            ),
        ),
    );

    deleteAssistantQueries$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.deleteAssistantQueriesRequest),
            switchMap(() =>
                this.gptService.deleteAssistantQueries().pipe(
                    map((assistantQueries: QAPair[]) =>
                        actions.deleteAssistantQueriesSuccess({ assistantQueries }),
                    ),
                    catchError((errorResponse: HttpErrorResponse) =>
                        of(actions.deleteAssistantQueriesFailure({ errorResponse })),
                    ),
                ),
            ),
        ),
    );
}
