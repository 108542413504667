import { ActionReducerMap } from '@ngrx/store';
import { AppState } from './app-state.interface';

/** reducers */
import * as fromData from './data/index';
import * as fromUI from './ui/index';

export const reducers: ActionReducerMap<AppState> = {
    data: fromData.reducers,
    ui: fromUI.reducers,
};
