/** third-party imports */
import { Injectable } from '@angular/core';

/** custom imports */
import QueryResponseRestApi from '../rest-api-interfaces/query-response.rest.interface';
import QueryResponse from '../interfaces/query-response.interface';
import QAPairRestApi from '../rest-api-interfaces/qa-pair.rest.interface';
import QAPair from '../interfaces/qa-pair.interface';

@Injectable()
export class GPTParser {
    constructor() {}

    parseQAPairs(qaPairs: QAPairRestApi[]): QAPair[] {
        return qaPairs?.map((qaPair: QAPairRestApi) => this.parseQAPair(qaPair)) || [];
    }

    parseQAPair(qaPair: QAPairRestApi): QAPair {
        return {
            question: qaPair?.question,
            answer: qaPair?.answer,
        };
    }

    parseQueryResponse(queryResponse: QueryResponseRestApi): QueryResponse {
        return {
            question: queryResponse.question,
            answer: queryResponse.answer,
            id: queryResponse.uuid,
            sessionId: queryResponse.session_uuid,
        };
    }
}
