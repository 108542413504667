export enum DairyProfilerActionTypes {
    GET_COMPOUNDS_REQUEST = '[DAIRY PROFILER][COMPOUNDS] Get Compounds Request',
    GET_COMPOUNDS_SUCCESS = '[DAIRY PROFILER][COMPOUNDS] Get Compounds Success',
    GET_COMPOUNDS_FAILURE = '[DAIRY PROFILER][COMPOUNDS] Get Compounds Failure',
    GET_INSIGHTS_REQUEST = '[DAIRY PROFILER][INSIGHTS] Get Insights Request',
    GET_INSIGHTS_SUCCESS = '[DAIRY PROFILER][INSIGHTS] Get Insights Success',
    GET_INSIGHTS_FAILURE = '[DAIRY PROFILER][INSIGHTS] Get Insights Failure',
    GET_DAIRY_PRODUCT_INSIGHTS_REQUEST = '[DAIRY PROFILER][INSIGHTS] Get Dairy Product Insights Request',
    GET_DAIRY_PRODUCT_INSIGHTS_SUCCESS = '[DAIRY PROFILER][INSIGHTS] Get Dairy Product Insights Success',
    GET_DAIRY_PRODUCT_INSIGHTS_FAILURE = '[DAIRY PROFILER][INSIGHTS] Get Dairy Product Insights Failure',
    GET_TARGET_INSIGHTS_REQUEST = '[DAIRY PROFILER][INSIGHTS] Get Target Insights Request',
    GET_TARGET_INSIGHTS_SUCCESS = '[DAIRY PROFILER][INSIGHTS] Get Target Insights Success',
    GET_TARGET_INSIGHTS_FAILURE = '[DAIRY PROFILER][INSIGHTS] Get Target Insights Failure',
    DOWNLOAD_INSIGHTS_REQUEST = '[DAIRY PROFILER][DOWNLOAD] Download Insights Request',
    DOWNLOAD_INSIGHTS_SUCCESS = '[DAIRY PROFILER][DOWNLOAD] Download Insights Success',
    DOWNLOAD_INSIGHTS_FAILURE = '[DAIRY PROFILER][DOWNLOAD] Download Insights Failure',
    GET_OVERVIEW_REQUEST = '[DAIRY PROFILER][OVERVIEW] Get Overview Request',
    GET_OVERVIEW_SUCCESS = '[DAIRY PROFILER][OVERVIEW] Get Overview Success',
    GET_OVERVIEW_FAILURE = '[DAIRY PROFILER][OVERVIEW] Get Overview Failure',
    GET_HEALTH_LABELS_STATISTICS_REQUEST = '[DAIRY PROFILER][HEALTH LABELS] Get Health Labels Statistics Request',
    GET_HEALTH_LABELS_STATISTICS_SUCCESS = '[DAIRY PROFILER][HEALTH LABELS] Get Health Labels Statistics Success',
    GET_HEALTH_LABELS_STATISTICS_FAILURE = '[DAIRY PROFILER][HEALTH LABELS] Get Health Labels Statistics Failure',
    GET_HEALTH_LABEL_SUMMARIES_REQUEST = '[DAIRY PROFILER][HEALTH LABELS] Get Health Label Summaries Request',
    GET_HEALTH_LABEL_SUMMARIES_SUCCESS = '[DAIRY PROFILER][HEALTH LABELS] Get Health Label Summaries Success',
    GET_HEALTH_LABEL_SUMMARIES_FAILURE = '[DAIRY PROFILER][HEALTH LABELS] Get Health Label Summaries Failure',
    GET_HEALTH_LABEL_TOP_COMPOUNDS_REQUEST = '[DAIRY PROFILER][HEALTH LABELS] Get Health Label Top Compounds Request',
    GET_HEALTH_LABEL_TOP_COMPOUNDS_SUCCESS = '[DAIRY PROFILER][HEALTH LABELS] Get Health Label Top Compounds Success',
    GET_HEALTH_LABEL_TOP_COMPOUNDS_FAILURE = '[DAIRY PROFILER][HEALTH LABELS] Get Health Label Top Compounds Failure',
    GET_SEARCH_SUGGESTIONS_REQUEST = '[DAIRY PROFILER][SEARCH SUGGESTIONS] Get Search Suggestions Request',
    GET_SEARCH_SUGGESTIONS_SUCCESS = '[DAIRY PROFILER][SEARCH SUGGESTIONS] Get Search Suggestions Success',
    GET_SEARCH_SUGGESTIONS_FAILURE = '[DAIRY PROFILER][SEARCH SUGGESTIONS] Get Search Suggestions Failure',
    CLEAR_SEARCH_SUGGESTIONS = '[DAIRY PROFILER][SEARCH SUGGESTIONS] Clear Search Suggestions',
    RESET_DISCOVERY = '[DAIRY PROFILER] Reset Discovery',
    CLEAR_NEXT_ERROR = '[DAIRY PROFILER] Clear Next Error',
}
