/** third-party imports */
import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { of } from 'rxjs';
import { mergeMap, map, catchError } from 'rxjs/operators';

/** custom imports */
import { PipelineExecutionsService } from './services/pipeline-executions.service';
import * as PipelineExecutionsActions from './pipeline-executions.actions';
import PaginatedResults from '@leap-common/interfaces/paginated-results.interface';
import PipelineExecution from './interfaces/pipeline-execution.interface';
import File from '@leap-store/core/src/lib/data/files/interfaces/file.interface';

@Injectable()
export class PipelineExecutionsEffects {
    constructor(
        private actions$: Actions,
        private pipelineExecutionsService: PipelineExecutionsService,
    ) {}

    getPipelineExecutions$ = createEffect(() =>
        this.actions$.pipe(
            ofType(PipelineExecutionsActions.getPipelineExecutionsRequest),
            mergeMap(({ pageIndex, pageSize, sortDirection, sortColumn }) =>
                this.pipelineExecutionsService
                    .getPipelineExecutions(pageIndex, pageSize, sortDirection, sortColumn)
                    .pipe(
                        map((paginatedPipelineExecutions: PaginatedResults<PipelineExecution>) =>
                            PipelineExecutionsActions.getPipelineExecutionsSuccess({
                                paginatedPipelineExecutions,
                                sortDirection,
                                sortColumn,
                            }),
                        ),
                        catchError((errorResponse: HttpErrorResponse) =>
                            of(
                                PipelineExecutionsActions.getPipelineExecutionsFailure({
                                    errorResponse,
                                }),
                            ),
                        ),
                    ),
            ),
        ),
    );

    getPipelineDownload$ = createEffect(() =>
        this.actions$.pipe(
            ofType(PipelineExecutionsActions.getPipelineResultRequest),
            mergeMap(({ id }) =>
                this.pipelineExecutionsService.getPipelineResult(id).pipe(
                    map((pipelineResult: File) =>
                        PipelineExecutionsActions.getPipelineResultSuccess({ pipelineResult }),
                    ),
                    catchError((errorResponse: HttpErrorResponse) =>
                        of(
                            PipelineExecutionsActions.getPipelineExecutionsFailure({
                                errorResponse,
                            }),
                        ),
                    ),
                ),
            ),
        ),
    );
}
