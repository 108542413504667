import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

/** custom imports */
import { CombinatorialDiscoveryInsightsService } from './service';
import Insight from '@leap-store/core/src/lib/data/discovery/combinatorial/interfaces/insight.interface';
import InsightsPerCategory from '@leap-store/core/src/lib/data/discovery/combinatorial/types/insights-per-category.type';
import Metadata from '@leap-store/core/src/lib/data/discovery/combinatorial/interfaces/metadata.interface';
import SortingOptions from '@leap-common/interfaces/sorting-options.interface';
import Filter from '@apps/leap/src/app/shared/modules/filters/interfaces/filter.interface';
import InsightRelationshipType from '@apps/leap/src/app/shared/types/discovery-insight-relationship-type.type';
import InsightsPerAssociation from '@apps/leap/src/app/shared/types/insights-per-association.type';
import InsightsPerAssociationType from '@apps/leap/src/app/shared/types/insights-per-association-type.type';
import InsightsPerRelationshipType from '@apps/leap/src/app/shared/types/insights-per-relationship-type.type';
import InsightsPerRelationshipTypeOrigin from '@apps/leap/src/app/shared/types/insights-per-relationship-type-origin.type';

@Injectable()
export class CombinatorialDiscoveryInsightsFacade {
    subject: BehaviorSubject<PouchDB.Core.ExistingDocument<Insight>[] | null> = new BehaviorSubject(
        null,
    );
    filteredSubject: BehaviorSubject<PouchDB.Core.ExistingDocument<Insight>[] | null> =
        new BehaviorSubject(null);
    displayingSubject: BehaviorSubject<number> = new BehaviorSubject(null);
    totalSubject: BehaviorSubject<number> = new BehaviorSubject(null);
    countPerCategorySubject: BehaviorSubject<InsightsPerCategory> = new BehaviorSubject(null);
    countPerAssociationSubject: BehaviorSubject<InsightsPerAssociation> = new BehaviorSubject(null);
    countPerAssociationTypeSubject: BehaviorSubject<InsightsPerAssociationType> =
        new BehaviorSubject(null);
    countPerRelationshipTypeABSubject: BehaviorSubject<InsightsPerRelationshipType> =
        new BehaviorSubject(null);
    countPerRelationshipTypeBCSubject: BehaviorSubject<InsightsPerRelationshipType> =
        new BehaviorSubject(null);
    countPerRelationshipTypeACSubject: BehaviorSubject<InsightsPerRelationshipType> =
        new BehaviorSubject(null);
    countPerRelationshipTypeOriginABSubject: BehaviorSubject<InsightsPerRelationshipTypeOrigin> =
        new BehaviorSubject(null);
    countPerRelationshipTypeOriginBCSubject: BehaviorSubject<InsightsPerRelationshipTypeOrigin> =
        new BehaviorSubject(null);
    countPerRelationshipTypeOriginACSubject: BehaviorSubject<InsightsPerRelationshipTypeOrigin> =
        new BehaviorSubject(null);
    oldestOccurrenceSubject: BehaviorSubject<number> = new BehaviorSubject(null);
    newestOccurrenceSubject: BehaviorSubject<number> = new BehaviorSubject(null);
    totalRelationshipsABSubject: BehaviorSubject<InsightRelationshipType[]> = new BehaviorSubject(
        [],
    );
    totalRelationshipsBCSubject: BehaviorSubject<InsightRelationshipType[]> = new BehaviorSubject(
        [],
    );
    totalRelationshipsACSubject: BehaviorSubject<InsightRelationshipType[]> = new BehaviorSubject(
        [],
    );
    isDiscoveryExecutionCompleteSubject: BehaviorSubject<boolean> = new BehaviorSubject(false);
    errorMessageSubject: BehaviorSubject<string> = new BehaviorSubject(null);

    constructor(private service: CombinatorialDiscoveryInsightsService) {}

    /** Database */
    getOrCreatePouchDB(id: string, categories: string[]): Promise<boolean> {
        return this.service.getOrCreateDB(id, categories);
    }

    createPouchDB(id: string, categories: string[]): Promise<void> {
        return this.service.createDB(id, categories);
    }

    doesPouchDBExist(id: string, categories: string[]): Promise<boolean> {
        return this.service.doesDBExist(id, categories);
    }

    destroyPouchDB(id: string, categories: string[]): Promise<void> {
        return this.service.destroyDB(id, categories);
    }

    destroyPouchDBs(): Promise<void[]> {
        return this.service.destroyDBs();
    }

    resetDiscovery(): void {
        this.resetInsights();
        this.resetMetadata();
    }

    /** Insights */
    resetInsights(): void {
        this.subject.next(null);
        this.filteredSubject.next(null);
        this.isDiscoveryExecutionCompleteSubject.next(false);
    }

    async getInsights({
        limit,
        sortingOptions,
        filters,
    }: {
        limit?: number;
        sortingOptions?: SortingOptions;
        filters?: Record<string, Filter[] | [number, number]>;
    }): Promise<PouchDB.Find.FindResponse<unknown>> {
        const response: PouchDB.Find.FindResponse<any> = await this.service.retrieve({
            limit,
            sortingOptions,
            filters,
        });

        if (limit) {
            this.filteredSubject.next(response.docs);
        } else {
            this.subject.next(response.docs);
        }

        this.isDiscoveryExecutionCompleteSubject.next(true);

        return response;
    }

    storeInsights({
        insights,
        fields,
    }: {
        insights: Insight[];
        fields?: string[];
    }): Promise<(PouchDB.Core.Response | PouchDB.Core.Error)[]> {
        return this.service.populate({ insights, fields });
    }

    /** Metadata */
    addMetadata(metadata: Metadata): Promise<PouchDB.Core.Response> {
        return this.service.createMetadata(metadata);
    }

    async getMetadata(): Promise<void> {
        const response: PouchDB.Core.IdMeta & PouchDB.Core.GetMeta & Metadata =
            await this.service.retrieveMetadata();

        this.displayingSubject.next(response.displaying);
        this.totalSubject.next(response.total);
        this.countPerCategorySubject.next(response.countPerCategory);
        this.countPerAssociationSubject.next(response.countPerAssociation);
        this.countPerAssociationTypeSubject.next(response.countPerAssociationType);
        this.countPerRelationshipTypeABSubject.next(response.countPerRelationshipTypeAB);
        this.countPerRelationshipTypeBCSubject.next(response.countPerRelationshipTypeBC);
        this.countPerRelationshipTypeACSubject.next(response.countPerRelationshipTypeAC);
        this.countPerRelationshipTypeOriginABSubject.next(
            response.countPerRelationshipTypeOriginAB,
        );
        this.countPerRelationshipTypeOriginBCSubject.next(
            response.countPerRelationshipTypeOriginBC,
        );
        this.countPerRelationshipTypeOriginACSubject.next(
            response.countPerRelationshipTypeOriginAC,
        );
        this.oldestOccurrenceSubject.next(response.oldestOccurrence);
        this.newestOccurrenceSubject.next(response.newestOccurrence);
        this.totalRelationshipsABSubject.next(response.totalRelationshipsAB);
        this.totalRelationshipsBCSubject.next(response.totalRelationshipsBC);
        this.totalRelationshipsACSubject.next(response.totalRelationshipsAC);
        this.errorMessageSubject.next(response.errorMessage);
    }

    resetMetadata(): void {
        this.displayingSubject.next(null);
        this.totalSubject.next(null);
        this.countPerCategorySubject.next(null);
        this.countPerAssociationSubject.next(null);
        this.countPerAssociationTypeSubject.next(null);
        this.countPerRelationshipTypeABSubject.next(null);
        this.countPerRelationshipTypeBCSubject.next(null);
        this.countPerRelationshipTypeACSubject.next(null);
        this.countPerRelationshipTypeOriginABSubject.next(null);
        this.countPerRelationshipTypeOriginBCSubject.next(null);
        this.countPerRelationshipTypeOriginACSubject.next(null);
        this.oldestOccurrenceSubject.next(null);
        this.newestOccurrenceSubject.next(null);
        this.totalRelationshipsABSubject.next([]);
        this.totalRelationshipsACSubject.next([]);
        this.totalRelationshipsBCSubject.next([]);
        this.errorMessageSubject.next(null);
    }
}
