import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';

/** Constants */
import { LOGOUT_TEXT, LOGOUT_SUBTITLE, LOGIN_AGAIN_TEXT } from '../../../../core/constants/auth';

/** Facades */
import { AuthFacade } from '@leap-store/core/src/lib/data/auth/auth.facade';

/** Interfaces */
import Jwt from '@leap-store/core/src/lib/data/auth/interfaces/jwt.interface';

@Component({
    selector: 'app-logout',
    templateUrl: 'logout.container.component.html',
    styleUrls: ['logout.container.component.scss'],
})
export class LogoutContainerComponent {
    // jwt
    jwt$: Observable<Jwt> = this.authFacade.jwt$;

    readonly title: string = LOGOUT_TEXT;
    readonly subtitle: string = LOGOUT_SUBTITLE;
    readonly loginAgainText: string = LOGIN_AGAIN_TEXT;

    constructor(private authFacade: AuthFacade, private router: Router) {}

    onButtonClick(): void {
        this.router.navigate(['auth/login']);
    }
}
