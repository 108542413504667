export enum FilesActionTypes {
    STAGE_FILE_TO_UPLOAD = '[FILES] Stage File to upload',
    UNSTAGE_FILE_TO_UPLOAD = '[FILES] Unstage File to upload',
    CREATE_FILE_PATH_REQUEST = '[FILES] Create File Path Request',
    CREATE_FILE_PATH_SUCCESS = '[FILES] Create File Path Success',
    CREATE_FILE_PATH_FAILURE = '[FILES] Create File Path Failure',
    UPLOAD_FILE_REQUEST = '[FILES] Upload File Request',
    UPLOAD_FILE_SUCCESS = '[FILES] Upload File Success',
    UPLOAD_FILE_FAILURE = '[FILES] Upload File Failure',
    UPDATE_UPLOAD_PROGRESS = '[FILES] Update Upload Progress',
    CANCEL_FILE_UPLOAD = '[FILES] Cancel File Upload',
    DELETE_FILE_PATH_REQUEST = '[FILES] Delete File Path Request',
    DELETE_FILE_PATH_SUCCESS = '[FILES] Delete File Path Success',
    DELETE_FILE_PATH_FAILURE = '[FILES] Delete File Path Failure',
    DELETE_FILE_REQUEST = '[FILES] Delete File Request',
    DELETE_FILE_SUCCESS = '[FILES] Delete File Success',
    DELETE_FILE_FAILURE = '[FILES] Delete File Failure',
    GET_FILES_REQUEST = '[FILES] Get Files Request',
    GET_FILES_SUCCESS = '[FILES] Get Files Success',
    GET_FILES_FAILURE = '[FILES] Get Files Failure',
    CLEAR_NEXT_ERROR = '[FILES] Clear Next Error',
    CLEAR_SHOULD_FETCH_FILES = '[FILES] Clear Should Fetch Files',
}
