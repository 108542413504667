import { Injectable } from '@angular/core';

/** Interfaces  */
import DefinitionsRestApi from '../rest-api-types/definitions.rest.type';
import Definitions from '../types/definitions.type';

@Injectable()
export class DefinitionsParser {
    constructor() {}

    parseDefinitions(definitions: DefinitionsRestApi): Definitions {
        return definitions;
    }

    serializeDefinitions(definitions: Definitions): DefinitionsRestApi {
        return definitions;
    }
}
