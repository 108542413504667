/** third-party imports */
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

/** custom imports */
import { environment } from '@environments/leap/environment';
import { OpenDiscoveryParser } from '../parsers/open-discovery.parser';

/** Interfaces - Types */
import PaginatedInsightsRestApi from '@apps/leap/src/app/shared/rest-api-interfaces/paginated-insights.rest.interface';
import PaginatedInsights from '../interfaces/paginated-insights.interface';
import InsightRestApi from '../rest-api-interfaces/insight.rest.interface';
import InsightsPerCategoryRestApi from '../rest-api-types/insights-per-category.rest.type';
import ExecutionFilters from '@apps/leap/src/app/shared/modules/filters/types/execution-filters.type';
import UserPreferences from '@apps/leap/src/app/shared/types/user-preferences.type';
import ResultsRestApi from '@leap-common/rest-api-interfaces/results.rest.interface';

@Injectable()
export class OpenDiscoveryService {
    constructor(private http: HttpClient, private insightsParser: OpenDiscoveryParser) {}

    /**
     * Performs open discovery and gets insights, parses them into the desired format and
     * returns an Observable of PaginatedInsights.
     */
    performDiscovery(
        source: string,
        targetCategories: string[],
        preferences: UserPreferences,
        filters: ExecutionFilters,
        pageSize: number,
        pageIndex: number,
    ): Observable<PaginatedInsights> {
        return this.http
            .post(`${environment.discoveryServerUrl}/open-discovery`, {
                sourceUid: source,
                targetCategories,
                preferences,
                filters,
                pageSize,
                pageIndex,
            })
            .pipe(
                map(
                    (
                        paginatedResults: PaginatedInsightsRestApi<
                            InsightRestApi,
                            InsightsPerCategoryRestApi
                        >,
                    ) => this.insightsParser.parsePaginatedResults(paginatedResults),
                ),
            );
    }

    download(
        source: string,
        targetCategories: string[],
        preferences: UserPreferences,
        filters?: ExecutionFilters,
    ): Observable<Blob> {
        return this.http.post(
            `${environment.discoveryServerUrl}/open-discovery/download`,
            {
                sourceUid: source,
                targetCategories,
                preferences,
                ...(filters && { filters }),
            },
            {
                headers: new HttpHeaders({
                    Accept: 'text/csv',
                }),
                responseType: 'blob',
            },
        );
    }

    getSearchSuggestions(source: string, query: string): Observable<string[]> {
        return this.http
            .post(`${environment.discoveryServerUrl}/open-discovery/search`, {
                sourceUid: source,
                searchString: query,
            })
            .pipe(map(({ results }: ResultsRestApi<string>) => results));
    }
}
