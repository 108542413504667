enum FileStatus {
    empty = '',
    staged = 'staged',
    uploading = 'uploading',
    initializing = 'initializing',
    success = 'success',
    error = 'error',
    retry = 'retry',
}

export default FileStatus;
