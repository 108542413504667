import { Directive, Input, Renderer2, ElementRef, OnChanges } from '@angular/core';
import { uniq } from 'lodash';

/** Constants */
import Range from '@leap-common/types/range.type';

/** Helpers */
import { escapeRegExpSpecialChars } from '@leap-common/utilities/helpers';

/** Interfaces */
import Highlight from './interfaces/highlight.interface';
import HighlightClassChange from './interfaces/highlight-class-change.interface';

@Directive({
    selector: '[libHighlight]',
})
export class HighlightDirective implements OnChanges {
    @Input() text: string;
    @Input() windowsToHighlight: Record<string, Range[]>;
    @Input() wordsToHighlight: Record<string, string[]>;

    constructor(private elementRef: ElementRef, private renderer: Renderer2) {}

    ngOnChanges(): void {
        this.updateHighlights();
    }

    updateHighlights(): void {
        if (!this.text) {
            return;
        }

        if (!this.wordsToHighlight && !this.windowsToHighlight) {
            this.renderer.setProperty(this.elementRef.nativeElement, 'innerHTML', this.text);
            return;
        }

        let highlights: Highlight[] = this.mapDataToHighlightsArray();

        highlights = this.separateOverlappingWindows(highlights);

        this.renderer.setProperty(
            this.elementRef.nativeElement,
            'innerHTML',
            this.getFormattedText(highlights),
        );
    }

    mapDataToHighlightsArray(): Highlight[] {
        let highlights: Highlight[] = [];

        if (this.windowsToHighlight) {
            Object.entries(this.windowsToHighlight).forEach(
                ([className, windows]: [string, Range[]]) => {
                    highlights = [
                        ...highlights,
                        ...windows.map((window: Range) => ({
                            window,
                            class: className,
                        })),
                    ];
                },
            );
        } else if (this.wordsToHighlight) {
            Object.entries(this.wordsToHighlight).forEach(
                ([className, words]: [string, string[]]) => {
                    // escapes special regex characters
                    const escapedHighlightWords: string[] = words.map(escapeRegExpSpecialChars);
                    const regex: RegExp = new RegExp(`(${escapedHighlightWords.join('|')})`, 'g');
                    const wordHighlights: Highlight[] = [];

                    let match: RegExpExecArray = regex.exec(this.text);
                    while (match) {
                        wordHighlights.push({
                            window: [match.index, match.index + match[0].length - 1],
                            class: className,
                        });
                        match = regex.exec(this.text);
                    }

                    highlights = [...highlights, ...wordHighlights];
                },
            );
        }

        return highlights;
    }

    /**
     * Accepts an array of possibly overlapping highlights and maps it to an array of non-overlapping
     * ones so that multiple classes are assigned to intervals in which there was overlap.
     *
     * Example:
     * Input = [
     *          {window: [0, 6], class: 'class1'},
     *          {window: [4, 10], class: 'class2'},
     *         ]
     * Output = [
     *          {window: [0, 3], class: 'class1'},
     *          {window: [4, 6], class: 'class1 class2'},
     *          {window: [7, 10], class: 'class2'},
     *         ]
     */
    separateOverlappingWindows(highlights: Highlight[]): Highlight[] {
        const mergedHighlights: Highlight[] = [];
        let mergedClasses: string[] = [];

        const classChanges: Map<number, HighlightClassChange> = this.getClassChanges(highlights);

        classChanges.forEach((change: HighlightClassChange, position: number) => {
            if (change.added) {
                mergedClasses = [...mergedClasses, ...change.added];
            }
            if (change.removed) {
                change.removed.forEach((removedClassName: string) => {
                    // we remove only one occurrence in case there is overlap of
                    // two windows with the same class
                    const indexToRemove: number = mergedClasses.findIndex(
                        (className: string) => className === removedClassName,
                    );
                    mergedClasses.splice(indexToRemove, 1);
                });
            }

            if (mergedHighlights.length) {
                mergedHighlights[mergedHighlights.length - 1].window[1] = position - 1;
            }

            mergedHighlights.push({
                window: [position, null],
                class: uniq(mergedClasses).join(' '),
            });
        });

        mergedHighlights.pop();

        return mergedHighlights;
    }

    /**
     * Creates a record of the class changes in each position to facilitate the merging of
     * overlapping windows.
     *
     * Example:
     * Input = [
     *          {window: [0, 6], class: 'class1'},
     *          {window: [4, 10], class: 'class2'},
     *         ]
     * Output = {
     *              0: {added: ['class1'], removed: []},
     *              4: {added: ['class2'], removed: []},
     *              7: {added: [], removed: ['class1']},
     *              11: {added: [], removed: ['class2']},
     *          }
     */
    getClassChanges(highlights: Highlight[]): Map<number, HighlightClassChange> {
        let classChanges: Map<number, HighlightClassChange> = new Map<
            number,
            HighlightClassChange
        >();

        highlights.forEach((highlight: Highlight) => {
            const className: string = highlight.class;
            const from: number = highlight.window[0];
            const to: number = highlight.window[1];
            const fromChanges: HighlightClassChange = classChanges.get(from);
            const toChanges: HighlightClassChange = classChanges.get(to + 1);

            classChanges.set(
                from,
                fromChanges
                    ? {
                          ...fromChanges,
                          added: [...fromChanges.added, className],
                      }
                    : { added: [className], removed: [] },
            );
            classChanges.set(
                to + 1,
                toChanges
                    ? {
                          ...toChanges,
                          removed: [...toChanges.removed, className],
                      }
                    : { added: [], removed: [className] },
            );
        });

        // sort by key
        classChanges = new Map(
            [...classChanges].sort((changeA, changeB) => changeA[0] - changeB[0]),
        );

        return classChanges;
    }

    getTextInWindow([start, end]: [number, number?]): string {
        return this.text.slice(start, end !== undefined ? end + 1 : undefined);
    }

    getFormattedText(highlights: Highlight[]): string {
        let text: string = '';
        // we initialize this at -1 so that the next index (lastHighlightedPosition + 1)
        // is the beginning of the text
        let lastHighlightedPosition: number;

        highlights.forEach((highlight: Highlight) => {
            // add normal text between highlights
            if (highlight.window[0] !== lastHighlightedPosition) {
                text = text.concat(
                    this.getTextInWindow([lastHighlightedPosition + 1, highlight.window[0] - 1]),
                );
            }

            // add highlighted text
            text = text.concat(
                `<span class="${highlight.class}">${this.getTextInWindow(highlight.window)}</span>`,
            );

            lastHighlightedPosition = highlight.window[1];
        });

        // add normal text after last highlight
        text = text.concat(this.getTextInWindow([lastHighlightedPosition + 1]));

        return text;
    }
}
