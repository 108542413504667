import { Pipe, PipeTransform } from '@angular/core';
@Pipe({ name: 'filesize' })
export class FilesizePipe implements PipeTransform {
    constructor() {}

    public transform(bytes: number): string {
        const units = ['B', 'KB', 'MB', 'GB', 'TB'];
        // if the uploaded file is empty, return
        if (bytes === 0 || isNaN(bytes)) {
            return `0 ${units[0]}`;
        }
        // find the proper unit
        const unit = Math.floor(Math.log(bytes) / Math.log(1024));
        // if the file size is bytes don't make any modification
        if (unit === 0) {
            return `${bytes} ${units[unit]}`;
        }
        return `${(bytes / 1024 ** unit)
            .toFixed(2)
            .replace(/([0-9]+(\.[0-9]+[1-9])?)(\.?0+$)/, '$1')} ${units[unit]}`;
    }
}
