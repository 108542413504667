import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

/** Guards */
import { FeatureGuard } from '@leap-libs/feature-flags/src/lib/feature.guard';

/** Components */
import { AuthLayoutContainerComponent } from './components/containers/auth-layout/auth-layout.container.component';
import { PermissionsContainerComponent } from './components/containers/permissions/permissions.container.component';
import { LogoutContainerComponent } from './components/containers/logout/logout.container.component';

const routes: Routes = [
    {
        path: '',
        component: AuthLayoutContainerComponent,
        children: [
            { path: '', redirectTo: 'login', pathMatch: 'full' },
            {
                path: 'login',
                loadChildren: () =>
                    import('../login/login.module').then((module) => module.LoginModule),
            },
            {
                path: 'forgot-password',
                loadChildren: () =>
                    import('../forgot-password/forgot-password.module').then(
                        (module) => module.ForgotPasswordModule,
                    ),
            },
            {
                path: 'reset-password',
                loadChildren: () =>
                    import('../reset-password/reset-password.module').then(
                        (module) => module.ResetPasswordModule,
                    ),
            },
            {
                path: 'external-login',
                canActivate: [FeatureGuard],
                data: {
                    feature: 'externalLogin',
                    path: 'login',
                },
                loadChildren: () =>
                    import('../external-login/external-login.module').then(
                        (module) => module.ExternalLoginModule,
                    ),
            },
            {
                path: 'permissions',
                component: PermissionsContainerComponent,
            },
            {
                path: 'logout',
                component: LogoutContainerComponent,
            },
        ],
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class AuthLayoutRoutingModule {}
