/** third-party imports */
import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';

/** custom imports */
import { ArticlesState } from './articles-state.interface';
import { updateAssociationArticlesDisplay } from './articles.actions';
import { getAssociationArticlesDisplay } from './articles.selectors';

@Injectable()
export class ArticlesFacade {
    areAssociationArticlesDisplayed$: Observable<boolean> = this.store.pipe(
        select(getAssociationArticlesDisplay),
    );

    constructor(private store: Store<ArticlesState>) {}

    updateAssociationArticlesDisplay(areDisplayed: boolean): void {
        this.store.dispatch(updateAssociationArticlesDisplay({ areDisplayed }));
    }
}
