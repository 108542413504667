enum FileExtension {
    txt = 'txt',
    csv = 'csv',
    docx = 'docx',
    xlsx = 'xlsx',
    pdf = 'pdf',
    png = 'png',
    zip = 'zip',
    html = 'html',
    bib = 'bib',
}

export default FileExtension;
