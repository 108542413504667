/** Modules */
import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthRoutingModule } from './auth-routing.module';
import { CoreModule } from './core/core.module';
import { LoginModule } from './login/login.module';
import { AuthLayoutModule } from './auth-layout/auth-layout.module';

@NgModule({
    imports: [CommonModule, AuthRoutingModule, CoreModule.forRoot(), LoginModule, AuthLayoutModule],
    declarations: [],
})
export class AuthModule {
    static forRoot(): ModuleWithProviders<AuthModule> {
        return {
            ngModule: AuthModule,
            providers: [],
        };
    }
}
