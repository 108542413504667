import { AfterViewInit, Directive, ElementRef, Input, OnDestroy, Renderer2 } from '@angular/core';
import { DebouncedFunc, throttle } from 'lodash';

@Directive({ selector: '[libBackToTop]' })
export class BackToTopDirective implements AfterViewInit, OnDestroy {
    @Input() scrollToTopSelector: string;

    private backToTopElement: HTMLElement;
    private isScrolled: boolean;

    constructor(private element: ElementRef, private renderer: Renderer2) {}

    ngAfterViewInit(): void {
        this.initializeBackToTopElement();
        this.addEventListeners();
        this.toggleBackToTopVisibility(false);
    }

    ngOnDestroy(): void {
        this.throttledScrollListener.cancel();
        this.element.nativeElement.removeEventListener('scroll', this.throttledScrollListener);
        this.backToTopElement?.removeEventListener('click', this.onBackToTopClicked);
    }

    /**
     *  The reason for using the arrow function instead of the traditional one is that the 'this'
     *  keyword is generally bound to various values depending on the situation in which it is invoked.
     *  The 'this' keyword is lexically bound with arrow functions, so it utilizes 'this' from the code
     *  that includes the arrow function.
     */
    scrollWatcherCallback = (): void => {
        const heightPercentage: number = 20 / 100;

        this.isScrolled =
            this.element.nativeElement.scrollTop >
            this.element.nativeElement.scrollHeight * heightPercentage;

        this.toggleBackToTopVisibility(this.isScrolled);
    };

    // Throttle scroll listener calls for better performance
    throttledScrollListener: DebouncedFunc<() => void> = throttle(this.scrollWatcherCallback, 40);

    initializeBackToTopElement(): void {
        this.backToTopElement = this.element.nativeElement.querySelector(this.scrollToTopSelector);
    }

    addEventListeners(): void {
        if (!this.backToTopElement) {
            return;
        }

        this.element.nativeElement.addEventListener('scroll', this.throttledScrollListener);
        this.backToTopElement.addEventListener('click', this.onBackToTopClicked);
    }

    toggleBackToTopVisibility(isVisible: boolean): void {
        if (!this.backToTopElement) {
            return;
        }

        if (isVisible) {
            this.renderer.removeClass(this.backToTopElement, 'd-none');
        } else {
            this.renderer.addClass(this.backToTopElement, 'd-none');
        }
    }

    onBackToTopClicked = (): void => {
        if (!this.isScrolled) {
            return;
        }

        this.element.nativeElement.scrollTo({ top: 0, behavior: 'smooth' });
    };
}
