import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { reducers } from '.';
import { metaReducers } from './meta-reducers';

/** Data */
import { DataStoreModule } from './data/data-store.module';

/** UI */
import { UIStoreModule } from './ui/ui-store.module';

@NgModule({
    imports: [
        CommonModule,
        DataStoreModule,
        UIStoreModule,
        StoreModule.forRoot(reducers, {
            metaReducers,
        }),
        StoreDevtoolsModule.instrument(),
    ],
    declarations: [],
    providers: [],
})
export class CoreStoreModule {}
