/** third-party imports */
import { createReducer, on, Action, ActionReducer } from '@ngrx/store';

/** custom imports */
import * as profilerActions from './ingredient-profiler.actions';
import { IngredientProfilerState } from './ingredient-profiler-state.interface';
import TabId from '@apps/leap/src/app/shared/enums/tab-id.enum';
import DiscoveryExecution from '../discovery/interfaces/discovery-execution.interface';
import DiscoverySummary from '../discovery/interfaces/discovery-summary.interface';

export const initialState: IngredientProfilerState = {
    activeTabId: null,
    execution: null,
    summary: null,
};

const ingredientProfilerReducer: ActionReducer<IngredientProfilerState, Action> = createReducer(
    initialState,
    on(
        profilerActions.updateActiveTabId,
        (state: IngredientProfilerState, { id }: { id: TabId }) => ({
            ...state,
            activeTabId: id,
        }),
    ),
    on(
        profilerActions.updateExecution,
        (state: IngredientProfilerState, { execution }: { execution: DiscoveryExecution }) => ({
            ...state,
            execution,
        }),
    ),
    on(
        profilerActions.updateSummary,
        (state: IngredientProfilerState, { summary }: { summary: DiscoverySummary }) => ({
            ...state,
            summary,
        }),
    ),
);

export const reducer = (
    state: IngredientProfilerState | undefined,
    action: Action,
): IngredientProfilerState => ingredientProfilerReducer(state, action);

// selectors
export const getActiveTabId: (state: IngredientProfilerState) => TabId = (
    state: IngredientProfilerState,
) => state.activeTabId;
export const getExecution: (state: IngredientProfilerState) => DiscoveryExecution = (
    state: IngredientProfilerState,
) => state.execution;
export const getSummary: (state: IngredientProfilerState) => DiscoverySummary = (
    state: IngredientProfilerState,
) => state.summary;
