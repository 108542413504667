/** third-party imports */
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';

/** custom imports */
import { reducers } from '../../index';
import { DefinitionsEffects } from './definitions.effects';
import { DefinitionsFacade } from './definitions.facade';
import { DefinitionsParser } from './parsers/definitions.parser';
import { DefinitionsService } from './services/definitions.service';

@NgModule({
    imports: [
        CommonModule,
        HttpClientModule,
        StoreModule.forRoot(reducers),
        StoreDevtoolsModule.instrument({ maxAge: 10 }),
        EffectsModule.forRoot([DefinitionsEffects]),
    ],
    declarations: [],
    providers: [DefinitionsParser, DefinitionsService, DefinitionsFacade],
})
export class DefinitionsStoreModule {}
