import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Actions, ofType, createEffect, CreateEffectMetadata } from '@ngrx/effects';
import { TypedAction } from '@ngrx/store/src/models';
import { of, Observable } from 'rxjs';
import { switchMap, map, catchError } from 'rxjs/operators';
import { RolesService } from './services/roles.service';
import * as rolesActions from './roles.actions';
import { RolesActionTypes } from './roles-action-types.enum';
import PaginatedResults from '@leap-common/interfaces/paginated-results.interface';
import UserRole from '@leap-store/core/src/lib/data/auth/interfaces/user-role.interface';

@Injectable()
export class RolesEffects {
    constructor(private actions$: Actions, private rolesService: RolesService) {}

    getRoles$: Observable<
        | ({
              paginatedRoles: PaginatedResults<UserRole>;
          } & TypedAction<RolesActionTypes.GET_ROLES_SUCCESS>)
        | ({
              errorResponse: HttpErrorResponse;
          } & TypedAction<RolesActionTypes.GET_ROLES_FAILURE>)
    > &
        CreateEffectMetadata = createEffect(() =>
        this.actions$.pipe(
            ofType(rolesActions.getRolesRequest),
            switchMap(() =>
                this.rolesService.getAvailableUserRoles().pipe(
                    map((paginatedRoles: PaginatedResults<UserRole>) =>
                        rolesActions.getRolesSuccess({ paginatedRoles }),
                    ),
                    catchError((errorResponse: HttpErrorResponse) =>
                        of(rolesActions.getRolesFailure({ errorResponse })),
                    ),
                ),
            ),
        ),
    );
}
