/** third-party libraries */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

/** custom imports */
import { environment } from '@environments/leap/environment';
import { IngredientViewerParser } from '../parsers/ingredient-viewer.parser';
import ResultsRestApi from '@leap-common/rest-api-interfaces/results.rest.interface';
import IngredientRestApi from '../rest-api-interfaces/ingredient.rest.interface';
import Ingredient from '../interfaces/ingredient.interface';
import StatisticsRestApi from '../rest-api-interfaces/statistics.rest.interface';
import Statistics from '../interfaces/statistics.interface';

@Injectable()
export class IngredientViewerService {
    constructor(private http: HttpClient, private ingredientViewerParser: IngredientViewerParser) {}

    getIngredients(query: string): Observable<Ingredient[]> {
        return this.http
            .post(`${environment.ingredientAnalyzerServerUrl}/ingredients/autocomplete/`, {
                name: query,
            })
            .pipe(
                map((suggestions: ResultsRestApi<IngredientRestApi>) =>
                    this.ingredientViewerParser.parseIngredients(suggestions.results),
                ),
            );
    }

    getStatistics(ingredientId: string): Observable<Statistics> {
        return this.http
            .post(`${environment.ingredientAnalyzerServerUrl}/report/`, {
                ingredientUid: ingredientId,
            })
            .pipe(
                map((statistics: StatisticsRestApi) =>
                    this.ingredientViewerParser.parseStatistics(statistics),
                ),
            );
    }
}
