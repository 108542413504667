import { Injectable } from '@angular/core';
import { featureFlags } from '@environments/leap/environment';

@Injectable()
export class FeatureFlagsService {
    constructor() {}

    isFeatureEnabled(feature: string): boolean {
        return featureFlags && featureFlags[feature];
    }
}
