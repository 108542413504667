/** third-party imports */
import { createReducer, on, Action, ActionReducer } from '@ngrx/store';
import { EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { HttpErrorResponse } from '@angular/common/http';

/** custom imports */
import * as ingredientManagementActions from './ingredient-management.actions';
import { IngredientManagementState } from './ingredient-management-state.interface';
import { NOTES_SUCCESS_MESSAGE } from '@leap-libs/notes/src/lib/constants/notes';
import {
    DELETE_INGREDIENT_SUCCESS_MESSAGE,
    EDIT_INGREDIENT_SUCCESS_MESSAGE,
} from './constants/ingredient-management';
import { FILE_UPLOAD_ERROR_STATUS } from './constants/file-upload';
import ErrorResponse from '@leap-common/interfaces/error-response.interface';
import PaginatedResults from '@leap-common/interfaces/paginated-results.interface';
import Alert from '@leap-store/core/src/lib/ui/alerts/interfaces/alert.interface';
import AlertType from '@leap-store/core/src/lib/ui/alerts/enums/alert-type.enum';
import Ingredient from '@leap-store/core/src/lib/data/ingredient-viewer/interfaces/ingredient.interface';
import FileUploadResult from './interfaces/file-upload-result.interface';

export const adapter: EntityAdapter<Ingredient> = createEntityAdapter<Ingredient>();

export const initialState: IngredientManagementState = adapter.getInitialState({
    success: [],
    errors: [],
    fileUploadResult: null,
    loading: false,
    loaded: false,
    total: 0,
    shouldFetchIngredients: false,
    ingredientBlob: null,
    templateBlob: null,
    instructionsBlob: null,
});

const ingredientManagement: ActionReducer<IngredientManagementState, Action> = createReducer(
    initialState,
    on(ingredientManagementActions.uploadFileRequest, (state: IngredientManagementState) => ({
        ...state,
    })),
    on(
        ingredientManagementActions.uploadFileSuccess,
        (state: IngredientManagementState, { result }: { result: FileUploadResult }) => ({
            ...state,
            fileUploadResult: result,
            shouldFetchIngredients: Boolean(result.ingredients?.length),
        }),
    ),
    on(
        ingredientManagementActions.uploadFileFailure,
        (
            state: IngredientManagementState,
            {
                errorResponse,
                result,
            }: { errorResponse: HttpErrorResponse; result: FileUploadResult },
        ) => ({
            ...state,
            errors:
                errorResponse.status === FILE_UPLOAD_ERROR_STATUS
                    ? state.errors
                    : [...state.errors, errorResponse.error],
            fileUploadResult: result,
            shouldFetchIngredients: Boolean(result.ingredients?.length),
        }),
    ),
    on(
        ingredientManagementActions.getIngredientsRequest,
        (state: IngredientManagementState, { suppressLoading }: { suppressLoading: boolean }) => ({
            ...state,
            shouldFetchIngredients: false,
            loading: suppressLoading ? state.loading : true,
            loaded: suppressLoading ? state.loaded : false,
        }),
    ),
    on(
        ingredientManagementActions.getIngredientsSuccess,
        (
            state: IngredientManagementState,
            { paginatedIngredients }: { paginatedIngredients: PaginatedResults<Ingredient> },
        ) =>
            adapter.setAll(paginatedIngredients.results, {
                ...state,
                loading: false,
                loaded: true,
                total: paginatedIngredients.total,
            }),
    ),
    on(
        ingredientManagementActions.getIngredientsFailure,
        (
            state: IngredientManagementState,
            { errorResponse }: { errorResponse: HttpErrorResponse },
        ) => ({
            ...state,
            errors: [...state.errors, errorResponse.error],
            loading: false,
            loaded: false,
        }),
    ),
    on(ingredientManagementActions.updateNameRequest, (state: IngredientManagementState) => ({
        ...state,
    })),
    on(
        ingredientManagementActions.updateNameSuccess,
        (state: IngredientManagementState, { ingredient }: { ingredient: Ingredient }) =>
            adapter.upsertOne(ingredient, {
                ...state,
                success: [
                    ...state.success,
                    {
                        type: AlertType.success,
                        icon: 'check',
                        messages: [EDIT_INGREDIENT_SUCCESS_MESSAGE],
                    },
                ],
            }),
    ),
    on(
        ingredientManagementActions.updateNameFailure,
        (
            state: IngredientManagementState,
            { errorResponse }: { errorResponse: HttpErrorResponse },
        ) => ({
            ...state,
            errors: [...state.errors, errorResponse.error],
        }),
    ),
    on(ingredientManagementActions.updateNotesRequest, (state: IngredientManagementState) => ({
        ...state,
    })),
    on(ingredientManagementActions.updateNotesSuccess, (state: IngredientManagementState) => ({
        ...state,
        success: [
            ...state.success,
            {
                type: AlertType.success,
                icon: 'check',
                messages: [NOTES_SUCCESS_MESSAGE],
            },
        ],
    })),
    on(
        ingredientManagementActions.updateNotesFailure,
        (
            state: IngredientManagementState,
            { errorResponse }: { errorResponse: HttpErrorResponse },
        ) => ({
            ...state,
            errors: [...state.errors, errorResponse.error],
        }),
    ),
    on(
        ingredientManagementActions.downloadIngredientRequest,
        (state: IngredientManagementState) => ({
            ...state,
            ingredientBlob: null as Blob,
        }),
    ),
    on(
        ingredientManagementActions.downloadIngredientSuccess,
        (state: IngredientManagementState, { blob }: { blob: Blob }) => ({
            ...state,
            ingredientBlob: blob,
        }),
    ),
    on(
        ingredientManagementActions.downloadIngredientFailure,
        (
            state: IngredientManagementState,
            { errorResponse }: { errorResponse: HttpErrorResponse },
        ) => ({
            ...state,
            errors: [...state.errors, errorResponse.error],
        }),
    ),
    on(ingredientManagementActions.deleteIngredientRequest, (state: IngredientManagementState) => ({
        ...state,
    })),
    on(ingredientManagementActions.deleteIngredientSuccess, (state: IngredientManagementState) => ({
        ...state,
        success: [
            ...state.success,
            {
                type: AlertType.success,
                icon: 'trash-alt',
                messages: [DELETE_INGREDIENT_SUCCESS_MESSAGE],
            },
        ],
        shouldFetchIngredients: true,
    })),
    on(
        ingredientManagementActions.deleteIngredientFailure,
        (
            state: IngredientManagementState,
            { errorResponse }: { errorResponse: HttpErrorResponse },
        ) => ({
            ...state,
            errors: [...state.errors, errorResponse.error],
        }),
    ),
    on(ingredientManagementActions.downloadTemplateRequest, (state: IngredientManagementState) => ({
        ...state,
        templateBlob: null as Blob,
    })),
    on(
        ingredientManagementActions.downloadTemplateSuccess,
        (state: IngredientManagementState, { blob }: { blob: Blob }) => ({
            ...state,
            templateBlob: blob,
        }),
    ),
    on(
        ingredientManagementActions.downloadTemplateFailure,
        (
            state: IngredientManagementState,
            { errorResponse }: { errorResponse: HttpErrorResponse },
        ) => ({
            ...state,
            errors: [...state.errors, errorResponse.error],
        }),
    ),
    on(
        ingredientManagementActions.downloadInstructionsRequest,
        (state: IngredientManagementState) => ({
            ...state,
            instructionsBlob: null as Blob,
        }),
    ),
    on(
        ingredientManagementActions.downloadInstructionsSuccess,
        (state: IngredientManagementState, { blob }: { blob: Blob }) => ({
            ...state,
            instructionsBlob: blob,
        }),
    ),
    on(
        ingredientManagementActions.downloadInstructionsFailure,
        (
            state: IngredientManagementState,
            { errorResponse }: { errorResponse: HttpErrorResponse },
        ) => ({
            ...state,
            errors: [...state.errors, errorResponse.error],
        }),
    ),
    on(ingredientManagementActions.clearNextSuccess, (state: IngredientManagementState) => ({
        ...state,
        success: state.success.slice(1),
    })),
    on(ingredientManagementActions.clearNextError, (state: IngredientManagementState) => ({
        ...state,
        errors: state.errors.slice(1),
    })),
    on(ingredientManagementActions.resetIngredientManagement, () => initialState),
);

export const reducer = (
    state: IngredientManagementState | undefined,
    action: Action,
): IngredientManagementState => ingredientManagement(state, action);

// selectors
export const getSuccess: (state: IngredientManagementState) => Alert[] = (
    state: IngredientManagementState,
) => state.success;
export const getErrors: (state: IngredientManagementState) => ErrorResponse[] = (
    state: IngredientManagementState,
) => state.errors;
export const getFileUploadResult: (state: IngredientManagementState) => FileUploadResult = (
    state: IngredientManagementState,
) => state.fileUploadResult;
export const getEntities: (state: IngredientManagementState) => Ingredient[] =
    adapter.getSelectors().selectAll;
export const getLoading: (state: IngredientManagementState) => boolean = (
    state: IngredientManagementState,
) => state.loading;
export const getLoaded: (state: IngredientManagementState) => boolean = (
    state: IngredientManagementState,
) => state.loaded;
export const getTotal: (state: IngredientManagementState) => number = (
    state: IngredientManagementState,
) => state.total;
export const getShouldFetchIngredients: (state: IngredientManagementState) => boolean = (
    state: IngredientManagementState,
) => state.shouldFetchIngredients;
export const getIngredientBlob: (state: IngredientManagementState) => Blob = (
    state: IngredientManagementState,
) => state.ingredientBlob;
export const getTemplateBlob: (state: IngredientManagementState) => Blob = (
    state: IngredientManagementState,
) => state.templateBlob;
export const getInstructionsBlob: (state: IngredientManagementState) => Blob = (
    state: IngredientManagementState,
) => state.instructionsBlob;
