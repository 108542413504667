/** third-party imports */
import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';

/** custom imports */
import { ProfilerState } from './profiler-state.interface';
import { updateActiveTabId, reset } from './profiler.actions';
import { getActiveTabId } from './profiler.selectors';
import TabId from '@apps/leap/src/app/shared/enums/tab-id.enum';

@Injectable()
export class ProfilerFacade {
    activeTabId$: Observable<TabId> = this.store.pipe(select(getActiveTabId));

    constructor(private store: Store<ProfilerState>) {}

    updateActiveTabId(id: TabId): void {
        this.store.dispatch(updateActiveTabId({ id }));
    }

    reset(): void {
        this.store.dispatch(reset());
    }
}
