/** third-party imports */
import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';

/** custom imports */
import ErrorResponse from '@leap-common/interfaces/error-response.interface';
import { SynonymsState } from './synonyms-state.interface';
import { getSynonymsRequest, clearSynonyms, clearNextError } from './synonyms.actions';
import { getSynonyms, getErrors, getLoading, getLoaded } from './synonyms.selectors';
import Synonyms from './types/synonyms.type';
import BookmarkIds from '@leap-store/core/src/lib/data/bookmarks/interfaces/bookmark-ids.interface';

@Injectable()
export class SynonymsFacade {
    synonyms$: Observable<Synonyms> = this.store.pipe(select(getSynonyms));
    errors$: Observable<ErrorResponse[]> = this.store.pipe(select(getErrors));
    loading$: Observable<boolean> = this.store.pipe(select(getLoading));
    loaded$: Observable<boolean> = this.store.pipe(select(getLoaded));

    constructor(private store: Store<SynonymsState>) {}

    getSynonyms(ids: string[], bookmarkIds?: BookmarkIds): void {
        this.store.dispatch(getSynonymsRequest({ ids, bookmarkIds }));
    }

    clearSynonyms(): void {
        this.store.dispatch(clearSynonyms());
    }

    clearNextError(): void {
        this.store.dispatch(clearNextError());
    }
}
