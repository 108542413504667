/** third-party imports */
import { createReducer, on, Action, ActionReducer } from '@ngrx/store';
import { HttpErrorResponse } from '@angular/common/http';

/** custom imports */
import * as gptActions from './gpt.actions';
import { GPTState } from './gpt-state.interface';
import { ELLIPSIS } from '@leap-common/constants/common';
import { ERROR_TEXT } from '@apps/leap/src/app/shared/constants/gpt';
import { INITIAL_ASSISTANT_QUERY } from './constants/gpt';
import ErrorResponse from '@leap-common/interfaces/error-response.interface';
import QueryResponse from './interfaces/query-response.interface';
import QAPair from './interfaces/qa-pair.interface';
import AssistantQuery from './interfaces/assistant-query.interface';

export const initialState: GPTState = {
    structuredQuery: null,
    freeQuery: null,
    latestQuery: null,
    queries: [],
    blob: null,
    errors: [],
    loading: false,
    loaded: false,
    assistantQueries: [INITIAL_ASSISTANT_QUERY],
    assistantQuery: null,
    assistantQueryLoading: false,
    assistantQueryLoaded: false,
    assistantQueryErrors: [],
};

const gptReducer: ActionReducer<GPTState, Action> = createReducer(
    initialState,
    on(
        gptActions.performStructuredOpenDiscoveryRequest,
        (state: GPTState, { source }: { source: string }) => {
            const lastQueryIndex: number = state.queries.length - 1;
            const lastQuery: QueryResponse = state.queries[lastQueryIndex];
            const currentQuery: QueryResponse = {
                ...state.structuredQuery,
                question: source,
                answer: ELLIPSIS,
            };

            return {
                ...state,
                structuredQuery: currentQuery,
                latestQuery: currentQuery,
                queries:
                    lastQuery?.question === currentQuery.question
                        ? [...state.queries.slice(0, lastQueryIndex), currentQuery]
                        : [...state.queries, currentQuery],
                loading: true,
                loaded: false,
            };
        },
    ),
    on(
        gptActions.performStructuredOpenDiscoverySuccess,
        (state: GPTState, { structuredQuery }: { structuredQuery: QueryResponse }) => {
            const lastQueryIndex: number = state.queries.length - 1;
            const lastQuery: QueryResponse = state.queries?.[lastQueryIndex];

            return {
                ...state,
                structuredQuery,
                latestQuery: structuredQuery,
                queries:
                    lastQuery?.question === structuredQuery.question
                        ? [...state.queries.slice(0, lastQueryIndex), structuredQuery]
                        : [...state.queries, structuredQuery],
                loading: false,
                loaded: true,
            };
        },
    ),
    on(
        gptActions.performStructuredOpenDiscoveryFailure,
        (state: GPTState, { errorResponse }: { errorResponse: HttpErrorResponse }) => {
            const lastQueryIndex: number = state.queries.length - 1;
            const lastQuery: QueryResponse = state.queries?.[lastQueryIndex];
            const lastQueryWithEmptyAnswer: QueryResponse = {
                ...lastQuery,
                answer: '',
            };

            const error: ErrorResponse = {
                errors: [
                    {
                        code: errorResponse.error?.errors?.[0]?.code,
                        message: ERROR_TEXT,
                        type: errorResponse.error?.errors?.[0]?.type,
                    },
                ],
            };

            return {
                ...state,
                latestQuery: lastQueryWithEmptyAnswer,
                queries: [...state.queries.slice(0, lastQueryIndex), lastQueryWithEmptyAnswer],
                errors: [
                    ...state.errors,
                    errorResponse.status === 503 ? error : errorResponse.error,
                ],
                loading: false,
                loaded: false,
            };
        },
    ),
    on(
        gptActions.performStructuredClosedDiscoveryRequest,
        (state: GPTState, { source, target }: { source: string; target: string }) => {
            const lastQueryIndex: number = state.queries.length - 1;
            const lastQuery: QueryResponse = state.queries[lastQueryIndex];
            const currentQuery: QueryResponse = {
                ...state.structuredQuery,
                question: `${source} + ${target}`,
                answer: ELLIPSIS,
            };

            return {
                ...state,
                structuredQuery: currentQuery,
                latestQuery: currentQuery,
                queries:
                    lastQuery?.question === currentQuery.question
                        ? [...state.queries.slice(0, lastQueryIndex), currentQuery]
                        : [...state.queries, currentQuery],
                loading: true,
                loaded: false,
            };
        },
    ),
    on(
        gptActions.performStructuredClosedDiscoverySuccess,
        (state: GPTState, { structuredQuery }: { structuredQuery: QueryResponse }) => {
            const lastQueryIndex: number = state.queries.length - 1;
            const lastQuery: QueryResponse = state.queries?.[lastQueryIndex];

            return {
                ...state,
                structuredQuery,
                latestQuery: structuredQuery,
                queries:
                    lastQuery?.question === structuredQuery.question
                        ? [...state.queries.slice(0, lastQueryIndex), structuredQuery]
                        : [...state.queries, structuredQuery],
                loading: false,
                loaded: true,
            };
        },
    ),
    on(
        gptActions.performStructuredClosedDiscoveryFailure,
        (state: GPTState, { errorResponse }: { errorResponse: HttpErrorResponse }) => {
            const lastQueryIndex: number = state.queries.length - 1;
            const lastQuery: QueryResponse = state.queries?.[lastQueryIndex];
            const lastQueryWithEmptyAnswer: QueryResponse = {
                ...lastQuery,
                answer: '',
            };

            const error: ErrorResponse = {
                errors: [
                    {
                        code: errorResponse.error?.errors?.[0]?.code,
                        message: ERROR_TEXT,
                        type: errorResponse.error?.errors?.[0]?.type,
                    },
                ],
            };

            return {
                ...state,
                latestQuery: lastQueryWithEmptyAnswer,
                queries: [...state.queries.slice(0, lastQueryIndex), lastQueryWithEmptyAnswer],
                errors: [
                    ...state.errors,
                    errorResponse.status === 503 ? error : errorResponse.error,
                ],
                loading: false,
                loaded: false,
            };
        },
    ),
    on(gptActions.performFreeQueryRequest, (state: GPTState, { query }: { query: string }) => {
        const lastQueryIndex: number = state.queries.length - 1;
        const lastQuery: QueryResponse = state.queries[lastQueryIndex];
        const currentQuery: QueryResponse = {
            ...state.freeQuery,
            question: query,
            answer: ELLIPSIS,
        };

        return {
            ...state,
            freeQuery: currentQuery,
            latestQuery: currentQuery,
            queries:
                lastQuery?.question === currentQuery.question
                    ? [...state.queries.slice(0, lastQueryIndex), currentQuery]
                    : [...state.queries, currentQuery],
            loading: true,
            loaded: false,
        };
    }),
    on(
        gptActions.performFreeQuerySuccess,
        (state: GPTState, { freeQuery }: { freeQuery: QueryResponse }) => {
            const lastQueryIndex: number = state.queries.length - 1;
            const lastQuery: QueryResponse = state.queries[lastQueryIndex];

            return {
                ...state,
                freeQuery,
                latestQuery: freeQuery,
                queries:
                    lastQuery.question === freeQuery.question
                        ? [...state.queries.slice(0, lastQueryIndex), freeQuery]
                        : [...state.queries, freeQuery],
                loading: false,
                loaded: true,
            };
        },
    ),
    on(
        gptActions.performFreeQueryFailure,
        (state: GPTState, { errorResponse }: { errorResponse: HttpErrorResponse }) => {
            const lastQueryIndex: number = state.queries.length - 1;
            const lastQuery: QueryResponse = state.queries?.[lastQueryIndex];
            const lastQueryWithEmptyAnswer: QueryResponse = {
                ...lastQuery,
                answer: '',
            };

            const error: ErrorResponse = {
                errors: [
                    {
                        code: errorResponse.error?.errors?.[0]?.code,
                        message: ERROR_TEXT,
                        type: errorResponse.error?.errors?.[0]?.type,
                    },
                ],
            };

            return {
                ...state,
                latestQuery: lastQueryWithEmptyAnswer,
                queries: [...state.queries.slice(0, lastQueryIndex), lastQueryWithEmptyAnswer],
                errors: [
                    ...state.errors,
                    errorResponse.status === 503 ? error : errorResponse.error,
                ],
                loading: false,
                loaded: false,
            };
        },
    ),
    on(gptActions.regenerateQueryRequest, (state: GPTState) => {
        const lastQueryIndex: number = state.queries.length - 1;
        const lastQuery: QueryResponse = {
            ...state.latestQuery,
            question: state.latestQuery.question,
            answer: ELLIPSIS,
        };

        return {
            ...state,
            queries: [...state.queries.slice(0, lastQueryIndex), lastQuery],
            loading: true,
            loaded: false,
        };
    }),
    on(
        gptActions.regenerateQuerySuccess,
        (state: GPTState, { query }: { query: QueryResponse }) => {
            const lastQueryIndex: number = state.queries.length - 1;

            return {
                ...state,
                latestQuery: query,
                queries: [...state.queries.slice(0, lastQueryIndex), query],
                loading: false,
                loaded: true,
            };
        },
    ),
    on(
        gptActions.regenerateQueryFailure,
        (state: GPTState, { errorResponse }: { errorResponse: HttpErrorResponse }) => {
            const lastQueryIndex: number = state.queries.length - 1;
            const lastQuery: QueryResponse = state.queries?.[lastQueryIndex];
            const lastQueryWithEmptyAnswer: QueryResponse = {
                ...lastQuery,
                answer: '',
            };

            const error: ErrorResponse = {
                errors: [
                    {
                        code: errorResponse.error?.errors?.[0]?.code,
                        message: ERROR_TEXT,
                        type: errorResponse.error?.errors?.[0]?.type,
                    },
                ],
            };

            return {
                ...state,
                latestQuery: lastQueryWithEmptyAnswer,
                queries: [...state.queries.slice(0, lastQueryIndex), lastQueryWithEmptyAnswer],
                errors: [
                    ...state.errors,
                    errorResponse.status === 503 ? error : errorResponse.error,
                ],
                loading: false,
                loaded: false,
            };
        },
    ),
    on(gptActions.downloadSessionHistoryRequest, (state: GPTState) => ({
        ...state,
        blob: null as Blob,
    })),
    on(gptActions.downloadSessionHistorySuccess, (state: GPTState, { blob }: { blob: Blob }) => ({
        ...state,
        blob,
    })),
    on(
        gptActions.downloadSessionHistoryFailure,
        (state: GPTState, { errorResponse }: { errorResponse: HttpErrorResponse }) => ({
            ...state,
            errors: [...state.errors, errorResponse.error],
        }),
    ),
    on(gptActions.resetSession, (state: GPTState) => ({
        ...state,
        structuredQuery: null as QueryResponse,
        freeQuery: null as QueryResponse,
        latestQuery: null as QueryResponse,
        queries: [] as QueryResponse[],
        blob: null as Blob,
        errors: [] as ErrorResponse[],
        loading: false,
        loaded: false,
    })),
    on(gptActions.performAssistantQueryRequest, (state: GPTState, { query }: { query: string }) => {
        const lastQueryIndex: number = state.assistantQueries.length - 1;
        const lastQuery: AssistantQuery = state.assistantQueries[lastQueryIndex];
        const currentQuery: AssistantQuery = {
            ...state.assistantQuery,
            question: query,
            answer: ELLIPSIS,
        };

        return {
            ...state,
            assistantQuery: currentQuery,
            assistantQueries:
                lastQuery?.question === currentQuery.question
                    ? [...state.assistantQueries.slice(0, lastQueryIndex), currentQuery]
                    : [...state.assistantQueries, currentQuery],
            assistantQueryLoading: true,
            assistantQueryLoaded: false,
        };
    }),
    on(
        gptActions.performAssistantQuerySuccess,
        (state: GPTState, { assistantQuery }: { assistantQuery: QAPair }) => {
            const lastQueryIndex: number = state.assistantQueries.length - 1;
            const lastQuery: AssistantQuery = state.assistantQueries[lastQueryIndex];

            return {
                ...state,
                assistantQuery,
                assistantQueries:
                    lastQuery.question === assistantQuery.question
                        ? [...state.assistantQueries.slice(0, lastQueryIndex), assistantQuery]
                        : [...state.assistantQueries, assistantQuery],
                assistantQueryLoading: false,
                assistantQueryLoaded: true,
            };
        },
    ),
    on(
        gptActions.performAssistantQueryFailure,
        (state: GPTState, { errorResponse }: { errorResponse: HttpErrorResponse }) => {
            const lastQueryIndex: number = state.assistantQueries.length - 1;
            const lastQuery: AssistantQuery = state.assistantQueries?.[lastQueryIndex];
            const lastQueryWithEmptyAnswer: AssistantQuery = {
                ...lastQuery,
                answer: '',
            };

            return {
                ...state,
                assistantQueries: [
                    ...state.assistantQueries.slice(0, lastQueryIndex),
                    lastQueryWithEmptyAnswer,
                ],
                assistantQueryErrors: [...state.assistantQueryErrors, errorResponse.error],
                assistantQueryLoading: false,
                assistantQueryLoaded: false,
            };
        },
    ),
    on(gptActions.getAssistantQueriesRequest, (state: GPTState) => ({
        ...state,
        assistantQueryLoading: true,
        assistantQueryLoaded: false,
    })),
    on(
        gptActions.getAssistantQueriesSuccess,
        (state: GPTState, { assistantQueries }: { assistantQueries: QAPair[] }) => ({
            ...state,
            assistantQueries: assistantQueries?.length
                ? [INITIAL_ASSISTANT_QUERY, ...assistantQueries]
                : [INITIAL_ASSISTANT_QUERY],
            assistantQueryLoading: false,
            assistantQueryLoaded: true,
        }),
    ),
    on(
        gptActions.getAssistantQueriesFailure,
        (state: GPTState, { errorResponse }: { errorResponse: HttpErrorResponse }) => ({
            ...state,
            assistantQueryErrors: [...state.assistantQueryErrors, errorResponse.error],
            assistantQueryLoading: false,
            assistantQueryLoaded: false,
        }),
    ),
    on(gptActions.deleteAssistantQueriesRequest, (state: GPTState) => ({
        ...state,
        assistantQueryLoading: true,
        assistantQueryLoaded: false,
    })),
    on(gptActions.deleteAssistantQueriesSuccess, (state: GPTState) => ({
        ...state,
        assistantQueries: [INITIAL_ASSISTANT_QUERY],
        assistantQueryLoading: false,
        assistantQueryLoaded: true,
    })),
    on(
        gptActions.deleteAssistantQueriesFailure,
        (state: GPTState, { errorResponse }: { errorResponse: HttpErrorResponse }) => ({
            ...state,
            assistantQueryErrors: [...state.assistantQueryErrors, errorResponse.error],
            assistantQueryLoading: false,
            assistantQueryLoaded: false,
        }),
    ),
    on(gptActions.clearNextError, (state: GPTState) => ({
        ...state,
        errors: state.errors.slice(1),
    })),
);

export const reducer = (state: GPTState | undefined, action: Action): GPTState =>
    gptReducer(state, action);

// selectors
export const getStructuredQuery: (state: GPTState) => QueryResponse = (state: GPTState) =>
    state.structuredQuery;
export const getFreeQuery: (state: GPTState) => QueryResponse = (state: GPTState) =>
    state.freeQuery;
export const getLatestQuery: (state: GPTState) => QueryResponse = (state: GPTState) =>
    state.latestQuery;
export const getQueries: (state: GPTState) => QueryResponse[] = (state: GPTState) => state.queries;
export const getBlob: (state: GPTState) => Blob = (state: GPTState) => state.blob;
export const getErrors: (state: GPTState) => ErrorResponse[] = (state: GPTState) => state.errors;
export const getLoading: (state: GPTState) => boolean = (state: GPTState) => state.loading;
export const getLoaded: (state: GPTState) => boolean = (state: GPTState) => state.loaded;
export const getAssistantQueries: (state: GPTState) => AssistantQuery[] = (state: GPTState) =>
    state.assistantQueries;
export const getAssistantQuery: (state: GPTState) => AssistantQuery = (state: GPTState) =>
    state.assistantQuery;
export const getAssistantQueryLoading: (state: GPTState) => boolean = (state: GPTState) =>
    state.assistantQueryLoading;
export const getAssistantQueryLoaded: (state: GPTState) => boolean = (state: GPTState) =>
    state.assistantQueryLoaded;
export const getAssistantQueryErrors: (state: GPTState) => ErrorResponse[] = (state: GPTState) =>
    state.assistantQueryErrors;
