import { Injectable } from '@angular/core';
import { uniq } from 'lodash';

/** Helpers */
import { isTruthy } from '@leap-common/utilities/helpers';

/** Services - Parsers */
import { ArrayHandlerService } from '@leap-common/services/array-handler.service';
import { InsightsService } from '@apps/leap/src/app/shared/services/insights.service';
import { EffectsParser } from './effects.parser';

/** Constants */
import { EMPTY_STRING } from '@leap-common/constants/common';
import { AMPERSAND_DELIMITER, DOUBLE_PIPE_DELIMITER } from '@leap-common/constants/delimiters';
import { EMPTY_REGEX } from '@leap-common/constants/regexes';
import { RELATIONSHIP_GENERIC_LABEL } from '@apps/leap/src/app/shared/constants/discovery';
import { PROFILE_CATEGORY_PREFIX } from '@apps/leap/src/app/shared/constants/profile-categories';

/** Interfaces - Types - Enums */
import InsightRelationshipType from '@apps/leap/src/app/shared/types/discovery-insight-relationship-type.type';
import InsightRelationshipTypeRestApi from '@apps/leap/src/app/shared/rest-api-types/insight-relationship-type.rest.type';
import AssociationOrigin from '@apps/leap/src/app/shared/enums/association-origin.enum';
import AssociationOriginRestApi from '@apps/leap/src/app/shared/enums/association-origin.rest.enum';
import AssociationType from '@apps/leap/src/app/shared/enums/association-type.enum';
import ScoreClassification from '@apps/leap/src/app/shared/enums/score-classification.enum';
import RelationshipOrigin from '@apps/leap/src/app/shared/enums/relationship-origin.enum';
import TagsRestApi from '@apps/leap/src/app/shared/rest-api-interfaces/tags.rest.interface';
import SortingOrder from '@leap-common/enums/sorting-order.enum';
import FilterCounts from '@apps/leap/src/app/shared/modules/filters/interfaces/filter-counts.interface';
import ProteinOrigin from '@leap-store/core/src/lib/data/metadata/interfaces/protein-origin.interface';
import ProteinOriginRestApi from '@leap-store/core/src/lib/data/metadata/rest-api-types/protein-origin.rest.type';
import InsightFilterCounts from '@apps/leap/src/app/shared/modules/insight-filters/interfaces/counts.interface';
import InsightFilterCountsRestApi from '@apps/leap/src/app/shared/modules/insight-filters/rest-api-interfaces/counts.rest.interface';
import InsightCategoriesRestApi from '@apps/leap/src/app/shared/rest-api-types/insight-categories.rest.type';

@Injectable()
export class InsightParser {
    constructor(
        private arrayHandlerService: ArrayHandlerService,
        private insightsService: InsightsService,
        private effectsParser: EffectsParser,
    ) {}

    parseCategories(categories: InsightCategoriesRestApi): string[] {
        return uniq(Object.values(categories || {}));
    }

    parseSubcategories(categories: InsightCategoriesRestApi): string[] {
        return Object.keys(categories || {}).sort((subcategoryA: string, subcategoryB: string) =>
            this.arrayHandlerService.sort(subcategoryA, subcategoryB, {
                direction: SortingOrder.ascending,
            }),
        );
    }

    isEmptyRelationshipType(relationshipType: InsightRelationshipTypeRestApi): boolean {
        return (
            !relationshipType ||
            (typeof relationshipType === 'string' && EMPTY_REGEX.test(relationshipType)) ||
            (typeof relationshipType === 'object' &&
                Object.values(relationshipType).every((mappedRelationship: string) =>
                    EMPTY_REGEX.test(mappedRelationship),
                ))
        );
    }

    parseRelationshipType(
        relationshipType: InsightRelationshipTypeRestApi,
    ): InsightRelationshipType {
        // takes care of empty values, null, undefined and invalid types
        if (
            this.isEmptyRelationshipType(relationshipType) ||
            !['string', 'object'].includes(typeof relationshipType)
        ) {
            return null;
        }

        // detect if the relationship type is string
        if (typeof relationshipType === 'string') {
            // detect if string is JSON stringified object (e.g. from a serialized bookmark) then JSON.parse
            if (relationshipType.startsWith('{') && relationshipType.endsWith('}')) {
                try {
                    return JSON.parse(relationshipType);
                } catch (error) {
                    // if malformed JSON (or erroneously detected JSON) then return null
                    return null;
                }
            } else {
                return null;
            }
        }

        // discard an empty object with no usable values
        if (typeof relationshipType === 'object' && !Object.values(relationshipType).length) {
            return null;
        }

        return { ...relationshipType };
    }

    parseRelationshipTypeValues(relationshipType: InsightRelationshipType): string[] {
        return relationshipType ? Object.values(relationshipType) : [RELATIONSHIP_GENERIC_LABEL];
    }

    parseCategory(category: string): string {
        return category
            ? category
                  .split(DOUBLE_PIPE_DELIMITER)
                  .sort((categoryA: string, categoryB: string) =>
                      this.arrayHandlerService.sort(categoryA, categoryB, {
                          direction: SortingOrder.ascending,
                      }),
                  )
                  .join(AMPERSAND_DELIMITER)
            : EMPTY_STRING;
    }

    parseAssociationOrigins(associationOrigins: AssociationOriginRestApi[]): AssociationOrigin[] {
        return associationOrigins?.map(this.parseAssociationOrigin) || [];
    }

    parseAssociationOrigin(associationOrigin: AssociationOriginRestApi): AssociationOrigin {
        switch (associationOrigin) {
            case AssociationOriginRestApi.graphPredictions:
                return AssociationOrigin.graphPredictions;
            case AssociationOriginRestApi.bioinformatics:
                return AssociationOrigin.bioinformatics;
            case AssociationOriginRestApi.knowledgeBase:
                return AssociationOrigin.knowledgeBase;
            case AssociationOriginRestApi.literature:
                return AssociationOrigin.literature;
            default:
                return null;
        }
    }

    parseKOOriginDatabases(dbs: string): string[] {
        return dbs
            ? dbs.split(DOUBLE_PIPE_DELIMITER)?.sort((databaseA: string, databaseB: string) =>
                  this.arrayHandlerService.sort(databaseA, databaseB, {
                      direction: SortingOrder.ascending,
                  }),
              )
            : [];
    }

    parseScoreClassification(scoreClassification: string): ScoreClassification | null {
        return scoreClassification === ScoreClassification.high
            ? ScoreClassification.high
            : scoreClassification === ScoreClassification.medium
            ? ScoreClassification.medium
            : scoreClassification === ScoreClassification.low
            ? ScoreClassification.low
            : null;
    }

    parseDate(date: number): number {
        return date || 0;
    }

    parseArticlesCount(count: number): number {
        return count || 0;
    }

    parseScore(score: number, numberOfDecimals: number = 2): number {
        return score ? parseFloat(score.toFixed(numberOfDecimals)) : 0;
    }

    /**
     * Gets a probability parameter the value of which can be number | null.
     * In case of number it returns the value rounded in numberOfDecimals digits.
     * In case the value is not number it returns null.
     */
    parseProbability(probability: number | null, numberOfDecimals: number = 2): number {
        return typeof probability === 'number'
            ? parseFloat(probability.toFixed(numberOfDecimals))
            : null;
    }

    parseSynonyms(synonyms: string[]): string[] {
        return synonyms || [];
    }

    parseTags(tags: string[]): string[] {
        return (
            tags?.sort((tagA: string, tagB: string) =>
                this.arrayHandlerService.sort(tagA, tagB, {
                    direction: SortingOrder.ascending,
                }),
            ) || []
        );
    }

    parseHealthLabels(healthLabels: string[]): string[] {
        return (
            healthLabels?.sort((healthLabelA: string, healthLabelB: string) =>
                this.arrayHandlerService.sort(healthLabelA, healthLabelB, {
                    direction: SortingOrder.ascending,
                }),
            ) || []
        );
    }

    parseMolecules(molecules: string[]): string[] {
        return (
            molecules?.sort((moleculeA: string, moleculeB: string) =>
                this.arrayHandlerService.sort(moleculeA, moleculeB, {
                    direction: SortingOrder.ascending,
                }),
            ) || []
        );
    }

    parseLabs(labs: string[]): string[] {
        return (
            labs?.sort((labA: string, labB: string) =>
                this.arrayHandlerService.sort(labA, labB, {
                    direction: SortingOrder.ascending,
                }),
            ) || []
        );
    }

    parseProfileCategories(profileCategories: string[]): number[] {
        return (
            profileCategories
                ?.map((category: string) => this.parseProfileCategory(category))
                .filter(isTruthy)
                .sort() || []
        );
    }

    parseProfileCategory(profileCategory: string): number {
        return !isNaN(parseInt(profileCategory?.replace(PROFILE_CATEGORY_PREFIX, EMPTY_STRING), 10))
            ? parseInt(profileCategory?.replace(PROFILE_CATEGORY_PREFIX, EMPTY_STRING), 10)
            : 0;
    }

    parseJournals(journals: string[]): string[] {
        return (
            journals?.sort((journalA: string, journalB: string) =>
                this.arrayHandlerService.sort(journalA, journalB, {
                    direction: SortingOrder.ascending,
                }),
            ) || []
        );
    }

    parseFilterCounts(counts: InsightFilterCountsRestApi): InsightFilterCounts {
        return Object.keys(counts).length
            ? {
                  categories: counts.categories,
                  subcategories: counts.subcategories,
                  strengths: counts.associationStrength,
                  associations: this.parseAssociationsCounts(counts.associations),
                  associationTypes: this.parseAssociationTypesCounts(counts.associationNovelty),
                  relationshipOrigins: this.parseRelationshipOriginsCounts(
                      counts.relationshipTypeSource,
                  ),
                  relationships: counts.relationshipType,
                  databases: counts.knowledgeBase,
                  proteinOrigins: counts.sourceProtein,
                  healthLabels: counts.tags['Health areas'] || {},
                  tags: counts.tags.Tags || {},
                  studyTypes: counts.typesOfStudy,
                  journals: counts.journals,
                  moleculesWeight: counts.molecularWeight,
                  molecules: counts.tags['Molecule classification'] || {},
                  profileCategories: this.parseProfileCategoryCounts(
                      counts?.tags['Uniqueness profile'],
                  ),
                  labs: counts.tags['UCD DMD lab'] || {},
                  targets: counts.targets,
                  effects: this.effectsParser.parseEffectsCounts(counts.effectClasses),
              }
            : null;
    }

    parseAssociationsCounts(
        associationsCounts: Record<
            'known' | 'novel',
            Partial<Record<AssociationOriginRestApi, FilterCounts>>
        >,
    ): Record<string, FilterCounts> {
        if (!associationsCounts) {
            return;
        }

        return Object.keys(associationsCounts).reduce(
            (accumulator: Record<string, FilterCounts>, type: 'known' | 'novel') => {
                Object.keys(associationsCounts[type]).forEach(
                    (origin: AssociationOriginRestApi) => {
                        accumulator[
                            this.insightsService.generateAssociationId(
                                this.parseAssociationOrigin(origin),
                                type === 'novel',
                            )
                        ] = associationsCounts[type][origin];
                    },
                );

                return accumulator;
            },
            {},
        );
    }

    parseAssociationTypesCounts(
        associationTypesCounts: Record<'known' | 'novel', FilterCounts>,
    ): Record<AssociationType, FilterCounts> {
        return associationTypesCounts
            ? {
                  [AssociationType.known]: associationTypesCounts.known,
                  [AssociationType.novel]: associationTypesCounts.novel,
              }
            : null;
    }

    parseRelationshipOriginsCounts(
        relationshipOriginsCounts: Record<
            'Known relationship' | 'Predicted relationship',
            FilterCounts
        >,
    ): Record<RelationshipOrigin, FilterCounts> {
        return relationshipOriginsCounts
            ? {
                  [RelationshipOrigin.known]: relationshipOriginsCounts['Known relationship'],
                  [RelationshipOrigin.predicted]:
                      relationshipOriginsCounts['Predicted relationship'],
              }
            : null;
    }

    parseProfileCategoryCounts(
        profileCategoryCounts: Record<string, FilterCounts>,
    ): Record<number, FilterCounts> {
        if (!profileCategoryCounts) {
            return {};
        }

        return Object.keys(profileCategoryCounts).reduce(
            (accumulator: Record<number, FilterCounts>, profileCategory: string) => {
                accumulator[this.parseProfileCategory(profileCategory)] =
                    profileCategoryCounts[profileCategory];
                return accumulator;
            },
            {},
        );
    }

    parseProteinOrigins(proteinOrigins: ProteinOriginRestApi[]): ProteinOrigin[] {
        return Array.isArray(proteinOrigins)
            ? proteinOrigins.map(([id, name, url]: [string, string, string]) => ({ id, name, url }))
            : [];
    }

    serializeCategory(category: string): string | null {
        return category ? category.split(AMPERSAND_DELIMITER).join(DOUBLE_PIPE_DELIMITER) : null;
    }

    serializeAssociationOrigins(
        associationOrigins: AssociationOrigin[],
    ): AssociationOriginRestApi[] {
        return associationOrigins?.map(this.serializeAssociationOrigin) || [];
    }

    serializeAssociationOrigin(associationOrigin: AssociationOrigin): AssociationOriginRestApi {
        switch (associationOrigin) {
            case AssociationOrigin.graphPredictions:
                return AssociationOriginRestApi.graphPredictions;
            case AssociationOrigin.bioinformatics:
                return AssociationOriginRestApi.bioinformatics;
            case AssociationOrigin.knowledgeBase:
                return AssociationOriginRestApi.knowledgeBase;
            case AssociationOrigin.literature:
                return AssociationOriginRestApi.literature;
            default:
                return null;
        }
    }

    serializeRelationshipType(relationshipType: InsightRelationshipType): string | null {
        return relationshipType ? JSON.stringify(relationshipType) : null;
    }

    serializeArticlesCount(count: number): number | null {
        return count || null;
    }

    serializeDate(date: number): number | null {
        return date || null;
    }

    serializeSynonyms(synonyms: string[]): string[] {
        return synonyms || [];
    }

    serializeKOOriginDatabases(dbs: string[]): string {
        return dbs ? dbs.join(DOUBLE_PIPE_DELIMITER) : EMPTY_STRING;
    }

    serializeTags(
        tags: string[],
        healthLabels: string[],
        labs: string[],
        molecules: string[],
        profileCategories: string[],
    ): TagsRestApi {
        return {
            Tags: tags || [],
            'Health areas': healthLabels || [],
            'UCD DMD lab': labs || [],
            'Molecule classification': molecules || [],
            'Uniqueness profile': profileCategories || [],
        };
    }

    serializeProteinOrigins(proteinOrigins: ProteinOrigin[]): ProteinOriginRestApi[] {
        return Array.isArray(proteinOrigins)
            ? proteinOrigins.map(({ id, name, url }: ProteinOrigin) => [id, name, url])
            : [];
    }

    serializeProfileCategories(profileCategories: string[] | number[]): string[] {
        return (
            profileCategories?.map((category: string | number) =>
                this.serializeProfileCategory(category),
            ) || []
        );
    }

    serializeProfileCategory(profileCategory: string | number): string {
        return profileCategory ? `${PROFILE_CATEGORY_PREFIX}${profileCategory}` : EMPTY_STRING;
    }
}
