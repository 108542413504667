import { Component, Input } from '@angular/core';
import { CHAT_ICON, DOM_CHANGES_OBSERVER_OPTIONS } from '../../../constants/chat';
import AssistantQuery from '@leap-store/core/src/lib/data/gpt/interfaces/assistant-query.interface';

@Component({
    selector: 'app-chat-qa',
    templateUrl: 'chat-qa.view.component.html',
    styleUrls: ['chat-qa.view.component.scss'],
})
export class ChatQAViewComponent {
    @Input() queries: AssistantQuery[];
    @Input() loading: boolean;

    readonly chatIcon: string = CHAT_ICON;
    readonly observerOptions: MutationObserverInit = DOM_CHANGES_OBSERVER_OPTIONS;

    constructor() {}

    scrollToLatestQuery(): void {
        const targetElement: HTMLElement = document.querySelector(
            `.wrapper .query-wrapper:last-child`,
        );

        targetElement?.scrollIntoView({
            behavior: 'smooth',
        });
    }
}
