/** third-party imports */
import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';

/** custom imports */
import { TagsState } from './tags-state.interface';
import { getTagsRequest, clearNextError } from './tags.actions';
import { getTags, getErrors } from './tags.selectors';
import Tag from './interfaces/tag.interface';
import ErrorResponse from '@leap-common/interfaces/error-response.interface';
@Injectable()
export class TagsFacade {
    tags$: Observable<Tag[]> = this.store.pipe(select(getTags));
    errors$: Observable<ErrorResponse[]> = this.store.pipe(select(getErrors));

    constructor(private store: Store<TagsState>) {}

    getTags(): void {
        this.store.dispatch(getTagsRequest());
    }

    clearNextError(): void {
        this.store.dispatch(clearNextError());
    }
}
