/** third-party imports */
import { createSelector } from '@ngrx/store';

/** custom imports */
import { DataState } from '../data-state.interface';
import { getDataState } from '../index';
import * as fromConversationalSearch from './conversational-search.reducer';

export const getConversationalSearchState = createSelector(
    getDataState,
    (state: DataState) => state.conversationalSearch,
);

export const getQueries = createSelector(
    getConversationalSearchState,
    fromConversationalSearch.getQueries,
);
export const getTabIds = createSelector(
    getConversationalSearchState,
    fromConversationalSearch.getTabIds,
);
export const getErrors = createSelector(
    getConversationalSearchState,
    fromConversationalSearch.getErrors,
);
export const getLoading = createSelector(
    getConversationalSearchState,
    fromConversationalSearch.getLoading,
);
export const getLoaded = createSelector(
    getConversationalSearchState,
    fromConversationalSearch.getLoaded,
);
