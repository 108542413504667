/** third-party imports */
import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';

/** custom imports */
import { IngredientViewerState } from './ingredient-viewer-state.interface';
import {
    getIngredientsRequest,
    getIngredientRequest,
    getStatisticsRequest,
    clearIngredients,
    clearNextError,
} from './ingredient-viewer.actions';
import {
    getIngredients,
    getIngredientsLoading,
    getIngredientsLoaded,
    getIngredient,
    getIngredientLoading,
    getIngredientLoaded,
    getStatistics,
    getStatisticsLoading,
    getStatisticsLoaded,
    getErrors,
} from './ingredient-viewer.selectors';
import ErrorResponse from '@leap-common/interfaces/error-response.interface';
import Ingredient from './interfaces/ingredient.interface';
import Statistics from './interfaces/statistics.interface';

@Injectable()
export class IngredientViewerFacade {
    errors$: Observable<ErrorResponse[]> = this.store.pipe(select(getErrors));
    ingredients$: Observable<Ingredient[]> = this.store.pipe(select(getIngredients));
    ingredientsLoading$: Observable<boolean> = this.store.pipe(select(getIngredientsLoading));
    ingredientsLoaded$: Observable<boolean> = this.store.pipe(select(getIngredientsLoaded));
    ingredient$: Observable<Ingredient> = this.store.pipe(select(getIngredient));
    ingredientLoading$: Observable<boolean> = this.store.pipe(select(getIngredientLoading));
    ingredientLoaded$: Observable<boolean> = this.store.pipe(select(getIngredientLoaded));
    statistics$: Observable<Statistics> = this.store.pipe(select(getStatistics));
    statisticsLoading$: Observable<boolean> = this.store.pipe(select(getStatisticsLoading));
    statisticsLoaded$: Observable<boolean> = this.store.pipe(select(getStatisticsLoaded));

    constructor(private store: Store<IngredientViewerState>) {}

    getIngredients(query: string): void {
        this.store.dispatch(getIngredientsRequest({ query }));
    }

    getIngredient(id: string): void {
        this.store.dispatch(getIngredientRequest({ id }));
    }

    getStatistics(ingredientId: string): void {
        this.store.dispatch(getStatisticsRequest({ ingredientId }));
    }

    clearIngredients(): void {
        this.store.dispatch(clearIngredients());
    }

    clearNextError(): void {
        this.store.dispatch(clearNextError());
    }
}
