import { Directive, ElementRef, OnInit, OnDestroy, Output, EventEmitter } from '@angular/core';
import { throttle } from 'lodash';

@Directive({
    selector: '[libResizeObserver]',
})
export class ResizeObserverDirective implements OnInit, OnDestroy {
    /** Takes advantage of the ResizeObserver native API */
    @Output() resized: EventEmitter<ResizeObserverEntry[]> = new EventEmitter<
        ResizeObserverEntry[]
    >();

    changes: ResizeObserver;

    constructor(private elementRef: ElementRef) {}

    ngOnInit(): void {
        // Element that will be observed for resizes
        const element: HTMLElement = this.elementRef.nativeElement;

        // Callback functions to execute when resizes are observed
        const callback = (resizes: ResizeObserverEntry[]): void => this.resized.emit(resizes);

        // Throttle callback to increase performance
        const throttledCallback = throttle(callback, 200);

        // Create an observer instance linked to the callback function
        this.changes = new ResizeObserver(throttledCallback);

        // Start observing the target node for resizes
        this.changes.observe(element);
    }

    ngOnDestroy(): void {
        // Stop observing
        this.changes.disconnect();
    }
}
