import { Pipe, PipeTransform } from '@angular/core';
import { ArrayHandlerService } from '@leap-common/services/array-handler.service';
import SortingOrder from '@leap-common/enums/sorting-order.enum';

@Pipe({
    name: 'sort',
})
export class SortPipe implements PipeTransform {
    constructor(private arrayHandlerService: ArrayHandlerService) {}

    transform<T = unknown>(
        items: T[],
        direction: SortingOrder = SortingOrder.ascending,
        property?: string,
    ): T[] {
        if (!items) {
            return [];
        }

        return [...items].sort((itemA: T, itemB: T) =>
            this.arrayHandlerService.sort(itemA, itemB, { property, direction }),
        );
    }
}
