import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'replaceTag' })
export class ReplaceTagPipe implements PipeTransform {
    /**
     * Replaces/removes `<tags>` from a string
     *
     * @param value - The string to be processed
     * @param tag - The tag to be removed
     * @param replacement - Replaces the tag. Defaults to `$1`. For wrapping replacement use `<wrap>$1</wrap>`.
     */
    transform(value: string, tag: string, replacement?: string): string {
        const regex: RegExp = new RegExp(`<${tag}>(.*?)</${tag}>`, 'g');
        return value.replace(regex, replacement ?? '$1');
    }
}
