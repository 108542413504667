import { Injectable } from '@angular/core';

/** Constants */
import { US_LOCALE } from '@leap-common/constants/common';
import { COMMA_REGEX, US_LOCALE_NUMBER_REGEX } from '@leap-common/constants/regexes';

/** Interfaces */
import Link from '@leap-common/interfaces/link.interface';

@Injectable()
export class FormatterService {
    constructor() {}

    /**
     * Takes a string template containing (optional) string or number placeholders, indicated by "%s"
     * and an equal number of parameter values which then interpolates into the template in given order.
     * If we want to add a literal % character into the template, we must escape it with another
     * preceding "%" (e.g. "the probability is %s%%").
     *
     * Specifications:
     * 1. If the number of value arguments is smaller than the total placeholders in the template
     * an error will be thrown to avoid having a malformed result string.
     * 2. If the number of value arguments is greater than the total placeholders in the template,
     * the excess will be ignored.
     * 3. Literal percentage placeholders ("%%") do not count towards the placeholder quota to be matched.
     * 4. A template which is an empty string, undefined or null will yield an empty string.
     *
     * @param template - string template with placeholders indicated by "%s" for strings and "%%" for
     * literal "%" characters
     * @param values - these positional variadic arguments will be interpolated in given order into
     * the template
     */
    formatString(template: string, ...values: (string | number)[]): string {
        const totalValues: number = values.length;
        let interpolationIndex: number = 0;
        return (
            template?.replace(/%[%s]?/g, (match: string): string => {
                switch (match) {
                    case '%%': {
                        // we do not increment interpolationIndex in this case
                        return '%';
                    }
                    case '%': {
                        throw new Error(
                            'String formatter error: unescaped "%" character in template',
                        );
                    }
                    case '%s': {
                        if (interpolationIndex >= totalValues) {
                            throw new Error(
                                'String formatter error: more placeholders in template than given values',
                            );
                        }
                        // TODO: for more refined number formatting we should add a new formatter
                        // placeholder such as "%d" to load it with extra options (e.g. "%.2d" for 2 fixed decimal positions).
                        // For now we just coerce all values to a strings.
                        return String(values[interpolationIndex++]);
                    }
                    default: {
                        throw new Error(`String formatter error: unknown error (match=${match})`);
                    }
                }
            }) || ''
        );
    }

    formatToLocaleString(
        value: number,
        locale: string = US_LOCALE,
        decimalDigits: number = 0,
    ): string {
        return value === null || value === undefined
            ? ''
            : value.toLocaleString(locale, {
                  minimumFractionDigits: decimalDigits,
                  maximumFractionDigits: decimalDigits,
              });
    }

    parseLocaleStringToNumber(value: string): number {
        if (!value.match(US_LOCALE_NUMBER_REGEX)) {
            return;
        }
        return Number(value.replace(COMMA_REGEX, ''));
    }

    /**
     * Replaces specified substrings in the provided text with corresponding anchor tags based on predefined link information.
     */
    addLinksToText(text: string, linksInfo: Link[]): string {
        if (!text) {
            return '';
        }

        if (!linksInfo) {
            return text;
        }

        linksInfo.forEach((link: Link) => {
            const target: string = link.shouldOpenInNewTab ? 'target="_blank"' : '';
            const anchorTag: string = `<a href="/${link.url}" ${target} class="link text-xs">${link.text}</a>`;

            // Use regular expression to match the link text and add hyperlink
            text = text.replace(new RegExp(link.text, 'gi'), anchorTag);
        });

        return text;
    }
}
