import { createSelector } from '@ngrx/store';
import { DataState } from '../../data-state.interface';
import { getDataState } from '../../index';
import { SuggestionsState } from './suggestions-state.interface';
import * as fromSuggestions from './suggestions.reducer';

export const getSuggestionsState = createSelector(
    getDataState,
    (state: DataState) => state.discovery.suggestions,
);

export const getSuggestions = createSelector(
    getSuggestionsState,
    (state: SuggestionsState, props: { id: string }) =>
        fromSuggestions.getSuggestions(state, props.id),
);
export const getErrors = createSelector(getSuggestionsState, fromSuggestions.getErrors);
export const getLoading = createSelector(getSuggestionsState, fromSuggestions.getLoading);
export const getLoaded = createSelector(getSuggestionsState, fromSuggestions.getLoaded);
