import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';
import ErrorResponse from '@leap-common/interfaces/error-response.interface';
import { RolesState } from './roles-state.interface';
import { getRolesRequest, clearNextError } from './roles.actions';
import { getRolesErrors, getRolesLoading, getRolesLoaded, getRolesList } from './roles.selectors';
import UserRole from '@leap-store/core/src/lib/data/auth/interfaces/user-role.interface';

@Injectable()
export class RolesFacade {
    roles$: Observable<UserRole[]> = this.store.pipe(select(getRolesList));
    errors$: Observable<ErrorResponse[]> = this.store.pipe(select(getRolesErrors));
    loading$: Observable<boolean> = this.store.pipe(select(getRolesLoading));
    loaded$: Observable<boolean> = this.store.pipe(select(getRolesLoaded));

    constructor(private store: Store<RolesState>) {}

    getRoles(): void {
        this.store.dispatch(getRolesRequest());
    }

    clearNextError(): void {
        this.store.dispatch(clearNextError());
    }
}
