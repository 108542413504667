/** third-party imports */
import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { of } from 'rxjs';
import { mergeMap, map, catchError } from 'rxjs/operators';

/** custom imports */
import { CategoriesService } from './services/categories.service';
import * as actions from './categories.actions';

@Injectable()
export class CategoriesEffects {
    constructor(private actions$: Actions, private categoriesService: CategoriesService) {}

    getCategories$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.getCategoriesRequest),
            mergeMap(() =>
                this.categoriesService.getCategories().pipe(
                    map((categories: Record<string, string[]>) =>
                        actions.getCategoriesSuccess({ categories }),
                    ),
                    catchError((errorResponse: HttpErrorResponse) =>
                        of(actions.getCategoriesFailure({ errorResponse })),
                    ),
                ),
            ),
        ),
    );
}
