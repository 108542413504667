/** third-party imports */
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { reducers } from '..';

/** Custom Imports */
import { PipelineExecutionsParser } from './parsers/pipeline-executions.parser';
import { PipelineExecutionsEffects } from './pipeline-executions.effects';
import { PipelineExecutionsFacade } from './pipeline-executions.facade';
import { PipelineExecutionsService } from './services/pipeline-executions.service';
import { FilesParser } from '@leap-store/core/src/lib/data/files/parsers/files.parser';

@NgModule({
    imports: [
        CommonModule,
        HttpClientModule,
        StoreModule.forRoot(reducers),
        StoreDevtoolsModule.instrument({ maxAge: 10 }),
        EffectsModule.forRoot([PipelineExecutionsEffects]),
    ],
    declarations: [],
    providers: [
        PipelineExecutionsService,
        PipelineExecutionsParser,
        PipelineExecutionsFacade,
        FilesParser,
    ],
})
export class PipelineExecutionsStoreModule {}
