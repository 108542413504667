/** third-party libraries */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

/** custom imports */
import { environment } from '@environments/leap/environment';
import { COW_MILK_NAME } from '@apps/leap/src/app/shared/constants/discovery';
import {
    OVERVIEW,
    HEALTH_LABEL,
    EFFECT,
    TOP_SENTENCES,
    STUDY_TYPE,
    SUMMARY,
} from '../constants/compound-report';
import { DAIRY_PRODUCT_NAME } from '@apps/leap/src/app/dairy-profiler/constants/profiler';
import { CompoundReportParser } from '../parsers/compound-report.parser';
import { EffectsParser } from '@apps/leap/src/app/shared/parsers/effects.parser';
import ResultsRestApi from '@leap-common/rest-api-interfaces/results.rest.interface';
import Overview from '../interfaces/overview.interface';
import OverviewRestApi from '../rest-api-interfaces/overview.rest.interface';
import TargetSentencesRestApi from '../rest-api-interfaces/target-sentences.rest.interface';
import HealthLabel from '../interfaces/health-label.interface';
import HealthLabelRestApi from '../rest-api-interfaces/health-label.rest.interface';
import StudyTypes from '../interfaces/study-types.interface';
import StudyTypesRestApi from '../rest-api-interfaces/study-types.rest.interface';
import StudyType from '../interfaces/study-type.interface';
import StudyTypeRestApi from '../rest-api-interfaces/study-type.rest.interface';
import ExecutionFilters from '@apps/leap/src/app/shared/modules/filters/types/execution-filters.type';
import Effect from '../../ingredient-profiler/enums/effect.enum';
import TargetsSentences from '../interfaces/targets-sentences.interface';

@Injectable()
export class CompoundReportService {
    constructor(
        private http: HttpClient,
        private compoundReportParser: CompoundReportParser,
        private effectsParser: EffectsParser,
    ) {}

    getBaseURL(ingredientName: string): string {
        return ingredientName === COW_MILK_NAME
            ? `${environment.profilerServerUrl}/molecule-report`
            : ingredientName === DAIRY_PRODUCT_NAME
            ? `${environment.dairyProfilerServerUrl}/entity-report`
            : null;
    }

    getOverview(
        compoundId: string,
        ingredientName: string,
        filters: ExecutionFilters,
    ): Observable<{ overview: Overview; total: number }> {
        const baseURL: string = this.getBaseURL(ingredientName);

        return this.http
            .post(`${baseURL}?section=${OVERVIEW}`, {
                moleculeCui: compoundId,
                filters,
            })
            .pipe(
                map((overview: OverviewRestApi) => ({
                    overview: this.compoundReportParser.parseOverview(overview),
                    total: this.compoundReportParser.parseTotal(overview),
                })),
            );
    }

    getEffectSentences(
        compoundId: string,
        ingredientName: string,
        effect: Effect,
        filters: ExecutionFilters,
    ): Observable<TargetsSentences> {
        const baseURL: string = this.getBaseURL(ingredientName);
        const serializedEffect: string = this.effectsParser.serializeEffect(effect);

        return this.http
            .post(`${baseURL}?section=${EFFECT}`, {
                moleculeCui: compoundId,
                effectClassification: serializedEffect,
                filters,
            })
            .pipe(
                map(({ targets }: { targets: TargetSentencesRestApi[] }) =>
                    this.compoundReportParser.parseTargetsSentences(targets, effect),
                ),
            );
    }

    getHealthLabels(
        compoundId: string,
        ingredientName: string,
        filters: ExecutionFilters,
    ): Observable<HealthLabel[]> {
        const baseURL: string = this.getBaseURL(ingredientName);

        return this.http
            .post(`${baseURL}?section=${HEALTH_LABEL}&subsection=${OVERVIEW.toLowerCase()}`, {
                moleculeCui: compoundId,
                filters,
            })
            .pipe(
                map((healthLabels: ResultsRestApi<HealthLabelRestApi>) =>
                    this.compoundReportParser.parseHealthLabels(healthLabels?.results),
                ),
            );
    }

    getHealthLabelSentences(
        compoundId: string,
        ingredientName: string,
        healthLabel: string,
        effect: Effect,
        filters: ExecutionFilters,
    ): Observable<TargetsSentences> {
        const baseURL: string = this.getBaseURL(ingredientName);

        return this.http
            .post(`${baseURL}?section=${HEALTH_LABEL}&subsection=${TOP_SENTENCES}`, {
                moleculeCui: compoundId,
                healthArea: healthLabel,
                effectClassification: effect,
                filters,
            })
            .pipe(
                map(({ targets }: { targets: TargetSentencesRestApi[] }) =>
                    this.compoundReportParser.parseTargetsSentences(targets, effect, healthLabel),
                ),
            );
    }

    getStudyTypes(
        compoundId: string,
        ingredientName: string,
        filters: ExecutionFilters,
    ): Observable<StudyTypes> {
        const baseURL: string = this.getBaseURL(ingredientName);

        return this.http
            .post(`${baseURL}?section=${STUDY_TYPE}&subsection=${OVERVIEW.toLowerCase()}`, {
                moleculeCui: compoundId,
                filters,
            })
            .pipe(
                map((studyTypes: StudyTypesRestApi) =>
                    this.compoundReportParser.parseStudyTypes(studyTypes),
                ),
            );
    }

    getStudyType(
        compoundId: string,
        ingredientName: string,
        name: string,
        filters: ExecutionFilters,
    ): Observable<StudyType> {
        const baseURL: string = this.getBaseURL(ingredientName);

        return this.http
            .post(`${baseURL}?section=${STUDY_TYPE}&subsection=${SUMMARY}`, {
                moleculeCui: compoundId,
                typeOfStudy: name,
                filters,
            })
            .pipe(
                map((studyType: StudyTypeRestApi) =>
                    this.compoundReportParser.parseStudyType(studyType, name),
                ),
            );
    }

    download(compoundId: string, ingredientName: string): Observable<Blob> {
        const baseURL: string = this.getBaseURL(ingredientName);

        return this.http.post(
            `${baseURL}/download/`,
            { moleculeCui: compoundId },
            {
                responseType: 'blob',
            },
        );
    }
}
