import { Component, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup } from '@angular/forms';

/** Constants */
import { BACK_TEXT } from '../../../../core/constants/auth';

/** Interfaces */
import DecoratedInputOptions from '@leap-libs/decorated-input/src/lib/decorated-input-options.interface';

@Component({
    selector: 'app-forgot-password-form',
    templateUrl: 'forgot-password-form.view.component.html',
    styleUrls: ['forgot-password-form.view.component.scss'],
})
export class ForgotPasswordFormViewComponent {
    @Input() form: FormGroup;
    @Input() emailOptions: DecoratedInputOptions;
    @Input() submitButtonTitle: string;
    @Input() isTextWhite: boolean;
    @Output() backClicked: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() submitForm: EventEmitter<string> = new EventEmitter<string>();

    readonly goBackText: string = BACK_TEXT;

    constructor() {}

    onBackClicked(): void {
        this.backClicked.emit(true);
    }

    onSubmit(): void {
        const email: string = this.form.value?.email;

        if (this.form.valid) {
            this.submitForm.emit(email);
        }
    }
}
