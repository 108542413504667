import { Injectable } from '@angular/core';
import { environment } from '@environments/leap/environment';
import { CONFIGURATION_PER_ANALYTICS_PROVIDER } from '../constants/analytics';
import AnalyticsProvider from '../enums/analytics-provider.enum';
import AnalyticsProviderConfiguration from '../interfaces/analytics-provider-configuration.interface';
import {
    AnalyticsScriptElement,
    AsyncAnalyticsScriptElement,
} from '../interfaces/analytics-script-element.interface';

@Injectable()
export class AnalyticsService {
    constructor() {}

    initializeProviders(): void {
        Object.values(AnalyticsProvider)
            .filter(this.isProviderEnabled)
            .map(this.mapToProviderConfiguration)
            .forEach(this.initializeProvider, this);
    }

    isProviderEnabled(provider: AnalyticsProvider): boolean {
        return Boolean(environment?.[`${provider}Key`]);
    }

    mapToProviderConfiguration(
        provider: AnalyticsProvider,
    ): [AnalyticsProviderConfiguration, AnalyticsProvider] {
        return [CONFIGURATION_PER_ANALYTICS_PROVIDER[provider], provider];
    }

    initializeProvider([{ scripts }, provider]: [
        AnalyticsProviderConfiguration,
        AnalyticsProvider,
    ]): void {
        // safety guard for analytics provider configuration
        if (!scripts?.length) {
            console.error(
                `Analytics provider script not present in configuration for "${provider}"`,
            );
            return;
        }

        for (const script of scripts) {
            const scriptElement: HTMLScriptElement = document.createElement('script');

            // create an async analytics script element
            if (this.isAsyncScriptElement(script)) {
                scriptElement.async = script.async;
                scriptElement.src = script.src;
            }

            // create an inline analytics script element
            else {
                scriptElement.type = 'text/javascript';
                scriptElement.innerHTML = script.innerHTML;
            }

            // append the element to the DOM
            document.head.appendChild(scriptElement);
        }
    }

    isAsyncScriptElement(script: AnalyticsScriptElement): script is AsyncAnalyticsScriptElement {
        return (script as AsyncAnalyticsScriptElement).async === true;
    }
}
