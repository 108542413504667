export enum IngredientProfilerActionTypes {
    GET_SUGGESTIONS_REQUEST = '[INGREDIENT PROFILER][SUGGESTIONS] Get Suggestions Request',
    GET_SUGGESTIONS_SUCCESS = '[INGREDIENT PROFILER][SUGGESTIONS] Get Suggestions Success',
    GET_SUGGESTIONS_FAILURE = '[INGREDIENT PROFILER][SUGGESTIONS] Get Suggestions Failure',
    GET_CUSTOM_INGREDIENT_REQUEST = '[INGREDIENT PROFILER][CUSTOM INGREDIENT] Get Custom Ingredient Request',
    GET_CUSTOM_INGREDIENT_SUCCESS = '[INGREDIENT PROFILER][CUSTOM INGREDIENT] Get Custom Ingredient Success',
    GET_CUSTOM_INGREDIENT_FAILURE = '[INGREDIENT PROFILER][CUSTOM INGREDIENT] Get Custom Ingredient Failure',
    QUIT_CUSTOM_INGREDIENT_REQUEST = '[INGREDIENT PROFILER][CUSTOM INGREDIENT] Quit Custom Ingredient Request',
    GET_COMPOUNDS_REQUEST = '[INGREDIENT PROFILER][COMPOUNDS] Get Compounds Request',
    GET_COMPOUNDS_SUCCESS = '[INGREDIENT PROFILER][COMPOUNDS] Get Compounds Success',
    GET_COMPOUNDS_FAILURE = '[INGREDIENT PROFILER][COMPOUNDS] Get Compounds Failure',
    GET_INSIGHTS_REQUEST = '[INGREDIENT PROFILER][INSIGHTS] Get Insights Request',
    GET_INSIGHTS_SUCCESS = '[INGREDIENT PROFILER][INSIGHTS] Get Insights Success',
    GET_INSIGHTS_FAILURE = '[INGREDIENT PROFILER][INSIGHTS] Get Insights Failure',
    GET_INGREDIENT_INSIGHTS_REQUEST = '[INGREDIENT PROFILER][INSIGHTS] Get Ingredient Insights Request',
    GET_INGREDIENT_INSIGHTS_SUCCESS = '[INGREDIENT PROFILER][INSIGHTS] Get Ingredient Insights Success',
    GET_INGREDIENT_INSIGHTS_FAILURE = '[INGREDIENT PROFILER][INSIGHTS] Get Ingredient Insights Failure',
    GET_TARGET_INSIGHTS_REQUEST = '[INGREDIENT PROFILER][INSIGHTS] Get Target Insights Request',
    GET_TARGET_INSIGHTS_SUCCESS = '[INGREDIENT PROFILER][INSIGHTS] Get Target Insights Success',
    GET_TARGET_INSIGHTS_FAILURE = '[INGREDIENT PROFILER][INSIGHTS] Get Target Insights Failure',
    DOWNLOAD_INSIGHTS_REQUEST = '[INGREDIENT PROFILER][DOWNLOAD] Download Insights Request',
    DOWNLOAD_INSIGHTS_SUCCESS = '[INGREDIENT PROFILER][DOWNLOAD] Download Insights Success',
    DOWNLOAD_INSIGHTS_FAILURE = '[INGREDIENT PROFILER][DOWNLOAD] Download Insights Failure',
    GET_CATEGORIES_REQUEST = '[INGREDIENT PROFILER][CATEGORIES] Get Categories Request',
    GET_CATEGORIES_SUCCESS = '[INGREDIENT PROFILER][CATEGORIES] Get Categories Success',
    GET_CATEGORIES_FAILURE = '[INGREDIENT PROFILER][CATEGORIES] Get Categories Failure',
    GET_OVERVIEW_REQUEST = '[INGREDIENT PROFILER][OVERVIEW] Get Overview Request',
    GET_OVERVIEW_SUCCESS = '[INGREDIENT PROFILER][OVERVIEW] Get Overview Success',
    GET_OVERVIEW_FAILURE = '[INGREDIENT PROFILER][OVERVIEW] Get Overview Failure',
    GET_HEALTH_LABELS_STATISTICS_REQUEST = '[INGREDIENT PROFILER][HEALTH LABELS] Get Health Labels Statistics Request',
    GET_HEALTH_LABELS_STATISTICS_SUCCESS = '[INGREDIENT PROFILER][HEALTH LABELS] Get Health Labels Statistics Success',
    GET_HEALTH_LABELS_STATISTICS_FAILURE = '[INGREDIENT PROFILER][HEALTH LABELS] Get Health Labels Statistics Failure',
    GET_HEALTH_LABEL_SUMMARIES_REQUEST = '[INGREDIENT PROFILER][HEALTH LABELS] Get Health Label Summaries Request',
    GET_HEALTH_LABEL_SUMMARIES_SUCCESS = '[INGREDIENT PROFILER][HEALTH LABELS] Get Health Label Summaries Success',
    GET_HEALTH_LABEL_SUMMARIES_FAILURE = '[INGREDIENT PROFILER][HEALTH LABELS] Get Health Label Summaries Failure',
    GET_HEALTH_LABEL_TOP_COMPOUNDS_REQUEST = '[INGREDIENT PROFILER][HEALTH LABELS] Get Health Label Top Compounds Request',
    GET_HEALTH_LABEL_TOP_COMPOUNDS_SUCCESS = '[INGREDIENT PROFILER][HEALTH LABELS] Get Health Label Top Compounds Success',
    GET_HEALTH_LABEL_TOP_COMPOUNDS_FAILURE = '[INGREDIENT PROFILER][HEALTH LABELS] Get Health Label Top Compounds Failure',
    GET_SEARCH_SUGGESTIONS_REQUEST = '[INGREDIENT PROFILER][SEARCH SUGGESTIONS] Get Search Suggestions Request',
    GET_SEARCH_SUGGESTIONS_SUCCESS = '[INGREDIENT PROFILER][SEARCH SUGGESTIONS] Get Search Suggestions Success',
    GET_SEARCH_SUGGESTIONS_FAILURE = '[INGREDIENT PROFILER][SEARCH SUGGESTIONS] Get Search Suggestions Failure',
    CLEAR_SEARCH_SUGGESTIONS = '[INGREDIENT PROFILER][SEARCH SUGGESTIONS] Clear Search Suggestions',
    RESET_DISCOVERY = '[INGREDIENT PROFILER] Reset Discovery',
    CLEAR_NEXT_ERROR = '[INGREDIENT PROFILER] Clear Next Error',
}
