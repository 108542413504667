/** third-party imports */
import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { BehaviorSubject, Observable, Subscription, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';

/**  custom imports */
import { IngredientProfilerService } from '../ingredient-profiler/services/ingredient-profiler.service';
import { CowMilkProfilerState } from './cow-milk-profiler-state.interface';
import {
    getCompoundsRequest,
    getCompoundsSuccess,
    getCompoundsFailure,
    getInsightsRequest,
    getCowMilkInsightsRequest,
    getCowMilkInsightsSuccess,
    getCowMilkInsightsFailure,
    getTargetInsightsRequest,
    downloadInsightsRequest,
    getOverviewRequest,
    getHealthLabelsStatisticsRequest,
    getHealthLabelSummariesRequest,
    getHealthLabelTopCompoundsRequest,
    getSearchSuggestionsRequest,
    clearSearchSuggestions,
    resetDiscovery,
    clearNextError,
} from './cow-milk-profiler.actions';
import {
    getCompoundsTotal,
    getCompoundsDisplaying,
    getCompoundsLoading,
    getCompoundsLoaded,
    getParentCompoundId,
    getInsights,
    getEnhancedInsights,
    getInsightsTotal,
    getInsightsDisplaying,
    getInsightsPageIndex,
    getInsightsLoading,
    getInsightsLoaded,
    getCowMilkInsightsTotal,
    getCowMilkInsightsLoading,
    getCowMilkInsightsLoaded,
    getTargetInsights,
    getTargetInsightsTotal,
    getTargetInsightsLoading,
    getTargetInsightsLoaded,
    getOldestOccurrence,
    getNewestOccurrence,
    getMinCowMilkConcentration,
    getMaxCowMilkConcentration,
    getPrevalenceRange,
    getPreferences,
    getMinMoleculeWeight,
    getMaxMoleculeWeight,
    getBlob,
    getOverview,
    getOverviewLoading,
    getOverviewLoaded,
    getHealthLabelsStatistics,
    getHealthLabelsStatisticsLoading,
    getHealthLabelsStatisticsLoaded,
    getRelationshipsPerGroup,
    getHealthLabelSummaries,
    getHealthLabelTopCompounds,
    getSearchSuggestions,
    getSearchSuggestionsLoading,
    getSearchSuggestionsLoaded,
    getErrors,
} from './cow-milk-profiler.selectors';
import { createDistinctUntilObjectChanged } from '@leap-common/utilities/helpers';
import ErrorResponse from '@leap-common/interfaces/error-response.interface';
import PaginatedInsights from '../ingredient-profiler/interfaces/paginated-insights.interface';
import PaginatedCompounds from '../ingredient-profiler/interfaces/paginated-compounds.interface';
import Compound from '../ingredient-profiler/interfaces/compound.interface';
import Insight from '../ingredient-profiler/interfaces/insight.interface';
import EnhancedInsights from '../ingredient-profiler/interfaces/enhanced-insights';
import ExecutionFilters from '@apps/leap/src/app/shared/modules/filters/types/execution-filters.type';
import InsightFilterCounts from '@apps/leap/src/app/shared/modules/insight-filters/interfaces/counts.interface';
import RelationshipGroup from '../ingredient-profiler/enums/relationship-group.enum';
import Overview from '../ingredient-profiler/interfaces/overview.interface';
import HealthLabelStatistics from '../ingredient-profiler/interfaces/health-label-statistics.interface';
import HealthLabelSummaries from '../ingredient-profiler/interfaces/health-label-summaries.interface';
import HealthLabelTopCompounds from '../ingredient-profiler/interfaces/health-label-top-compounds.interface';
import SortingOptions from '@leap-common/interfaces/sorting-options.interface';
import PrevalenceRange from '@leap-store/core/src/lib/data/metadata/interfaces/prevalence-range.interface';
import UserPreferences from '@apps/leap/src/app/shared/types/user-preferences.type';
import ProfilerSearch from '@apps/leap/src/app/shared/enums/profiler-search.enum';

@Injectable()
export class CowMilkProfilerFacade {
    errors$: Observable<ErrorResponse[]> = this.store.pipe(select(getErrors));
    compounds$: BehaviorSubject<Compound[] | null> = new BehaviorSubject(null);
    compoundsTotal$: Observable<number> = this.store.pipe(select(getCompoundsTotal));
    compoundsDisplaying$: Observable<number> = this.store.pipe(select(getCompoundsDisplaying));
    compoundsLoading$: Observable<boolean> = this.store.pipe(select(getCompoundsLoading));
    compoundsLoaded$: Observable<boolean> = this.store.pipe(select(getCompoundsLoaded));
    parentCompoundId$: Observable<string> = this.store.pipe(select(getParentCompoundId));
    insights$: Observable<Insight[]> = this.store.pipe(select(getInsights));
    enhancedInsights$: Observable<EnhancedInsights> = this.store.pipe(
        select(getEnhancedInsights),
        createDistinctUntilObjectChanged<EnhancedInsights>(),
    );
    insightsTotal$: Observable<number> = this.store.pipe(select(getInsightsTotal));
    insightsDisplaying$: Observable<number> = this.store.pipe(select(getInsightsDisplaying));
    insightsPageIndex$: Observable<number> = this.store.pipe(select(getInsightsPageIndex));
    insightsLoading$: Observable<boolean> = this.store.pipe(select(getInsightsLoading));
    insightsLoaded$: Observable<boolean> = this.store.pipe(select(getInsightsLoaded));
    cowMilkInsightsTotal$: Observable<number> = this.store.pipe(select(getCowMilkInsightsTotal));
    cowMilkInsights$: BehaviorSubject<Insight[] | null> = new BehaviorSubject(null);
    cowMilkInsightsLoading$: Observable<boolean> = this.store.pipe(
        select(getCowMilkInsightsLoading),
    );
    cowMilkInsightsLoaded$: Observable<boolean> = this.store.pipe(select(getCowMilkInsightsLoaded));
    targetInsights$: Observable<Insight[]> = this.store.pipe(select(getTargetInsights));
    targetInsightsTotal$: Observable<number> = this.store.pipe(select(getTargetInsightsTotal));
    targetInsightsLoading$: Observable<boolean> = this.store.pipe(select(getTargetInsightsLoading));
    targetInsightsLoaded$: Observable<boolean> = this.store.pipe(select(getTargetInsightsLoaded));
    filterCounts$: BehaviorSubject<InsightFilterCounts | null> = new BehaviorSubject(null);
    oldestOccurrence$: Observable<number> = this.store.pipe(select(getOldestOccurrence));
    newestOccurrence$: Observable<number> = this.store.pipe(select(getNewestOccurrence));
    minCowMilkConcentration$: Observable<number> = this.store.pipe(
        select(getMinCowMilkConcentration),
    );
    maxCowMilkConcentration$: Observable<number> = this.store.pipe(
        select(getMaxCowMilkConcentration),
    );
    prevalenceRange$: Observable<PrevalenceRange> = this.store.pipe(select(getPrevalenceRange));
    preferences$: Observable<UserPreferences> = this.store.pipe(select(getPreferences));
    minMoleculeWeight$: Observable<number> = this.store.pipe(select(getMinMoleculeWeight));
    maxMoleculeWeight$: Observable<number> = this.store.pipe(select(getMaxMoleculeWeight));
    blob$: Observable<Blob> = this.store.pipe(select(getBlob));
    overview$: Observable<Overview> = this.store.pipe(select(getOverview));
    overviewLoading$: Observable<boolean> = this.store.pipe(select(getOverviewLoading));
    overviewLoaded$: Observable<boolean> = this.store.pipe(select(getOverviewLoaded));
    healthLabelsStatistics$: Observable<HealthLabelStatistics[]> = this.store.pipe(
        select(getHealthLabelsStatistics),
    );
    healthLabelsStatisticsLoading$: Observable<boolean> = this.store.pipe(
        select(getHealthLabelsStatisticsLoading),
    );
    healthLabelsStatisticsLoaded$: Observable<boolean> = this.store.pipe(
        select(getHealthLabelsStatisticsLoaded),
    );
    relationshipsPerGroup$: Observable<Record<RelationshipGroup, string[]>> = this.store.pipe(
        select(getRelationshipsPerGroup),
    );
    healthLabelSummaries$: Observable<HealthLabelSummaries> = this.store.pipe(
        select(getHealthLabelSummaries),
    );
    healthLabelTopCompounds$: Observable<HealthLabelTopCompounds> = this.store.pipe(
        select(getHealthLabelTopCompounds),
    );
    searchSuggestions$: Observable<string[]> = this.store.pipe(select(getSearchSuggestions));
    searchSuggestionsLoading$: Observable<boolean> = this.store.pipe(
        select(getSearchSuggestionsLoading),
    );
    searchSuggestionsLoaded$: Observable<boolean> = this.store.pipe(
        select(getSearchSuggestionsLoaded),
    );

    // internal variables
    internalCompounds$: Observable<void>;
    compoundsSubscription: Subscription;
    internalCowMilkInsights$: Observable<void>;
    cowMilkInsightsSubscription: Subscription;

    constructor(
        private store: Store<CowMilkProfilerState>,
        private ingredientProfilerService: IngredientProfilerService,
    ) {}

    getCompounds({
        filters,
        pageSize,
        pageIndex,
        sortingOptions,
        preferences,
    }: {
        filters: ExecutionFilters;
        pageSize?: number;
        pageIndex?: number;
        sortingOptions: SortingOptions;
        preferences: UserPreferences;
    }): void {
        this.store.dispatch(
            getCompoundsRequest({ filters, pageSize, pageIndex, sortingOptions, preferences }),
        );

        this.compoundsSubscription?.unsubscribe();

        this.internalCompounds$ = this.ingredientProfilerService
            // when the ingredient id is undefined the BE uses the cow milk id
            .getCompounds(undefined, filters, pageSize, pageIndex, sortingOptions, preferences)
            .pipe(
                map((paginatedCompounds: PaginatedCompounds) => {
                    this.compounds$.next(paginatedCompounds?.results);
                    this.filterCounts$.next(paginatedCompounds?.filterCounts);
                    this.store.dispatch(getCompoundsSuccess({ paginatedCompounds }));
                }),
                catchError((errorResponse: HttpErrorResponse) =>
                    of(this.store.dispatch(getCompoundsFailure({ errorResponse }))),
                ),
            );

        this.compoundsSubscription = this.internalCompounds$.subscribe();
    }

    getInsights({
        compoundId,
        filters,
        pageSize,
        pageIndex,
        preferences,
        sortingOptions,
    }: {
        compoundId: string;
        filters: ExecutionFilters;
        pageSize?: number;
        pageIndex?: number;
        preferences: UserPreferences;
        sortingOptions?: SortingOptions;
    }): void {
        this.store.dispatch(
            getInsightsRequest({
                compoundId,
                filters,
                pageSize,
                pageIndex,
                preferences,
                sortingOptions,
            }),
        );
    }

    getCowMilkInsights({
        filters,
        pageSize,
        pageIndex,
        preferences,
    }: {
        filters: ExecutionFilters;
        pageSize?: number;
        pageIndex?: number;
        preferences: UserPreferences;
    }): void {
        this.store.dispatch(
            getCowMilkInsightsRequest({
                filters,
                pageSize,
                pageIndex,
                preferences,
            }),
        );

        this.cowMilkInsightsSubscription?.unsubscribe();

        this.internalCowMilkInsights$ = this.ingredientProfilerService
            // when the ingredient id is undefined the BE uses the cow milk id
            .getIngredientInsights(undefined, filters, pageSize, pageIndex, preferences)
            .pipe(
                map((paginatedInsights: PaginatedInsights) => {
                    this.cowMilkInsights$.next(paginatedInsights?.results);
                    this.store.dispatch(getCowMilkInsightsSuccess({ paginatedInsights }));
                }),
                catchError((errorResponse: HttpErrorResponse) =>
                    of(this.store.dispatch(getCowMilkInsightsFailure({ errorResponse }))),
                ),
            );

        this.cowMilkInsightsSubscription = this.internalCowMilkInsights$.subscribe();
    }

    getTargetInsights({
        targetId,
        filters,
        pageSize,
        pageIndex,
        preferences,
    }: {
        targetId: string;
        filters?: ExecutionFilters;
        pageSize?: number;
        pageIndex?: number;
        preferences: UserPreferences;
    }): void {
        this.store.dispatch(
            getTargetInsightsRequest({ targetId, filters, pageSize, pageIndex, preferences }),
        );
    }

    downloadInsights(
        filters: ExecutionFilters,
        sortingOptions: SortingOptions,
        preferences: UserPreferences,
    ): void {
        this.store.dispatch(downloadInsightsRequest({ filters, sortingOptions, preferences }));
    }

    getOverview({
        countBy,
        filters,
        preferences,
    }: {
        countBy: string;
        filters: ExecutionFilters;
        preferences: UserPreferences;
    }): void {
        this.store.dispatch(getOverviewRequest({ countBy, filters, preferences }));
    }

    getHealthLabelsStatistics({
        filters,
        preferences,
        areEffectsEnabled,
    }: {
        filters: ExecutionFilters;
        preferences: UserPreferences;
        areEffectsEnabled: boolean;
    }): void {
        this.store.dispatch(
            getHealthLabelsStatisticsRequest({ filters, preferences, areEffectsEnabled }),
        );
    }

    getHealthLabelSummaries({
        healthLabel,
        filters,
        preferences,
        areEffectsEnabled,
    }: {
        healthLabel: string;
        filters: ExecutionFilters;
        preferences: UserPreferences;
        areEffectsEnabled: boolean;
    }): void {
        this.store.dispatch(
            getHealthLabelSummariesRequest({
                healthLabel,
                filters,
                preferences,
                areEffectsEnabled,
            }),
        );
    }

    getHealthLabelTopCompounds({
        healthLabel,
        filters,
        preferences,
        areEffectsEnabled,
    }: {
        healthLabel: string;
        filters: ExecutionFilters;
        preferences: UserPreferences;
        areEffectsEnabled: boolean;
    }): void {
        this.store.dispatch(
            getHealthLabelTopCompoundsRequest({
                healthLabel,
                filters,
                preferences,
                areEffectsEnabled,
            }),
        );
    }

    getSearchSuggestions(query: string, activeSearch: ProfilerSearch): void {
        this.store.dispatch(getSearchSuggestionsRequest({ query, activeSearch }));
    }

    clearSearchSuggestions(): void {
        this.store.dispatch(clearSearchSuggestions());
    }

    resetDiscovery(): void {
        this.compounds$.next(null);
        this.cowMilkInsights$.next(null);
        this.filterCounts$.next(null);
        this.store.dispatch(resetDiscovery());
    }

    clearNextError(): void {
        this.store.dispatch(clearNextError());
    }
}
