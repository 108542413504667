/** third-party imports */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

/** custom imports */
import { environment } from '@environments/leap/environment';
import { DefinitionsParser } from '../parsers/definitions.parser';

/** Interfaces */
import DefinitionsRestApi from '../rest-api-types/definitions.rest.type';
import Definitions from '../types/definitions.type';

@Injectable()
export class DefinitionsService {
    constructor(private http: HttpClient, private definitionsParser: DefinitionsParser) {}

    getDefinitions(ids: string[]): Observable<Definitions> {
        return this.http
            .post(`${environment.metadataServerUrl}/nodes/definitions`, {
                cuis: ids,
            })
            .pipe(
                map((definitions: DefinitionsRestApi) =>
                    this.definitionsParser.parseDefinitions(definitions),
                ),
            );
    }
}
