import { Pipe, PipeTransform } from '@angular/core';
import { WHITESPACE } from '@leap-common/constants/common';
import { UNDERSCORE_DELIMITER } from '@leap-common/constants/delimiters';

@Pipe({ name: 'snakecase' })
export class SnakeCasePipe implements PipeTransform {
    constructor() {}

    public transform(value: string): string {
        if (!value) {
            return;
        }
        return value.replace(/\s\s+/g, WHITESPACE).replace(/\s/g, UNDERSCORE_DELIMITER);
    }
}
