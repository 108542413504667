/** third-party imports */
import { createReducer, on, Action, ActionReducer } from '@ngrx/store';
import { HttpErrorResponse } from '@angular/common/http';

/** custom imports */
import * as discoveryActions from './combinatorial-discovery.actions';
import ErrorResponse from '@leap-common/interfaces/error-response.interface';
import { CombinatorialDiscoveryState } from './combinatorial-discovery-state.interface';

export const initialState: CombinatorialDiscoveryState = {
    errors: [],
    loading: false,
    loaded: false,
    blob: null,
    searchSuggestions: [],
    searchSuggestionsLoading: false,
    searchSuggestionsLoaded: false,
    dbsIds: [],
    // This property will be true only on 404 errors
    notFoundErrorLoaded: false,
    sessionId: null,
};

const discoveryReducer: ActionReducer<CombinatorialDiscoveryState, Action> = createReducer(
    initialState,
    on(discoveryActions.performDiscoveryRequest, (state: CombinatorialDiscoveryState) => ({
        ...state,
        loading: true,
        loaded: false,
        notFoundErrorLoaded: false,
    })),
    on(discoveryActions.performDiscoverySuccess, (state: CombinatorialDiscoveryState) => ({
        ...state,
        loading: false,
        loaded: true,
        notFoundErrorLoaded: false,
    })),
    on(
        discoveryActions.performDiscoveryFailure,
        (
            state: CombinatorialDiscoveryState,
            { errorResponse }: { errorResponse: HttpErrorResponse },
        ) => ({
            ...state,
            errors:
                // On 404 errors we do not want to display the alert,
                // so we are not forwarding the error
                errorResponse.status === 404
                    ? [...state.errors]
                    : [...state.errors, errorResponse.error],
            loading: false,
            loaded: false,
            notFoundErrorLoaded: errorResponse.status === 404,
        }),
    ),
    on(discoveryActions.fetchLocalDiscoveryRequest, (state: CombinatorialDiscoveryState) => ({
        ...state,
        loading: true,
        loaded: false,
    })),
    on(discoveryActions.downloadDiscoveryRequest, (state: CombinatorialDiscoveryState) => ({
        ...state,
        blob: null as Blob,
    })),
    on(
        discoveryActions.downloadDiscoverySuccess,
        (state: CombinatorialDiscoveryState, { blob }: { blob: Blob }) => ({
            ...state,
            blob,
        }),
    ),
    on(
        discoveryActions.downloadDiscoveryFailure,
        (
            state: CombinatorialDiscoveryState,
            { errorResponse }: { errorResponse: HttpErrorResponse },
        ) => ({
            ...state,
            errors: [...state.errors, errorResponse.error],
        }),
    ),
    on(discoveryActions.fetchLocalDiscoverySuccess, (state: CombinatorialDiscoveryState) => ({
        ...state,
        loading: false,
        loaded: true,
    })),
    on(discoveryActions.fetchLocalDiscoveryFailure, (state: CombinatorialDiscoveryState) => ({
        ...state,
        loading: false,
        loaded: false,
    })),
    on(discoveryActions.resetDiscovery, (state: CombinatorialDiscoveryState) => ({
        ...state,
        loading: false,
        loaded: false,
        notFoundErrorLoaded: false,
    })),
    on(discoveryActions.getSearchSuggestionsRequest, (state: CombinatorialDiscoveryState) => ({
        ...state,
        searchSuggestionsLoading: true,
        searchSuggestionsLoaded: false,
    })),
    on(
        discoveryActions.getSearchSuggestionsSuccess,
        (state: CombinatorialDiscoveryState, { suggestionIds }: { suggestionIds: string[] }) => ({
            ...state,
            searchSuggestions: suggestionIds,
            searchSuggestionsLoading: false,
            searchSuggestionsLoaded: true,
        }),
    ),
    on(
        discoveryActions.getSearchSuggestionsFailure,
        (
            state: CombinatorialDiscoveryState,
            { errorResponse }: { errorResponse: HttpErrorResponse },
        ) => ({
            ...state,
            errors: [...state.errors, errorResponse.error],
            searchSuggestionsLoading: false,
            searchSuggestionsLoaded: false,
        }),
    ),
    on(discoveryActions.clearSearchSuggestions, (state: CombinatorialDiscoveryState) => ({
        ...state,
        searchSuggestions: null as string[],
        searchSuggestionsLoading: false,
        searchSuggestionsLoaded: false,
    })),
    on(discoveryActions.clearNextError, (state: CombinatorialDiscoveryState) => ({
        ...state,
        errors: state.errors.slice(1),
    })),
    on(discoveryActions.saveDBId, (state: CombinatorialDiscoveryState, { id }: { id: string }) => ({
        ...state,
        dbsIds: [...state.dbsIds, id],
    })),
    on(
        discoveryActions.removeDBId,
        (state: CombinatorialDiscoveryState, { id }: { id: string }) => ({
            ...state,
            dbsIds: state.dbsIds.filter((dbId: string) => dbId !== id),
        }),
    ),
    on(
        discoveryActions.saveSessionId,
        (state: CombinatorialDiscoveryState, { id }: { id: string }) => ({
            ...state,
            sessionId: id,
        }),
    ),
    on(discoveryActions.exitDiscovery, (state: CombinatorialDiscoveryState) => ({
        ...state,
    })),
);

export const reducer = (
    state: CombinatorialDiscoveryState | undefined,
    action: Action,
): CombinatorialDiscoveryState => discoveryReducer(state, action);

// selectors
export const getErrors: (state: CombinatorialDiscoveryState) => ErrorResponse[] = (
    state: CombinatorialDiscoveryState,
) => state.errors;
export const getLoading: (state: CombinatorialDiscoveryState) => boolean = (
    state: CombinatorialDiscoveryState,
) => state.loading;
export const getLoaded: (state: CombinatorialDiscoveryState) => boolean = (
    state: CombinatorialDiscoveryState,
) => state.loaded;
export const getBlob: (state: CombinatorialDiscoveryState) => Blob = (
    state: CombinatorialDiscoveryState,
) => state.blob;
export const getSearchSuggestions: (state: CombinatorialDiscoveryState) => string[] = (
    state: CombinatorialDiscoveryState,
) => state.searchSuggestions;
export const getSearchSuggestionsLoading: (state: CombinatorialDiscoveryState) => boolean = (
    state: CombinatorialDiscoveryState,
) => state.searchSuggestionsLoading;
export const getSearchSuggestionsLoaded: (state: CombinatorialDiscoveryState) => boolean = (
    state: CombinatorialDiscoveryState,
) => state.searchSuggestionsLoaded;
export const getDBsIds: (state: CombinatorialDiscoveryState) => string[] = (
    state: CombinatorialDiscoveryState,
) => state.dbsIds;
export const getNotFoundErrorLoaded: (state: CombinatorialDiscoveryState) => boolean = (
    state: CombinatorialDiscoveryState,
) => state.notFoundErrorLoaded;
export const getSessionId: (state: CombinatorialDiscoveryState) => string = (
    state: CombinatorialDiscoveryState,
) => state.sessionId;
