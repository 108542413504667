import { Injectable } from '@angular/core';

/** Interfaces */
import PaginatedResultsRestApi from '@leap-common/rest-api-interfaces/paginated-results.rest.interface';
import PaginatedResults from '@leap-common/interfaces/paginated-results.interface';
import PipelineExecutionRestApi from '../rest-api-interfaces/pipeline-execution.rest.interface';
import PipelineExecution from '../interfaces/pipeline-execution.interface';
import PipelineResult from '../interfaces/pipeline-result.interface';
import PipelineResultRestApi from '../rest-api-interfaces/pipeline-result.rest.interface';
import SortingOrder from '@leap-common/enums/sorting-order.enum';

@Injectable()
export class PipelineExecutionsParser {
    constructor() {}

    /** Parses PaginatedResults */
    parsePaginatedResults(
        pipelineExecutions: PaginatedResultsRestApi<PipelineExecutionRestApi>,
    ): PaginatedResults<PipelineExecution> {
        return {
            results: pipelineExecutions.results
                ? this.parsePipelineExecutions(pipelineExecutions.results)
                : [],
            pageIndex: pipelineExecutions.pageIndex - 1,
            pageSize: pipelineExecutions.pageSize,
            total: pipelineExecutions.total,
        };
    }

    /** Parses BE Pipeline Executions to integrate them on the FE */
    parsePipelineExecutions(pipelineExecutions: PipelineExecutionRestApi[]): PipelineExecution[] {
        return pipelineExecutions.map((pipelineExecution: PipelineExecutionRestApi) =>
            this.parsePipelineExecution(pipelineExecution),
        );
    }

    /** Parses PipelineExecutionRestApi to PipelineExecution */
    parsePipelineExecution(pipelineExecution: PipelineExecutionRestApi): PipelineExecution {
        return {
            id: pipelineExecution.uuid,
            type: pipelineExecution.workflow_name,
            creationDate: pipelineExecution.submitted_at,
            finishDate: pipelineExecution.finished_at,
            notebookId: pipelineExecution.configuration.notebook_uuid,
            status: pipelineExecution.status,
            indication: pipelineExecution.configuration.indication,
            results: this.parsePipelineExecutionResults(pipelineExecution.results),
        };
    }

    /** Parses PipelineResultRestApi Array to PipelineResult Array */
    parsePipelineExecutionResults(results: PipelineResultRestApi[]): PipelineResult[] {
        return results
            ? results.map((result: PipelineResultRestApi) =>
                  this.parsePipelineExecutionResult(result),
              )
            : [];
    }

    /** Parses PipelineResultRestApi to PipelineResult */
    parsePipelineExecutionResult(result: PipelineResultRestApi): PipelineResult {
        return { name: result.name, id: result.blob_uuid };
    }

    /** Serialize PipelineExecutions Sorting Parameter */
    serializePipelineExecutionsOrderByParameter(
        sortDirection: SortingOrder,
        sortColumn: string,
    ): string {
        const direction: string = sortDirection === SortingOrder.descending ? '-' : '';
        const column: string =
            sortColumn === 'id'
                ? 'uuid'
                : sortColumn === 'indication'
                ? 'indication'
                : sortColumn === 'status'
                ? 'status'
                : sortColumn === 'type'
                ? 'type'
                : sortColumn === 'creationDate'
                ? 'submitted_at'
                : sortColumn === 'finishDate'
                ? 'finished_at'
                : undefined;
        return `${direction}${column}`;
    }
}
