/** third-party imports */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

/** custom imports */
import { environment } from '@environments/leap/environment';
import { GroupsParser } from '../parsers/groups.parser';

/** Interfaces */
import PaginatedResultsRestApi from '@leap-common/rest-api-interfaces/paginated-results.rest.interface';
import PaginatedResults from '@leap-common/interfaces/paginated-results.interface';
import UserGroupRestApi from '@leap-store/core/src/lib/data/auth/rest-api-interfaces/user-group.rest.interface';
import UserGroup from '@leap-store/core/src/lib/data/auth/interfaces/user-group.interface';
import GroupTreeRestApi from '../rest-api-interfaces/group-tree.rest.interface';

@Injectable()
export class GroupsService {
    constructor(private http: HttpClient, private groupsParser: GroupsParser) {}

    /**
     * Gets a list of UserGroupRestApi with the available user groups and
     * maps the response to the desired format. It returns an Observable of PaginatedResults<UserGroup>.
     */
    getAvailableUserGroups(): Observable<PaginatedResults<UserGroup>> {
        return this.http
            .get(`${environment.serverUrl}/groups/`)
            .pipe(
                map((paginatedResults: PaginatedResultsRestApi<UserGroupRestApi>) =>
                    this.groupsParser.parsePaginatedResults(paginatedResults),
                ),
            );
    }

    /**
     * Gets a list of UserGroupRestApi in tree format. It returns an Observable of UserGroup[].
     */
    getGroupsTree(parentId: string): Observable<UserGroup[]> {
        return this.http
            .get(`${environment.serverUrl}/groups/${parentId}/?tree`)
            .pipe(
                map((group: GroupTreeRestApi<UserGroupRestApi>) =>
                    this.groupsParser.parseGroupsTree(group),
                ),
            );
    }

    /**
     * Makes a call with the id to server in order to delete the specific group and
     * maps the response to the desired format. It returns an Observable of UserGroup.
     */
    deleteGroup(id: string): Observable<UserGroup> {
        return this.http
            .delete(`${environment.serverUrl}/groups/${id}/`)
            .pipe(map((group: UserGroupRestApi) => this.groupsParser.parseGroup(group)));
    }

    /**
     * Makes a call with the ids to server in order to delete the specific groups and
     * maps the response to the desired format. It returns an Observable of PaginatedResults<UserGroup>.
     *
     *
     * ******************************** NOTE ********************************************
     * We are using http.request('delete') this once because we need a body within
     * our DELETE request and the regular http.delete() doesn't allow that.
     * **********************************************************************************
     */
    deleteGroups(ids: string[]): Observable<PaginatedResults<UserGroup>> {
        return this.http
            .request('delete', `${environment.serverUrl}/groups/`, {
                body: this.groupsParser.serializeGroupsIds(ids),
            })
            .pipe(
                map((paginatedResults: PaginatedResultsRestApi<UserGroupRestApi>) =>
                    this.groupsParser.parsePaginatedResults(paginatedResults),
                ),
            );
    }

    /**
     * Makes a request to the server with group parameters name and label to create a group.
     * Returns an Observable of type UserGroup
     */
    createGroup(name: string, label: string, parentId: string): Observable<UserGroup> {
        const params: UserGroupRestApi = this.groupsParser.serializeGroup(name, label, parentId);
        return this.http
            .post(`${environment.serverUrl}/groups/`, params)
            .pipe(map((group: UserGroupRestApi) => this.groupsParser.parseGroup(group)));
    }

    /**
     * Makes a request to the server with group parameters name and id to update a group.
     * Returns an Observable of type UserGroup
     */
    updateGroup(id: string, name: string): Observable<UserGroup> {
        return this.http
            .patch(`${environment.serverUrl}/groups/${id}/`, {
                name,
            })
            .pipe(map((group: UserGroupRestApi) => this.groupsParser.parseGroup(group)));
    }
}
