/** third-party imports */
import { createReducer, on, Action, ActionReducer } from '@ngrx/store';
import { HttpErrorResponse } from '@angular/common/http';

/** custom imports */
import * as ingredientComparisonActions from './ingredient-comparison.actions';
import { IngredientComparisonState } from './ingredient-comparison-state.interface';
import ErrorResponse from '@leap-common/interfaces/error-response.interface';
import PaginatedResults from '@leap-common/interfaces/paginated-results.interface';
import Ingredient from '../ingredient-viewer/interfaces/ingredient.interface';
import Bioactivity from './interfaces/bioactivity.interface';
import MultiLineChartDataItem from '@leap-libs/charts/src/lib/interfaces/multi-line-chart-data-item.interface';
import StatisticsItem from '../report/interfaces/statistics-item.interface';

export const initialState: IngredientComparisonState = {
    errors: [],
    ingredients: [],
    ingredientsLoading: false,
    ingredientsLoaded: false,
    bioactivity: [],
    bioactivityLoading: false,
    bioactivityLoaded: false,
    aminoAcids: {},
    aminoAcidsLoading: false,
    aminoAcidsLoaded: false,
    molecularWeights: [],
    molecularWeightsLoading: false,
    molecularWeightsLoaded: false,
};

const ingredientComparison: ActionReducer<IngredientComparisonState, Action> = createReducer(
    initialState,
    on(ingredientComparisonActions.getIngredientsRequest, (state: IngredientComparisonState) => ({
        ...state,
        ingredientsLoading: true,
        ingredientsLoaded: false,
    })),
    on(
        ingredientComparisonActions.getIngredientsSuccess,
        (
            state: IngredientComparisonState,
            { paginatedIngredients }: { paginatedIngredients: PaginatedResults<Ingredient> },
        ) => ({
            ...state,
            ingredients: paginatedIngredients.results,
            ingredientsLoading: false,
            ingredientsLoaded: true,
        }),
    ),
    on(
        ingredientComparisonActions.getIngredientsFailure,
        (
            state: IngredientComparisonState,
            { errorResponse }: { errorResponse: HttpErrorResponse },
        ) => ({
            ...state,
            errors: [...state.errors, errorResponse.error],
            ingredientsLoading: false,
            ingredientsLoaded: false,
        }),
    ),
    on(ingredientComparisonActions.clearNextError, (state: IngredientComparisonState) => ({
        ...state,
        errors: state.errors.slice(1),
    })),
    on(ingredientComparisonActions.getBioactivityRequest, (state: IngredientComparisonState) => ({
        ...state,
        bioactivityLoading: true,
        bioactivityLoaded: false,
    })),
    on(
        ingredientComparisonActions.getBioactivitySuccess,
        (state: IngredientComparisonState, { bioactivity }: { bioactivity: Bioactivity[] }) => ({
            ...state,
            bioactivity,
            bioactivityLoading: false,
            bioactivityLoaded: true,
        }),
    ),
    on(
        ingredientComparisonActions.getBioactivityFailure,
        (
            state: IngredientComparisonState,
            { errorResponse }: { errorResponse: HttpErrorResponse },
        ) => ({
            ...state,
            errors: [...state.errors, errorResponse.error],
            bioactivityLoading: false,
            bioactivityLoaded: false,
        }),
    ),
    on(ingredientComparisonActions.getAminoAcidsRequest, (state: IngredientComparisonState) => ({
        ...state,
        aminoAcidsLoading: true,
        aminoAcidsLoaded: false,
    })),
    on(
        ingredientComparisonActions.getAminoAcidsSuccess,
        (
            state: IngredientComparisonState,
            { aminoAcids }: { aminoAcids: Record<string, StatisticsItem[]> },
        ) => ({
            ...state,
            aminoAcids,
            aminoAcidsLoading: false,
            aminoAcidsLoaded: true,
        }),
    ),
    on(
        ingredientComparisonActions.getAminoAcidsFailure,
        (
            state: IngredientComparisonState,
            { errorResponse }: { errorResponse: HttpErrorResponse },
        ) => ({
            ...state,
            errors: [...state.errors, errorResponse.error],
            aminoAcidsLoading: false,
            aminoAcidsLoaded: false,
        }),
    ),
    on(
        ingredientComparisonActions.getMolecularWeightsRequest,
        (state: IngredientComparisonState) => ({
            ...state,
            molecularWeightsLoading: true,
            molecularWeightsLoaded: false,
        }),
    ),
    on(
        ingredientComparisonActions.getMolecularWeightsSuccess,
        (
            state: IngredientComparisonState,
            { molecularWeights }: { molecularWeights: MultiLineChartDataItem[] },
        ) => ({
            ...state,
            molecularWeights,
            molecularWeightsLoading: false,
            molecularWeightsLoaded: true,
        }),
    ),
    on(
        ingredientComparisonActions.getMolecularWeightsFailure,
        (
            state: IngredientComparisonState,
            { errorResponse }: { errorResponse: HttpErrorResponse },
        ) => ({
            ...state,
            errors: [...state.errors, errorResponse.error],
            molecularWeightsLoading: false,
            molecularWeightsLoaded: false,
        }),
    ),
);

export const reducer = (
    state: IngredientComparisonState | undefined,
    action: Action,
): IngredientComparisonState => ingredientComparison(state, action);

// selectors
export const getErrors: (state: IngredientComparisonState) => ErrorResponse[] = (
    state: IngredientComparisonState,
) => state.errors;
export const getIngredients: (state: IngredientComparisonState) => Ingredient[] = (
    state: IngredientComparisonState,
) => state.ingredients;
export const getIngredientsLoading: (state: IngredientComparisonState) => boolean = (
    state: IngredientComparisonState,
) => state.ingredientsLoading;
export const getIngredientsLoaded: (state: IngredientComparisonState) => boolean = (
    state: IngredientComparisonState,
) => state.ingredientsLoaded;
export const getBioactivity: (state: IngredientComparisonState) => Bioactivity[] = (
    state: IngredientComparisonState,
) => state.bioactivity;
export const getBioactivityLoading: (state: IngredientComparisonState) => boolean = (
    state: IngredientComparisonState,
) => state.bioactivityLoading;
export const getBioactivityLoaded: (state: IngredientComparisonState) => boolean = (
    state: IngredientComparisonState,
) => state.bioactivityLoaded;
export const getAminoAcids: (
    state: IngredientComparisonState,
) => Record<string, StatisticsItem[]> = (state: IngredientComparisonState) => state.aminoAcids;
export const getAminoAcidsLoading: (state: IngredientComparisonState) => boolean = (
    state: IngredientComparisonState,
) => state.aminoAcidsLoading;
export const getAminoAcidsLoaded: (state: IngredientComparisonState) => boolean = (
    state: IngredientComparisonState,
) => state.aminoAcidsLoaded;
export const getMolecularWeights: (state: IngredientComparisonState) => MultiLineChartDataItem[] = (
    state: IngredientComparisonState,
) => state.molecularWeights;
export const getMolecularWeightsLoading: (state: IngredientComparisonState) => boolean = (
    state: IngredientComparisonState,
) => state.molecularWeightsLoading;
export const getMolecularWeightsLoaded: (state: IngredientComparisonState) => boolean = (
    state: IngredientComparisonState,
) => state.molecularWeightsLoaded;
