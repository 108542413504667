/** third-party imports */
import { createAction, props } from '@ngrx/store';

/** custom imports */
import { CowMilkProfilerActionTypes } from './cow-milk-profiler-action-types.enum';
import { HttpErrorResponse } from '@angular/common/http';
import PaginatedInsights from '../ingredient-profiler/interfaces/paginated-insights.interface';
import PaginatedCompounds from '../ingredient-profiler/interfaces/paginated-compounds.interface';
import ExecutionFilters from '@apps/leap/src/app/shared/modules/filters/types/execution-filters.type';
import RelationshipGroup from '../ingredient-profiler/enums/relationship-group.enum';
import Overview from '../ingredient-profiler/interfaces/overview.interface';
import HealthLabelStatistics from '../ingredient-profiler/interfaces/health-label-statistics.interface';
import HealthLabelSummaries from '../ingredient-profiler/interfaces/health-label-summaries.interface';
import HealthLabelTopCompounds from '../ingredient-profiler/interfaces/health-label-top-compounds.interface';
import SortingOptions from '@leap-common/interfaces/sorting-options.interface';
import UserPreferences from '@apps/leap/src/app/shared/types/user-preferences.type';
import ProfilerSearch from '@apps/leap/src/app/shared/enums/profiler-search.enum';

export const getCompoundsRequest = createAction(
    CowMilkProfilerActionTypes.GET_COMPOUNDS_REQUEST,
    props<{
        filters: ExecutionFilters;
        pageSize: number;
        pageIndex: number;
        sortingOptions: SortingOptions;
        preferences: UserPreferences;
    }>(),
);

export const getCompoundsSuccess = createAction(
    CowMilkProfilerActionTypes.GET_COMPOUNDS_SUCCESS,
    props<{ paginatedCompounds: PaginatedCompounds }>(),
);

export const getCompoundsFailure = createAction(
    CowMilkProfilerActionTypes.GET_COMPOUNDS_FAILURE,
    props<{ errorResponse: HttpErrorResponse }>(),
);

export const getInsightsRequest = createAction(
    CowMilkProfilerActionTypes.GET_INSIGHTS_REQUEST,
    props<{
        compoundId: string;
        filters: ExecutionFilters;
        pageSize?: number;
        pageIndex?: number;
        preferences: UserPreferences;
        sortingOptions?: SortingOptions;
    }>(),
);

export const getInsightsSuccess = createAction(
    CowMilkProfilerActionTypes.GET_INSIGHTS_SUCCESS,
    props<{ compoundId: string; paginatedInsights: PaginatedInsights }>(),
);

export const getInsightsFailure = createAction(
    CowMilkProfilerActionTypes.GET_INSIGHTS_FAILURE,
    props<{ errorResponse: HttpErrorResponse }>(),
);

export const getCowMilkInsightsRequest = createAction(
    CowMilkProfilerActionTypes.GET_COW_MILK_INSIGHTS_REQUEST,
    props<{
        filters: ExecutionFilters;
        pageSize?: number;
        pageIndex?: number;
        preferences: UserPreferences;
    }>(),
);

export const getCowMilkInsightsSuccess = createAction(
    CowMilkProfilerActionTypes.GET_COW_MILK_INSIGHTS_SUCCESS,
    props<{ paginatedInsights: PaginatedInsights }>(),
);

export const getCowMilkInsightsFailure = createAction(
    CowMilkProfilerActionTypes.GET_COW_MILK_INSIGHTS_FAILURE,
    props<{ errorResponse: HttpErrorResponse }>(),
);

export const getTargetInsightsRequest = createAction(
    CowMilkProfilerActionTypes.GET_TARGET_INSIGHTS_REQUEST,
    props<{
        targetId: string;
        filters: ExecutionFilters;
        pageSize?: number;
        pageIndex?: number;
        preferences: UserPreferences;
    }>(),
);

export const getTargetInsightsSuccess = createAction(
    CowMilkProfilerActionTypes.GET_TARGET_INSIGHTS_SUCCESS,
    props<{ paginatedInsights: PaginatedInsights }>(),
);

export const getTargetInsightsFailure = createAction(
    CowMilkProfilerActionTypes.GET_TARGET_INSIGHTS_FAILURE,
    props<{ errorResponse: HttpErrorResponse }>(),
);

export const downloadInsightsRequest = createAction(
    CowMilkProfilerActionTypes.DOWNLOAD_INSIGHTS_REQUEST,
    props<{
        filters: ExecutionFilters;
        sortingOptions: SortingOptions;
        preferences: UserPreferences;
    }>(),
);

export const downloadInsightsSuccess = createAction(
    CowMilkProfilerActionTypes.DOWNLOAD_INSIGHTS_SUCCESS,
    props<{ blob: Blob }>(),
);

export const downloadInsightsFailure = createAction(
    CowMilkProfilerActionTypes.DOWNLOAD_INSIGHTS_FAILURE,
    props<{ errorResponse: HttpErrorResponse }>(),
);

export const getOverviewRequest = createAction(
    CowMilkProfilerActionTypes.GET_OVERVIEW_REQUEST,
    props<{
        countBy: string;
        filters: ExecutionFilters;
        preferences: UserPreferences;
    }>(),
);

export const getOverviewSuccess = createAction(
    CowMilkProfilerActionTypes.GET_OVERVIEW_SUCCESS,
    props<{ overview: Overview }>(),
);

export const getOverviewFailure = createAction(
    CowMilkProfilerActionTypes.GET_OVERVIEW_FAILURE,
    props<{ errorResponse: HttpErrorResponse }>(),
);

export const getHealthLabelsStatisticsRequest = createAction(
    CowMilkProfilerActionTypes.GET_HEALTH_LABELS_STATISTICS_REQUEST,
    props<{
        filters: ExecutionFilters;
        preferences: UserPreferences;
        areEffectsEnabled: boolean;
    }>(),
);

export const getHealthLabelsStatisticsSuccess = createAction(
    CowMilkProfilerActionTypes.GET_HEALTH_LABELS_STATISTICS_SUCCESS,
    props<{
        healthLabelsStatistics: HealthLabelStatistics[];
        relationshipsPerGroup: Record<RelationshipGroup, string[]>;
    }>(),
);

export const getHealthLabelsStatisticsFailure = createAction(
    CowMilkProfilerActionTypes.GET_HEALTH_LABELS_STATISTICS_FAILURE,
    props<{ errorResponse: HttpErrorResponse }>(),
);

export const getHealthLabelSummariesRequest = createAction(
    CowMilkProfilerActionTypes.GET_HEALTH_LABEL_SUMMARIES_REQUEST,
    props<{
        healthLabel: string;
        filters: ExecutionFilters;
        preferences: UserPreferences;
        areEffectsEnabled: boolean;
    }>(),
);

export const getHealthLabelSummariesSuccess = createAction(
    CowMilkProfilerActionTypes.GET_HEALTH_LABEL_SUMMARIES_SUCCESS,
    props<{ healthLabelSummaries: HealthLabelSummaries }>(),
);

export const getHealthLabelSummariesFailure = createAction(
    CowMilkProfilerActionTypes.GET_HEALTH_LABEL_SUMMARIES_FAILURE,
    props<{ errorResponse: HttpErrorResponse }>(),
);

export const getHealthLabelTopCompoundsRequest = createAction(
    CowMilkProfilerActionTypes.GET_HEALTH_LABEL_TOP_COMPOUNDS_REQUEST,
    props<{
        healthLabel: string;
        filters: ExecutionFilters;
        preferences: UserPreferences;
        areEffectsEnabled: boolean;
    }>(),
);

export const getHealthLabelTopCompoundsSuccess = createAction(
    CowMilkProfilerActionTypes.GET_HEALTH_LABEL_TOP_COMPOUNDS_SUCCESS,
    props<{ healthLabelTopCompounds: HealthLabelTopCompounds }>(),
);

export const getHealthLabelTopCompoundsFailure = createAction(
    CowMilkProfilerActionTypes.GET_HEALTH_LABEL_TOP_COMPOUNDS_FAILURE,
    props<{ errorResponse: HttpErrorResponse }>(),
);

export const getSearchSuggestionsRequest = createAction(
    CowMilkProfilerActionTypes.GET_SEARCH_SUGGESTIONS_REQUEST,
    props<{ query: string; activeSearch: ProfilerSearch }>(),
);

export const getSearchSuggestionsSuccess = createAction(
    CowMilkProfilerActionTypes.GET_SEARCH_SUGGESTIONS_SUCCESS,
    props<{ suggestionIds: string[] }>(),
);

export const getSearchSuggestionsFailure = createAction(
    CowMilkProfilerActionTypes.GET_SEARCH_SUGGESTIONS_FAILURE,
    props<{ errorResponse: HttpErrorResponse }>(),
);

export const clearSearchSuggestions = createAction(
    CowMilkProfilerActionTypes.CLEAR_SEARCH_SUGGESTIONS,
);

export const resetDiscovery = createAction(CowMilkProfilerActionTypes.RESET_DISCOVERY);

export const clearNextError = createAction(CowMilkProfilerActionTypes.CLEAR_NEXT_ERROR);
