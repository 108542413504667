import { ActionReducer, MetaReducer } from '@ngrx/store';
import { AuthActionTypes } from './data/auth/auth-action-types.enum';

const clearStore =
    (reducer: ActionReducer<any>): ActionReducer<any> =>
    (state, action) =>
        reducer(
            action.type === AuthActionTypes.LOGOUT_USER_SUCCESS
                ? { ...state, data: undefined }
                : state,
            action,
        );

export const metaReducers: MetaReducer<any>[] = [clearStore];
