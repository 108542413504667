import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { InputModule } from '@leap-libs/input/src/public-api';
import { FormComponent } from './form.component';
import { FormValidationService } from './form-validation.service';
import { ArrayHandlerService } from '@leap-common/services/array-handler.service';

@NgModule({
    declarations: [FormComponent],
    imports: [CommonModule, ReactiveFormsModule, InputModule],
    exports: [FormComponent],
    providers: [FormValidationService, ArrayHandlerService],
})
export class FormModule {}
