import { Injectable } from '@angular/core';
import { debounce } from 'lodash';

/** custom imports */
import { AuthFacade } from '../auth.facade';

@Injectable()
export class RefreshTokenService {
    refreshTimer: ReturnType<typeof setTimeout> = null;
    refreshTime: number = 5 * 60 * 1000;

    focusListener: EventListener;

    constructor(private authFacade: AuthFacade) {}

    /** Returns the refreshTimer */
    getTimer(): ReturnType<typeof setTimeout> {
        return this.refreshTimer;
    }

    /**
     * Sets a timer to fire after a given time. It calls auth facade refreshToken()
     * method. Then calls itself to create a loop which will keep the
     * token refreshed during the user session.
     */
    setTimer(): void {
        this.authFacade.refreshToken();
        this.refreshTimer = setTimeout(() => {
            this.setTimer();
        }, this.refreshTime);
    }

    /**
     * Stops the refresh token loop by clearing the refreshTimer
     * timeout.
     */
    removeTimer(): void {
        clearTimeout(this.refreshTimer);
        this.refreshTimer = null;
    }

    /** Returns the focusListener */
    getFocusListener(): any {
        return this.focusListener;
    }

    /**
     * Sets up a focus event listener on the window to monitor when the window gains focus.
     * The listener is debounced with a 400ms delay to prevent rapid consecutive calls.
     *
     * When the window gains focus, the event listener triggers the `authFacade.refreshToken()`.
     */
    setFocusListener(): void {
        this.focusListener = debounce((): void => {
            this.authFacade.refreshToken();
        }, 400);

        window.addEventListener('focus', this.focusListener);
    }

    /** Removes the focusListener */
    removeFocusListener(): void {
        window.removeEventListener('focus', this.focusListener);
    }
}
