import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { InputComponent } from './components/input/input.component';
import { CheckboxComponent } from './components/checkbox/checkbox.component';

@NgModule({
    declarations: [InputComponent, CheckboxComponent],
    imports: [CommonModule, ReactiveFormsModule],
    exports: [InputComponent, CheckboxComponent],
})
export class InputModule {}
