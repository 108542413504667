import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';

/** pouchDB */
import PouchDB from 'pouchdb';
import PouchDBFind from 'pouchdb-find';
import PouchDBMemoryAdapter from 'pouchdb-adapter-memory';

/** custom imports */
import { DatabaseService } from '../common/database.service';
import { LocalStorageService } from '../common/local-storage.service';
import { CombinatorialDiscoveryFacade } from '@leap-store/core/src/lib/data/discovery/combinatorial/combinatorial-discovery.facade';
import Database from '../database.enum';
import Insight from '@leap-store/core/src/lib/data/discovery/combinatorial/interfaces/insight.interface';
import Metadata from '@leap-store/core/src/lib/data/discovery/combinatorial/interfaces/metadata.interface';
import CombinatorialDiscoveryRelation from '@leap-store/core/src/lib/ui/discovery/enums/combinatorial-discovery-relation.enum';
import SortingOptions from '@leap-common/interfaces/sorting-options.interface';
import Filter from '@apps/leap/src/app/shared/modules/filters/interfaces/filter.interface';
import RelationshipOrigin from '@apps/leap/src/app/shared/enums/relationship-origin.enum';
import SortingOrder from '@leap-common/enums/sorting-order.enum';

const TEST_DB_NAME: string = 'test-database';

PouchDB.plugin(PouchDBFind);
PouchDB.plugin(PouchDBMemoryAdapter);
@Injectable()
export class CombinatorialDiscoveryInsightsService {
    MASK_LENGTH: number = 16;

    pouchDB: PouchDB.Database;

    dbsIds$: Observable<string[]> = this.facade.dbsIds$;

    constructor(
        private databaseService: DatabaseService,
        private localStorageService: LocalStorageService,
        private facade: CombinatorialDiscoveryFacade,
    ) {}

    appendToActiveDBs(dbName: string): void {
        let activeDBs: string[] = this.getActiveDBs();
        activeDBs = activeDBs.concat(dbName);
        this.updateActiveDBs(activeDBs);
    }

    getActiveDBs(): string[] {
        return this.localStorageService.splitValueInDelimiter(
            this.localStorageService.getItem(Database.discoveryDBs),
            Database.delimiter,
        );
    }

    updateActiveDBs(activeDBs: string[]): void {
        this.localStorageService.setItem(Database.discoveryDBs, activeDBs.join(Database.delimiter));
    }

    async isIndexedDBSupported(): Promise<boolean> {
        const testDB: PouchDB.Database = new PouchDB(TEST_DB_NAME);
        const isNotSupported: boolean = await this.databaseService.checkDB(testDB);

        if (isNotSupported) {
            return false;
        }

        await this.databaseService.destroyDB(TEST_DB_NAME);
        return true;
    }

    /** Database */

    /**
     * Returns true when database is created and false when it already exists
     */
    async getOrCreateDB(id: string, categories: string[]): Promise<boolean> {
        const dbName: string = `${Database.combinatorialDiscoveryInsights}-${id}-${categories.join(
            '-',
        )}`;

        // Side effect: Creates empty DB which may never be used.
        this.pouchDB = this.databaseService.switchDB(dbName);

        const isIndexedDBUnknown: boolean = await this.databaseService.checkDB(this.pouchDB);

        // Firefox private mode doesn't support indexed db, so we need
        // to fall back to in-memory db.
        if (isIndexedDBUnknown) {
            const existingDBs: string[] = await this.dbsIds$.pipe(take(1)).toPromise();

            if (existingDBs.includes(id)) {
                // Side effect: Creates empty DB which may never be used.
                this.pouchDB = this.databaseService.switchDB(dbName, isIndexedDBUnknown);
                return false;
            }

            // save DB id
            this.facade.saveDBId(`${id}-${categories.join('-')}`);

            // create in-memory DB
            this.pouchDB = this.databaseService.createDB(dbName, isIndexedDBUnknown);

            return true;
        }

        let activeDBs: string[] = this.localStorageService.splitValueInDelimiter(
            this.localStorageService.getItem(Database.discoveryDBs),
            Database.delimiter,
        );

        if (activeDBs.includes(dbName)) {
            // switch DB
            this.pouchDB = this.databaseService.switchDB(dbName);
            return false;
        }

        // create DB
        this.pouchDB = this.databaseService.createDB(dbName);

        // update localStorage / active DBs
        activeDBs = activeDBs.concat(dbName);
        this.localStorageService.setItem(Database.discoveryDBs, activeDBs.join(Database.delimiter));

        return true;
    }

    async createDB(id: string, categories: string[]): Promise<void> {
        const dbName: string = `${Database.combinatorialDiscoveryInsights}-${id}-${categories.join(
            '-',
        )}`;
        const isIndexedDBSupported: boolean = await this.isIndexedDBSupported();

        this.pouchDB = this.databaseService.switchDB(dbName);

        // Firefox private mode doesn't support indexed db, so we need
        // to fall back to in-memory db.
        if (!isIndexedDBSupported) {
            // save DB id
            this.facade.saveDBId(`${id}-${categories.join('-')}`);

            // create in-memory DB
            this.pouchDB = this.databaseService.createDB(dbName, true);

            return;
        }

        // create DB
        this.pouchDB = this.databaseService.createDB(dbName);

        // update active DBs
        this.appendToActiveDBs(dbName);
    }

    async doesDBExist(id: string, categories: string[]): Promise<boolean> {
        const dbName: string = `${Database.combinatorialDiscoveryInsights}-${id}-${categories.join(
            '-',
        )}`;
        const isIndexedDBSupported: boolean = await this.isIndexedDBSupported();

        // Firefox private mode doesn't support indexed db, so we need
        // to fall back to in-memory db.
        if (!isIndexedDBSupported) {
            const existingDBs: string[] = await this.dbsIds$.pipe(take(1)).toPromise();

            if (existingDBs.includes(`${id}-${categories.join('-')}`)) {
                // Side effect: Creates empty DB which may never be used.
                this.pouchDB = this.databaseService.switchDB(dbName, true);
                return true;
            }

            return false;
        }

        const activeDBs: string[] = this.getActiveDBs();

        if (activeDBs.includes(dbName)) {
            // Side effect: Creates empty DB which may never be used.
            this.pouchDB = this.databaseService.switchDB(dbName);

            return true;
        }

        return false;
    }

    async destroyDB(id: string, categories: string[]): Promise<void> {
        const dbName: string = `${Database.combinatorialDiscoveryInsights}-${id}-${categories.join(
            '-',
        )}`;
        const isIndexedDBSupported: boolean = await this.isIndexedDBSupported();

        this.pouchDB = this.databaseService.switchDB(dbName);

        if (!isIndexedDBSupported) {
            // remove DB id
            this.facade.removeDBId(`${id}-${categories.join('-')}`);
            return;
        }

        // reset localStorage
        this.localStorageService.removeElementFromItemList(
            dbName,
            Database.discoveryDBs,
            Database.delimiter,
        );

        return this.databaseService.destroyDB(dbName);
    }

    async destroyDBs(): Promise<void[]> {
        const isIndexedDBSupported: boolean = await this.isIndexedDBSupported();
        if (!isIndexedDBSupported) {
            return;
        }
        return this.databaseService.destroyDBs(Database.discoveryDBs, Database.delimiter);
    }

    /** Insights */
    async populate({
        insights,
    }: {
        insights: Insight[];
        fields?: string[];
    }): Promise<(PouchDB.Core.Response | PouchDB.Core.Error)[]> {
        const documents: Insight[] = insights.map((insight: Insight) => ({
            ...insight,
            _id: insight.rankingIndex.toString().padStart(this.MASK_LENGTH, '0'),
        }));

        // await this.databaseService.createIndex(this.pouchDB, { fields });

        const response: (PouchDB.Core.Response | PouchDB.Core.Error)[] =
            await this.pouchDB.bulkDocs(documents);

        return response;
    }

    async retrieve({
        limit,
        sortingOptions,
        filters,
    }: {
        limit?: number;
        sortingOptions?: SortingOptions;
        filters?: Record<string, Filter[] | [number, number]>;
    }): Promise<PouchDB.Find.FindResponse<unknown>> {
        const selector: PouchDB.Find.Selector = { _id: { $gt: null } };
        const sort: (string | Record<string, SortingOrder>)[] = [];

        if (sortingOptions?.field) {
            selector[sortingOptions.field] = { $gt: null };
            sort.push({ [sortingOptions.field]: sortingOptions.order });
        }

        if (filters) {
            for (const [key, value] of Object.entries(filters)) {
                if (key === 'associationIds') {
                    const activeAssociations: string[] = (value as Filter[])
                        .filter((association: Filter) => association.isActive)
                        .map((association: Filter) => association.id);

                    if (activeAssociations?.length > 0) {
                        selector[key] = {
                            $in: activeAssociations,
                        };
                    }
                }

                if (key === 'relationshipTypeValuesAB') {
                    const activeRelationships: string[] = (value as Filter[])
                        .filter((relationship: Filter) => relationship.isActive)
                        .map((relationship: Filter) => relationship.text);

                    if (activeRelationships?.length > 0) {
                        selector[key] = { $in: activeRelationships };
                    }
                }

                if (key === 'relationshipTypeValuesBC') {
                    const activeRelationships: string[] = (value as Filter[])
                        .filter((relationship: Filter) => relationship.isActive)
                        .map((relationship: Filter) => relationship.text);

                    if (activeRelationships?.length > 0) {
                        selector[key] = { $in: activeRelationships };
                    }
                }

                if (key === 'relationshipTypeValuesAC') {
                    const activeRelationships: string[] = (value as Filter[])
                        .filter((relationship: Filter) => relationship.isActive)
                        .map((relationship: Filter) => relationship.text);

                    if (activeRelationships?.length > 0) {
                        selector[key] = { $in: activeRelationships };
                    }
                }

                if (key === 'isRelationshipTypeABPredicted') {
                    const activeRelationshipOrigins: boolean[] = (value as Filter[])
                        .filter((relationshipOrigin: Filter) => relationshipOrigin.isActive)
                        .map(
                            (relationshipOrigin: Filter) =>
                                relationshipOrigin.id ===
                                `${RelationshipOrigin.predicted}-${CombinatorialDiscoveryRelation.AB}`,
                        );

                    if (activeRelationshipOrigins?.length > 0) {
                        selector[key] = { $in: activeRelationshipOrigins };
                    }
                }

                if (key === 'isRelationshipTypeBCPredicted') {
                    const activeRelationshipOrigins: boolean[] = (value as Filter[])
                        .filter((relationshipOrigin: Filter) => relationshipOrigin.isActive)
                        .map(
                            (relationshipOrigin: Filter) =>
                                relationshipOrigin.id ===
                                `${RelationshipOrigin.predicted}-${CombinatorialDiscoveryRelation.BC}`,
                        );

                    if (activeRelationshipOrigins?.length > 0) {
                        selector[key] = { $in: activeRelationshipOrigins };
                    }
                }

                if (key === 'isRelationshipTypeACPredicted') {
                    const activeRelationshipOrigins: boolean[] = (value as Filter[])
                        .filter((relationshipOrigin: Filter) => relationshipOrigin.isActive)
                        .map(
                            (relationshipOrigin: Filter) =>
                                relationshipOrigin.id ===
                                `${RelationshipOrigin.predicted}-${CombinatorialDiscoveryRelation.AC}`,
                        );

                    if (activeRelationshipOrigins?.length > 0) {
                        selector[key] = { $in: activeRelationshipOrigins };
                    }
                }
            }
        }

        const result: PouchDB.Find.FindResponse<unknown> = await this.pouchDB.find({
            selector,
            sort: sort.length === 0 ? undefined : sort,
            limit,
        });

        return result;
    }

    /** Metadata */
    async createMetadata(metadata: Metadata): Promise<PouchDB.Core.Response> {
        const {
            countPerCategory,
            countPerAssociation,
            countPerAssociationType,
            countPerRelationshipTypeAB,
            countPerRelationshipTypeBC,
            countPerRelationshipTypeAC,
            countPerRelationshipTypeOriginAB,
            countPerRelationshipTypeOriginBC,
            countPerRelationshipTypeOriginAC,
            displaying,
            total,
            newestOccurrence,
            oldestOccurrence,
            totalRelationshipsAB,
            totalRelationshipsAC,
            totalRelationshipsBC,
            errorMessage,
        } = metadata;

        const response: PouchDB.Core.Response = await this.pouchDB.put({
            _id: '_local/metadata',
            countPerCategory,
            countPerAssociation,
            countPerAssociationType,
            countPerRelationshipTypeAB,
            countPerRelationshipTypeBC,
            countPerRelationshipTypeAC,
            countPerRelationshipTypeOriginAB,
            countPerRelationshipTypeOriginBC,
            countPerRelationshipTypeOriginAC,
            displaying,
            total,
            newestOccurrence,
            oldestOccurrence,
            totalRelationshipsAB,
            totalRelationshipsAC,
            totalRelationshipsBC,
            errorMessage,
        });

        return response;
    }

    retrieveMetadata(): Promise<PouchDB.Core.IdMeta & PouchDB.Core.GetMeta & Metadata> {
        return this.pouchDB.get('_local/metadata');
    }
}
