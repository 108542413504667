export enum GPTActionTypes {
    PERFORM_STRUCTURED_OPEN_DISCOVERY_REQUEST = '[GPT][STRUCTURED][OPEN] Perform Structured Open Discovery Request',
    PERFORM_STRUCTURED_OPEN_DISCOVERY_SUCCESS = '[GPT][STRUCTURED][OPEN] Perform Structured Open Discovery Success',
    PERFORM_STRUCTURED_OPEN_DISCOVERY_FAILURE = '[GPT][STRUCTURED][OPEN] Perform Structured Open Discovery Failure',
    PERFORM_STRUCTURED_CLOSED_DISCOVERY_REQUEST = '[GPT][STRUCTURED][CLOSED] Perform Structured Closed Discovery Request',
    PERFORM_STRUCTURED_CLOSED_DISCOVERY_SUCCESS = '[GPT][STRUCTURED][CLOSED] Perform Structured Closed Discovery Success',
    PERFORM_STRUCTURED_CLOSED_DISCOVERY_FAILURE = '[GPT][STRUCTURED][CLOSED] Perform Structured Closed Discovery Failure',
    PERFORM_FREE_QUERY_REQUEST = '[GPT][FREE] Perform Free Query Request',
    PERFORM_FREE_QUERY_SUCCESS = '[GPT][FREE] Perform Free Query Success',
    PERFORM_FREE_QUERY_FAILURE = '[GPT][FREE] Perform Free Query Failure',
    REGENERATE_QUERY_REQUEST = '[GPT][REGENERATE] Regenerate Query Request',
    REGENERATE_QUERY_SUCCESS = '[GPT][REGENERATE] Regenerate Query Success',
    REGENERATE_QUERY_FAILURE = '[GPT][REGENERATE] Regenerate Query Failure',
    DOWNLOAD_SESSION_HISTORY_REQUEST = '[GPT][DOWNLOAD] Download Session History Request',
    DOWNLOAD_SESSION_HISTORY_SUCCESS = '[GPT][DOWNLOAD] Download Session History Success',
    DOWNLOAD_SESSION_HISTORY_FAILURE = '[GPT][DOWNLOAD] Download Session History Failure',
    RESET_SESSION = '[GPT] Reset Session',
    PERFORM_ASSISTANT_QUERY_REQUEST = '[GPT][ASSISTANT] Perform Assistant Query Request',
    PERFORM_ASSISTANT_QUERY_SUCCESS = '[GPT][ASSISTANT] Perform Assistant Query Success',
    PERFORM_ASSISTANT_QUERY_FAILURE = '[GPT][ASSISTANT] Perform Assistant Query Failure',
    GET_ASSISTANT_QUERIES_REQUEST = '[GPT][ASSISTANT] Get Assistant Queries Request',
    GET_ASSISTANT_QUERIES_SUCCESS = '[GPT][ASSISTANT] Get Assistant Queries Success',
    GET_ASSISTANT_QUERIES_FAILURE = '[GPT][ASSISTANT] Get Assistant Queries Failure',
    DELETE_ASSISTANT_QUERIES_REQUEST = '[GPT][ASSISTANT] Delete Assistant Queries Request',
    DELETE_ASSISTANT_QUERIES_SUCCESS = '[GPT][ASSISTANT] Delete Assistant Queries Success',
    DELETE_ASSISTANT_QUERIES_FAILURE = '[GPT][ASSISTANT] Delete Assistant Queries Failure',
    CLEAR_NEXT_ERROR = '[GPT] Clear Next Error',
}
