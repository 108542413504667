import {
    Directive,
    Renderer2,
    ElementRef,
    AfterViewInit,
    Input,
    TemplateRef,
    ViewContainerRef,
} from '@angular/core';

/** Constants */
import { EMPTY_STRING } from '@leap-common/constants/common';

@Directive({
    selector: '[libInteractiveText]',
})
export class InteractiveTextDirective implements AfterViewInit {
    @Input() regex: RegExp;
    @Input() template: TemplateRef<{ match: string }>;

    constructor(
        private elementRef: ElementRef,
        private viewContainerRef: ViewContainerRef,
        private renderer: Renderer2,
    ) {}

    ngAfterViewInit(): void {
        this.initializeInteractiveText();
    }

    initializeInteractiveText(): void {
        const text: string = this.elementRef.nativeElement.innerHTML;
        const textParts: string[] = text.split(this.regex);
        const matches: RegExpMatchArray = text.match(this.regex);

        if (!matches) {
            return;
        }

        // reset content
        this.renderer.setProperty(this.elementRef.nativeElement, 'innerHTML', EMPTY_STRING);

        // add plain or interactive text
        textParts.forEach((textPart: string) => {
            this.renderer.appendChild(
                this.elementRef.nativeElement,
                matches.includes(textPart)
                    ? this.viewContainerRef.createEmbeddedView(this.template, { match: textPart })
                          .rootNodes[0]
                    : this.renderer.createText(textPart),
            );
        });
    }
}
