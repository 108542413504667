import { Injectable } from '@angular/core';

/** Interfaces */
import PaginatedResultsRestApi from '@leap-common/rest-api-interfaces/paginated-results.rest.interface';
import PaginatedResults from '@leap-common/interfaces/paginated-results.interface';
import NotebookRestApi from '../rest-api-interfaces/notebook.rest.interface';
import Notebook from '../interfaces/notebook.interface';
import SortingOrder from '@leap-common/enums/sorting-order.enum';

@Injectable()
export class NotebooksParser {
    constructor() {}

    /** Parses Paginated Notebooks */
    parsePaginatedResults(
        paginatedNotebooks: PaginatedResultsRestApi<NotebookRestApi>,
    ): PaginatedResults<Notebook> {
        return {
            results: paginatedNotebooks.results
                ? this.parseNotebooks(paginatedNotebooks.results)
                : [],
            pageIndex: paginatedNotebooks.pageIndex - 1,
            pageSize: paginatedNotebooks.pageSize,
            total: paginatedNotebooks.total,
        };
    }

    /** Parses BE Notebooks to integrate them on the FE */
    parseNotebooks(notebooks: NotebookRestApi[]): Notebook[] {
        return notebooks.map((notebook: any) => this.parseNotebook(notebook));
    }

    /** Parses NotebookRestApi to Notebook */
    parseNotebook(notebook: NotebookRestApi): Notebook {
        return {
            id: notebook.uuid,
            name: notebook.name,
            url: notebook.notebook_url,
            creationDate: notebook.created_on,
            type: notebook.type,
        };
    }

    /** Serialize Notebooks Sorting Parameter */
    serializeNotebooksOrderByParameter(sortDirection: SortingOrder, sortColumn: string): string {
        const direction: string = sortDirection === SortingOrder.descending ? '-' : '';
        const column: string =
            sortColumn === 'name'
                ? 'name'
                : sortColumn === 'creationDate'
                ? 'created_on'
                : undefined;
        return `${direction}${column}`;
    }
}
