/** third-party imports */
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, iif, of, timer } from 'rxjs';
import { map, switchMap, mergeMap } from 'rxjs/operators';

/** custom imports */
import { environment } from '@environments/leap/environment';
import { CombinatorialDiscoveryParser } from '../parsers/combinatorial-discovery.parser';

/** Interfaces */
import PaginatedInsightsRestApi from '@apps/leap/src/app/shared/rest-api-interfaces/paginated-insights.rest.interface';
import PaginatedInsights from '../interfaces/paginated-insights.interface';
import InsightRestApi from '../rest-api-interfaces/insight.rest.interface';
import InsightsPerCategoryRestApi from '../rest-api-types/insights-per-category.rest.type';
import QueryRestApi from '../rest-api-interfaces/query.rest.interface';
import ResultsRestApi from '@leap-common/rest-api-interfaces/results.rest.interface';

@Injectable()
export class CombinatorialDiscoveryService {
    constructor(private http: HttpClient, private insightsParser: CombinatorialDiscoveryParser) {}

    /**
     * Performs a combinatorial query and gets insights, parses them into the desired format and
     * returns an Observable of PaginatedInsights.
     */
    performDiscovery(categories: string[], term: string): Observable<PaginatedInsights> {
        const serializedQuery: QueryRestApi = this.insightsParser.serializeCombinatorialQuery({
            term,
            category1: categories[0],
            category2: categories[1],
        });

        return this.http
            .post(`${environment.discoveryServerUrl}/chained-query`, serializedQuery)
            .pipe(
                mergeMap(
                    (
                        paginatedInsights: PaginatedInsightsRestApi<
                            InsightRestApi,
                            InsightsPerCategoryRestApi
                        >,
                    ) =>
                        iif(
                            () => paginatedInsights.queryStatus === 'Completed',
                            of(paginatedInsights).pipe(
                                map(
                                    (
                                        paginatedResults: PaginatedInsightsRestApi<
                                            InsightRestApi,
                                            InsightsPerCategoryRestApi
                                        >,
                                    ) =>
                                        this.insightsParser.parsePaginatedResults(paginatedResults),
                                ),
                            ),
                            timer(7000).pipe(
                                switchMap(() => this.performDiscovery(categories, term)),
                            ),
                        ),
                ),
            );
    }

    download(term: string, categories: string[], isGrouped: boolean): Observable<Blob> {
        const grouped: string = isGrouped ? '?grouped=true' : '';

        const serializedPayload: QueryRestApi = this.insightsParser.serializeCombinatorialQuery({
            term,
            category1: categories[0],
            category2: categories[1],
        });
        return this.http.post(
            `${environment.discoveryServerUrl}/chained-query/download${grouped}`,
            serializedPayload,
            {
                headers: new HttpHeaders({
                    Accept: 'text/csv',
                }),
                responseType: 'blob',
            },
        );
    }

    getSearchSuggestions(
        source: string,
        target: string,
        target2: string,
        query: string,
    ): Observable<string[]> {
        return this.http
            .post(`${environment.discoveryServerUrl}/chained-query/search`, {
                sourceUid: source,
                categoryA: target,
                categoryB: target2,
                searchString: query,
            })
            .pipe(map(({ results }: ResultsRestApi<string>) => results));
    }
}
