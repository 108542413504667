import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { TooltipComponent } from './tooltip.component';

@NgModule({
    declarations: [TooltipComponent],
    imports: [NgbTooltipModule, CommonModule],
    exports: [TooltipComponent],
})
export class TooltipModule {}
