/** third-party imports */
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';

/** custom imports */
import { reducers } from '../../index';
import { CategoriesEffects } from './categories.effects';
import { CategoriesFacade } from './categories.facade';
import { CategoriesParser } from './parsers/categories.parser';
import { CategoriesService } from './services/categories.service';

@NgModule({
    imports: [
        CommonModule,
        HttpClientModule,
        StoreModule.forRoot(reducers),
        StoreDevtoolsModule.instrument({ maxAge: 10 }),
        EffectsModule.forRoot([CategoriesEffects]),
    ],
    declarations: [],
    providers: [CategoriesParser, CategoriesService, CategoriesFacade],
})
export class CategoriesStoreModule {}
