import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'filterUniqueObjectValues',
})
export class FilterUniqueObjectValuesPipe implements PipeTransform {
    constructor() {}

    public transform(object: Record<string, string>): string[] {
        if (!object) {
            return;
        }

        return [...new Set(Object.values(object))];
    }
}
