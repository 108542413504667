/** third-party imports */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

/** custom imports */
import { environment } from '@environments/leap/environment';
import { DatabasesParser } from '../parsers/databases.parser';
import DatabaseRestApi from '../rest-api-interfaces/database.rest.interface';
import Database from '../interfaces/database.interface';

@Injectable()
export class DatabasesService {
    constructor(private http: HttpClient, private databasesParser: DatabasesParser) {}

    getDatabases(): Observable<Record<string, Database>> {
        return this.http
            .get(`${environment.metadataServerUrl}/database-origins`)
            .pipe(
                map((databases: Record<string, DatabaseRestApi>) =>
                    this.databasesParser.parseDatabases(databases),
                ),
            );
    }
}
