/** Modules */
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { ForgotPasswordRoutingModule } from './forgot-password-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbAlertModule, NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { DecoratedInputModule } from '@leap-libs/decorated-input/src/public-api';
import { InputModule } from '@leap-libs/input/src/public-api';

/** Services */
import { ErrorHandlerService } from '@leap-common/services/error-handler.service';
import { ForgotPasswordService } from './services/forgot-password.service';
import { ForgotPasswordParser } from './parsers/forgot-password.parser';

/** Components */
import { ForgotPasswordContainerComponent } from './components/containers/forgot-password/forgot-password.container.component';
import { ForgotPasswordFormViewComponent } from './components/views/forgot-password-form/forgot-password-form.view.component';

@NgModule({
    imports: [
        CommonModule,
        ForgotPasswordRoutingModule,
        HttpClientModule,
        FormsModule,
        ReactiveFormsModule,
        NgbAlertModule,
        NgbModalModule,
        DecoratedInputModule,
        InputModule,
    ],
    declarations: [ForgotPasswordContainerComponent, ForgotPasswordFormViewComponent],
    providers: [ErrorHandlerService, ForgotPasswordService, ForgotPasswordParser],
})
export class ForgotPasswordModule {}
