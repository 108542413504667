/** third-party imports */
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';

/** custom imports */
import { reducers } from '../../index';
import { SynonymsEffects } from './synonyms.effects';
import { SynonymsFacade } from './synonyms.facade';
import { SynonymsParser } from './parsers/synonyms.parser';
import { SynonymsService } from './services/synonyms.service';

@NgModule({
    imports: [
        CommonModule,
        HttpClientModule,
        StoreModule.forRoot(reducers),
        StoreDevtoolsModule.instrument({ maxAge: 10 }),
        EffectsModule.forRoot([SynonymsEffects]),
    ],
    declarations: [],
    providers: [SynonymsParser, SynonymsService, SynonymsFacade],
})
export class SynonymsStoreModule {}
