/** Modules */
import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';

/** Guards */
import { AuthGuard } from './guards/auth.guard';
import { NonAuthGuard } from './guards/non-auth.guard';
import { RoleGuard } from './guards/role.guard';
import { AppGuard } from './guards/app.guard';

/** Interceptors */
import { AuthInterceptor } from './interceptors/auth.interceptor';

/** Services */
import { JwtOptionsService } from './services/jwt-options.service';
import { AuthService } from './services/auth.service';
import { RoleService } from './services/role.service';

@NgModule({
    imports: [CommonModule],
    declarations: [],
    exports: [],
})
export class CoreModule {
    static forRoot(): ModuleWithProviders<CoreModule> {
        return {
            ngModule: CoreModule,
            providers: [
                { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
                JwtOptionsService,
                AuthService,
                AuthGuard,
                NonAuthGuard,
                RoleService,
                RoleGuard,
                AppGuard,
            ],
        };
    }
}
