/** third-party imports */
import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { of } from 'rxjs';
import { switchMap, map, catchError } from 'rxjs/operators';

/** custom imports */
import { TermsService } from './services/terms.service';
import { BookmarksService } from '@leap-store/core/src/lib/data/bookmarks/services/bookmarks.service';
import * as actions from './terms.actions';
import TermOverview from './interfaces/term-overview.interface';

@Injectable()
export class TermsEffects {
    constructor(
        private actions$: Actions,
        private termsService: TermsService,
        private bookmarksService: BookmarksService,
    ) {}

    getOverview$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.getOverviewRequest),
            switchMap(({ ids, bookmarkIds }) =>
                (bookmarkIds
                    ? this.bookmarksService.getTermsOverview(bookmarkIds)
                    : this.termsService.getTermsOverview(ids)
                ).pipe(
                    map((terms: TermOverview[]) => actions.getOverviewSuccess({ terms })),
                    catchError((errorResponse: HttpErrorResponse) =>
                        of(actions.getOverviewFailure({ errorResponse })),
                    ),
                ),
            ),
        ),
    );
}
