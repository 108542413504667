/** third-party imports */
import { createReducer, on, Action, ActionReducer } from '@ngrx/store';

/** custom imports */
import * as articlesActions from './articles.actions';
import { ArticlesState } from './articles-state.interface';

export const initialState: ArticlesState = {
    areAssociationArticlesDisplayed: false,
};

const articlesReducer: ActionReducer<ArticlesState, Action> = createReducer(
    initialState,
    on(
        articlesActions.updateAssociationArticlesDisplay,
        (state: ArticlesState, { areDisplayed }: { areDisplayed: boolean }) => ({
            ...state,
            areAssociationArticlesDisplayed: areDisplayed,
        }),
    ),
);

export const reducer = (state: ArticlesState | undefined, action: Action): ArticlesState =>
    articlesReducer(state, action);

// selectors
export const getAssociationArticlesDisplay: (state: ArticlesState) => boolean = (
    state: ArticlesState,
) => state.areAssociationArticlesDisplayed;
