/** third-party imports */
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';

/** custom imports */
import { reducers } from '../index';
import { ArticlesEffects } from './articles.effects';
import { ArticlesFacade } from './articles.facade';
import { ArticlesParser } from './parsers/articles.parser';
import { ArticlesService } from './services/articles.service';

@NgModule({
    imports: [
        CommonModule,
        HttpClientModule,
        StoreModule.forRoot(reducers),
        StoreDevtoolsModule.instrument({ maxAge: 10 }),
        EffectsModule.forRoot([ArticlesEffects]),
    ],
    declarations: [],
    providers: [ArticlesParser, ArticlesService, ArticlesFacade],
})
export class ArticlesStoreModule {}
