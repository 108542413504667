import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LayoutContainerComponent } from './components/containers/layout/layout.container.component';
import { PrivacyPolicyContainerComponent } from './components/containers/privacy-policy/privacy-policy.container.component';
import { TermsOfUseContainerComponent } from './components/containers/terms-of-use/terms-of-use.container.component';

const routes: Routes = [
    {
        path: '',
        component: LayoutContainerComponent,
        children: [
            {
                path: 'privacy-policy',
                component: PrivacyPolicyContainerComponent,
            },
            {
                path: 'terms-of-service',
                component: TermsOfUseContainerComponent,
            },
        ],
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class LegalRoutingModule {}
