/** third-party imports */
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';

/** custom imports */
import { reducers } from '../index';
import { ProjectsEffects } from './projects.effects';
import { ProjectsFacade } from './projects.facade';
import { ProjectsParser } from './parsers/projects.parser';
import { ProjectsService } from './services/projects.service';
import { DiscoveryParser } from '@apps/leap/src/app/shared/parsers/discovery.parser';

@NgModule({
    imports: [
        CommonModule,
        HttpClientModule,
        StoreModule.forRoot(reducers),
        StoreDevtoolsModule.instrument({ maxAge: 10 }),
        EffectsModule.forRoot([ProjectsEffects]),
    ],
    declarations: [],
    providers: [ProjectsParser, ProjectsService, ProjectsFacade, DiscoveryParser],
})
export class ProjectsStoreModule {}
