import { createAction, props } from '@ngrx/store';
import { LayoutActionTypes } from './layout-action-types.enum';

export const updateFooterCopyrightDisplay = createAction(
    LayoutActionTypes.UPDATE_FOOTER_COPYRIGHT_DISPLAY,
    props<{ isDisplayed: boolean }>(),
);

export const updateTermsOfUseAcceptanceStatus = createAction(
    LayoutActionTypes.UPDATE_TERMS_OF_USE_ACCEPTANCE_STATUS,
    props<{ isEnabled: boolean }>(),
);
