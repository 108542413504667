/** third-party libraries */
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

/** custom imports */
import { environment } from '@environments/leap/environment';
import { EMPTY_STRING, NO_OP_CALLBACK } from '@leap-common/constants/common';
import { HttpParamsService } from '@leap-common/services/http-params.service';
import { IngredientManagementParser } from '../parsers/ingredient-management.parser';
import { IngredientViewerParser } from '../../ingredient-viewer/parsers/ingredient-viewer.parser';
import PaginatedResultsRestApi from '@leap-common/rest-api-interfaces/paginated-results.rest.interface';
import PaginatedResults from '@leap-common/interfaces/paginated-results.interface';
import SortingOptions from '@leap-common/interfaces/sorting-options.interface';
import File from '@leap-store/core/src/lib/data/files/interfaces/file.interface';
import IngredientRestApi from '../../ingredient-viewer/rest-api-interfaces/ingredient.rest.interface';
import FileUploadResultRestApi from '../rest-api-interfaces/file-upload-result.rest.interface';
import FileUploadResult from '../interfaces/file-upload-result.interface';
import Ingredient from '../../ingredient-viewer/interfaces/ingredient.interface';

@Injectable()
export class IngredientManagementService {
    constructor(
        private http: HttpClient,
        private httpParamsService: HttpParamsService,
        private ingredientManagementParser: IngredientManagementParser,
        private ingredientViewerParser: IngredientViewerParser,
    ) {}

    uploadFile(file: File): Observable<FileUploadResult> {
        const formData: FormData = new FormData();
        formData.append('ingredient', file as Blob);

        return this.http
            .post(`${environment.ingredientAnalyzerServerUrl}/ingredients/`, formData)
            .pipe(
                map((result: FileUploadResultRestApi) =>
                    this.ingredientManagementParser.parseFileUploadResult(result),
                ),
            );
    }

    getIngredients({
        pageSize,
        pageIndex,
        searchQuery,
        sortingOptions,
        ids,
    }: {
        pageSize?: number;
        pageIndex?: number;
        searchQuery?: string;
        sortingOptions?: SortingOptions;
        ids?: string[];
    }): Observable<PaginatedResults<Ingredient>> {
        const serializedSortingField: string =
            this.ingredientManagementParser.serializeSortingField(sortingOptions?.field);
        const params: HttpParams = this.httpParamsService.createHttpParams({
            pageSize,
            pageIndex: pageIndex + 1 || undefined,
            name: searchQuery,
            sortingBy: serializedSortingField,
            sortingOrder: sortingOptions?.order,
            includeNotes: true,
            uuids: ids,
        });

        return this.http
            .get(`${environment.ingredientAnalyzerServerUrl}/ingredients/`, { params })
            .pipe(
                map((paginatedIngredients: PaginatedResultsRestApi<IngredientRestApi>) =>
                    this.ingredientViewerParser.parsePaginatedIngredients(
                        paginatedIngredients,
                        ids,
                    ),
                ),
            );
    }

    updateName(id: string, name: string): Observable<{ ingredient: Ingredient }> {
        return this.http
            .patch(`${environment.ingredientAnalyzerServerUrl}/ingredients/${id}/`, {
                name,
            })
            .pipe(
                map(({ results }: { results: IngredientRestApi }) => ({
                    ingredient: this.ingredientViewerParser.parseIngredient(results),
                })),
            );
    }

    updateNotes(id: string, notes: string): Observable<void> {
        return this.http
            .patch(`${environment.ingredientAnalyzerServerUrl}/ingredients/${id}/notes/`, {
                note: notes || EMPTY_STRING,
            })
            .pipe(map(NO_OP_CALLBACK));
    }

    downloadIngredient(id: string): Observable<Blob> {
        return this.http.get(
            `${environment.ingredientAnalyzerServerUrl}/ingredients/${id}/download/`,
            { responseType: 'blob' },
        );
    }

    deleteIngredient(id: string): Observable<void> {
        return this.http
            .delete(`${environment.ingredientAnalyzerServerUrl}/ingredients/${id}/`)
            .pipe(map(NO_OP_CALLBACK));
    }

    downloadTemplate(): Observable<Blob> {
        return this.http.get(`${environment.ingredientAnalyzerServerUrl}/ingredients/template/`, {
            responseType: 'blob',
        });
    }

    downloadInstructions(): Observable<Blob> {
        return this.http.get(
            `${environment.ingredientAnalyzerServerUrl}/ingredients/instructions/`,
            { responseType: 'blob' },
        );
    }
}
