/** third-party imports */
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';

/** custom imports */
import { reducers } from '../../index';
import { PathsEffects } from './paths.effects';
import { PathsFacade } from './paths.facade';
import { PathsParser } from './parsers/paths.parser';
import { PathsService } from './services/paths.service';

@NgModule({
    imports: [
        CommonModule,
        HttpClientModule,
        StoreModule.forRoot(reducers),
        StoreDevtoolsModule.instrument({ maxAge: 10 }),
        EffectsModule.forRoot([PathsEffects]),
    ],
    declarations: [],
    providers: [PathsParser, PathsService, PathsFacade],
})
export class PathsStoreModule {}
