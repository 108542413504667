import { createAction, props } from '@ngrx/store';
import { HttpErrorResponse } from '@angular/common/http';
import { DefinitionsActionTypes } from './definitions-action-types.enum';
import Definitions from './types/definitions.type';
import BookmarkIds from '@leap-store/core/src/lib/data/bookmarks/interfaces/bookmark-ids.interface';

export const getDefinitionsRequest = createAction(
    DefinitionsActionTypes.GET_DEFINITIONS_REQUEST,
    props<{
        ids: string[];
        bookmarkIds: BookmarkIds;
    }>(),
);

export const getDefinitionsSuccess = createAction(
    DefinitionsActionTypes.GET_DEFINITIONS_SUCCESS,
    props<{
        definitions: Definitions;
    }>(),
);

export const getDefinitionsFailure = createAction(
    DefinitionsActionTypes.GET_DEFINITIONS_FAILURE,
    props<{ errorResponse: HttpErrorResponse }>(),
);

export const clearDefinitions = createAction(DefinitionsActionTypes.CLEAR_DEFINITIONS);

export const clearNextError = createAction(DefinitionsActionTypes.CLEAR_NEXT_ERROR);
