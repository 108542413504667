/** third-party imports */
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { Actions, createEffect, ofType } from '@ngrx/effects';

/** custom imports */
import { ReportService } from './services/report.service';
import { HttpErrorResponse } from '@angular/common/http';
import * as actions from './report.actions';
import ReportPayload from './interfaces/report-payload.interface';
import Background from './interfaces/background.interface';
import Statistics from './interfaces/statistics.interface';
import TopResults from './types/top-results.type';
import ReferenceSummarizationPerCategory from './interfaces/reference-summarization-per-category.interface';
import Reference from './interfaces/reference.interface';
import Discovery from '@apps/leap/src/app/shared/enums/discovery.enum';

@Injectable()
export class ReportEffects {
    constructor(private actions$: Actions, private reportService: ReportService) {}

    getIntroduction$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.getIntroductionRequest),
            switchMap(({ payload }: { payload: ReportPayload }) =>
                this.reportService.getIntroduction(payload).pipe(
                    map((introduction: string) => actions.getIntroductionSuccess({ introduction })),
                    catchError((errorResponse: HttpErrorResponse) =>
                        of(actions.getIntroductionFailure({ errorResponse })),
                    ),
                ),
            ),
        ),
    );

    getBackground$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.getBackgroundRequest),
            switchMap(({ payload }: { payload: ReportPayload }) =>
                this.reportService.getBackground(payload).pipe(
                    map((background: Background) => actions.getBackgroundSuccess({ background })),
                    catchError((errorResponse: HttpErrorResponse) =>
                        of(actions.getBackgroundFailure({ errorResponse })),
                    ),
                ),
            ),
        ),
    );

    getStatistics$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.getStatisticsRequest),
            switchMap(({ payload }: { payload: ReportPayload }) =>
                this.reportService.getStatistics(payload).pipe(
                    map((statistics: Statistics) => actions.getStatisticsSuccess({ statistics })),
                    catchError((errorResponse: HttpErrorResponse) =>
                        of(actions.getStatisticsFailure({ errorResponse })),
                    ),
                ),
            ),
        ),
    );

    getTopResults$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.getTopResultsRequest),
            switchMap(
                ({
                    payload,
                    discovery,
                    studyTypesOrder,
                }: {
                    payload: ReportPayload;
                    discovery: Discovery;
                    studyTypesOrder: string[];
                }) =>
                    this.reportService.getTopResults(payload, discovery, studyTypesOrder).pipe(
                        map((topResults: TopResults) =>
                            actions.getTopResultsSuccess({ topResults }),
                        ),
                        catchError((errorResponse: HttpErrorResponse) =>
                            of(actions.getTopResultsFailure({ errorResponse })),
                        ),
                    ),
            ),
        ),
    );

    getReferencesSummarization$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.getReferencesSummarizationRequest),
            switchMap(({ payload }: { payload: ReportPayload }) =>
                this.reportService.getReferencesSummarization(payload).pipe(
                    map((referencesSummarization: ReferenceSummarizationPerCategory[]) =>
                        actions.getReferencesSummarizationSuccess({ referencesSummarization }),
                    ),
                    catchError((errorResponse: HttpErrorResponse) =>
                        of(actions.getReferencesSummarizationFailure({ errorResponse })),
                    ),
                ),
            ),
        ),
    );

    getReferences$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.getReferencesRequest),
            switchMap(({ payload }: { payload: ReportPayload }) =>
                this.reportService.getReferences(payload).pipe(
                    map((references: Reference[]) => actions.getReferencesSuccess({ references })),
                    catchError((errorResponse: HttpErrorResponse) =>
                        of(actions.getReferencesFailure({ errorResponse })),
                    ),
                ),
            ),
        ),
    );

    downloadReport$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.downloadRequest),
            switchMap(({ payload, format }) =>
                this.reportService.download(payload, format).pipe(
                    map((blob: Blob) => actions.downloadSuccess({ blob })),
                    catchError((errorResponse: HttpErrorResponse) =>
                        of(actions.downloadFailure({ errorResponse })),
                    ),
                ),
            ),
        ),
    );
}
