/** third-party imports */
import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';

/** custom imports */
import ErrorResponse from '@leap-common/interfaces/error-response.interface';
import Id from './types/id.type';
import Suggestion from '@apps/leap/src/app/shared/interfaces/suggestion.interface';
import { SuggestionsState } from './suggestions-state.interface';
import { getSuggestionsRequest, clearNextError } from './suggestions.actions';
import { getSuggestions, getErrors, getLoading, getLoaded } from './suggestions.selectors';

@Injectable()
export class SuggestionsFacade {
    errors$: Observable<ErrorResponse[]> = this.store.pipe(select(getErrors));
    loading$: Observable<boolean> = this.store.pipe(select(getLoading));
    loaded$: Observable<boolean> = this.store.pipe(select(getLoaded));

    suggestions$ = (id: string): Observable<Suggestion[]> =>
        this.store.pipe(select(getSuggestions, { id }));

    constructor(private store: Store<SuggestionsState>) {}

    getSuggestions(id: Id, query: string, limit: number = 10): void {
        this.store.dispatch(getSuggestionsRequest({ id, query, limit }));
    }

    clearNextError(): void {
        this.store.dispatch(clearNextError());
    }
}
