import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';

/** custom imports */
import { reducers } from '..';
import { GPTEffects } from './gpt.effects';
import { GPTFacade } from './gpt.facade';
import { GPTParser } from './parsers/gpt.parser';
import { GPTService } from './services/gpt.service';

@NgModule({
    imports: [
        CommonModule,
        HttpClientModule,
        StoreModule.forRoot(reducers),
        StoreDevtoolsModule.instrument({ maxAge: 10 }),
        EffectsModule.forRoot([GPTEffects]),
    ],
    declarations: [],
    providers: [GPTParser, GPTService, GPTFacade],
})
export class GPTStoreModule {}
