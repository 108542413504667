/** third-party imports */
import { createReducer, on, Action, ActionReducer } from '@ngrx/store';
import { HttpErrorResponse } from '@angular/common/http';

/** custom imports */
import * as notebookServersActions from './notebook-servers.actions';
import ErrorResponse from '@leap-common/interfaces/error-response.interface';
import { NotebookServersState } from './notebook-servers-state.interface';

export const initialState: NotebookServersState = {
    error: [],
    loading: false,
    loaded: false,
    connections: 0,
    isConnectionOpen: false,
    isConnectionClosed: false,
};

const notebookServersReducer: ActionReducer<NotebookServersState, Action> = createReducer(
    initialState,
    on(notebookServersActions.getNotebookServersRequest, (state: NotebookServersState) => ({
        ...state,
        connections: false,
        loading: true,
        loaded: false,
    })),
    on(
        notebookServersActions.getNotebookServersSuccess,
        (state: NotebookServersState, { servers }) => {
            const openConnections: number = Object.entries(servers).length;
            return {
                ...state,
                loading: true,
                loaded: false,
                connections: openConnections,
                isConnectionOpen: openConnections > 0,
            };
        },
    ),
    on(
        notebookServersActions.getNotebookServersFailure,
        (state: NotebookServersState, { errorResponse }: { errorResponse: HttpErrorResponse }) => ({
            ...state,
            error: [...state.error, errorResponse.error],
            loading: false,
            loaded: false,
        }),
    ),
    on(notebookServersActions.createNotebookServerSuccess, (state: NotebookServersState) => ({
        ...state,
        loaded: true,
        loading: false,
        connections:
            typeof state.connections === 'number' ? state.connections + 1 : state.connections,
        isConnectionOpen: true,
    })),
    on(
        notebookServersActions.createNotebookServerFailure,
        (state: NotebookServersState, { errorResponse }: { errorResponse: HttpErrorResponse }) => ({
            ...state,
            error: [...state.error, errorResponse.error],
        }),
    ),
    on(notebookServersActions.deleteNotebookServerSuccess, (state: NotebookServersState) => ({
        ...state,
        connections:
            typeof state.connections === 'number' ? state.connections-- : state.connections,
        isConnectionClosed: true,
    })),
    on(
        notebookServersActions.deleteNotebookServerFailure,
        (state: NotebookServersState, { errorResponse }: { errorResponse: HttpErrorResponse }) => ({
            ...state,
            error: [...state.error, errorResponse.error],
        }),
    ),
    on(notebookServersActions.clearNextError, (state: NotebookServersState) => ({
        ...state,
        error: state.error.slice(1),
    })),
);

export const reducer = (
    state: NotebookServersState | undefined,
    action: Action,
): NotebookServersState => notebookServersReducer(state, action);

// selectors
export const getError: (state: NotebookServersState) => ErrorResponse[] = (
    state: NotebookServersState,
) => state.error;
export const getLoading: (state: NotebookServersState) => boolean = (state: NotebookServersState) =>
    state.loading;
export const getLoaded: (state: NotebookServersState) => boolean = (state: NotebookServersState) =>
    state.loaded;
export const getConnections: (state: NotebookServersState) => number | boolean = (
    state: NotebookServersState,
) => state.connections;
export const getIsConnectionOpen: (state: NotebookServersState) => boolean = (
    state: NotebookServersState,
) => state.isConnectionOpen;
export const getIsConnectionClosed: (state: NotebookServersState) => boolean = (
    state: NotebookServersState,
) => state.isConnectionClosed;
