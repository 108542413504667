/** third-party imports */
import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';

/** custom imports */
import ErrorResponse from '@leap-common/interfaces/error-response.interface';
import { MetapathsState } from './metapaths-state.interface';
import { getMetapathsRequest, clearNextError } from './metapaths.actions';
import {
    getMetapaths,
    getEnhancedMetapaths,
    getParentInsightId,
    getErrors,
    getLoading,
    getLoaded,
    getPageIndex,
    getPageSize,
    getTotal,
} from './metapaths.selectors';
import Metapath from './interfaces/metapath.interface';
import EnhancedMetapaths from './interfaces/enhanced-metapaths';
import { createDistinctUntilObjectChanged } from '@leap-common/utilities/helpers';

@Injectable()
export class MetapathsFacade {
    metapaths$: Observable<Metapath[]> = this.store.pipe(select(getMetapaths));
    enhancedMetapaths$: Observable<EnhancedMetapaths> = this.store.pipe(
        select(getEnhancedMetapaths),
        createDistinctUntilObjectChanged<EnhancedMetapaths>(),
    );
    parentInsightId$: Observable<string> = this.store.pipe(select(getParentInsightId));
    errors$: Observable<ErrorResponse[]> = this.store.pipe(select(getErrors));
    loading$: Observable<boolean> = this.store.pipe(select(getLoading));
    loaded$: Observable<boolean> = this.store.pipe(select(getLoaded));
    pageSize$: Observable<number> = this.store.pipe(select(getPageSize));
    pageIndex$: Observable<number> = this.store.pipe(select(getPageIndex));
    total$: Observable<number> = this.store.pipe(select(getTotal));

    constructor(private store: Store<MetapathsState>) {}

    getMetapaths(
        parentInsightId: string,
        sourceId: string,
        targetId: string,
        sourceCategories: string[],
        targetCategories: string[],
        sourceSubcategories: string[],
        targetSubcategories: string[],
        pageSize: number,
        pageIndex: number,
    ): void {
        this.store.dispatch(
            getMetapathsRequest({
                parentInsightId,
                sourceId,
                targetId,
                sourceCategories,
                targetCategories,
                sourceSubcategories,
                targetSubcategories,
                pageSize,
                pageIndex,
            }),
        );
    }

    clearNextError(): void {
        this.store.dispatch(clearNextError());
    }
}
