import { Injectable } from '@angular/core';
import { AMINO_ACIDS_ORDER } from '../constants/ingredient-comparison';
import MolecularWeights from '../rest-api-interfaces/molecular-weights.rest.interface';
import MultiLineChartDataItem from '@leap-libs/charts/src/lib/interfaces/multi-line-chart-data-item.interface';
import CoordinateRestApi from '../rest-api-interfaces/coordinate.rest.interface';
import AminoAcidsRestApi from '../rest-api-interfaces/amino-acids.rest.interface';
import StatisticsItem from '../../report/interfaces/statistics-item.interface';
import Bioactivity from '../interfaces/bioactivity.interface';

@Injectable()
export class IngredientComparisonParser {
    constructor() {}

    parseBioactivities(results: Bioactivity[]): Bioactivity[] {
        const hasNullAttributes = results.some((item) =>
            Object.values(item.attributes).includes(null),
        );
        return hasNullAttributes ? [] : results;
    }

    parseMolecularWeights(molecularWeights: MolecularWeights[]): MultiLineChartDataItem[] {
        return molecularWeights.map((molecularWeight: MolecularWeights) =>
            this.parseMolecularWeight(molecularWeight),
        );
    }

    parseMolecularWeight(molecularWeight: MolecularWeights): MultiLineChartDataItem {
        return {
            name: molecularWeight.name,
            points: molecularWeight.coordinates.map(({ x, y }: CoordinateRestApi) => ({
                xAxisValue: x,
                yAxisValue: y,
            })),
        };
    }

    parseAminoAcids(results: AminoAcidsRestApi[]): Record<string, StatisticsItem[]> {
        return results.reduce(
            (aminoAcidData: Record<string, StatisticsItem[]>, result: AminoAcidsRestApi) => {
                // For each ingredient, map its concentrations to an array of { name, value } and order them
                aminoAcidData[result.name] = AMINO_ACIDS_ORDER.filter(
                    (key: string) => key in result.concentrations,
                ).map((key: string) => ({
                    name: key,
                    value: result.concentrations[key],
                }));

                return aminoAcidData;
            },
            {} as Record<string, StatisticsItem[]>,
        );
    }
}
