export enum BookmarksActionTypes {
    GET_BOOKMARKS_REQUEST = '[BOOKMARKS] Get Bookmarks Request',
    GET_BOOKMARKS_SUCCESS = '[BOOKMARKS] Get Bookmarks Success',
    GET_BOOKMARKS_FAILURE = '[BOOKMARKS] Get Bookmarks Failure',
    CLEAR_BOOKMARKS = '[BOOKMARKS] Clear Bookmarks',
    CREATE_ENTITY_BOOKMARKS_REQUEST = '[BOOKMARKS] Create Entity Bookmarks Request',
    CREATE_ENTITY_BOOKMARKS_SUCCESS = '[BOOKMARKS] Create Entity Bookmarks Success',
    CREATE_ENTITY_BOOKMARKS_FAILURE = '[BOOKMARKS] Create Entity Bookmarks Failure',
    CREATE_ASSOCIATION_OPEN_BOOKMARKS_REQUEST = '[BOOKMARKS] Create Association Open Bookmarks Request',
    CREATE_ASSOCIATION_OPEN_BOOKMARKS_SUCCESS = '[BOOKMARKS] Create Association Open Bookmarks Success',
    CREATE_ASSOCIATION_OPEN_BOOKMARKS_FAILURE = '[BOOKMARKS] Create Association Open Bookmarks Failure',
    CREATE_ASSOCIATION_CLOSED_BOOKMARKS_REQUEST = '[BOOKMARKS] Create Association Closed Bookmarks Request',
    CREATE_ASSOCIATION_CLOSED_BOOKMARKS_SUCCESS = '[BOOKMARKS] Create Association Closed Bookmarks Success',
    CREATE_ASSOCIATION_CLOSED_BOOKMARKS_FAILURE = '[BOOKMARKS] Create Association Closed Bookmarks Failure',
    CREATE_ARTICLE_BOOKMARKS_REQUEST = '[BOOKMARKS] Create Article Bookmarks Request',
    CREATE_ARTICLE_BOOKMARKS_SUCCESS = '[BOOKMARKS] Create Article Bookmarks Success',
    CREATE_ARTICLE_BOOKMARKS_FAILURE = '[BOOKMARKS] Create Article Bookmarks Failure',
    UPLOAD_ARTICLE_REQUEST = '[BOOKMARKS] Upload Article Request',
    UPLOAD_ARTICLE_SUCCESS = '[BOOKMARKS] Upload Article Success',
    UPLOAD_ARTICLE_FAILURE = '[BOOKMARKS] Upload Article Failure',
    UPDATE_NOTES_REQUEST = '[BOOKMARKS] Update Notes Request',
    UPDATE_NOTES_SUCCESS = '[BOOKMARKS] Update Notes Success',
    UPDATE_NOTES_FAILURE = '[BOOKMARKS] Update Notes Failure',
    DOWNLOAD_ARTICLE_BOOKMARKS_REQUEST = '[BOOKMARKS] Download Article Bookmarks Request',
    DOWNLOAD_ARTICLE_BOOKMARKS_SUCCESS = '[BOOKMARKS] Download Article Bookmarks Success',
    DOWNLOAD_ARTICLE_BOOKMARKS_FAILURE = '[BOOKMARKS] Download Article Bookmarks Failure',
    DELETE_BOOKMARK_REQUEST = '[BOOKMARKS] Delete Bookmark Request',
    DELETE_BOOKMARK_SUCCESS = '[BOOKMARKS] Delete Bookmark Success',
    DELETE_BOOKMARK_FAILURE = '[BOOKMARKS] Delete Bookmark Failure',
    CLEAR_NEXT_SUCCESS = '[BOOKMARKS] Clear Next Success',
    CLEAR_NEXT_ERROR = '[BOOKMARKS] Clear Next Error',
    CLEAR_SHOULD_FETCH = '[BOOKMARKS] Clear Should Fetch',
}
