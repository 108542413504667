import { createSelector, MemoizedSelector, DefaultProjectorFn } from '@ngrx/store';
import { DataState } from '../data-state.interface';
import { getDataState } from './../index';
import { UsersState } from './users-state.interface';
import * as fromUsers from './users.reducer';
import ErrorResponse from '@leap-common/interfaces/error-response.interface';
import User from '@leap-store/core/src/lib/data/users/interfaces/user.interface';

export const getUsersState: MemoizedSelector<
    object,
    UsersState,
    DefaultProjectorFn<UsersState>
> = createSelector(getDataState, (state: DataState) => state.users);

export const getErrors: MemoizedSelector<
    object,
    ErrorResponse[],
    DefaultProjectorFn<ErrorResponse[]>
> = createSelector(getUsersState, fromUsers.getErrors);

export const getSuccess: MemoizedSelector<
    object,
    User[],
    DefaultProjectorFn<User[]>
> = createSelector(getUsersState, fromUsers.getSuccess);

export const getLoading: MemoizedSelector<
    object,
    boolean,
    DefaultProjectorFn<boolean>
> = createSelector(getUsersState, fromUsers.getLoading);

export const getLoaded: MemoizedSelector<
    object,
    boolean,
    DefaultProjectorFn<boolean>
> = createSelector(getUsersState, fromUsers.getLoaded);

export const getEntities: MemoizedSelector<
    object,
    User[],
    DefaultProjectorFn<User[]>
> = createSelector(getUsersState, fromUsers.getEntities);

export const getPageIndex = createSelector(getUsersState, fromUsers.getPageIndex);
export const getPageSize = createSelector(getUsersState, fromUsers.getPageSize);
export const getTotal = createSelector(getUsersState, fromUsers.getTotal);
export const getSortDirection = createSelector(getUsersState, fromUsers.getSortDirection);
export const getSortColumn = createSelector(getUsersState, fromUsers.getSortColumn);
export const getUpdatePendingItems = createSelector(getUsersState, fromUsers.getUpdatePendingItems);
export const getDeletePendingItems = createSelector(getUsersState, fromUsers.getDeletePendingItems);
export const getEnhancedUsers = createSelector(
    getEntities,
    getUpdatePendingItems,
    getDeletePendingItems,
    (users, updatePendingItems, deletePendingItems) =>
        users.map((user: User) => ({
            ...user,
            updating: updatePendingItems.includes(user.id),
            deleting: deletePendingItems.includes(user.id),
        })),
);
export const getShouldFetchUsers = createSelector(getUsersState, fromUsers.getShouldFetchUsers);
