import { Injectable } from '@angular/core';
import JwtRestApi from '../rest-api-interfaces/jwt.rest.interface';
import Jwt from '../interfaces/jwt.interface';

@Injectable()
export class JwtParser {
    constructor() {}

    /** Parses jwt related BE data to integrate them on the FE */
    parseJwt(jwt: JwtRestApi): Jwt {
        return {
            accessToken: jwt.accessToken,
            refreshToken: jwt.refreshToken,
        };
    }
}
