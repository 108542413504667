/** third-party imports */
import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';

/** custom imports */
import ErrorResponse from '@leap-common/interfaces/error-response.interface';
import { PathsState } from './paths-state.interface';
import { getPathsRequest, downloadPathsRequest, clearNextError } from './paths.actions';
import {
    getPaths,
    getEnhancedPaths,
    getParentInsightId,
    getParentMetapathIndex,
    getErrors,
    getLoading,
    getLoaded,
    getPageIndex,
    getPageSize,
    getTotal,
    getSortingOrder,
    getBlob,
    getFilename,
} from './paths.selectors';
import ExplorationPath from './interfaces/exploration-path.interface';
import EnhancedPaths from './interfaces/enhanced-paths';
import MetapathBaseExplorationPath from '@leap-store/core/src/lib/data/metapaths/interfaces/metapath-base-exploration-path.interface';
import SortingOrder from '@leap-common/enums/sorting-order.enum';
import { createDistinctUntilObjectChanged } from '@leap-common/utilities/helpers';

@Injectable()
export class PathsFacade {
    paths$: Observable<ExplorationPath[]> = this.store.pipe(select(getPaths));
    enhancedPaths$: Observable<EnhancedPaths> = this.store.pipe(
        select(getEnhancedPaths),
        createDistinctUntilObjectChanged<EnhancedPaths>(),
    );
    parentInsightId$: Observable<string> = this.store.pipe(select(getParentInsightId));
    parentMetapathIndex$: Observable<number> = this.store.pipe(select(getParentMetapathIndex));
    errors$: Observable<ErrorResponse[]> = this.store.pipe(select(getErrors));
    loading$: Observable<boolean> = this.store.pipe(select(getLoading));
    loaded$: Observable<boolean> = this.store.pipe(select(getLoaded));
    pageSize$: Observable<number> = this.store.pipe(select(getPageSize));
    pageIndex$: Observable<number> = this.store.pipe(select(getPageIndex));
    total$: Observable<number> = this.store.pipe(select(getTotal));
    sortingOrder$: Observable<SortingOrder> = this.store.pipe(select(getSortingOrder));
    blob$: Observable<Blob> = this.store.pipe(select(getBlob));
    filename$: Observable<string> = this.store.pipe(select(getFilename));

    constructor(private store: Store<PathsState>) {}

    getPaths(
        parentInsightId: string,
        parentMetapathIndex: number,
        sourceId: string,
        targetId: string,
        metapathBaseExplorationPath: MetapathBaseExplorationPath,
        pageSize: number,
        pageIndex: number,
        sortingOrder: SortingOrder,
    ): void {
        this.store.dispatch(
            getPathsRequest({
                parentInsightId,
                parentMetapathIndex,
                sourceId,
                targetId,
                metapathBaseExplorationPath,
                pageSize,
                pageIndex,
                sortingOrder,
            }),
        );
    }

    downloadPaths(
        filename: string,
        sourceId: string,
        targetId: string,
        metapathBaseExplorationPath: MetapathBaseExplorationPath,
        sortingOrder: SortingOrder,
    ): void {
        this.store.dispatch(
            downloadPathsRequest({
                filename,
                sourceId,
                targetId,
                metapathBaseExplorationPath,
                sortingOrder,
            }),
        );
    }

    clearNextError(): void {
        this.store.dispatch(clearNextError());
    }
}
