/** third-party imports */
import { createAction, props } from '@ngrx/store';
import { HttpErrorResponse } from '@angular/common/http';

/** custom imports */
import { ConversationalSearchActionTypes } from './conversational-search-action-types.enum';
import SearchQuery from './interfaces/search-query.interface';

export const performQueryRequest = createAction(
    ConversationalSearchActionTypes.PERFORM_QUERY_REQUEST,
    props<{ query: string; tabId: string; sessionId: string }>(),
);

export const performQuerySuccess = createAction(
    ConversationalSearchActionTypes.PERFORM_QUERY_SUCCESS,
    props<{ query: SearchQuery; tabId: string }>(),
);

export const performQueryFailure = createAction(
    ConversationalSearchActionTypes.PERFORM_QUERY_FAILURE,
    props<{ errorResponse: HttpErrorResponse; tabId: string }>(),
);

export const initializeSession = createAction(
    ConversationalSearchActionTypes.INITIALIZE_SESSION,
    props<{ tabId: string; hasInitialText: boolean }>(),
);

export const deleteSession = createAction(
    ConversationalSearchActionTypes.DELETE_SESSION,
    props<{ tabId: string }>(),
);

export const clearNextError = createAction(ConversationalSearchActionTypes.CLEAR_NEXT_ERROR);
