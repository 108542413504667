/** third-party imports */
import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { of } from 'rxjs';
import { switchMap, map, catchError } from 'rxjs/operators';

/** custom imports */
import { DefinitionsService } from './services/definitions.service';
import { BookmarksService } from '@leap-store/core/src/lib/data/bookmarks/services/bookmarks.service';
import * as actions from './definitions.actions';
import Definitions from './types/definitions.type';

@Injectable()
export class DefinitionsEffects {
    constructor(
        private actions$: Actions,
        private definitionsService: DefinitionsService,
        private bookmarksService: BookmarksService,
    ) {}

    getDefinitions$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.getDefinitionsRequest),
            switchMap(({ ids, bookmarkIds }) =>
                (bookmarkIds
                    ? this.bookmarksService.getDefinitions(bookmarkIds)
                    : this.definitionsService.getDefinitions(ids)
                ).pipe(
                    map((definitions: Definitions) =>
                        actions.getDefinitionsSuccess({ definitions }),
                    ),
                    catchError((errorResponse: HttpErrorResponse) =>
                        of(actions.getDefinitionsFailure({ errorResponse })),
                    ),
                ),
            ),
        ),
    );
}
