import { NgModule } from '@angular/core';
import { MainMenuComponent } from './main-menu.component';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { HelperMenuModule } from '@leap-libs/helper-menu/src/public-api';
import { TooltipModule } from '@leap-libs/tooltip/src/public-api';

@NgModule({
    declarations: [MainMenuComponent],
    imports: [CommonModule, RouterModule, HelperMenuModule, TooltipModule],
    exports: [MainMenuComponent],
})
export class MainMenuModule {}
