import { NgModule } from '@angular/core';
import { DatabaseService } from './common/database.service';
import { LocalStorageService } from './common/local-storage.service';
import { OpenDiscoveryInsightsService } from './open-discovery-insights/service';
import { OpenDiscoveryInsightsFacade } from './open-discovery-insights/facade';
import { ClosedDiscoveryInsightsService } from './closed-discovery-insights/service';
import { ClosedDiscoveryInsightsFacade } from './closed-discovery-insights/facade';
import { CombinatorialDiscoveryInsightsService } from './combinatorial-discovery-insights/service';
import { CombinatorialDiscoveryInsightsFacade } from './combinatorial-discovery-insights/facade';

@NgModule({
    declarations: [],
    imports: [],
    exports: [],
    providers: [
        DatabaseService,
        LocalStorageService,
        OpenDiscoveryInsightsService,
        OpenDiscoveryInsightsFacade,
        ClosedDiscoveryInsightsService,
        ClosedDiscoveryInsightsFacade,
        CombinatorialDiscoveryInsightsService,
        CombinatorialDiscoveryInsightsFacade,
    ],
})
export class PouchDBStoreModule {}
