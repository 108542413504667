import { createReducer, on, Action, ActionReducer } from '@ngrx/store';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { HttpErrorResponse } from '@angular/common/http';
import User from '@leap-store/core/src/lib/data/users/interfaces/user.interface';
import ErrorResponse from '@leap-common/interfaces/error-response.interface';
import { UsersState } from './users-state.interface';
import * as usersActions from './users.actions';
import PaginatedResults from '@leap-common/interfaces/paginated-results.interface';
import SortingOrder from '@leap-common/enums/sorting-order.enum';

export const adapter: EntityAdapter<User> = createEntityAdapter<User>();
export const initialState: UsersState = adapter.getInitialState({
    errors: [],
    success: [],
    loading: false,
    loaded: false,
    pageIndex: 0,
    pageSize: 0,
    total: 0,
    sortDirection: SortingOrder.descending,
    sortColumn: 'createdAt',
    updatePendingItems: [],
    deletePendingItems: [],
    shouldFetchUsers: false,
});

const usersReducer: ActionReducer<UsersState, Action> = createReducer(
    initialState,
    on(
        usersActions.createUserSuccess,
        (state: UsersState, { createdUser }: { createdUser: User }) =>
            adapter.addOne(createdUser, {
                ...state,
                success: [
                    ...state.success,
                    {
                        ...createdUser,
                        message: `The user ${createdUser.email} created successfully!`,
                    },
                ],
            }),
    ),
    on(
        usersActions.createUserFailure,
        (state: UsersState, { errorResponse }: { errorResponse: HttpErrorResponse }) => ({
            ...state,
            errors: [...state.errors, errorResponse.error],
        }),
    ),
    on(usersActions.updateUserRequest, (state: UsersState, { id }: { id: string }) => ({
        ...state,
        updatePendingItems: [...state.updatePendingItems, id],
    })),
    on(usersActions.updateUserSuccess, (state: UsersState, { user }: { user: User }) =>
        adapter.upsertOne(user, {
            ...state,
            updatePendingItems: state.updatePendingItems.filter(
                (pendingItem: string) => pendingItem !== user.id,
            ),
            shouldFetchUsers: true,
            success: [
                ...state.success,
                {
                    ...user,
                    message: `The user ${user.email} updated successfully!`,
                },
            ],
        }),
    ),
    on(
        usersActions.updateUserFailure,
        (
            state: UsersState,
            { id, errorResponse }: { id: string; errorResponse: HttpErrorResponse },
        ) => ({
            ...state,
            errors: [...state.errors, errorResponse.error],
            updatePendingItems: state.updatePendingItems.filter(
                (pendingItem: string) => pendingItem !== id,
            ),
        }),
    ),
    on(usersActions.deleteUserRequest, (state: UsersState, { id }: { id: string }) => ({
        ...state,
        deletePendingItems: [...state.deletePendingItems, id],
    })),
    on(usersActions.deleteUserSuccess, (state: UsersState, { user }: { user: User }) =>
        adapter.removeOne(user.id, {
            ...state,
            deletePendingItems: state.deletePendingItems.filter(
                (pendingItem: string) => pendingItem !== user.id,
            ),
            shouldFetchUsers: true,
            success: [
                ...state.success,
                {
                    ...user,
                    message: `The user ${user.email} deleted successfully!`,
                },
            ],
        }),
    ),
    on(
        usersActions.deleteUserFailure,
        (
            state: UsersState,
            { id, errorResponse }: { id: string; errorResponse: HttpErrorResponse },
        ) => ({
            ...state,
            errors: [...state.errors, errorResponse.error],
            deletePendingItems: state.deletePendingItems.filter(
                (pendingItem: string) => pendingItem !== id,
            ),
        }),
    ),
    on(usersActions.deleteUsersRequest, (state: UsersState, { ids }: { ids: string[] }) => ({
        ...state,
        deletePendingItems: [...state.deletePendingItems, ...ids],
    })),
    on(
        usersActions.deleteUsersSuccess,
        (state: UsersState, { paginatedUsers }: { paginatedUsers: PaginatedResults<User> }) => {
            const usersIds: string[] = paginatedUsers.results.map((user: User) => user.id);
            return adapter.removeMany(usersIds, {
                ...state,
                deletePendingItems: state.deletePendingItems.filter(
                    (pendingItem: string) => !usersIds.includes(pendingItem),
                ),
                shouldFetchUsers: true,
                success: [
                    ...state.success,
                    ...paginatedUsers.results.map((user: User) => ({
                        ...user,
                        message: `The user ${user.email} deleted successfully!`,
                    })),
                ],
            });
        },
    ),
    on(
        usersActions.deleteUsersFailure,
        (
            state: UsersState,
            { ids, errorResponse }: { ids: string[]; errorResponse: HttpErrorResponse },
        ) => ({
            ...state,
            errors: [...state.errors, errorResponse.error],
            deletePendingItems: state.deletePendingItems.filter(
                (pendingItem: string) => !ids.includes(pendingItem),
            ),
        }),
    ),
    on(usersActions.getUsersRequest, (state: UsersState) => ({
        ...state,
        loading: true,
        loaded: false,
    })),
    on(
        usersActions.getUsersSuccess,
        (
            state: UsersState,
            {
                paginatedUsers,
                sortDirection,
                sortColumn,
            }: {
                paginatedUsers: PaginatedResults<User>;
                sortDirection: SortingOrder;
                sortColumn: string;
            },
        ) =>
            adapter.setAll(paginatedUsers.results, {
                ...state,
                loading: false,
                loaded: true,
                pageIndex: paginatedUsers.pageIndex,
                pageSize: paginatedUsers.pageSize,
                total: paginatedUsers.total,
                sortDirection,
                sortColumn,
            }),
    ),
    on(
        usersActions.getUsersFailure,
        (state: UsersState, { errorResponse }: { errorResponse: HttpErrorResponse }) => ({
            ...state,
            errors: [...state.errors, errorResponse.error],
            loading: false,
            loaded: false,
        }),
    ),
    on(usersActions.clearNextSuccess, (state: UsersState) => ({
        ...state,
        success: state.success.slice(1),
    })),
    on(usersActions.clearNextError, (state: UsersState) => ({
        ...state,
        errors: state.errors.slice(1),
    })),
    on(usersActions.clearShouldFetchUsers, (state: UsersState) => ({
        ...state,
        shouldFetchUsers: false,
    })),
);

export const reducer = (state: UsersState | undefined, action: Action): UsersState =>
    usersReducer(state, action);

// get the selectors
const { selectAll } = adapter.getSelectors();

// select the array of users
export const getEntities: (state: EntityState<User>) => User[] = selectAll;
export const getErrors: (state: UsersState) => ErrorResponse[] = (state: UsersState) =>
    state.errors;
export const getSuccess: (state: UsersState) => User[] = (state: UsersState) => state.success;
export const getLoading: (state: UsersState) => boolean = (state: UsersState) => state.loading;
export const getLoaded: (state: UsersState) => boolean = (state: UsersState) => state.loaded;
export const getPageIndex: (state: UsersState) => number = (state: UsersState) => state.pageIndex;
export const getPageSize: (state: UsersState) => number = (state: UsersState) => state.pageSize;
export const getTotal: (state: UsersState) => number = (state: UsersState) => state.total;
export const getSortDirection: (state: UsersState) => SortingOrder = (state: UsersState) =>
    state.sortDirection;
export const getSortColumn: (state: UsersState) => string = (state: UsersState) => state.sortColumn;
export const getUpdatePendingItems: (state: UsersState) => string[] = (state: UsersState) =>
    state.updatePendingItems;
export const getDeletePendingItems: (state: UsersState) => string[] = (state: UsersState) =>
    state.deletePendingItems;
export const getShouldFetchUsers: (state: UsersState) => boolean = (state: UsersState) =>
    state.shouldFetchUsers;
