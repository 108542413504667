import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'mapToProperty',
})
export class MapToPropertyPipe implements PipeTransform {
    constructor() {}

    transform<T = Record<string, any>>(array: T[], property: keyof T): string[] {
        if (!Array.isArray(array)) {
            return;
        }

        return array
            .filter(
                (object: T) =>
                    object?.[property] !== null && typeof object?.[property] !== 'undefined',
            )
            .map((object: T) => String(object?.[property]));
    }
}
