import { createSelector } from '@ngrx/store';
import { getUIState } from '../index';
import { UIState } from '../ui-state.interface';
import * as fromArticles from './articles.reducer';

export const getArticlesState = createSelector(getUIState, (state: UIState) => state.articles);

export const getAssociationArticlesDisplay = createSelector(
    getArticlesState,
    fromArticles.getAssociationArticlesDisplay,
);
