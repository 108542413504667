import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
    name: 'filterFirst',
})
export class FilterFirstPipe implements PipeTransform {
    constructor() {}

    public transform(value: string, splitCharacter: string, known?: string[]): string {
        if (!value || value.length === 0) {
            return;
        }

        if (!known || known.length === 0) {
            return value.split(splitCharacter)[0];
        }

        const derived: string[] = value.split(splitCharacter);

        return derived.find((element: string) => known.includes(element)) || derived[0];
    }
}
