import { createSelector } from '@ngrx/store';
import { DataState } from '../../data-state.interface';
import { getDataState } from '../../index';
import * as fromDiscovery from './combinatorial-discovery.reducer';

export const getCombinatorialDiscoveryState = createSelector(
    getDataState,
    (state: DataState) => state.discovery.combinatorial,
);

export const getErrors = createSelector(getCombinatorialDiscoveryState, fromDiscovery.getErrors);
export const getLoading = createSelector(getCombinatorialDiscoveryState, fromDiscovery.getLoading);
export const getLoaded = createSelector(getCombinatorialDiscoveryState, fromDiscovery.getLoaded);
export const getBlob = createSelector(getCombinatorialDiscoveryState, fromDiscovery.getBlob);
export const getSearchSuggestions = createSelector(
    getCombinatorialDiscoveryState,
    fromDiscovery.getSearchSuggestions,
);
export const getSearchSuggestionsLoading = createSelector(
    getCombinatorialDiscoveryState,
    fromDiscovery.getSearchSuggestionsLoading,
);
export const getSearchSuggestionsLoaded = createSelector(
    getCombinatorialDiscoveryState,
    fromDiscovery.getSearchSuggestionsLoaded,
);
export const getDBsIds = createSelector(getCombinatorialDiscoveryState, fromDiscovery.getDBsIds);
export const getNotFoundErrorLoaded = createSelector(
    getCombinatorialDiscoveryState,
    fromDiscovery.getNotFoundErrorLoaded,
);
export const getSessionId = createSelector(
    getCombinatorialDiscoveryState,
    fromDiscovery.getSessionId,
);
