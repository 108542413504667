/** third-party imports */
import { createReducer, on, Action, ActionReducer } from '@ngrx/store';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { HttpErrorResponse } from '@angular/common/http';

/** custom imports */
import * as actions from './concentrations.actions';
import ErrorResponse from '@leap-common/interfaces/error-response.interface';
import { ConcentrationsState } from './concentrations-state.interface';
import Concentration from './interfaces/concentration.interface';

export const adapter: EntityAdapter<Concentration> = createEntityAdapter<Concentration>();
export const initialState: ConcentrationsState = adapter.getInitialState({
    errors: [],
    loading: false,
    loaded: false,
    areNotDetected: false,
    concentrationDetails: [],
    concentrationDetailsLoading: false,
    concentrationDetailsLoaded: false,
});

const concentrationsReducer: ActionReducer<ConcentrationsState, Action> = createReducer(
    initialState,
    on(actions.getConcentrationsRequest, (state: ConcentrationsState) => ({
        ...state,
        loading: true,
        loaded: false,
        areNotDetected: false,
    })),
    on(
        actions.getConcentrationsSuccess,
        (state: ConcentrationsState, { concentrations }: { concentrations: Concentration[] }) =>
            adapter.setAll(concentrations, {
                ...state,
                loading: false,
                loaded: true,
            }),
    ),
    on(
        actions.getConcentrationsFailure,
        (state: ConcentrationsState, { errorResponse }: { errorResponse: HttpErrorResponse }) => ({
            ...state,
            errors:
                // A 404 error means that all the sources are of type "Not detected", in which case
                // we don't want to display an error
                errorResponse.status === 404
                    ? [...state.errors]
                    : [...state.errors, errorResponse.error],
            loading: false,
            loaded: errorResponse.status === 404,
            areNotDetected: errorResponse.status === 404,
        }),
    ),
    on(actions.getConcentrationDetailsRequest, (state: ConcentrationsState) => ({
        ...state,
        concentrationDetailsLoading: true,
        concentrationDetailsLoaded: false,
    })),
    on(
        actions.getConcentrationDetailsSuccess,
        (
            state: ConcentrationsState,
            { concentrationDetails }: { concentrationDetails: Concentration[] },
        ) => ({
            ...state,
            concentrationDetails,
            concentrationDetailsLoading: false,
            concentrationDetailsLoaded: true,
        }),
    ),
    on(
        actions.getConcentrationDetailsFailure,
        (state: ConcentrationsState, { errorResponse }: { errorResponse: HttpErrorResponse }) => ({
            ...state,
            errors: [...state.errors, errorResponse.error],
            concentrationDetailsLoading: false,
            concentrationDetailsLoaded: false,
        }),
    ),
    on(actions.clearNextError, (state: ConcentrationsState) => ({
        ...state,
        errors: state.errors.slice(1),
    })),
);

export const reducer = (
    state: ConcentrationsState | undefined,
    action: Action,
): ConcentrationsState => concentrationsReducer(state, action);

// selectors
export const getEntities: (state: EntityState<Concentration>) => Concentration[] =
    adapter.getSelectors().selectAll;
export const getErrors: (state: ConcentrationsState) => ErrorResponse[] = (
    state: ConcentrationsState,
) => state.errors;
export const getLoading: (state: ConcentrationsState) => boolean = (state: ConcentrationsState) =>
    state.loading;
export const getLoaded: (state: ConcentrationsState) => boolean = (state: ConcentrationsState) =>
    state.loaded;
export const getAreNotDetected: (state: ConcentrationsState) => boolean = (
    state: ConcentrationsState,
) => state.areNotDetected;
export const getConcentrationDetails: (state: ConcentrationsState) => Concentration[] = (
    state: ConcentrationsState,
) => state.concentrationDetails;
export const getConcentrationDetailsLoading: (state: ConcentrationsState) => boolean = (
    state: ConcentrationsState,
) => state.concentrationDetailsLoading;
export const getConcentrationDetailsLoaded: (state: ConcentrationsState) => boolean = (
    state: ConcentrationsState,
) => state.concentrationDetailsLoaded;
