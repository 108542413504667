import { ActionReducer, combineReducers, createFeatureSelector } from '@ngrx/store';
import { UIState } from './ui-state.interface';

/** reducers */
import * as fromLayout from './layout/layout.reducer';
import * as fromAlerts from './alerts/alerts.reducer';
import * as fromDiscovery from './discovery/discovery.reducer';
import * as fromArticles from './articles/articles.reducer';
import * as fromProjects from './projects/projects.reducer';
import * as fromProfiler from './profiler/profiler.reducer';
import * as fromIngredientProfiler from './ingredient-profiler/ingredient-profiler.reducer';
import * as fromIngredientAnalyzer from './ingredient-analyzer/ingredient-analyzer.reducer';

export const reducers: ActionReducer<UIState> = combineReducers({
    layout: fromLayout.reducer,
    alerts: fromAlerts.reducer,
    discovery: fromDiscovery.reducer,
    articles: fromArticles.reducer,
    projects: fromProjects.reducer,
    profiler: fromProfiler.reducer,
    ingredientProfiler: fromIngredientProfiler.reducer,
    ingredientAnalyzer: fromIngredientAnalyzer.reducer,
});

export const getUIState = createFeatureSelector<UIState>('ui');
