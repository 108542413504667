/** third-party imports */
import { createReducer, on, Action, ActionReducer } from '@ngrx/store';

/** custom imports */
import * as ingredientAnalyzerActions from './ingredient-analyzer.actions';
import { IngredientAnalyzerState } from './ingredient-analyzer-state.interface';
import Ingredient from '../../data/ingredient-viewer/interfaces/ingredient.interface';

export const initialState: IngredientAnalyzerState = {
    selectedIngredient: null,
    baselineIngredient: null,
    selectedIngredients: [],
};

const ingredientAnalyzerReducer: ActionReducer<IngredientAnalyzerState, Action> = createReducer(
    initialState,
    on(
        ingredientAnalyzerActions.updateSelectedIngredient,
        (
            state: IngredientAnalyzerState,
            { selectedIngredient }: { selectedIngredient: Ingredient },
        ) => ({
            ...state,
            selectedIngredient,
        }),
    ),
    on(
        ingredientAnalyzerActions.updateBaselineIngredient,
        (
            state: IngredientAnalyzerState,
            { baselineIngredient }: { baselineIngredient: Ingredient },
        ) => ({
            ...state,
            baselineIngredient,
        }),
    ),
    on(
        ingredientAnalyzerActions.updateSelectedIngredients,
        (
            state: IngredientAnalyzerState,
            { selectedIngredients }: { selectedIngredients: Ingredient[] },
        ) => ({
            ...state,
            selectedIngredients,
        }),
    ),
);

export const reducer = (
    state: IngredientAnalyzerState | undefined,
    action: Action,
): IngredientAnalyzerState => ingredientAnalyzerReducer(state, action);

// selectors
export const getSelectedIngredient: (state: IngredientAnalyzerState) => Ingredient = (
    state: IngredientAnalyzerState,
) => state.selectedIngredient;
export const getBaselineIngredient: (state: IngredientAnalyzerState) => Ingredient = (
    state: IngredientAnalyzerState,
) => state.baselineIngredient;
export const getSelectedIngredients: (state: IngredientAnalyzerState) => Ingredient[] = (
    state: IngredientAnalyzerState,
) => state.selectedIngredients;
