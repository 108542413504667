import { createAction, props } from '@ngrx/store';
import { HttpErrorResponse } from '@angular/common/http';
import { TermsActionTypes } from './terms-action-types.enum';
import TermOverview from './interfaces/term-overview.interface';
import BookmarkIds from '@leap-store/core/src/lib/data/bookmarks/interfaces/bookmark-ids.interface';

export const getOverviewRequest = createAction(
    TermsActionTypes.GET_OVERVIEW_REQUEST,
    props<{
        ids: string[];
        bookmarkIds: BookmarkIds;
    }>(),
);

export const getOverviewSuccess = createAction(
    TermsActionTypes.GET_OVERVIEW_SUCCESS,
    props<{
        terms: TermOverview[];
    }>(),
);

export const getOverviewFailure = createAction(
    TermsActionTypes.GET_OVERVIEW_FAILURE,
    props<{ errorResponse: HttpErrorResponse }>(),
);

export const clearOverview = createAction(TermsActionTypes.CLEAR_OVERVIEW);

export const clearNextError = createAction(TermsActionTypes.CLEAR_NEXT_ERROR);
