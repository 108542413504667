/** third-party imports */
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';

/** custom imports */
import { reducers } from '../../index';
import { ExplainabilityEffects } from './explainability.effects';
import { ExplainabilityFacade } from './explainability.facade';
import { ExplainabilityParser } from './parsers/explainability.parser';
import { ExplainabilityService } from './services/explainability.service';

@NgModule({
    imports: [
        CommonModule,
        HttpClientModule,
        StoreModule.forRoot(reducers),
        StoreDevtoolsModule.instrument({ maxAge: 10 }),
        EffectsModule.forRoot([ExplainabilityEffects]),
    ],
    declarations: [],
    providers: [ExplainabilityParser, ExplainabilityService, ExplainabilityFacade],
})
export class ExplainabilityStoreModule {}
