import { Injectable } from '@angular/core';

/** Interfaces */
import Alert from '@leap-store/core/src/lib/ui/alerts/interfaces/alert.interface';
import ErrorResponse from '@leap-common/interfaces/error-response.interface';
import Error from '@leap-common/interfaces/error.interface';
import AlertType from '@leap-store/core/src/lib/ui/alerts/enums/alert-type.enum';

@Injectable()
export class ErrorHandlerService {
    constructor() {}

    /**
     * Gets an ErrorResponse, parses it and returns an error Alert.
     */
    handleErrorResponse(errorResponse: ErrorResponse): Alert {
        return {
            type: AlertType.error,
            messages: errorResponse.errors.map((error: Error) => error.message),
            entity: errorResponse.entity,
        };
    }
}
