import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { reducers } from '..';
import { AuthEffects } from './auth.effects';

/** services */
import { LoginService } from './services/login.service';
import { LogoutService } from './services/logout.service';
import { TokenService } from './services/token.service';
import { CurrentUserService } from './services/current-user.service';
import { RefreshTokenService } from './services/refresh-token.service';

/** parsers */
import { JwtParser } from './parsers/jwt.parser';
import { CurrentUserParser } from './parsers/current-user.parser';

/** facades */
import { AuthFacade } from './auth.facade';

@NgModule({
    imports: [
        CommonModule,
        HttpClientModule,
        StoreModule.forRoot(reducers),
        StoreDevtoolsModule.instrument({ maxAge: 10 }),
        EffectsModule.forRoot([AuthEffects]),
    ],
    declarations: [],
    providers: [
        LoginService,
        LogoutService,
        TokenService,
        CurrentUserService,
        RefreshTokenService,
        JwtParser,
        CurrentUserParser,
        AuthFacade,
    ],
})
export class AuthStoreModule {}
