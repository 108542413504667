import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthLayoutRoutingModule } from './auth-layout-routing.module';
import { FooterModule } from '@leap-libs/footer/src/lib/footer.module';
import { PipesModule } from '@leap-libs/pipes/src/public-api';
import { LoginModule } from '../login/login.module';
import { ForgotPasswordModule } from '../forgot-password/forgot-password.module';
import { ResetPasswordModule } from '../reset-password/reset-password.module';
import { LegalModule } from '../../legal/legal.module';

/** Components */
import { AuthLayoutContainerComponent } from './components/containers/auth-layout/auth-layout.container.component';
import { PermissionsContainerComponent } from './components/containers/permissions/permissions.container.component';
import { LogoutContainerComponent } from './components/containers/logout/logout.container.component';

@NgModule({
    declarations: [
        AuthLayoutContainerComponent,
        PermissionsContainerComponent,
        LogoutContainerComponent,
    ],
    imports: [
        CommonModule,
        AuthLayoutRoutingModule,
        FooterModule,
        PipesModule,
        LoginModule,
        ForgotPasswordModule,
        ResetPasswordModule,
        LegalModule,
    ],
})
export class AuthLayoutModule {}
