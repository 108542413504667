/** third-party imports */
import { createReducer, on, Action, ActionReducer } from '@ngrx/store';
import { HttpErrorResponse } from '@angular/common/http';

/** custom imports */
import * as profilerActions from './dairy-profiler.actions';
import { DairyProfilerState } from './dairy-profiler-state.interface';
import ErrorResponse from '@leap-common/interfaces/error-response.interface';
import PaginatedInsights from '../ingredient-profiler/interfaces/paginated-insights.interface';
import Insight from '../ingredient-profiler/interfaces/insight.interface';
import PaginatedCompounds from '../ingredient-profiler/interfaces/paginated-compounds.interface';
import UserPreferences from '@apps/leap/src/app/shared/types/user-preferences.type';
import Overview from '../ingredient-profiler/interfaces/overview.interface';
import HealthLabelStatistics from '../ingredient-profiler/interfaces/health-label-statistics.interface';
import HealthLabelSummaries from '../ingredient-profiler/interfaces/health-label-summaries.interface';
import HealthLabelTopCompounds from '../ingredient-profiler/interfaces/health-label-top-compounds.interface';
import RelationshipGroup from '../ingredient-profiler/enums/relationship-group.enum';

export const initialState: DairyProfilerState = {
    errors: [],
    compoundsTotal: 0,
    compoundsDisplaying: 0,
    compoundsLoading: false,
    compoundsLoaded: false,
    parentCompoundId: '',
    insights: [],
    insightsTotal: 0,
    insightsDisplaying: 0,
    insightsPageIndex: 0,
    insightsLoading: false,
    insightsLoaded: false,
    dairyProductInsightsTotal: 0,
    dairyProductInsightsLoading: false,
    dairyProductInsightsLoaded: false,
    targetInsights: null,
    targetInsightsTotal: 0,
    targetInsightsLoading: false,
    targetInsightsLoaded: false,
    oldestOccurrence: null,
    newestOccurrence: null,
    preferences: null,
    minMoleculeWeight: null,
    maxMoleculeWeight: null,
    blob: null,
    overview: null,
    overviewLoading: false,
    overviewLoaded: false,
    healthLabelsStatistics: null,
    healthLabelsStatisticsLoading: false,
    healthLabelsStatisticsLoaded: false,
    relationshipsPerGroup: null,
    healthLabelSummaries: null,
    healthLabelTopCompounds: null,
    searchSuggestions: [],
    searchSuggestionsLoading: false,
    searchSuggestionsLoaded: false,
};

const profilerReducer: ActionReducer<DairyProfilerState, Action> = createReducer(
    initialState,
    on(profilerActions.getCompoundsRequest, (state: DairyProfilerState) => ({
        ...state,
        compoundsLoading: true,
        compoundsLoaded: false,
    })),
    on(
        profilerActions.getCompoundsSuccess,
        (
            state: DairyProfilerState,
            { paginatedCompounds }: { paginatedCompounds: PaginatedCompounds },
        ) => ({
            ...state,
            oldestOccurrence: paginatedCompounds.oldestOccurrence,
            newestOccurrence: paginatedCompounds.newestOccurrence,
            preferences: paginatedCompounds.preferences,
            minMoleculeWeight: paginatedCompounds.minMoleculeWeight,
            maxMoleculeWeight: paginatedCompounds.maxMoleculeWeight,
            compoundsTotal: paginatedCompounds.total,
            compoundsDisplaying: paginatedCompounds.displayingCompounds,
            compoundsLoading: false,
            compoundsLoaded: true,
        }),
    ),
    on(
        profilerActions.getCompoundsFailure,
        (state: DairyProfilerState, { errorResponse }: { errorResponse: HttpErrorResponse }) => ({
            ...state,
            errors: [...state.errors, errorResponse.error],
            compoundsLoading: false,
            compoundsLoaded: false,
        }),
    ),
    on(
        profilerActions.getInsightsRequest,
        (state: DairyProfilerState, { compoundId }: { compoundId: string }) => ({
            ...state,
            parentCompoundId: compoundId,
            insightsLoading: true,
            insightsLoaded: false,
        }),
    ),
    on(
        profilerActions.getInsightsSuccess,
        (
            state: DairyProfilerState,
            {
                paginatedInsights,
                compoundId,
            }: { paginatedInsights: PaginatedInsights; compoundId: string },
        ) => ({
            ...state,
            parentCompoundId: compoundId,
            insights: paginatedInsights.results,
            insightsPageIndex: paginatedInsights.pageIndex,
            insightsTotal: paginatedInsights.total,
            insightsDisplaying: paginatedInsights.displayingInsights,
            insightsLoading: false,
            insightsLoaded: true,
        }),
    ),
    on(
        profilerActions.getInsightsFailure,
        (state: DairyProfilerState, { errorResponse }: { errorResponse: HttpErrorResponse }) => ({
            ...state,
            errors: [...state.errors, errorResponse.error],
            insightsLoading: false,
            insightsLoaded: false,
        }),
    ),
    on(profilerActions.getDairyProductInsightsRequest, (state: DairyProfilerState) => ({
        ...state,
        dairyProductInsightsLoading: true,
        dairyProductInsightsLoaded: false,
    })),
    on(
        profilerActions.getDairyProductInsightsSuccess,
        (
            state: DairyProfilerState,
            { paginatedInsights }: { paginatedInsights: PaginatedInsights },
        ) => ({
            ...state,
            dairyProductInsightsTotal: paginatedInsights.total,
            dairyProductInsightsLoading: false,
            dairyProductInsightsLoaded: true,
        }),
    ),
    on(
        profilerActions.getDairyProductInsightsFailure,
        (state: DairyProfilerState, { errorResponse }: { errorResponse: HttpErrorResponse }) => ({
            ...state,
            errors: [...state.errors, errorResponse.error],
            dairyProductInsightsLoading: false,
            dairyProductInsightsLoaded: false,
        }),
    ),
    on(profilerActions.getTargetInsightsRequest, (state: DairyProfilerState) => ({
        ...state,
        targetInsightsLoading: true,
        targetInsightsLoaded: false,
    })),
    on(
        profilerActions.getTargetInsightsSuccess,
        (
            state: DairyProfilerState,
            { paginatedInsights }: { paginatedInsights: PaginatedInsights },
        ) => ({
            ...state,
            targetInsights: paginatedInsights.results,
            targetInsightsTotal: paginatedInsights.total,
            targetInsightsLoading: false,
            targetInsightsLoaded: true,
        }),
    ),
    on(
        profilerActions.getTargetInsightsFailure,
        (state: DairyProfilerState, { errorResponse }: { errorResponse: HttpErrorResponse }) => ({
            ...state,
            errors: [...state.errors, errorResponse.error],
            targetInsightsLoading: false,
            targetInsightsLoaded: false,
        }),
    ),
    on(profilerActions.downloadInsightsRequest, (state: DairyProfilerState) => ({
        ...state,
        blob: null as Blob,
    })),
    on(
        profilerActions.downloadInsightsSuccess,
        (state: DairyProfilerState, { blob }: { blob: Blob }) => ({
            ...state,
            blob,
        }),
    ),
    on(
        profilerActions.downloadInsightsFailure,
        (state: DairyProfilerState, { errorResponse }: { errorResponse: HttpErrorResponse }) => ({
            ...state,
            errors: [...state.errors, errorResponse.error],
        }),
    ),
    on(profilerActions.getOverviewRequest, (state: DairyProfilerState) => ({
        ...state,
        overviewLoading: true,
        overviewLoaded: false,
    })),
    on(
        profilerActions.getOverviewSuccess,
        (state: DairyProfilerState, { overview }: { overview: Overview }) => ({
            ...state,
            overview,
            overviewLoading: false,
            overviewLoaded: true,
        }),
    ),
    on(
        profilerActions.getOverviewFailure,
        (state: DairyProfilerState, { errorResponse }: { errorResponse: HttpErrorResponse }) => ({
            ...state,
            errors: [...state.errors, errorResponse.error],
            overviewLoading: false,
            overviewLoaded: false,
        }),
    ),
    on(profilerActions.getHealthLabelsStatisticsRequest, (state: DairyProfilerState) => ({
        ...state,
        healthLabelsStatisticsLoading: true,
        healthLabelsStatisticsLoaded: false,
        referencesLoading: true,
        referencesLoaded: false,
    })),
    on(
        profilerActions.getHealthLabelsStatisticsSuccess,
        (
            state: DairyProfilerState,
            {
                healthLabelsStatistics,
                relationshipsPerGroup,
            }: {
                healthLabelsStatistics: HealthLabelStatistics[];
                relationshipsPerGroup: Record<RelationshipGroup, string[]>;
            },
        ) => ({
            ...state,
            healthLabelsStatistics,
            healthLabelsStatisticsLoading: false,
            healthLabelsStatisticsLoaded: true,
            relationshipsPerGroup,
            referencesLoading: false,
            referencesLoaded: true,
        }),
    ),
    on(
        profilerActions.getHealthLabelsStatisticsFailure,
        (state: DairyProfilerState, { errorResponse }: { errorResponse: HttpErrorResponse }) => ({
            ...state,
            errors: [...state.errors, errorResponse.error],
            healthLabelsStatisticsLoading: false,
            healthLabelsStatisticsLoaded: false,
            referencesLoading: false,
            referencesLoaded: false,
        }),
    ),
    on(profilerActions.getHealthLabelSummariesRequest, (state: DairyProfilerState) => ({
        ...state,
    })),
    on(
        profilerActions.getHealthLabelSummariesSuccess,
        (
            state: DairyProfilerState,
            { healthLabelSummaries }: { healthLabelSummaries: HealthLabelSummaries },
        ) => ({
            ...state,
            healthLabelSummaries,
        }),
    ),
    on(
        profilerActions.getHealthLabelSummariesFailure,
        (state: DairyProfilerState, { errorResponse }: { errorResponse: HttpErrorResponse }) => ({
            ...state,
            errors: [...state.errors, errorResponse.error],
        }),
    ),
    on(profilerActions.getHealthLabelTopCompoundsRequest, (state: DairyProfilerState) => ({
        ...state,
    })),
    on(
        profilerActions.getHealthLabelTopCompoundsSuccess,
        (
            state: DairyProfilerState,
            { healthLabelTopCompounds }: { healthLabelTopCompounds: HealthLabelTopCompounds },
        ) => ({
            ...state,
            healthLabelTopCompounds,
        }),
    ),
    on(
        profilerActions.getHealthLabelTopCompoundsFailure,
        (state: DairyProfilerState, { errorResponse }: { errorResponse: HttpErrorResponse }) => ({
            ...state,
            errors: [...state.errors, errorResponse.error],
        }),
    ),
    on(profilerActions.getSearchSuggestionsRequest, (state: DairyProfilerState) => ({
        ...state,
        searchSuggestionsLoading: true,
        searchSuggestionsLoaded: false,
    })),
    on(
        profilerActions.getSearchSuggestionsSuccess,
        (state: DairyProfilerState, { suggestionIds }: { suggestionIds: string[] }) => ({
            ...state,
            searchSuggestions: suggestionIds,
            searchSuggestionsLoading: false,
            searchSuggestionsLoaded: true,
        }),
    ),
    on(
        profilerActions.getSearchSuggestionsFailure,
        (state: DairyProfilerState, { errorResponse }: { errorResponse: HttpErrorResponse }) => ({
            ...state,
            errors: [...state.errors, errorResponse.error],
            searchSuggestionsLoading: false,
            searchSuggestionsLoaded: false,
        }),
    ),
    on(profilerActions.clearSearchSuggestions, (state: DairyProfilerState) => ({
        ...state,
        searchSuggestions: null as string[],
        searchSuggestionsLoading: false,
        searchSuggestionsLoaded: false,
    })),
    on(profilerActions.resetDiscovery, (state: DairyProfilerState) => ({
        ...state,
        compoundsTotal: null as number,
        compoundsDisplaying: null as number,
        compoundsLoading: false as boolean,
        compoundsLoaded: false as boolean,
        parentCompoundId: null as string,
        insights: null as Insight[],
        insightsTotal: null as number,
        insightsDisplaying: null as number,
        insightsPageIndex: null as number,
        insightsLoading: false as boolean,
        insightsLoaded: false as boolean,
        dairyProductInsightsTotal: null as number,
        dairyProductInsightsLoading: false as boolean,
        dairyProductInsightsLoaded: false as boolean,
        targetInsights: null as Insight[],
        targetInsightsTotal: null as number,
        targetInsightsLoading: false as boolean,
        targetInsightsLoaded: false as boolean,
        oldestOccurrence: null as number,
        newestOccurrence: null as number,
        preferences: null as UserPreferences,
        minMoleculeWeight: null as number,
        maxMoleculeWeight: null as number,
        blob: null as Blob,
        searchSuggestions: null as string[],
        searchSuggestionsLoading: false,
        searchSuggestionsLoaded: false,
    })),
    on(profilerActions.clearNextError, (state: DairyProfilerState) => ({
        ...state,
        errors: state.errors.slice(1),
    })),
);

export const reducer = (
    state: DairyProfilerState | undefined,
    action: Action,
): DairyProfilerState => profilerReducer(state, action);

// selectors
export const getErrors: (state: DairyProfilerState) => ErrorResponse[] = (
    state: DairyProfilerState,
) => state.errors;
export const getCompoundsTotal: (state: DairyProfilerState) => number = (
    state: DairyProfilerState,
) => state.compoundsTotal;
export const getCompoundsDisplaying: (state: DairyProfilerState) => number = (
    state: DairyProfilerState,
) => state.compoundsDisplaying;
export const getCompoundsLoading: (state: DairyProfilerState) => boolean = (
    state: DairyProfilerState,
) => state.compoundsLoading;
export const getCompoundsLoaded: (state: DairyProfilerState) => boolean = (
    state: DairyProfilerState,
) => state.compoundsLoaded;
export const getParentCompoundId: (state: DairyProfilerState) => string = (
    state: DairyProfilerState,
) => state.parentCompoundId;
export const getInsights: (state: DairyProfilerState) => Insight[] = (state: DairyProfilerState) =>
    state.insights;
export const getInsightsTotal: (state: DairyProfilerState) => number = (
    state: DairyProfilerState,
) => state.insightsTotal;
export const getInsightsDisplaying: (state: DairyProfilerState) => number = (
    state: DairyProfilerState,
) => state.insightsDisplaying;
export const getInsightsPageIndex: (state: DairyProfilerState) => number = (
    state: DairyProfilerState,
) => state.insightsPageIndex;
export const getInsightsLoading: (state: DairyProfilerState) => boolean = (
    state: DairyProfilerState,
) => state.insightsLoading;
export const getInsightsLoaded: (state: DairyProfilerState) => boolean = (
    state: DairyProfilerState,
) => state.insightsLoaded;
export const getDairyProductInsightsTotal: (state: DairyProfilerState) => number = (
    state: DairyProfilerState,
) => state.dairyProductInsightsTotal;
export const getDairyProductInsightsLoading: (state: DairyProfilerState) => boolean = (
    state: DairyProfilerState,
) => state.dairyProductInsightsLoading;
export const getDairyProductInsightsLoaded: (state: DairyProfilerState) => boolean = (
    state: DairyProfilerState,
) => state.dairyProductInsightsLoaded;
export const getTargetInsights: (state: DairyProfilerState) => Insight[] = (
    state: DairyProfilerState,
) => state.targetInsights;
export const getTargetInsightsTotal: (state: DairyProfilerState) => number = (
    state: DairyProfilerState,
) => state.targetInsightsTotal;
export const getTargetInsightsLoading: (state: DairyProfilerState) => boolean = (
    state: DairyProfilerState,
) => state.targetInsightsLoading;
export const getTargetInsightsLoaded: (state: DairyProfilerState) => boolean = (
    state: DairyProfilerState,
) => state.targetInsightsLoaded;
export const getOldestOccurrence: (state: DairyProfilerState) => number = (
    state: DairyProfilerState,
) => state.oldestOccurrence;
export const getNewestOccurrence: (state: DairyProfilerState) => number = (
    state: DairyProfilerState,
) => state.newestOccurrence;
export const getPreferences: (state: DairyProfilerState) => UserPreferences = (
    state: DairyProfilerState,
) => state.preferences;
export const getMinMoleculeWeight: (state: DairyProfilerState) => number = (
    state: DairyProfilerState,
) => state.minMoleculeWeight;
export const getMaxMoleculeWeight: (state: DairyProfilerState) => number = (
    state: DairyProfilerState,
) => state.maxMoleculeWeight;
export const getBlob: (state: DairyProfilerState) => Blob = (state: DairyProfilerState) =>
    state.blob;
export const getOverview: (state: DairyProfilerState) => Overview = (state: DairyProfilerState) =>
    state.overview;
export const getOverviewLoading: (state: DairyProfilerState) => boolean = (
    state: DairyProfilerState,
) => state.overviewLoading;
export const getOverviewLoaded: (state: DairyProfilerState) => boolean = (
    state: DairyProfilerState,
) => state.overviewLoaded;
export const getHealthLabelsStatistics: (state: DairyProfilerState) => HealthLabelStatistics[] = (
    state: DairyProfilerState,
) => state.healthLabelsStatistics;
export const getHealthLabelsStatisticsLoading: (state: DairyProfilerState) => boolean = (
    state: DairyProfilerState,
) => state.healthLabelsStatisticsLoading;
export const getHealthLabelsStatisticsLoaded: (state: DairyProfilerState) => boolean = (
    state: DairyProfilerState,
) => state.healthLabelsStatisticsLoaded;
export const getRelationshipsPerGroup: (
    state: DairyProfilerState,
) => Record<RelationshipGroup, string[]> = (state: DairyProfilerState) =>
    state.relationshipsPerGroup;
export const getHealthLabelSummaries: (state: DairyProfilerState) => HealthLabelSummaries = (
    state: DairyProfilerState,
) => state.healthLabelSummaries;
export const getHealthLabelTopCompounds: (state: DairyProfilerState) => HealthLabelTopCompounds = (
    state: DairyProfilerState,
) => state.healthLabelTopCompounds;
export const getSearchSuggestions: (state: DairyProfilerState) => string[] = (
    state: DairyProfilerState,
) => state.searchSuggestions;
export const getSearchSuggestionsLoading: (state: DairyProfilerState) => boolean = (
    state: DairyProfilerState,
) => state.searchSuggestionsLoading;
export const getSearchSuggestionsLoaded: (state: DairyProfilerState) => boolean = (
    state: DairyProfilerState,
) => state.searchSuggestionsLoaded;
