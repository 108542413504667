/** third-party imports */
import { createReducer, on, Action, ActionReducer } from '@ngrx/store';
import { HttpErrorResponse } from '@angular/common/http';

/** custom imports */
import * as contentWriterActions from './content-writer.actions';
import { ContentWriterState } from './content-writer-state.interface';
import ErrorResponse from '@leap-common/interfaces/error-response.interface';
import GeneratedContent from './interfaces/generated-content.interface';

export const initialState: ContentWriterState = {
    errors: [],
    topics: null,
    topicsLoading: false,
    topicsLoaded: false,
    longContent: null,
    longContentLoading: false,
    longContentLoaded: false,
    shortContent: null,
    shortContentLoading: false,
    shortContentLoaded: false,
    contentBlob: null,
};

const contentWriterReducer: ActionReducer<ContentWriterState, Action> = createReducer(
    initialState,
    on(contentWriterActions.getTopicsRequest, (state: ContentWriterState) => ({
        ...state,
        topicsLoading: true,
        topicsLoaded: false,
    })),
    on(
        contentWriterActions.getTopicsSuccess,
        (state: ContentWriterState, { topics }: { topics: string[] }) => ({
            ...state,
            topics,
            topicsLoading: false,
            topicsLoaded: true,
        }),
    ),
    on(
        contentWriterActions.getTopicsFailure,
        (state: ContentWriterState, { errorResponse }: { errorResponse: HttpErrorResponse }) => ({
            ...state,
            errors: [...state.errors, errorResponse.error],
            topicsLoading: false,
            topicsLoaded: false,
        }),
    ),
    on(contentWriterActions.getAllContentRequest, (state: ContentWriterState) => ({
        ...state,
        longContentLoading: true,
        longContentLoaded: false,
        shortContentLoading: true,
        shortContentLoaded: false,
    })),
    on(
        contentWriterActions.getAllContentSuccess,
        (
            state: ContentWriterState,
            {
                longContent,
                shortContent,
            }: { longContent: GeneratedContent; shortContent: GeneratedContent },
        ) => ({
            ...state,
            longContent,
            longContentLoading: false,
            longContentLoaded: true,
            shortContent,
            shortContentLoading: false,
            shortContentLoaded: true,
        }),
    ),
    on(
        contentWriterActions.getAllContentFailure,
        (state: ContentWriterState, { errorResponse }: { errorResponse: HttpErrorResponse }) => ({
            ...state,
            errors: [...state.errors, errorResponse.error],
            longContentLoading: false,
            longContentLoaded: false,
            shortContentLoading: false,
            shortContentLoaded: false,
        }),
    ),
    on(contentWriterActions.getLongContentRequest, (state: ContentWriterState) => ({
        ...state,
        longContentLoading: true,
        longContentLoaded: false,
    })),
    on(
        contentWriterActions.getLongContentSuccess,
        (state: ContentWriterState, { longContent }: { longContent: GeneratedContent }) => ({
            ...state,
            longContent,
            longContentLoading: false,
            longContentLoaded: true,
        }),
    ),
    on(
        contentWriterActions.getLongContentFailure,
        (state: ContentWriterState, { errorResponse }: { errorResponse: HttpErrorResponse }) => ({
            ...state,
            errors: [...state.errors, errorResponse.error],
            longContentLoading: false,
            longContentLoaded: false,
        }),
    ),
    on(contentWriterActions.getShortContentRequest, (state: ContentWriterState) => ({
        ...state,
        shortContentLoading: true,
        shortContentLoaded: false,
    })),
    on(
        contentWriterActions.getShortContentSuccess,
        (state: ContentWriterState, { shortContent }: { shortContent: GeneratedContent }) => ({
            ...state,
            shortContent,
            shortContentLoading: false,
            shortContentLoaded: true,
        }),
    ),
    on(
        contentWriterActions.getShortContentFailure,
        (state: ContentWriterState, { errorResponse }: { errorResponse: HttpErrorResponse }) => ({
            ...state,
            errors: [...state.errors, errorResponse.error],
            shortContentLoading: false,
            shortContentLoaded: false,
        }),
    ),
    on(contentWriterActions.downloadContentRequest, (state: ContentWriterState) => ({
        ...state,
        contentBlob: null as Blob,
    })),
    on(
        contentWriterActions.downloadContentSuccess,
        (state: ContentWriterState, { contentBlob }: { contentBlob: Blob }) => ({
            ...state,
            contentBlob,
        }),
    ),
    on(
        contentWriterActions.downloadContentFailure,
        (state: ContentWriterState, { errorResponse }: { errorResponse: HttpErrorResponse }) => ({
            ...state,
            errors: [...state.errors, errorResponse.error],
        }),
    ),
);

export const reducer = (
    state: ContentWriterState | undefined,
    action: Action,
): ContentWriterState => contentWriterReducer(state, action);

// selectors
export const getErrors: (state: ContentWriterState) => ErrorResponse[] = (
    state: ContentWriterState,
) => state.errors;
export const getTopics: (state: ContentWriterState) => string[] = (state: ContentWriterState) =>
    state.topics;
export const getTopicsLoading: (state: ContentWriterState) => boolean = (
    state: ContentWriterState,
) => state.topicsLoading;
export const getTopicsLoaded: (state: ContentWriterState) => boolean = (
    state: ContentWriterState,
) => state.topicsLoaded;
export const getLongContent: (state: ContentWriterState) => GeneratedContent = (
    state: ContentWriterState,
) => state.longContent;
export const getLongContentLoading: (state: ContentWriterState) => boolean = (
    state: ContentWriterState,
) => state.longContentLoading;
export const getLongContentLoaded: (state: ContentWriterState) => boolean = (
    state: ContentWriterState,
) => state.longContentLoaded;
export const getShortContent: (state: ContentWriterState) => GeneratedContent = (
    state: ContentWriterState,
) => state.shortContent;
export const getShortContentLoading: (state: ContentWriterState) => boolean = (
    state: ContentWriterState,
) => state.shortContentLoading;
export const getShortContentLoaded: (state: ContentWriterState) => boolean = (
    state: ContentWriterState,
) => state.shortContentLoaded;
export const getContentBlob: (state: ContentWriterState) => Blob = (state: ContentWriterState) =>
    state.contentBlob;
