import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LegalRoutingModule } from './legal-routing.module';
import { MainMenuModule } from '@leap-libs/main-menu/src/public-api';
import { HeaderModule } from '@leap-libs/header/src/public-api';
import { PreloaderModule } from '@leap-libs/preloader/src/public-api';
import { PipesModule } from '@leap-libs/pipes/src/public-api';

/** Components */
import { LayoutContainerComponent } from './components/containers/layout/layout.container.component';
import { PrivacyPolicyContainerComponent } from './components/containers/privacy-policy/privacy-policy.container.component';
import { TermsOfUseContainerComponent } from './components/containers/terms-of-use/terms-of-use.container.component';
import { IframeViewComponent } from './components/views/iframe/iframe.view.component';
import { LinkViewComponent } from './components/views/link/link.view.component';
import { FooterViewComponent } from './components/views/footer/footer.view.component';

@NgModule({
    declarations: [
        LayoutContainerComponent,
        PrivacyPolicyContainerComponent,
        TermsOfUseContainerComponent,
        IframeViewComponent,
        LinkViewComponent,
        FooterViewComponent,
    ],
    imports: [
        CommonModule,
        LegalRoutingModule,
        MainMenuModule,
        HeaderModule,
        PreloaderModule,
        PipesModule,
    ],
    exports: [LinkViewComponent],
})
export class LegalModule {}
