/** third-party imports */
import { createReducer, on, Action, ActionReducer } from '@ngrx/store';
import { EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { HttpErrorResponse } from '@angular/common/http';

/** custom imports */
import * as actions from './articles.actions';
import ErrorResponse from '@leap-common/interfaces/error-response.interface';
import { ArticlesState } from './articles-state.interface';
import Range from '@leap-common/types/range.type';
import PaginatedArticles from './interfaces/paginated-articles.interface';
import ArticleInfo from './interfaces/article-info.interface';
import Article from './interfaces/article.interface';
import ArticleFilterCounts from './interfaces/filter-counts.interface';

export const adapter: EntityAdapter<Article> = createEntityAdapter<Article>();

export const initialState: ArticlesState = adapter.getInitialState({
    errors: [],
    loading: false,
    loaded: false,
    articlesInfo: [],
    total: undefined,
    filteredTotal: undefined,
    filterCounts: null,
    dateRange: null,
    selectedArticleInfo: null,
    termArticles: null,
    termArticlesLoading: false,
    termArticlesLoaded: false,
    termArticlesFilteredTotal: undefined,
    blob: null,
    termArticlesBlob: null,
    article: null,
    articleLoading: false,
    articleLoaded: false,
    fullArticleBlob: null,
    selectedArticlesBlob: null,
    bibTex: null,
    bibTexLoading: false,
    bibTexLoaded: false,
    bibTexBlob: null,
});

const articleReducer: ActionReducer<ArticlesState, Action> = createReducer(
    initialState,
    on(actions.performQueryRequest, (state: ArticlesState) => ({
        ...state,
        loading: true,
        loaded: false,
    })),
    on(
        actions.performQuerySuccess,
        (state: ArticlesState, { paginatedArticles }: { paginatedArticles: PaginatedArticles }) =>
            adapter.setAll(paginatedArticles.results, {
                ...state,
                loading: false,
                loaded: true,
                total: paginatedArticles.total,
                filteredTotal: paginatedArticles.filteredTotal,
                filterCounts: paginatedArticles.filterCounts,
                dateRange: paginatedArticles.dateRange,
            }),
    ),
    on(
        actions.performQueryFailure,
        (state: ArticlesState, { errorResponse }: { errorResponse: HttpErrorResponse }) => ({
            ...state,
            errors: [...state.errors, errorResponse.error],
            loading: false,
            loaded: false,
        }),
    ),
    on(actions.downloadQueryArticlesRequest, (state: ArticlesState) => ({
        ...state,
        blob: null as Blob,
    })),
    on(actions.downloadQueryArticlesSuccess, (state: ArticlesState, { blob }: { blob: Blob }) => ({
        ...state,
        blob,
    })),
    on(
        actions.downloadQueryArticlesFailure,
        (state: ArticlesState, { errorResponse }: { errorResponse: HttpErrorResponse }) => ({
            ...state,
            errors: [...state.errors, errorResponse.error],
        }),
    ),
    on(actions.getArticlesInfoRequest, (state: ArticlesState) => ({
        ...state,
        loading: true,
        loaded: false,
        termArticlesLoading: true,
        termArticlesLoaded: false,
    })),
    on(
        actions.getArticlesInfoSuccess,
        (
            state: ArticlesState,
            {
                articlesInfo,
                isSelected,
            }: {
                articlesInfo: ArticleInfo[];
                isSelected: boolean;
            },
        ) => {
            const uniqueArticlesInfo: Map<string, ArticleInfo> = new Map();

            state.articlesInfo.forEach((articleInfo: ArticleInfo) =>
                uniqueArticlesInfo.set(articleInfo.id, articleInfo),
            );

            articlesInfo.forEach((articleInfo: ArticleInfo) =>
                uniqueArticlesInfo.set(articleInfo.id, articleInfo),
            );

            return {
                ...state,
                filterCounts: {
                    ...state.filterCounts,
                    ...(articlesInfo[0]?.fullTextTotal && {
                        fullTextTotal: articlesInfo[0]?.fullTextTotal,
                    }),
                    ...(articlesInfo[0]?.animalMilkTotal && {
                        animalMilkTotal: articlesInfo[0]?.animalMilkTotal,
                    }),
                    ...(articlesInfo[0]?.biomarkersTotal && {
                        biomarkersTotal: articlesInfo[0]?.biomarkersTotal,
                    }),
                },
                articlesInfo: !isSelected
                    ? Array.from(uniqueArticlesInfo.values())
                    : state.articlesInfo,
                selectedArticleInfo: isSelected ? articlesInfo[0] : state.selectedArticleInfo,
            };
        },
    ),
    on(
        actions.getArticlesInfoFailure,
        (
            state: ArticlesState,
            { errorResponse, entity }: { errorResponse: HttpErrorResponse; entity: string },
        ) => ({
            ...state,
            errors: [...state.errors, { ...errorResponse.error, entity }],
        }),
    ),
    on(
        actions.selectArticleInfo,
        (
            state: ArticlesState,
            {
                sourceId,
                targetId,
                intermediateId,
            }: { sourceId: string; targetId: string; intermediateId?: string },
        ) => {
            const selectedArticleInfo: ArticleInfo = (state.articlesInfo || []).find(
                (articleInfo: ArticleInfo) =>
                    articleInfo.sourceId === sourceId &&
                    articleInfo.targetId === targetId &&
                    (!intermediateId || articleInfo.intermediateId === intermediateId), // intermediateId maybe undefined and articleInfo.intermediateId null
            );
            return {
                ...state,
                selectedArticleInfo,
            };
        },
    ),
    on(actions.clearSelectedArticleInfo, (state: ArticlesState) => ({
        ...state,
        selectedArticleInfo: null as ArticleInfo,
    })),
    on(actions.clearArticlesInfo, (state: ArticlesState) => ({
        ...state,
        articlesInfo: [] as ArticleInfo[],
    })),
    on(actions.getArticlesRequest, (state: ArticlesState) => ({
        ...state,
        loading: true,
        loaded: false,
    })),
    on(
        actions.getArticlesSuccess,
        (state: ArticlesState, { paginatedArticles }: { paginatedArticles: PaginatedArticles }) =>
            adapter.setAll(paginatedArticles.results, {
                ...state,
                loading: false,
                loaded: true,
                filteredTotal: paginatedArticles.filteredTotal,
                filterCounts: {
                    ...paginatedArticles.filterCounts,
                    /**
                     * Some parts of the applications fetch the following properties from paginatedArticles,
                     * while others fetch them from `/info` endpoint (getArticlesInfoSuccess),
                     * therefore we have to check which one is undefined and avoid overwriting the common state.
                     */
                    fullTextTotal:
                        paginatedArticles.filterCounts?.fullTextTotal ??
                        state.filterCounts?.fullTextTotal,
                    animalMilkTotal:
                        paginatedArticles.filterCounts?.animalMilkTotal ??
                        state.filterCounts?.animalMilkTotal,
                    biomarkersTotal:
                        paginatedArticles.filterCounts?.biomarkersTotal ??
                        state.filterCounts?.biomarkersTotal,
                },
            }),
    ),
    on(
        actions.getArticlesFailure,
        (state: ArticlesState, { errorResponse }: { errorResponse: HttpErrorResponse }) => ({
            ...state,
            errors: [...state.errors, errorResponse.error],
            loading: false,
            loaded: false,
        }),
    ),
    on(actions.downloadArticlesRequest, (state: ArticlesState) => ({
        ...state,
        blob: null as Blob,
    })),
    on(actions.downloadArticlesSuccess, (state: ArticlesState, { blob }: { blob: Blob }) => ({
        ...state,
        blob,
    })),
    on(
        actions.downloadArticlesFailure,
        (state: ArticlesState, { errorResponse }: { errorResponse: HttpErrorResponse }) => ({
            ...state,
            errors: [...state.errors, errorResponse.error],
        }),
    ),
    on(actions.getTermArticlesRequest, (state: ArticlesState) => ({
        ...state,
        termArticlesLoading: true,
        termArticlesLoaded: false,
    })),
    on(
        actions.getTermArticlesSuccess,
        (
            state: ArticlesState,
            { paginatedTermArticles }: { paginatedTermArticles: PaginatedArticles },
        ) => ({
            ...state,
            termArticles: paginatedTermArticles.results,
            termArticlesLoading: false,
            termArticlesLoaded: true,
            termArticlesFilteredTotal: paginatedTermArticles.filteredTotal,
            filterCounts: {
                ...paginatedTermArticles.filterCounts,
                fullTextTotal:
                    paginatedTermArticles.filterCounts?.fullTextTotal ??
                    state.filterCounts?.fullTextTotal,
                animalMilkTotal:
                    paginatedTermArticles.filterCounts?.animalMilkTotal ??
                    state.filterCounts?.animalMilkTotal,
            },
        }),
    ),
    on(
        actions.getTermArticlesFailure,
        (state: ArticlesState, { errorResponse }: { errorResponse: HttpErrorResponse }) => ({
            ...state,
            errors: [...state.errors, errorResponse.error],
            termArticlesLoading: false,
            termArticlesLoaded: false,
        }),
    ),
    on(actions.downloadTermArticlesRequest, (state: ArticlesState) => ({
        ...state,
        termArticlesBlob: null as Blob,
    })),
    on(actions.downloadTermArticlesSuccess, (state: ArticlesState, { blob }: { blob: Blob }) => ({
        ...state,
        termArticlesBlob: blob,
    })),
    on(
        actions.downloadTermArticlesFailure,
        (state: ArticlesState, { errorResponse }: { errorResponse: HttpErrorResponse }) => ({
            ...state,
            errors: [...state.errors, errorResponse.error],
        }),
    ),
    on(actions.getArticleRequest, (state: ArticlesState) => ({
        ...state,
        article: null as Article,
        articleLoading: true,
        articleLoaded: false,
    })),
    on(actions.getArticleSuccess, (state: ArticlesState, { article }: { article: Article }) => ({
        ...state,
        article,
        articleLoading: false,
        articleLoaded: true,
    })),
    on(
        actions.getArticleFailure,
        (state: ArticlesState, { errorResponse }: { errorResponse: HttpErrorResponse }) => ({
            ...state,
            errors: [...state.errors, errorResponse.error],
            articleLoading: false,
            articleLoaded: false,
        }),
    ),
    on(actions.downloadFullArticleRequest, (state: ArticlesState) => ({
        ...state,
        fullArticleBlob: null as Blob,
    })),
    on(actions.downloadFullArticleSuccess, (state: ArticlesState, { blob }: { blob: Blob }) => ({
        ...state,
        fullArticleBlob: blob,
    })),
    on(
        actions.downloadFullArticleFailure,
        (state: ArticlesState, { errorResponse }: { errorResponse: HttpErrorResponse }) => ({
            ...state,
            errors: [...state.errors, errorResponse.error],
        }),
    ),
    on(actions.downloadSelectedArticlesRequest, (state: ArticlesState) => ({
        ...state,
        selectedArticlesBlob: null as Blob,
    })),
    on(
        actions.downloadSelectedArticlesSuccess,
        (state: ArticlesState, { blob }: { blob: Blob }) => ({
            ...state,
            selectedArticlesBlob: blob,
        }),
    ),
    on(
        actions.downloadSelectedArticlesFailure,
        (state: ArticlesState, { errorResponse }: { errorResponse: HttpErrorResponse }) => ({
            ...state,
            errors: [...state.errors, errorResponse.error],
        }),
    ),
    on(actions.getBibTexRequest, (state: ArticlesState) => ({
        ...state,
        bibTexLoading: true,
        bibTexLoaded: false,
    })),
    on(actions.getBibTexSuccess, (state: ArticlesState, { bibTex }: { bibTex: string }) => ({
        ...state,
        bibTex,
        bibTexLoading: false,
        bibTexLoaded: true,
    })),
    on(
        actions.getBibTexFailure,
        (state: ArticlesState, { errorResponse }: { errorResponse: HttpErrorResponse }) => ({
            ...state,
            errors: [...state.errors, errorResponse.error],
            bibTexLoading: false,
            bibTexLoaded: false,
        }),
    ),
    on(actions.downloadBibTexRequest, (state: ArticlesState) => ({
        ...state,
        bibTexBlob: null as Blob,
    })),
    on(actions.downloadBibTexSuccess, (state: ArticlesState, { blob }: { blob: Blob }) => ({
        ...state,
        bibTexBlob: blob,
    })),
    on(
        actions.downloadBibTexFailure,
        (state: ArticlesState, { errorResponse }: { errorResponse: HttpErrorResponse }) => ({
            ...state,
            errors: [...state.errors, errorResponse.error],
        }),
    ),
    on(actions.clearTermArticles, (state: ArticlesState) => ({
        ...state,
        termArticles: [] as Article[],
        termArticlesLoading: false,
        termArticlesLoaded: false,
    })),
    on(actions.clearDateRange, (state: ArticlesState) => ({
        ...state,
        dateRange: null as Range,
    })),
    on(actions.clearCounts, (state: ArticlesState) => ({
        ...state,
        total: undefined as number,
        filteredTotal: undefined as number,
        termArticlesFilteredTotal: undefined as number,
        filterCounts: null as ArticleFilterCounts,
    })),
    on(actions.clearNextError, (state: ArticlesState) => ({
        ...state,
        errors: state.errors.slice(1),
    })),
);

export const reducer = (state: ArticlesState | undefined, action: Action): ArticlesState =>
    articleReducer(state, action);

// selectors
export const getArticlesInfo: (state: ArticlesState) => ArticleInfo[] = (state: ArticlesState) =>
    state.articlesInfo;
export const getTotal: (state: ArticlesState) => number = (state: ArticlesState) => state.total;
export const getFilteredTotal: (state: ArticlesState) => number = (state: ArticlesState) =>
    state.filteredTotal;
export const getFilterCounts: (state: ArticlesState) => ArticleFilterCounts = (
    state: ArticlesState,
) => state.filterCounts;
export const getDateRange: (state: ArticlesState) => Range = (state: ArticlesState) =>
    state.dateRange;
export const getSelectedArticleInfo: (state: ArticlesState) => ArticleInfo = (
    state: ArticlesState,
) => state.selectedArticleInfo;
export const getArticlesEntities: (state: ArticlesState) => Article[] =
    adapter.getSelectors().selectAll;
export const getActiveArticles = (state: ArticlesState, dateRange?: Range): Article[] => {
    if (!dateRange) {
        return getArticlesEntities(state);
    }

    return getArticlesEntities(state).filter((article: Article) => {
        const date: number = Number(article.publicationDate.split('-')[0]);
        return date >= dateRange[0] && date <= dateRange[1];
    });
};
export const getTermArticles: (state: ArticlesState) => Article[] = (state: ArticlesState) =>
    state.termArticles;
export const getTermArticlesLoading: (state: ArticlesState) => boolean = (state: ArticlesState) =>
    state.termArticlesLoading;
export const getTermArticlesLoaded: (state: ArticlesState) => boolean = (state: ArticlesState) =>
    state.termArticlesLoaded;
export const getTermArticlesFilteredTotal: (state: ArticlesState) => number = (
    state: ArticlesState,
) => state.termArticlesFilteredTotal;
export const getErrors: (state: ArticlesState) => ErrorResponse[] = (state: ArticlesState) =>
    state.errors;
export const getLoading: (state: ArticlesState) => boolean = (state: ArticlesState) =>
    state.loading;
export const getLoaded: (state: ArticlesState) => boolean = (state: ArticlesState) => state.loaded;
export const getBlob: (state: ArticlesState) => Blob = (state: ArticlesState) => state.blob;
export const getTermArticlesBlob: (state: ArticlesState) => Blob = (state: ArticlesState) =>
    state.termArticlesBlob;
export const getArticle: (state: ArticlesState) => Article = (state: ArticlesState) =>
    state.article;
export const getArticleLoading: (state: ArticlesState) => boolean = (state: ArticlesState) =>
    state.articleLoading;
export const getArticleLoaded: (state: ArticlesState) => boolean = (state: ArticlesState) =>
    state.articleLoaded;
export const getFullArticleBlob: (state: ArticlesState) => Blob = (state: ArticlesState) =>
    state.fullArticleBlob;
export const getSelectedArticlesBlob: (state: ArticlesState) => Blob = (state: ArticlesState) =>
    state.selectedArticlesBlob;
export const getBibTex: (state: ArticlesState) => string = (state: ArticlesState) => state.bibTex;
export const getBibTexLoading: (state: ArticlesState) => boolean = (state: ArticlesState) =>
    state.bibTexLoading;
export const getBibTexLoaded: (state: ArticlesState) => boolean = (state: ArticlesState) =>
    state.bibTexLoaded;
export const getBibTexBlob: (state: ArticlesState) => Blob = (state: ArticlesState) =>
    state.bibTexBlob;
