import { createAction, props } from '@ngrx/store';
import { HttpErrorResponse } from '@angular/common/http';
import { SynonymsActionTypes } from './synonyms-action-types.enum';
import Synonyms from './types/synonyms.type';
import BookmarkIds from '@leap-store/core/src/lib/data/bookmarks/interfaces/bookmark-ids.interface';

export const getSynonymsRequest = createAction(
    SynonymsActionTypes.GET_SYNONYMS_REQUEST,
    props<{
        ids: string[];
        bookmarkIds: BookmarkIds;
    }>(),
);

export const getSynonymsSuccess = createAction(
    SynonymsActionTypes.GET_SYNONYMS_SUCCESS,
    props<{
        synonyms: Synonyms;
    }>(),
);

export const getSynonymsFailure = createAction(
    SynonymsActionTypes.GET_SYNONYMS_FAILURE,
    props<{ errorResponse: HttpErrorResponse }>(),
);

export const clearSynonyms = createAction(SynonymsActionTypes.CLEAR_SYNONYMS);

export const clearNextError = createAction(SynonymsActionTypes.CLEAR_NEXT_ERROR);
