/** third-party imports */
import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';

/** custom imports */
import {
    getConcentrationsRequest,
    getConcentrationDetailsRequest,
    clearNextError,
} from './concentrations.actions';
import {
    getConcentrations,
    getErrors,
    getLoading,
    getLoaded,
    getAreNotDetected,
    getConcentrationDetails,
    getConcentrationDetailsLoading,
    getConcentrationDetailsLoaded,
} from './concentrations.selectors';
import { ConcentrationsState } from './concentrations-state.interface';
import ErrorResponse from '@leap-common/interfaces/error-response.interface';
import Concentration from './interfaces/concentration.interface';
import BookmarkIds from '@leap-store/core/src/lib/data/bookmarks/interfaces/bookmark-ids.interface';

@Injectable()
export class ConcentrationsFacade {
    concentrations$: Observable<Concentration[]> = this.store.pipe(select(getConcentrations));
    errors$: Observable<ErrorResponse[]> = this.store.pipe(select(getErrors));
    loading$: Observable<boolean> = this.store.pipe(select(getLoading));
    loaded$: Observable<boolean> = this.store.pipe(select(getLoaded));
    areNotDetected$: Observable<boolean> = this.store.pipe(select(getAreNotDetected));
    concentrationDetails$: Observable<Concentration[]> = this.store.pipe(
        select(getConcentrationDetails),
    );
    concentrationDetailsLoading$: Observable<boolean> = this.store.pipe(
        select(getConcentrationDetailsLoading),
    );
    concentrationDetailsLoaded$: Observable<boolean> = this.store.pipe(
        select(getConcentrationDetailsLoaded),
    );

    constructor(private store: Store<ConcentrationsState>) {}

    getConcentrations(
        sourceId: string | undefined, // An undefined `sourceId` corresponds to a BE request for Cow Milk concentration
        targetId: string,
        bookmarkIds?: BookmarkIds,
    ): void {
        this.store.dispatch(
            getConcentrationsRequest({
                sourceId,
                targetId,
                bookmarkIds,
            }),
        );
    }

    getConcentrationDetails(sourceId: string, targetId: string, sourceName: string): void {
        this.store.dispatch(
            getConcentrationDetailsRequest({
                sourceId,
                targetId,
                sourceName,
            }),
        );
    }

    clearNextError(): void {
        this.store.dispatch(clearNextError());
    }
}
