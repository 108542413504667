import { createAction, props } from '@ngrx/store';
import { HttpErrorResponse } from '@angular/common/http';
import { DatabasesActionTypes } from './databases-action-types.enum';
import Database from './interfaces/database.interface';
import BookmarkIds from '@leap-store/core/src/lib/data/bookmarks/interfaces/bookmark-ids.interface';

export const getDatabasesRequest = createAction(
    DatabasesActionTypes.GET_DATABASES_REQUEST,
    props<{ bookmarkIds: BookmarkIds }>(),
);

export const getDatabasesSuccess = createAction(
    DatabasesActionTypes.GET_DATABASES_SUCCESS,
    props<{ databases: Record<string, Database> }>(),
);

export const getDatabasesFailure = createAction(
    DatabasesActionTypes.GET_DATABASES_FAILURE,
    props<{ errorResponse: HttpErrorResponse }>(),
);

export const clearNextError = createAction(DatabasesActionTypes.CLEAR_NEXT_ERROR);
