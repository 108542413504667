import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Actions, ofType, createEffect, CreateEffectMetadata } from '@ngrx/effects';
import { TypedAction } from '@ngrx/store/src/models';
import { of, Observable } from 'rxjs';
import { switchMap, map, catchError } from 'rxjs/operators';
import { UsersActionTypes } from './users-action-types.enum';
import { UsersService } from './services/users.service';
import * as usersActions from './users.actions';
import User from '@leap-store/core/src/lib/data/users/interfaces/user.interface';
import PaginatedResults from '@leap-common/interfaces/paginated-results.interface';
import SortingOrder from '@leap-common/enums/sorting-order.enum';

@Injectable()
export class UsersEffects {
    constructor(private actions$: Actions, private usersService: UsersService) {}

    createUser$: Observable<
        | ({
              createdUser: User;
          } & TypedAction<UsersActionTypes.CREATE_USER_SUCCESS>)
        | ({
              errorResponse: HttpErrorResponse;
          } & TypedAction<UsersActionTypes.CREATE_USER_FAILURE>)
    > &
        CreateEffectMetadata = createEffect(() =>
        this.actions$.pipe(
            ofType(usersActions.createUserRequest),
            switchMap(({ user, app }) =>
                this.usersService.createUser(user, app).pipe(
                    map((createdUser: User) => usersActions.createUserSuccess({ createdUser })),
                    catchError((errorResponse: HttpErrorResponse) =>
                        of(usersActions.createUserFailure({ errorResponse })),
                    ),
                ),
            ),
        ),
    );

    updateUser$: Observable<
        | ({
              user: User;
          } & TypedAction<UsersActionTypes.UPDATE_USER_SUCCESS>)
        | ({
              errorResponse: HttpErrorResponse;
          } & TypedAction<UsersActionTypes.UPDATE_USER_FAILURE>)
    > &
        CreateEffectMetadata = createEffect(() =>
        this.actions$.pipe(
            ofType(usersActions.updateUserRequest),
            switchMap(({ id, properties }) =>
                this.usersService.updateUser(id, properties).pipe(
                    map((user: User) => usersActions.updateUserSuccess({ user })),
                    catchError((errorResponse: HttpErrorResponse) =>
                        of(usersActions.updateUserFailure({ id, errorResponse })),
                    ),
                ),
            ),
        ),
    );

    deleteUser$: Observable<
        | ({
              user: User;
          } & TypedAction<UsersActionTypes.DELETE_USER_SUCCESS>)
        | ({
              errorResponse: HttpErrorResponse;
          } & TypedAction<UsersActionTypes.DELETE_USER_FAILURE>)
    > &
        CreateEffectMetadata = createEffect(() =>
        this.actions$.pipe(
            ofType(usersActions.deleteUserRequest),
            switchMap(({ id }) =>
                this.usersService.deleteUser(id).pipe(
                    map((user: User) => usersActions.deleteUserSuccess({ user })),
                    catchError((errorResponse: HttpErrorResponse) =>
                        of(usersActions.deleteUserFailure({ id, errorResponse })),
                    ),
                ),
            ),
        ),
    );

    deleteUsers$: Observable<
        | ({
              paginatedUsers: PaginatedResults<User>;
          } & TypedAction<UsersActionTypes.DELETE_USERS_SUCCESS>)
        | ({
              errorResponse: HttpErrorResponse;
          } & TypedAction<UsersActionTypes.DELETE_USERS_FAILURE>)
    > &
        CreateEffectMetadata = createEffect(() =>
        this.actions$.pipe(
            ofType(usersActions.deleteUsersRequest),
            switchMap(({ ids }) =>
                this.usersService.deleteUsers(ids).pipe(
                    map((paginatedUsers: PaginatedResults<User>) =>
                        usersActions.deleteUsersSuccess({ paginatedUsers }),
                    ),
                    catchError((errorResponse: HttpErrorResponse) =>
                        of(usersActions.deleteUsersFailure({ ids, errorResponse })),
                    ),
                ),
            ),
        ),
    );

    getUsers$: Observable<
        | ({
              paginatedUsers: PaginatedResults<User>;
              sortDirection: SortingOrder;
              sortColumn: string;
          } & TypedAction<UsersActionTypes.GET_USERS_SUCCESS>)
        | ({
              errorResponse: HttpErrorResponse;
          } & TypedAction<UsersActionTypes.GET_USERS_FAILURE>)
    > &
        CreateEffectMetadata = createEffect(() =>
        this.actions$.pipe(
            ofType(usersActions.getUsersRequest),
            switchMap(
                ({ pageIndex, pageSize, sortDirection, sortColumn, app, department, search }) =>
                    this.usersService
                        .getUsers(
                            pageIndex,
                            pageSize,
                            sortDirection,
                            sortColumn,
                            app,
                            department,
                            search,
                        )
                        .pipe(
                            // map((paginatedUsers: PaginatedResults<User>) =>
                            //     this.rearrangeUsers(currentUserEmail, paginatedUsers.results),
                            // ),
                            map((paginatedUsers: PaginatedResults<User>) =>
                                usersActions.getUsersSuccess({
                                    paginatedUsers,
                                    sortDirection,
                                    sortColumn,
                                }),
                            ),
                            catchError((errorResponse: HttpErrorResponse) =>
                                of(usersActions.getUsersFailure({ errorResponse })),
                            ),
                        ),
            ),
        ),
    );

    /** Gets the users[] and moves the current user on the top of the array */
    rearrangeUsers(currentUserEmail: string, users: User[]): User[] {
        const currentUser: User = users.find((user: User) => user.email === currentUserEmail);

        const rearrangedUsers: User[] = [
            currentUser,
            ...users.filter((user: User) => user.email !== currentUserEmail),
        ];

        return rearrangedUsers;
    }
}
