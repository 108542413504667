import { createAction, props } from '@ngrx/store';
import { HttpErrorResponse } from '@angular/common/http';
import { NotificationsActionTypes } from './notifications-action-types.enum';
import Message from './interfaces/message.interface';
import MessageData from './interfaces/message-data.interface';

export const sendMessageRequest = createAction(
    NotificationsActionTypes.SEND_MESSAGE_REQUEST,
    props<{ name: string; data: MessageData }>(),
);

export const sendMessageSuccess = createAction(
    NotificationsActionTypes.SEND_MESSAGE_SUCCESS,
    props<{ message: Message }>(),
);

export const sendMessageFailure = createAction(
    NotificationsActionTypes.SEND_MESSAGE_FAILURE,
    props<{ errorResponse: HttpErrorResponse }>(),
);

export const clearMessage = createAction(NotificationsActionTypes.CLEAR_MESSAGE);

export const clearNextError = createAction(NotificationsActionTypes.CLEAR_NEXT_ERROR);
