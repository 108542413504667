import { Directive, HostListener, Input } from '@angular/core';

@Directive({
    selector: '[libContextMenu]',
})
export class ContextMenuDirective {
    /**
     *  We need to reference the host component, so we are passing it as an input.
     *  More info in https://stackoverflow.com/a/67540041
     */
    @Input() componentRef: { isContextMenuDisplayed?: boolean } | null;

    constructor() {}

    /**
     * A setter is used to avoid repeating the syntax.
     */
    set isContextMenuDisplayed(value: boolean) {
        if (!this.contextMenuExistsInHostComponent()) {
            return;
        }

        this.componentRef.isContextMenuDisplayed = value;
    }

    @HostListener('document:click', ['$event'])
    documentClicked(): void {
        this.isContextMenuDisplayed = false;
    }

    @HostListener('document:contextmenu', ['$event'])
    contextMenuOpened(contextMenuEvent: any): void {
        // if click event target is a child of lib-context-menu do not proceed
        if (!contextMenuEvent.target.closest('lib-context-menu')) {
            this.isContextMenuDisplayed = false;
        }
    }

    contextMenuExistsInHostComponent(): boolean {
        return Object.prototype.hasOwnProperty.call(this.componentRef, 'isContextMenuDisplayed');
    }
}
