import { Injectable } from '@angular/core';

/** Interfaces - Enums */
import GeneratedContentRestApi from '../rest-api-interfaces/generated-content.rest.interface';
import GeneratedContentReferenceRestApi from '../rest-api-interfaces/generated-content-reference.rest.interface';
import GeneratedContent from '../interfaces/generated-content.interface';
import GeneratedContentReference from '../interfaces/generated-content-reference.interface';
import Configuration from '../interfaces/configuration.interface';
import ConfigurationRestApi from '../rest-api-interfaces/configuration.rest.interface';
import ContentWriterFormatId from '../enums/format-id.enum';

@Injectable()
export class ContentWriterParser {
    constructor() {}

    parseGeneratedContent(
        content: GeneratedContentRestApi,
        longContentText?: string,
    ): GeneratedContent {
        return {
            text: this.parseGeneratedContentText(content?.post, longContentText),
            references: this.parseGeneratedContentReferences(content?.articles),
        };
    }

    /**
     * In the case of short text, check if the text includes a heading (h2).
     * If no heading is found and if a long text heading is found, use it instead.
     *
     * @param text - Can be either long or short text
     * @param longText - when this exists, the (@param text) is short text
     */
    parseGeneratedContentText(text: string, longText?: string): string {
        if (!longText) {
            return text;
        }

        const shortHeading: string = text?.match(/<h[1-6][^>]*>([^<]*)<\/h[1-6]>/gi)?.[0];
        if (shortHeading) {
            return text;
        }

        const longHeading: string = longText
            ?.match(/<h1[^>]*>([^<]*)<\/h1>/gi)?.[0]
            ?.replace('h1', 'h2');
        if (!longHeading) {
            return text;
        }

        return longHeading + text;
    }

    parseGeneratedContentReferences(
        references: GeneratedContentReferenceRestApi[],
    ): GeneratedContentReference[] {
        return (
            references?.map((reference: GeneratedContentReferenceRestApi) =>
                this.parseGeneratedContentReference(reference),
            ) || []
        );
    }

    parseGeneratedContentReference(
        reference: GeneratedContentReferenceRestApi,
    ): GeneratedContentReference {
        return {
            title: reference?.title,
            publicationDate: reference?.publicationDate,
            url: reference?.url,
            origin: reference?.source,
        };
    }

    serializeConfiguration(configuration: Configuration): ConfigurationRestApi {
        return {
            tone_of_voice: configuration.tone,
            type_of_content: configuration.contentType,
            length: configuration.length,
        };
    }

    serializeFormat(format: ContentWriterFormatId): string {
        return format === ContentWriterFormatId.longArticle
            ? 'blog'
            : format === ContentWriterFormatId.shortPost
            ? 'linkedin'
            : undefined;
    }
}
