import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

/** custom imports */
import { environment } from '@environments/leap/environment';
import { ForgotPasswordParser } from '../parsers/forgot-password.parser';

/** Interfaces */
import ForgotPasswordRestApi from '../rest-api-interfaces/forgot-password.rest.interface';
import ForgotPassword from '../interfaces/forgot-password.interface';

@Injectable()
export class ForgotPasswordService {
    constructor(private http: HttpClient, private forgotPasswordParser: ForgotPasswordParser) {}

    forgotPasswordRequest(userEmail: string): Observable<ForgotPassword> {
        const app: string = environment.app;
        return this.http
            .post(`${environment.serverUrl}/authentication/reset-password/?app=${app}`, {
                email: userEmail,
            })
            .pipe(
                map((forgotPassword: ForgotPasswordRestApi) =>
                    this.forgotPasswordParser.parseForgotPassword(forgotPassword),
                ),
            );
    }
}
