import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';

/** Services - Facades */
import { CurrentUserService } from '@leap-store/core/src/lib/data/auth/services/current-user.service';
import { RedirectService } from '../../../../layout/services/redirect.service';
import { AuthFacade } from '@leap-store/core/src/lib/data/auth/auth.facade';
import { LayoutFacade } from '@leap-store/core/src/lib/ui/layout/layout.facade';

/** Interfaces - Enums */
import Jwt from '@leap-store/core/src/lib/data/auth/interfaces/jwt.interface';
import CurrentUser from '@leap-store/core/src/lib/data/auth/interfaces/current-user.interface';
import TokenTypes from '@leap-store/core/src/lib/data/auth/enums/token-types.enum';

@Component({
    selector: 'app-layout',
    templateUrl: 'layout.container.component.html',
})
export class LayoutContainerComponent implements OnInit, OnDestroy {
    isTermsOfUseAcceptanceEnabled$ = this.uiFacade.isTermsOfUseAcceptanceEnabled$;

    isFooterDisplayed: boolean;

    jwt$: Observable<Jwt> = this.authFacade.jwt$;

    // subscriptions
    queryParamsSubscription: Subscription;
    jwtSubscription: Subscription;

    constructor(
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private currentUserService: CurrentUserService,
        private redirectService: RedirectService,
        private authFacade: AuthFacade,
        private uiFacade: LayoutFacade,
    ) {}

    ngOnInit(): void {
        this.subscribeToQueryParams();
        this.rehydrateStore();
        this.handleAcceptedTermsOfUse();
    }

    ngOnDestroy(): void {
        this.queryParamsSubscription?.unsubscribe();
        this.jwtSubscription?.unsubscribe();
    }

    subscribeToQueryParams(): void {
        this.queryParamsSubscription = this.activatedRoute.queryParams.subscribe(
            (params: Params) => {
                this.isFooterDisplayed = params?.shouldAcceptTerms === 'true';
            },
        );
    }

    rehydrateStore(): void {
        this.authFacade.rehydrateToken(TokenTypes.access);
        this.authFacade.rehydrateToken(TokenTypes.refresh);
        this.authFacade.getCurrentUser();
    }

    handleAcceptedTermsOfUse(): void {
        this.jwtSubscription = this.jwt$.subscribe((jwt: Jwt) => {
            if (!jwt?.accessToken) {
                this.redirectToBaseUrl();
            }

            if (jwt?.accessToken && this.isFooterDisplayed) {
                const currentUser: CurrentUser = this.currentUserService.getUserIdentity();
                const areTermsOfUseAccepted: boolean = currentUser.areTermsOfUseAccepted;

                this.redirectToLanding(areTermsOfUseAccepted);
            }
        });
    }

    onLogin(event: boolean): void {
        if (!event) {
            return;
        }

        this.router.navigate(['./auth/login']);
    }

    onAcceptClicked(isClicked: boolean): void {
        if (!isClicked) {
            return;
        }

        this.authFacade.acceptTermsOfUse();
    }

    onCancelClicked(isClicked: boolean): void {
        if (!isClicked) {
            return;
        }

        this.logoutAndRedirectToLogin();
    }

    redirectToBaseUrl(): void {
        this.router.navigate([], {
            relativeTo: this.activatedRoute,
            queryParams: {},
            replaceUrl: true,
        });
    }

    redirectToLanding(areTermsOfUseAccepted: boolean): void {
        if (!areTermsOfUseAccepted) {
            return;
        }

        this.redirectService.redirectToLanding();
    }

    logoutAndRedirectToLogin(): void {
        this.authFacade.logout([TokenTypes.access, TokenTypes.refresh]);
        this.authFacade.resetAuth();
        this.router.navigate(['/auth/login']);
    }
}
