import { Component, OnDestroy, OnInit, TemplateRef } from '@angular/core';
import { Router, RouterEvent, NavigationEnd } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { filter, map, pairwise, startWith } from 'rxjs/operators';
import { environment } from '@environments/leap/environment';

/** Constants */
import { CURRENT_YEAR } from '@leap-common/constants/common';
import { DISCOVERY_PATH, EMPTY_ROUTE_PATH, HELP_CENTER_PATH } from '../../../constants/menu-items';

/** Services - Facades */
import { RoleService } from '../../../../auth/core/services/role.service';
import { FeatureFlagsService } from '@leap-libs/feature-flags/src/public-api';
import { MenuItemsService } from '../../../services/menu-items.service';
import { AlertsFacade } from '@leap-store/core/src/lib/ui/alerts/alerts.facade';
import { LayoutFacade } from '@leap-store/core/src/lib/ui/layout/layout.facade';
import { AuthFacade } from '@leap-store/core/src/lib/data/auth/auth.facade';
import { MetadataFacade } from '@leap-store/core/src/lib/data/metadata/metadata.facade';
import { GPTFacade } from '@leap-store/core/src/lib/data/gpt/gpt.facade';
import { NotebookServersFacade } from '@leap-store/core/src/lib/data/notebook-servers/notebook-servers.facade';

/** Interfaces - Types - Enums */
import MenuItem from '@leap-libs/main-menu/src/lib/menu-item.interface';
import MenuOrientation from '@leap-libs/main-menu/src/lib/menu-orientation.type';
import CurrentUser from '@leap-store/core/src/lib/data/auth/interfaces/current-user.interface';
import UserApp from '@leap-store/core/src/lib/data/auth/interfaces/user-app.interface';
import TokenTypes from '@leap-store/core/src/lib/data/auth/enums/token-types.enum';

@Component({
    selector: 'app-layout',
    templateUrl: 'layout.container.component.html',
})
export class LayoutContainerComponent implements OnInit, OnDestroy {
    app: string = environment.app;

    // current user
    currentUser$: Observable<CurrentUser> = this.authFacade.currentUser$;

    // menu
    menuOrientation: MenuOrientation = 'horizontal';
    mainMenuItems: MenuItem[];
    userMenuItems: MenuItem[];
    mainMenuCustomContent$: Observable<TemplateRef<unknown>> =
        this.layoutFacade.mainMenuCustomContent$;

    // footer
    footerCustomContent$: Observable<TemplateRef<unknown>> = this.layoutFacade.footerCustomContent$;
    readonly currentYear: number = CURRENT_YEAR;

    // articles ingestion date
    articlesIngestionDate$: Observable<string> = this.metadataFacade.articlesIngestionDate$;

    // feature flags
    areNotebooksEnabled: boolean;
    isChatAssistantEnabled: boolean;
    isArticlesIngestionDateEnabled: boolean;

    // subscriptions
    routerSubscription: Subscription;
    roleSubscription: Subscription;
    currentUserSubscription: Subscription;

    constructor(
        private router: Router,
        private roleService: RoleService,
        private featureFlagsService: FeatureFlagsService,
        private menuItemsService: MenuItemsService,
        private alertsFacade: AlertsFacade,
        private layoutFacade: LayoutFacade,
        private authFacade: AuthFacade,
        private metadataFacade: MetadataFacade,
        private gptFacade: GPTFacade,
        private notebookServersFacade: NotebookServersFacade,
    ) {
        this.subscribeToRouterEvents();
    }

    ngOnInit(): void {
        this.rehydrateStore();
        if (this.isArticlesIngestionDateEnabled) {
            this.getArticlesIngestionDate();
        }
    }

    ngOnDestroy(): void {
        this.routerSubscription?.unsubscribe();
        this.roleSubscription?.unsubscribe();
        this.currentUserSubscription?.unsubscribe();
    }

    /**
     * Calls once the authFacade.rehydrateToken() with parameter TokenTypes.access and
     * TokenTypes.refresh and the authFacade.getCurrentUser().
     */
    rehydrateStore(): void {
        this.authFacade.rehydrateToken(TokenTypes.access);
        this.authFacade.rehydrateToken(TokenTypes.refresh);
        this.authFacade.getCurrentUser();
    }

    getArticlesIngestionDate(): void {
        this.metadataFacade.getArticlesIngestionDate();
    }

    subscribeToRouterEvents(): void {
        this.routerSubscription = this.router.events
            .pipe(
                filter(
                    (event: RouterEvent) =>
                        event instanceof NavigationEnd &&
                        event.urlAfterRedirects !== `/${EMPTY_ROUTE_PATH}`,
                ),
                map((event: NavigationEnd) =>
                    this.menuItemsService.getActiveRoute(event?.urlAfterRedirects),
                ),
                startWith(undefined),
                pairwise(),
            )
            .subscribe(([previousActiveRoute, activeRoute]: [string, string]) => {
                this.initializeEnabledFeatures();

                this.updateMenuItems(activeRoute);

                // if the active route has changed, clear the active alerts
                if (previousActiveRoute !== activeRoute) {
                    this.alertsFacade.clearAlertEvents();
                }
            });
    }

    initializeEnabledFeatures(): void {
        this.areNotebooksEnabled = this.featureFlagsService.isFeatureEnabled('notebooks');
        this.isChatAssistantEnabled = this.featureFlagsService.isFeatureEnabled('chatAssistant');
        this.isArticlesIngestionDateEnabled =
            this.featureFlagsService.isFeatureEnabled('articlesIngestionDate');
    }

    updateMenuItems(activeRoute: string): void {
        if (activeRoute === HELP_CENTER_PATH) {
            this.mainMenuItems = [];
            this.userMenuItems = [];
        } else {
            this.setupMainMenu();
            this.setupUserMenu();
        }
    }

    setupMainMenu(): void {
        this.currentUserSubscription = this.currentUser$
            .pipe(filter((currentUser: CurrentUser) => Boolean(currentUser)))
            .subscribe((currentUser: CurrentUser) => {
                const currentUserApps: string[] = currentUser.apps.map((app: UserApp) =>
                    app.name.toLowerCase(),
                );

                this.mainMenuItems = this.menuItemsService.getMainMenuItems(
                    this.router.url,
                    currentUserApps,
                );
            });
    }

    setupUserMenu(): void {
        this.roleSubscription = this.roleService
            .hasUserAdministratorPrivileges()
            .subscribe((hasUserAdminPrivileges: boolean) => {
                this.userMenuItems = this.menuItemsService.getUserMenuItems(hasUserAdminPrivileges);
            });
    }

    onDiscovery(discovery: boolean): void {
        if (discovery) {
            this.router
                .navigateByUrl(`/${EMPTY_ROUTE_PATH}`, { skipLocationChange: true })
                .then(() => {
                    this.router.navigate([DISCOVERY_PATH]);
                });
        }
    }

    /**
     * Implements user logout by calling the logoutNotebookServer(), the authFacade.logout(), the authFacade.resetAuth()
     * and redirecting the user to the /login page.
     * The logout from the notebook server takes place only if the notebooks feature flag is enabled.
     */
    onLogout(logout: boolean): void {
        if (logout) {
            if (this.areNotebooksEnabled) {
                this.logoutNotebookServer();
            }

            if (this.isChatAssistantEnabled) {
                this.gptFacade.deleteAssistantQueries();
            }

            // normally, the `logout()` should suffice.
            // However, because it triggers asynchronous code (effects), it is not guaranteed to complete
            // before any other calls that update the auth store slice (e.g., refresh).
            // TODO: Investigate whether `logout()` should be asynchronous. If it shouldn't, retain only one of these two actions.
            this.authFacade.logout([TokenTypes.access, TokenTypes.refresh]);
            this.authFacade.resetAuth();
            this.router.navigate(['auth/logout']);
        }
    }

    /**
     * Removes XSRF-TOKEN cookie, closes open notebook server connections and
     * logouts from the jupyterHub.
     */
    logoutNotebookServer(): void {
        this.notebookServersFacade.deleteNotebookServer();
        document.cookie = `XSRF-TOKEN=; Path=/; Domain=.${environment.domain}; Max-Age=0`;
        this.notebookServersFacade.logoutJupyterHub();
    }
}
