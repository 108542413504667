/** third-party imports */
import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';

/** custom imports */
import ErrorResponse from '@leap-common/interfaces/error-response.interface';
import Message from './interfaces/message.interface';
import MessageData from './interfaces/message-data.interface';
import { NotificationsState } from './notifications-state.interface';
import { sendMessageRequest, clearMessage, clearNextError } from './notifications.actions';
import { getMessage, getErrors, getLoading, getLoaded } from './notifications.selectors';

@Injectable()
export class NotificationsFacade {
    message$: Observable<Message> = this.store.pipe(select(getMessage));
    errors$: Observable<ErrorResponse[]> = this.store.pipe(select(getErrors));
    loading$: Observable<boolean> = this.store.pipe(select(getLoading));
    loaded$: Observable<boolean> = this.store.pipe(select(getLoaded));

    constructor(private store: Store<NotificationsState>) {}

    sendMessage(name: string, data: MessageData): void {
        this.store.dispatch(sendMessageRequest({ name, data }));
    }

    clearMessage(): void {
        this.store.dispatch(clearMessage());
    }

    clearNextError(): void {
        this.store.dispatch(clearNextError());
    }
}
