import { createSelector } from '@ngrx/store';
import { DataState } from '../../data-state.interface';
import { getDataState } from '../../index';
import * as fromSynonyms from './synonyms.reducer';

export const getSynonymsState = createSelector(
    getDataState,
    (state: DataState) => state.discovery.synonyms,
);

export const getSynonyms = createSelector(getSynonymsState, fromSynonyms.getSynonyms);
export const getErrors = createSelector(getSynonymsState, fromSynonyms.getErrors);
export const getLoading = createSelector(getSynonymsState, fromSynonyms.getLoading);
export const getLoaded = createSelector(getSynonymsState, fromSynonyms.getLoaded);
