/** third-party imports */
import { Injectable } from '@angular/core';

/** custom imports */
import PaginatedResultsRestApi from '@leap-common/rest-api-interfaces/paginated-results.rest.interface';
import PaginatedResults from '@leap-common/interfaces/paginated-results.interface';
import FileUploadResponse from '../interfaces/file-upload-response.interface';
import FileUploadEnvelopeRestApi from '../rest-api-interfaces/file-upload-envelope.rest.interface';
import File from '../interfaces/file.interface';
import FileMetadata from '../interfaces/file-metadata.interface';
import FileRestApi from '../rest-api-interfaces/file.rest.interface';
import FileMetadataRestApi from '../rest-api-interfaces/file-metadata.rest.interface';
import SortingOrder from '@leap-common/enums/sorting-order.enum';

@Injectable()
export class FilesParser {
    constructor() {}

    /** Parses BE data to integrate them on the FE */
    parseFileUploadEnvelope(
        blob: FileUploadEnvelopeRestApi<FileUploadResponse>,
    ): FileUploadResponse {
        return blob._response;
    }

    /** Serializes Files Sorting Parameter */
    serializeFilesOrderByParameter(sortDirection: SortingOrder, sortColumn: string): string {
        const direction: string = sortDirection === SortingOrder.descending ? '-' : '';
        const column: string =
            sortColumn === 'path'
                ? 'path'
                : sortColumn === 'createdAt'
                ? 'created'
                : sortColumn === 'size'
                ? 'size'
                : undefined;
        return `${direction}${column}`;
    }

    /** Parses PaginatedResultsRestApi<FileRestApi> to PaginatedResults<File> */
    parsePaginatedResults(
        paginatedFiles: PaginatedResultsRestApi<FileRestApi>,
    ): PaginatedResults<File> {
        return {
            results: paginatedFiles.results
                ? paginatedFiles.results.map((file: FileRestApi) => this.parseFile(file))
                : [],
            pageIndex: paginatedFiles.pageIndex - 1,
            pageSize: paginatedFiles.pageSize,
            total: paginatedFiles.total,
        };
    }

    parseFile(file: FileRestApi): File {
        return {
            path: file.path,
            id: file.uuid,
            sas: file.sas,
            createdAt: file.created,
            ...(file.metadata ? { ...this.parseFileMetadata(file.metadata) } : undefined),
        };
    }

    parseFileMetadata(metadata: FileMetadataRestApi): FileMetadata {
        return {
            name: metadata.name,
            size: metadata.size,
            lastModifiedAt: metadata.last_modified,
        };
    }
}
