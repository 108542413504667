import { environment } from '@environments/leap/environment';

/** Helpers */
import { replaceDelimiter } from '@leap-common/utilities/helpers';

/** Constants */
import { LINE_BREAK, WHITESPACE } from '@leap-common/constants/common';
import {
    COW_MILK_PROFILER_ICON_ACTIVE,
    COW_MILK_PROFILER_PATH,
    COW_MILK_PROFILER_TITLE,
    DAIRY_PROFILER_ICON_ACTIVE,
    DAIRY_PROFILER_PATH,
    DAIRY_PROFILER_TITLE,
    ARTICLES_ICON_ACTIVE,
    ARTICLES_PATH,
    ARTICLES_TITLE,
    DISCOVERY_ICON_ACTIVE,
    MY_DISCOVERY_PATH,
    OPEN_DISCOVERY_TITLE,
    PROJECTS_ICON_ACTIVE,
    PROJECTS_PATH,
    PROJECTS_TITLE,
    INGREDIENT_VIEWER_ICON_ACTIVE,
    INGREDIENT_VIEWER_PATH,
    INGREDIENT_VIEWER_TITLE,
    INGREDIENT_COMPARISON_ICON_ACTIVE,
    INGREDIENT_COMPARISON_PATH,
    INGREDIENT_COMPARISON_TITLE,
    INGREDIENT_MANAGEMENT_ICON_ACTIVE,
    INGREDIENT_MANAGEMENT_PATH,
    INGREDIENT_MANAGEMENT_TITLE,
} from './menu-items';

/** Interfaces */
import MenuItem from '@leap-libs/main-menu/src/lib/menu-item.interface';

export const SUBTITLE: string =
    environment.app === 'd3'
        ? 'What would you like to do today?'
        : 'Select one of the items below to begin your journey';

export const COW_MILK_PROFILER_DESCRIPTION_TEXT: string =
    "Click here if you want to search thousands of Cow's milk molecules, discover all of their associated health benefits, and concentrations";

export const DAIRY_PROFILER_DESCRIPTION_TEXT: string =
    'Click here if you want to search whole dairy products and compounds as cited in literature, discover all of their associated health benefits';

export const ARTICLES_DESCRIPTION_TEXT: string =
    "Click here if you want to quickly search articles on general or adjacent topics that fall outside of a search in the cow's milk and dairy profiler";

export const DISCOVERY_DESCRIPTION_TEXT: string =
    'Click here if you want to explore all foods, organisms, compounds, and related properties and health associations';

export const PROJECTS_DESCRIPTION_TEXT: string = 'Click here to access your saved projects';

export const INGREDIENT_VIEWER_DESCRIPTION_TEXT: string =
    'View the characteristics of a single ingredient and its relation to health effects';

export const INGREDIENT_COMPARISON_DESCRIPTION_TEXT: string =
    'Compare two or more ingredients and their health effects';

export const INGREDIENT_MANAGEMENT_DESCRIPTION_TEXT: string =
    'View the ingredients list, upload and manage a new ingredient';

const D3_FEATURE_CARD_ITEMS: MenuItem[] = [
    {
        id: 'landing-cow-milk-profiler',
        icon: COW_MILK_PROFILER_ICON_ACTIVE,
        path: COW_MILK_PROFILER_PATH,
        title: COW_MILK_PROFILER_TITLE,
        description: COW_MILK_PROFILER_DESCRIPTION_TEXT,
    },
    {
        id: 'landing-dairy-profiler',
        icon: DAIRY_PROFILER_ICON_ACTIVE,
        path: DAIRY_PROFILER_PATH,
        title: DAIRY_PROFILER_TITLE,
        description: DAIRY_PROFILER_DESCRIPTION_TEXT,
    },
    {
        id: 'landing-articles',
        icon: ARTICLES_ICON_ACTIVE,
        path: ARTICLES_PATH,
        title: ARTICLES_TITLE,
        description: ARTICLES_DESCRIPTION_TEXT,
    },
    {
        id: 'landing-discovery',
        icon: DISCOVERY_ICON_ACTIVE,
        path: MY_DISCOVERY_PATH,
        title: OPEN_DISCOVERY_TITLE,
        description: DISCOVERY_DESCRIPTION_TEXT,
    },
    {
        id: 'landing-projects',
        icon: PROJECTS_ICON_ACTIVE,
        path: PROJECTS_PATH,
        title: PROJECTS_TITLE,
        description: PROJECTS_DESCRIPTION_TEXT,
    },
];

const INGREDIENT_ANALYZER_FEATURE_CARD_ITEMS: MenuItem[] = [
    {
        id: 'landing-ingredient-viewer',
        icon: INGREDIENT_VIEWER_ICON_ACTIVE,
        path: INGREDIENT_VIEWER_PATH,
        title: replaceDelimiter(INGREDIENT_VIEWER_TITLE, WHITESPACE, LINE_BREAK),
        description: INGREDIENT_VIEWER_DESCRIPTION_TEXT,
    },
    {
        id: 'landing-ingredient-comparison',
        icon: INGREDIENT_COMPARISON_ICON_ACTIVE,
        path: INGREDIENT_COMPARISON_PATH,
        title: replaceDelimiter(INGREDIENT_COMPARISON_TITLE, WHITESPACE, LINE_BREAK),
        description: INGREDIENT_COMPARISON_DESCRIPTION_TEXT,
    },
    {
        id: 'landing-ingredient-management',
        icon: INGREDIENT_MANAGEMENT_ICON_ACTIVE,
        path: INGREDIENT_MANAGEMENT_PATH,
        title: replaceDelimiter(INGREDIENT_MANAGEMENT_TITLE, WHITESPACE, LINE_BREAK),
        description: INGREDIENT_MANAGEMENT_DESCRIPTION_TEXT,
    },
];

export const FEATURE_CARD_ITEMS: MenuItem[] =
    environment.app === 'd3'
        ? D3_FEATURE_CARD_ITEMS
        : environment.app === 'dia'
        ? INGREDIENT_ANALYZER_FEATURE_CARD_ITEMS
        : [];
