import { Injectable } from '@angular/core';

/** Interfaces - Enums */
import PaginatedResultsRestApi from '@leap-common/rest-api-interfaces/paginated-results.rest.interface';
import PaginatedResults from '@leap-common/interfaces/paginated-results.interface';
import UserRoleRestApi from '@leap-store/core/src/lib/data/auth/rest-api-interfaces/user-role.rest.interface';
import UserRole from '@leap-store/core/src/lib/data/auth/interfaces/user-role.interface';
import UserRoleEnum from '@leap-store/core/src/lib/data/roles/enums/user-role.enum';

@Injectable()
export class RolesParser {
    constructor() {}

    parsePaginatedResults(
        paginatedRoles: PaginatedResultsRestApi<UserRoleRestApi>,
    ): PaginatedResults<UserRole> {
        return {
            results: paginatedRoles.results ? this.parseRoles(paginatedRoles.results) : [],
            pageIndex: paginatedRoles.pageIndex,
            pageSize: paginatedRoles.pageSize,
            total: paginatedRoles.total,
        };
    }

    parseRoles(roles: UserRoleRestApi[]): UserRole[] {
        const parsedRoles: UserRole[] = roles.map((role: UserRoleRestApi) => this.parseRole(role));
        return this.filterRoles(parsedRoles);
    }

    parseRole(role: UserRoleRestApi): UserRole {
        return {
            id: role.uuid,
            name:
                role.name === UserRoleEnum.reader
                    ? UserRoleEnum.user
                    : role.name === UserRoleEnum.groupAdmin
                    ? UserRoleEnum.admin
                    : (role.name as UserRoleEnum),
        };
    }

    filterRoles(roles: UserRole[]): UserRole[] {
        return roles.filter(
            (role: UserRole) => role.name === UserRoleEnum.user || role.name === UserRoleEnum.admin,
        );
    }
}
