import { ActionReducer, combineReducers, createFeatureSelector } from '@ngrx/store';
import { DataState } from './data-state.interface';

/** reducers */
import * as fromAuth from './auth/auth.reducer';
import * as fromUsers from './users/users.reducer';
import * as fromRoles from './roles/roles.reducer';
import * as fromGroups from './groups/groups.reducer';
import * as fromNotebooks from './notebooks/notebooks.reducer';
import * as fromNotebookServers from './notebook-servers/notebook-servers.reducer';
import * as fromProjects from './projects/projects.reducer';
import * as fromBookmarks from './bookmarks/bookmarks.reducer';
import * as fromArticlesReport from './articles-report/articles-report.reducer';
import * as fromContentWriter from './content-writer/content-writer.reducer';
import * as fromTags from './tags/tags.reducer';
import * as fromDiscovery from './discovery/index';
import * as fromNotifications from './notifications/notifications.reducer';
import * as fromPipelineExecutions from './pipeline-executions/pipeline-executions.reducer';
import * as fromFiles from './files/files.reducer';
import * as fromMetadata from './metadata/metadata.reducer';
import * as fromMetapaths from './metapaths/metapaths.reducer';
import * as fromPaths from './paths/paths.reducer';
import * as fromExplainability from './explainability/explainability.reducer';
import * as fromGPT from './gpt/gpt.reducer';
import * as fromConversationalSearch from './conversational-search/conversational-search.reducer';
import * as fromArticles from './articles/articles.reducer';
import * as fromReport from './report/report.reducer';
import * as fromCompoundReport from './compound-report/compound-report.reducer';
import * as fromIngredientProfiler from './ingredient-profiler/ingredient-profiler.reducer';
import * as fromCowMilkProfiler from './cow-milk-profiler/cow-milk-profiler.reducer';
import * as fromDairyProfiler from './dairy-profiler/dairy-profiler.reducer';
import * as fromIngredientViewer from './ingredient-viewer/ingredient-viewer.reducer';
import * as fromIngredientComparison from './ingredient-comparison/ingredient-comparison.reducer';
import * as fromIngredientManagement from './ingredient-management/ingredient-management.reducer';

export const reducers: ActionReducer<DataState> = combineReducers({
    auth: fromAuth.reducer,
    users: fromUsers.reducer,
    roles: fromRoles.reducer,
    groups: fromGroups.reducer,
    notebooks: fromNotebooks.reducer,
    notebookServers: fromNotebookServers.reducer,
    projects: fromProjects.reducer,
    bookmarks: fromBookmarks.reducer,
    articlesReport: fromArticlesReport.reducer,
    contentWriter: fromContentWriter.reducer,
    tags: fromTags.reducer,
    discovery: fromDiscovery.reducers,
    notifications: fromNotifications.reducer,
    pipelineExecutions: fromPipelineExecutions.reducer,
    files: fromFiles.reducer,
    metadata: fromMetadata.reducer,
    metapaths: fromMetapaths.reducer,
    paths: fromPaths.reducer,
    explainability: fromExplainability.reducer,
    gpt: fromGPT.reducer,
    conversationalSearch: fromConversationalSearch.reducer,
    articles: fromArticles.reducer,
    report: fromReport.reducer,
    compoundReport: fromCompoundReport.reducer,
    ingredientProfiler: fromIngredientProfiler.reducer,
    cowMilkProfiler: fromCowMilkProfiler.reducer,
    dairyProfiler: fromDairyProfiler.reducer,
    ingredientViewer: fromIngredientViewer.reducer,
    ingredientComparison: fromIngredientComparison.reducer,
    ingredientManagement: fromIngredientManagement.reducer,
});

export const getDataState = createFeatureSelector<DataState>('data');
