/** third-party imports */
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

/** custom imports */
import { environment } from '@environments/leap/environment';
import { PatentsParser } from '../parsers/patents.parser';

/** Interfaces */
import PaginatedPatentsRestApi from '../rest-api-interfaces/paginated-patents.rest.interface';
import PaginatedPatents from '../interfaces/paginated-patents.interface';
import SortingOptions from '@leap-common/interfaces/sorting-options.interface';

@Injectable()
export class PatentsService {
    constructor(private http: HttpClient, private patentsParser: PatentsParser) {}

    getPatents(
        id: string,
        sortingOptions?: SortingOptions,
        startYear?: number,
        endYear?: number,
        pageSize?: number,
        pageIndex?: number,
    ): Observable<PaginatedPatents> {
        return this.http
            .post(`${environment.discoveryServerUrl}/patents`, {
                cui: id,
                sortingBy: sortingOptions?.field,
                sortingOrder: sortingOptions?.order,
                ...(startYear && { fromYear: startYear }),
                ...(endYear && { toYear: endYear }),
                ...(pageSize && { pageSize }),
                ...(pageIndex && { pageIndex }),
            })
            .pipe(
                map((patents: PaginatedPatentsRestApi) =>
                    this.patentsParser.parsePaginatedResults(patents),
                ),
            );
    }

    downloadPatents(
        id: string,
        sortingOptions?: SortingOptions,
        startYear?: number,
        endYear?: number,
    ): Observable<Blob> {
        return this.http.post(
            `${environment.discoveryServerUrl}/patents/download`,
            {
                cui: id,
                sortingOrder: sortingOptions?.order,
                sortingBy: sortingOptions?.field,
                ...(startYear && { fromYear: startYear }),
                ...(endYear && { toYear: endYear }),
            },
            {
                headers: new HttpHeaders({
                    Accept: 'text/csv',
                }),
                responseType: 'blob',
            },
        );
    }
}
