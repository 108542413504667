/** third-party imports */
import { createReducer, on, Action, ActionReducer } from '@ngrx/store';
import { HttpErrorResponse } from '@angular/common/http';

/** custom imports */
import * as actions from './definitions.actions';
import ErrorResponse from '@leap-common/interfaces/error-response.interface';
import { DefinitionsState } from './definitions-state.interface';
import Definitions from './types/definitions.type';

export const initialState: DefinitionsState = {
    definitions: null,
    errors: [],
    loading: false,
    loaded: false,
};

const definitionsReducer: ActionReducer<DefinitionsState, Action> = createReducer(
    initialState,
    on(actions.getDefinitionsRequest, (state: DefinitionsState) => ({
        ...state,
        definitions: null as Definitions,
        loading: true,
        loaded: false,
    })),
    on(
        actions.getDefinitionsSuccess,
        (state: DefinitionsState, { definitions }: { definitions: Definitions }) => ({
            ...state,
            definitions,
            loading: false,
            loaded: true,
        }),
    ),
    on(
        actions.getDefinitionsFailure,
        (state: DefinitionsState, { errorResponse }: { errorResponse: HttpErrorResponse }) => ({
            ...state,
            errors: [...state.errors, errorResponse.error],
            loading: false,
            loaded: false,
        }),
    ),
    on(actions.clearDefinitions, (state: DefinitionsState) => ({
        ...state,
        definitions: null as Definitions,
        loading: false,
        loaded: false,
        errors: [] as ErrorResponse[],
    })),
    on(actions.clearNextError, (state: DefinitionsState) => ({
        ...state,
        errors: state.errors.slice(1),
    })),
);

export const reducer = (state: DefinitionsState | undefined, action: Action): DefinitionsState =>
    definitionsReducer(state, action);

// selectors
export const getDefinitions: (state: DefinitionsState) => Definitions = (state: DefinitionsState) =>
    state.definitions;
export const getErrors: (state: DefinitionsState) => ErrorResponse[] = (state: DefinitionsState) =>
    state.errors;
export const getLoading: (state: DefinitionsState) => boolean = (state: DefinitionsState) =>
    state.loading;
export const getLoaded: (state: DefinitionsState) => boolean = (state: DefinitionsState) =>
    state.loaded;
