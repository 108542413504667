import { createAction, props } from '@ngrx/store';
import { HttpErrorResponse } from '@angular/common/http';
import { ArticlesActionTypes } from './articles-action-types.enum';
import ArticleInfo from './interfaces/article-info.interface';
import PaginatedArticles from './interfaces/paginated-articles.interface';
import SortingOptions from '@leap-common/interfaces/sorting-options.interface';
import FileExtension from '@leap-common/enums/file-extension.enum';
import Discovery from '@apps/leap/src/app/shared/types/discovery.type';
import BookmarkIds from '@leap-store/core/src/lib/data/bookmarks/interfaces/bookmark-ids.interface';
import ExecutionFilters from '@apps/leap/src/app/shared/modules/filters/types/execution-filters.type';
import Article from './interfaces/article.interface';
import EffectsModel from '@apps/leap/src/app/shared/enums/effects-model.enum';

export const performQueryRequest = createAction(
    ArticlesActionTypes.PERFORM_QUERY_REQUEST,
    props<{
        query: string;
        filters: ExecutionFilters;
        pageSize: number;
        pageIndex: number;
        searchQuery: string;
        sortingOptions: SortingOptions;
    }>(),
);

export const performQuerySuccess = createAction(
    ArticlesActionTypes.PERFORM_QUERY_SUCCESS,
    props<{ paginatedArticles: PaginatedArticles }>(),
);

export const performQueryFailure = createAction(
    ArticlesActionTypes.PERFORM_QUERY_FAILURE,
    props<{ errorResponse: HttpErrorResponse }>(),
);

export const downloadQueryArticlesRequest = createAction(
    ArticlesActionTypes.DOWNLOAD_QUERY_ARTICLES_REQUEST,
    props<{
        query: string;
        filters: ExecutionFilters;
        pageSize: number;
        pageIndex: number;
        searchQuery: string;
        sortingOptions: SortingOptions;
    }>(),
);

export const downloadQueryArticlesSuccess = createAction(
    ArticlesActionTypes.DOWNLOAD_QUERY_ARTICLES_SUCCESS,
    props<{ blob: Blob }>(),
);

export const downloadQueryArticlesFailure = createAction(
    ArticlesActionTypes.DOWNLOAD_QUERY_ARTICLES_FAILURE,
    props<{ errorResponse: HttpErrorResponse }>(),
);

export const getArticlesInfoRequest = createAction(
    ArticlesActionTypes.GET_ARTICLES_INFO_REQUEST,
    props<{
        ids: [string, string, string?][];
        isSelected: boolean;
        discovery: Discovery;
        bookmarkIds: BookmarkIds;
    }>(),
);

export const getArticlesInfoSuccess = createAction(
    ArticlesActionTypes.GET_ARTICLES_INFO_SUCCESS,
    props<{
        ids: [string, string, string?][];
        articlesInfo: ArticleInfo[];
        isSelected: boolean;
        discovery: Discovery;
    }>(),
);

export const getArticlesInfoFailure = createAction(
    ArticlesActionTypes.GET_ARTICLES_INFO_FAILURE,
    props<{ errorResponse: HttpErrorResponse; entity: 'articles-info' }>(),
);

export const selectArticleInfo = createAction(
    ArticlesActionTypes.SELECT_ARTICLE_INFO,
    props<{
        sourceId: string;
        targetId: string;
        intermediateId?: string;
    }>(),
);

export const clearSelectedArticleInfo = createAction(
    ArticlesActionTypes.CLEAR_SELECTED_ARTICLE_INFO,
);

export const clearArticlesInfo = createAction(ArticlesActionTypes.CLEAR_ARTICLES_INFO);

export const getArticlesRequest = createAction(
    ArticlesActionTypes.GET_ARTICLES_REQUEST,
    props<{
        ids: [string, string, string?];
        filters: ExecutionFilters;
        pageSize: number;
        pageIndex: number;
        searchQuery: string;
        sortingOptions: SortingOptions;
        areResultsHighlighted: boolean;
        effectsModel: EffectsModel;
        bookmarkIds: BookmarkIds;
    }>(),
);

export const getArticlesSuccess = createAction(
    ArticlesActionTypes.GET_ARTICLES_SUCCESS,
    props<{
        paginatedArticles: PaginatedArticles;
    }>(),
);

export const getArticlesFailure = createAction(
    ArticlesActionTypes.GET_ARTICLES_FAILURE,
    props<{ errorResponse: HttpErrorResponse }>(),
);

export const downloadArticlesRequest = createAction(
    ArticlesActionTypes.DOWNLOAD_ARTICLES_REQUEST,
    props<{
        ids: [string, string, string?];
        filters: ExecutionFilters;
        pageSize: number;
        pageIndex: number;
        searchQuery: string;
        sortingOptions: SortingOptions;
        effectsModel: EffectsModel;
        bookmarkIds: BookmarkIds;
    }>(),
);

export const downloadArticlesSuccess = createAction(
    ArticlesActionTypes.DOWNLOAD_ARTICLES_SUCCESS,
    props<{ blob: Blob }>(),
);

export const downloadArticlesFailure = createAction(
    ArticlesActionTypes.DOWNLOAD_ARTICLES_FAILURE,
    props<{ errorResponse: HttpErrorResponse }>(),
);

export const getTermArticlesRequest = createAction(
    ArticlesActionTypes.GET_TERM_ARTICLES_REQUEST,
    props<{
        id: string;
        filters: ExecutionFilters;
        pageSize: number;
        pageIndex: number;
        sortingOptions: SortingOptions;
        searchQuery: string;
        areResultsHighlighted: boolean;
        bookmarkIds: BookmarkIds;
    }>(),
);

export const getTermArticlesSuccess = createAction(
    ArticlesActionTypes.GET_TERM_ARTICLES_SUCCESS,
    props<{
        paginatedTermArticles: PaginatedArticles;
    }>(),
);

export const getTermArticlesFailure = createAction(
    ArticlesActionTypes.GET_TERM_ARTICLES_FAILURE,
    props<{ errorResponse: HttpErrorResponse }>(),
);

export const downloadTermArticlesRequest = createAction(
    ArticlesActionTypes.DOWNLOAD_TERM_ARTICLES_REQUEST,
    props<{
        id: string;
        filters: ExecutionFilters;
        pageSize: number;
        pageIndex: number;
        searchQuery: string;
        sortingOptions: SortingOptions;
        bookmarkIds: BookmarkIds;
    }>(),
);

export const downloadTermArticlesSuccess = createAction(
    ArticlesActionTypes.DOWNLOAD_TERM_ARTICLES_SUCCESS,
    props<{ blob: Blob }>(),
);

export const downloadTermArticlesFailure = createAction(
    ArticlesActionTypes.DOWNLOAD_TERM_ARTICLES_FAILURE,
    props<{ errorResponse: HttpErrorResponse }>(),
);

export const getArticleRequest = createAction(
    ArticlesActionTypes.GET_ARTICLE_REQUEST,
    props<{ id: string; effectsModel: EffectsModel; associationIds: string[] }>(),
);

export const getArticleSuccess = createAction(
    ArticlesActionTypes.GET_ARTICLE_SUCCESS,
    props<{ article: Article }>(),
);

export const getArticleFailure = createAction(
    ArticlesActionTypes.GET_ARTICLE_FAILURE,
    props<{ errorResponse: HttpErrorResponse }>(),
);

export const downloadFullArticleRequest = createAction(
    ArticlesActionTypes.DOWNLOAD_FULL_ARTICLE_REQUEST,
    props<{ id: string; selectedTerm: string; sourceName: string; targetName: string }>(),
);

export const downloadFullArticleSuccess = createAction(
    ArticlesActionTypes.DOWNLOAD_FULL_ARTICLE_SUCCESS,
    props<{ blob: Blob }>(),
);

export const downloadFullArticleFailure = createAction(
    ArticlesActionTypes.DOWNLOAD_FULL_ARTICLE_FAILURE,
    props<{ errorResponse: HttpErrorResponse }>(),
);

export const downloadSelectedArticlesRequest = createAction(
    ArticlesActionTypes.DOWNLOAD_SELECTED_ARTICLES_REQUEST,
    props<{
        ids: string[];
        extension: FileExtension;
        filters: ExecutionFilters;
        searchQuery: string;
        query: string;
        selectedTerm: string;
        sourceName: string;
        targetName: string;
    }>(),
);

export const downloadSelectedArticlesSuccess = createAction(
    ArticlesActionTypes.DOWNLOAD_SELECTED_ARTICLES_SUCCESS,
    props<{ blob: Blob }>(),
);

export const downloadSelectedArticlesFailure = createAction(
    ArticlesActionTypes.DOWNLOAD_SELECTED_ARTICLES_FAILURE,
    props<{ errorResponse: HttpErrorResponse }>(),
);

export const getBibTexRequest = createAction(
    ArticlesActionTypes.GET_BIBTEX_REQUEST,
    props<{ id: string }>(),
);

export const getBibTexSuccess = createAction(
    ArticlesActionTypes.GET_BIBTEX_SUCCESS,
    props<{ bibTex: string }>(),
);

export const getBibTexFailure = createAction(
    ArticlesActionTypes.GET_BIBTEX_FAILURE,
    props<{ errorResponse: HttpErrorResponse }>(),
);

export const downloadBibTexRequest = createAction(
    ArticlesActionTypes.DOWNLOAD_BIBTEX_REQUEST,
    props<{ id: string }>(),
);

export const downloadBibTexSuccess = createAction(
    ArticlesActionTypes.DOWNLOAD_BIBTEX_SUCCESS,
    props<{ blob: Blob }>(),
);

export const downloadBibTexFailure = createAction(
    ArticlesActionTypes.DOWNLOAD_BIBTEX_FAILURE,
    props<{ errorResponse: HttpErrorResponse }>(),
);

export const clearTermArticles = createAction(ArticlesActionTypes.CLEAR_TERM_ARTICLES);

export const clearDateRange = createAction(ArticlesActionTypes.CLEAR_DATE_RANGE);

export const clearCounts = createAction(ArticlesActionTypes.CLEAR_COUNTS);

export const clearNextError = createAction(ArticlesActionTypes.CLEAR_NEXT_ERROR);
