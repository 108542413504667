import { Component, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup } from '@angular/forms';

/** Constants */
import { DOT } from '@leap-common/constants/common';
import { COMMA_DELIMITER, OR_DELIMITER } from '@leap-common/constants/delimiters';
import {
    TERMS_OF_USE_TEXT,
    PRIVACY_POLICY_TEXT,
    EXTERNAL_LOGIN_BUTTON_TEXT,
} from '../../../../core/constants/auth';
import { EXTERNAL_POLICY_TEXT } from '@apps/leap/src/app/shared/constants/external';

/** Interfaces */
import DecoratedInputOptions from '@leap-libs/decorated-input/src/lib/decorated-input-options.interface';
import InputOptions from '@leap-libs/input/src/lib/interfaces/input-options.interface';
import LoginUser from '@leap-store/core/src/lib/data/auth/interfaces/login-user.interface';

@Component({
    selector: 'app-login-form',
    templateUrl: 'login-form.view.component.html',
    styleUrls: ['login-form.view.component.scss'],
})
export class LoginFormViewComponent {
    @Input() form: FormGroup;
    @Input() emailOptions: DecoratedInputOptions;
    @Input() passwordOptions: DecoratedInputOptions;
    @Input() agreedToTermsOptions: InputOptions;
    @Input() submitButtonTitle: string;
    @Input() isTextWhite: boolean;
    @Input() areExternalLinksEnabled: boolean;
    @Input() isExternalLoginEnabled: boolean;
    @Output() inputVisibilityToggled: EventEmitter<string> = new EventEmitter<string>();
    @Output() linkClicked: EventEmitter<string> = new EventEmitter<string>();
    @Output() login: EventEmitter<LoginUser> = new EventEmitter<LoginUser>();
    @Output() externalLogin: EventEmitter<boolean> = new EventEmitter<boolean>();

    readonly termsOfUseTitle: string = TERMS_OF_USE_TEXT;
    readonly privacyPolicyTitle: string = PRIVACY_POLICY_TEXT;
    readonly externalPolicyTitle: string = EXTERNAL_POLICY_TEXT;

    readonly dot: string = DOT;
    readonly comma: string = COMMA_DELIMITER;
    readonly or: string = OR_DELIMITER;

    readonly externalLoginButtonTitle: string = EXTERNAL_LOGIN_BUTTON_TEXT;

    constructor() {}

    onInputVisibilityToggled(event: string): void {
        this.inputVisibilityToggled.emit(event);
    }

    onLinkClick(id: string): void {
        this.linkClicked.emit(id);
    }

    onExternalLoginClicked(): void {
        this.externalLogin.emit(true);
    }

    onSubmit(): void {
        const user: LoginUser = { ...this.form.value };

        if (this.form.valid) {
            this.login.emit(user);
        }
    }
}
