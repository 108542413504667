import { Injectable } from '@angular/core';

/** Interfaces */
import PaginatedResultsRestApi from '@leap-common/rest-api-interfaces/paginated-results.rest.interface';
import PaginatedResults from '@leap-common/interfaces/paginated-results.interface';
import UserRestApi from '@leap-store/core/src/lib/data/users/rest-api-interfaces/user.rest.interface';
import User from '@leap-store/core/src/lib/data/users/interfaces/user.interface';
import UserIdsRestApi from '../rest-api-interfaces/user-ids.rest.interface';
import SortingOrder from '@leap-common/enums/sorting-order.enum';
import UserProperties from '../interfaces/user-properties.interface';
import UserPropertiesRestApi from '../rest-api-interfaces/user-properties.rest.interface';
import UserRole from '@leap-store/core/src/lib/data/roles/enums/user-role.enum';

@Injectable()
export class UsersParser {
    constructor() {}

    parsePaginatedResults(
        paginatedUsers: PaginatedResultsRestApi<UserRestApi>,
    ): PaginatedResults<User> {
        return {
            results: paginatedUsers.results ? this.parseUsers(paginatedUsers.results) : [],
            pageIndex: paginatedUsers.pageIndex - 1,
            pageSize: paginatedUsers.pageSize,
            total: paginatedUsers.total,
        };
    }

    parseUsers(users: UserRestApi[]): User[] {
        return users.map((user: UserRestApi) => this.parseUser(user));
    }

    /** Parses BE User to integrate it on the FE */
    parseUser(user: UserRestApi): User {
        return {
            id: user.uuid ? user.uuid : null,
            email: user.email ? user.email : null,
            active: user.is_active,
            hasPasswordChanged: user.changed_password,
            applications: user.applications ? user.applications : [],
            groups: user.groups ? user.groups : [],
            organizations: user.organizations ? user.organizations : [],
            departments: user.departments ? user.departments : [],
            roles: user.roles ? this.parseRoles(user.roles) : [],
            createdAt: user.created_at ? user.created_at : null,
            createdBy: user.created_by ? user.created_by : null,
            updatedAt: user.updated_at ? user.updated_at : null,
            updatedBy: user.updated_by ? user.updated_by : null,
        };
    }

    parseRoles(roles: string[]): string[] {
        return roles.map((role: string) =>
            role === UserRole.reader
                ? UserRole.user
                : role === UserRole.groupAdmin
                ? UserRole.admin
                : role,
        );
    }

    /** Parses FE User to be sent to BE */
    serializeUser(user: User): UserRestApi {
        return {
            email: user.email ? user.email : null,
            roles: user.roles ? this.serializeUserRoles(user.roles) : null,
            departmentUuids: user.departmentIds ? user.departmentIds : null,
            groupAdminOf: user.groupAdminOf,
            is_active: user.active,
        };
    }

    /**
     * Parses FE User to be sent to BE.
     * Gets the properties of a user are to be updated and converts them to UserPropertiesRestApi.
     * Properties that do not exist in the initial object they do not get included in
     * the final object.
     */
    serializeUserProperties(userProperties: UserProperties): UserPropertiesRestApi {
        return {
            ...(userProperties.active !== undefined && { isActive: userProperties.active }),
            ...(userProperties.roles && { roles: this.serializeUserRoles(userProperties.roles) }),
            ...(userProperties.departmentIds && { departmentUuids: userProperties.departmentIds }),
            ...(userProperties.groupAdminOf && { groupAdminOf: userProperties.groupAdminOf }),
            ...(userProperties.groupIds && { groupUuids: userProperties.groupIds }),
            ...(userProperties.organizationIds && {
                organizationUuids: userProperties.organizationIds,
            }),
        };
    }

    serializeUserRoles(roles: string[]): string[] {
        return roles.map((role: string) =>
            role === UserRole.user
                ? UserRole.reader
                : role === UserRole.admin
                ? UserRole.groupAdmin
                : role,
        );
    }

    serializeUsersOrderByParameter(sortDirection: SortingOrder, sortColumn: string): string {
        const direction: string = sortDirection === SortingOrder.descending ? '-' : '';
        const column: string =
            sortColumn === 'email'
                ? 'email'
                : sortColumn === 'roles'
                ? 'role'
                : sortColumn === 'createdAt'
                ? 'created_at'
                : undefined;
        return `${direction}${column}`;
    }

    serializeUserIds(ids: string[]): UserIdsRestApi {
        return { uuids: ids };
    }
}
