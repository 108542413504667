import { Pipe, PipeTransform } from '@angular/core';
import { stripHTMLTags } from '@leap-common/utilities/helpers';

@Pipe({
    name: 'strip',
})
export class StripPipe implements PipeTransform {
    constructor() {}

    transform(value: string, type: string): string {
        switch (type) {
            case 'htmlTags':
                return stripHTMLTags(value);
            default:
                return value;
        }
    }
}
