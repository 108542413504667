import { Injectable } from '@angular/core';

@Injectable()
export class LocalStorageService {
    constructor() {}

    getItem(key: string): string {
        return localStorage.getItem(key);
    }

    setItem(key: string, value: string): void {
        localStorage.setItem(key, value);
    }

    removeItem(key: string): void {
        const value: string = this.getItem(key);

        if (!value) {
            return;
        }

        localStorage.removeItem(key);
    }

    /**
     * Excludes an element from the localStorage given key and resets its value.
     */
    removeElementFromItemList(element: string, key: string, delimiter: string): void {
        const valueItems: string[] = this.splitValueInDelimiter(this.getItem(key), delimiter);
        const newValue: string = valueItems
            .filter((item: string) => item !== element)
            .join(delimiter);

        // reset localStorage
        this.setItem(key, newValue);
    }

    /** TODO: After common/ refactor move this method in common/helpers */
    /**
     * @description Gets a value and a delimiter and splits the value in delimiter.
     * If the value does not exist it returns an empty [].
     * @param value: string
     * @param delimiter: string
     * @returns string[] or empty[]
     */
    splitValueInDelimiter(value: string, delimiter: string): string[] {
        return value ? value.split(delimiter) : [];
    }
}
