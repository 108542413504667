import { createAction, props } from '@ngrx/store';
import { HttpErrorResponse } from '@angular/common/http';
import { NotebooksActionTypes } from './notebooks-action-types.enum';
import PaginatedResults from '@leap-common/interfaces/paginated-results.interface';
import Notebook from './interfaces/notebook.interface';
import SortingOrder from '@leap-common/enums/sorting-order.enum';

export const getNotebooksRequest = createAction(
    NotebooksActionTypes.GET_NOTEBOOKS_REQUEST,
    props<{
        pageIndex: number;
        pageSize: number;
        sortDirection: SortingOrder;
        sortColumn: string;
        ownerType: string;
        suppress: boolean;
    }>(),
);

export const getNotebooksSuccess = createAction(
    NotebooksActionTypes.GET_NOTEBOOKS_SUCCESS,
    props<{
        paginatedNotebooks: PaginatedResults<Notebook>;
        sortDirection: SortingOrder;
        sortColumn: string;
        ownerType: string;
    }>(),
);

export const getNotebooksFailure = createAction(
    NotebooksActionTypes.GET_NOTEBOOKS_FAILURE,
    props<{ errorResponse: HttpErrorResponse; suppress: boolean }>(),
);

export const getNotebookRequest = createAction(
    NotebooksActionTypes.GET_NOTEBOOK_REQUEST,
    props<{ id: string }>(),
);

export const getNotebookSuccess = createAction(
    NotebooksActionTypes.GET_NOTEBOOK_SUCCESS,
    props<{ notebook: Notebook }>(),
);

export const getNotebookFailure = createAction(
    NotebooksActionTypes.GET_NOTEBOOK_FAILURE,
    props<{ errorResponse: HttpErrorResponse }>(),
);

export const createNotebookRequest = createAction(
    NotebooksActionTypes.CREATE_NOTEBOOK_REQUEST,
    props<{ name?: string }>(),
);

export const createNotebookSuccess = createAction(
    NotebooksActionTypes.CREATE_NOTEBOOK_SUCCESS,
    props<{ notebook: Notebook }>(),
);

export const createNotebookFailure = createAction(
    NotebooksActionTypes.CREATE_NOTEBOOK_FAILURE,
    props<{ errorResponse: HttpErrorResponse }>(),
);

export const cloneNotebookRequest = createAction(
    NotebooksActionTypes.CLONE_NOTEBOOK_REQUEST,
    props<{ id: string }>(),
);

export const cloneNotebookSuccess = createAction(
    NotebooksActionTypes.CLONE_NOTEBOOK_SUCCESS,
    props<{ notebook: Notebook }>(),
);

export const cloneNotebookFailure = createAction(
    NotebooksActionTypes.CLONE_NOTEBOOK_FAILURE,
    props<{ errorResponse: HttpErrorResponse }>(),
);

export const updateNotebookRequest = createAction(
    NotebooksActionTypes.UPDATE_NOTEBOOK_REQUEST,
    props<{ id: string; name: string }>(),
);

export const updateNotebookSuccess = createAction(
    NotebooksActionTypes.UPDATE_NOTEBOOK_SUCCESS,
    props<{ notebook: Notebook }>(),
);

export const updateNotebookFailure = createAction(
    NotebooksActionTypes.UPDATE_NOTEBOOK_FAILURE,
    props<{ errorResponse: HttpErrorResponse }>(),
);

export const deleteNotebookRequest = createAction(
    NotebooksActionTypes.DELETE_NOTEBOOK_REQUEST,
    props<{ id: string }>(),
);

export const deleteNotebookSuccess = createAction(
    NotebooksActionTypes.DELETE_NOTEBOOK_SUCCESS,
    props<{ id: string }>(),
);

export const deleteNotebookFailure = createAction(
    NotebooksActionTypes.DELETE_NOTEBOOK_FAILURE,
    props<{ id: string; errorResponse: HttpErrorResponse }>(),
);

export const clearNextError = createAction(NotebooksActionTypes.CLEAR_NEXT_ERROR);

export const clearShouldFetchNotebooks = createAction(
    NotebooksActionTypes.CLEAR_SHOULD_FETCH_NOTEBOOKS,
);
