import {
    Directive,
    ElementRef,
    OnInit,
    OnDestroy,
    Input,
    Output,
    EventEmitter,
} from '@angular/core';

@Directive({
    selector: '[libDomChangesObserver]',
})
export class DomChangesObserverDirective implements OnInit, OnDestroy {
    /** Takes advantage of the MutationObserver native API */
    @Input() options: MutationObserverInit;

    @Output() domChanges: EventEmitter<MutationRecord[]> = new EventEmitter<MutationRecord[]>();

    changes: MutationObserver;

    constructor(private elementRef: ElementRef) {}

    ngOnInit(): void {
        // Element that will be observed for mutations
        const element: HTMLElement = this.elementRef.nativeElement;

        // Default options for the observer (which mutations to observe)
        const defaultOptions: MutationObserverInit = {
            attributes: true,
            childList: true,
            subtree: true,
        };

        const options: MutationObserverInit = this.options || defaultOptions;

        // Callback function to execute when mutations are observed
        const callback = (mutations: MutationRecord[]): void => this.domChanges.emit(mutations);

        // Create an observer instance linked to the callback function
        this.changes = new MutationObserver(callback);

        // Start observing the target node for configured mutations
        this.changes.observe(element, options);
    }

    ngOnDestroy(): void {
        // Stop observing
        this.changes.disconnect();
    }
}
