export const NOTES_TITLE: string = 'Notes';
export const NOTES_SUCCESS_MESSAGE: string = 'Notes saved';

export const VALID_NOTES_DIALOG_MESSAGE: string =
    'You have unsaved changes in your note. Do you want to save these changes before leaving?';
export const INVALID_NOTES_DIALOG_MESSAGE: string =
    'You have unsaved changes in your note that exceed the allowed character limit. Do you want to edit these changes?';

export const SAVE_CHANGES: string = 'Save changes';
export const CANCEL_CHANGES: string = 'Cancel changes';
export const RETURN_TO_EDIT: string = 'Edit changes';

export const NOTES_VIEW_PLACEHOLDER: string = 'No notes added for this %s.';
export const NOTES_EDIT_PLACEHOLDER: string = 'Type your notes for this %s...';
export const EDIT_NOTES_TEXT: string = 'Edit note';
export const ADD_NOTES_PLACEHOLDER_TEXT: string = 'Type your note here...';
export const ADD_NOTES_TEXT: string = 'Add note';
export const HIDE_NOTES_TEXT: string = 'Hide editor';
export const CHARACTER_LIMIT_ERROR: string =
    'The maximum number of characters allowed in this field is ';
export const NOTES_CHARACTER_LIMIT: number = 1000;
export const NOTES_CHARACTER_LIMIT_ERROR: string = `${CHARACTER_LIMIT_ERROR}${NOTES_CHARACTER_LIMIT}.`;
