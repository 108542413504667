import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Actions, ofType, createEffect, CreateEffectMetadata } from '@ngrx/effects';
import { TypedAction } from '@ngrx/store/src/models';
import { of, Observable, from } from 'rxjs';
import { switchMap, map, filter, catchError } from 'rxjs/operators';
import { LoginService } from './services/login.service';
import { LogoutService } from './services/logout.service';
import * as authActions from './auth.actions';
import { AuthActionTypes } from './auth-action-types.enum';
import Jwt from './interfaces/jwt.interface';
import LoginUser from './interfaces/login-user.interface';
import { TokenService } from './services/token.service';
import { CurrentUserService } from './services/current-user.service';
import CurrentUser from './interfaces/current-user.interface';
import { environment } from '@environments/leap/environment';
import { OpenDiscoveryInsightsFacade } from '@leap-store/pouchdb/open-discovery-insights/facade';
import { ClosedDiscoveryInsightsFacade } from '@leap-store/pouchdb/closed-discovery-insights/facade';
import Database from '@leap-store/pouchdb/database.enum';
import { RefreshTokenService } from './services/refresh-token.service';
import TokenTypes from './enums/token-types.enum';

@Injectable()
export class AuthEffects {
    constructor(
        private actions$: Actions,
        private loginService: LoginService,
        private logoutService: LogoutService,
        private tokenService: TokenService,
        private refreshTokenService: RefreshTokenService,
        private currentUserService: CurrentUserService,
        private pouchDBOpenDiscoveryFacade: OpenDiscoveryInsightsFacade,
        private pouchDBClosedDiscoveryFacade: ClosedDiscoveryInsightsFacade,
    ) {}

    login$: Observable<
        | ({
              jwt: Jwt;
          } & TypedAction<AuthActionTypes.LOGIN_USER_SUCCESS>)
        | ({
              key: string;
          } & TypedAction<AuthActionTypes.LOGIN_USER_RESET_PASSWORD_SUCCESS>)
        | ({
              errorResponse: HttpErrorResponse;
          } & TypedAction<AuthActionTypes.LOGIN_USER_FAILURE>)
    > &
        CreateEffectMetadata = createEffect(() =>
        this.actions$.pipe(
            ofType(authActions.loginUserRequest),
            switchMap(({ user }: { user: LoginUser }) =>
                this.loginService.login(user).pipe(
                    map((response: Jwt | string) => {
                        // if the response is a string, it is a key for resetting the password
                        if (typeof response === 'string') {
                            return authActions.loginUserResetPasswordSuccess({ key: response });
                        }

                        // if the response is a Jwt, it is a successful login
                        this.tokenService.setToken(TokenTypes.access, response.accessToken);
                        this.tokenService.setToken(TokenTypes.refresh, response.refreshToken);
                        document.cookie = `XSRF-TOKEN=${response.accessToken}; Path=/; Domain=.${environment.domain}`;
                        const currentUser: CurrentUser = this.currentUserService.getUserIdentity();
                        return authActions.loginUserSuccess({ jwt: response, currentUser });
                    }),
                    catchError((errorResponse: HttpErrorResponse) =>
                        of(authActions.loginUserFailure({ errorResponse })),
                    ),
                ),
            ),
        ),
    );

    externalLogin$: Observable<
        | ({
              jwt: Jwt;
          } & TypedAction<AuthActionTypes.EXTERNAL_LOGIN_USER_SUCCESS>)
        | ({
              errorResponse: HttpErrorResponse;
          } & TypedAction<AuthActionTypes.EXTERNAL_LOGIN_USER_FAILURE>)
    > &
        CreateEffectMetadata = createEffect(() =>
        this.actions$.pipe(
            ofType(authActions.externalLoginUserRequest),
            switchMap(
                ({
                    redirectUri,
                    clientId,
                    code,
                    codeVerifier,
                }: {
                    redirectUri: string;
                    clientId: string;
                    code: string;
                    codeVerifier: string;
                }) =>
                    this.loginService.externalLogin(redirectUri, clientId, code, codeVerifier).pipe(
                        map((jwt: Jwt) => {
                            this.tokenService.setToken(TokenTypes.access, jwt.accessToken);
                            this.tokenService.setToken(TokenTypes.refresh, jwt.refreshToken);
                            document.cookie = `XSRF-TOKEN=${jwt.accessToken}; Path=/; Domain=.${environment.domain}`;
                            const currentUser: CurrentUser =
                                this.currentUserService.getUserIdentity();
                            return authActions.externalLoginUserSuccess({
                                jwt,
                                currentUser,
                            });
                        }),
                        catchError((errorResponse: HttpErrorResponse) =>
                            of(authActions.externalLoginUserFailure({ errorResponse })),
                        ),
                    ),
            ),
        ),
    );

    logout$: Observable<
        | ({
              message: string;
          } & TypedAction<AuthActionTypes.LOGOUT_USER_SUCCESS>)
        | ({
              message: string;
          } & TypedAction<AuthActionTypes.LOGOUT_USER_FAILURE>)
    > &
        CreateEffectMetadata = createEffect(() =>
        this.actions$.pipe(
            ofType(authActions.logoutUserRequest),
            switchMap(({ tokenKeys }: { tokenKeys: TokenTypes[] }) =>
                this.logoutService.logout(tokenKeys).pipe(
                    map(async (message: string) => {
                        await this.pouchDBOpenDiscoveryFacade.destroyPouchDBs();
                        await this.pouchDBClosedDiscoveryFacade.destroyPouchDBs();
                        localStorage.removeItem(Database.discoveryDBs);
                        this.refreshTokenService.removeTimer();
                        this.refreshTokenService.removeFocusListener();
                        return authActions.logoutUserSuccess({ message });
                    }),
                    switchMap((promise) => from(promise)),
                    catchError((message: string) => of(authActions.logoutUserFailure({ message }))),
                ),
            ),
        ),
    );

    rehydrateToken$: Observable<
        | TypedAction<AuthActionTypes.REHYDRATE_TOKEN_FAILURE>
        | ({
              key: TokenTypes;
          } & TypedAction<AuthActionTypes.REHYDRATE_TOKEN_SUCCESS>)
    > &
        CreateEffectMetadata = createEffect(() =>
        this.actions$.pipe(
            ofType(authActions.rehydrateToken),
            switchMap(({ key }) => {
                const token: string | null = this.tokenService.getToken(key);
                if (!token) {
                    return of(authActions.rehydrateTokenFailure({ key }));
                }
                return of(authActions.rehydrateTokenSuccess({ key, token }));
            }),
        ),
    );

    refreshLogin$: Observable<
        | ({
              jwt: Jwt;
          } & TypedAction<AuthActionTypes.REFRESH_LOGIN_SUCCESS>)
        | ({
              errorResponse: HttpErrorResponse;
          } & TypedAction<AuthActionTypes.REFRESH_LOGIN_FAILURE>)
    > &
        CreateEffectMetadata = createEffect(() =>
        this.actions$.pipe(
            ofType(authActions.refreshLoginRequest),
            filter(() => Boolean(this.tokenService.getToken(TokenTypes.access))),
            switchMap(() =>
                this.loginService.refreshLogin(this.tokenService.getToken(TokenTypes.refresh)).pipe(
                    map((jwt: Jwt) => {
                        this.tokenService.setToken(TokenTypes.access, jwt.accessToken);
                        document.cookie = `XSRF-TOKEN=${jwt.accessToken}; Path=/; Domain=.${environment.domain}`;
                        return authActions.refreshLoginSuccess({ jwt });
                    }),
                    catchError((errorResponse: HttpErrorResponse) =>
                        of(authActions.refreshLoginFailure({ errorResponse })),
                    ),
                ),
            ),
        ),
    );

    getCurrentUser$: Observable<
        | ({
              currentUser: CurrentUser;
          } & TypedAction<AuthActionTypes.GET_CURRENT_USER_SUCCESS>)
        | TypedAction<AuthActionTypes.GET_CURRENT_USER_FAILURE>
    > &
        CreateEffectMetadata = createEffect(() =>
        this.actions$.pipe(
            ofType(authActions.getCurrentUser),
            switchMap(() => {
                const currentUser: CurrentUser = this.currentUserService.getUserIdentity();

                if (!currentUser) {
                    return of(authActions.getCurrentUserFailure());
                }

                return of(authActions.getCurrentUserSuccess({ currentUser }));
            }),
        ),
    );

    acceptTermsOfUse$: Observable<
        | ({
              jwt: Jwt;
          } & TypedAction<AuthActionTypes.ACCEPT_TERMS_OF_USE_SUCCESS>)
        | ({
              errorResponse: HttpErrorResponse;
          } & TypedAction<AuthActionTypes.ACCEPT_TERMS_OF_USE_FAILURE>)
    > &
        CreateEffectMetadata = createEffect(() =>
        this.actions$.pipe(
            ofType(authActions.acceptTermsOfUseRequest),
            switchMap(() =>
                this.loginService.acceptTermsOfUse().pipe(
                    map((jwt: Jwt) => {
                        this.tokenService.setToken(TokenTypes.access, jwt.accessToken);
                        this.tokenService.setToken(TokenTypes.refresh, jwt.refreshToken);
                        document.cookie = `XSRF-TOKEN=${jwt.accessToken}; Path=/; Domain=.${environment.domain}`;
                        return authActions.acceptTermsOfUseSuccess({ jwt });
                    }),
                    catchError((errorResponse: HttpErrorResponse) =>
                        of(authActions.acceptTermsOfUseFailure({ errorResponse })),
                    ),
                ),
            ),
        ),
    );
}
