/** third-party imports */
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

/** custom imports */
import { IngredientManagementService } from './services/ingredient-management.service';
import { IngredientManagementParser } from './parsers/ingredient-management.parser';
import { HttpErrorResponse } from '@angular/common/http';
import * as actions from './ingredient-management.actions';
import SortingOptions from '@leap-common/interfaces/sorting-options.interface';
import PaginatedResults from '@leap-common/interfaces/paginated-results.interface';
import File from '@leap-store/core/src/lib/data/files/interfaces/file.interface';
import Ingredient from '@leap-store/core/src/lib/data/ingredient-viewer/interfaces/ingredient.interface';
import FileUploadResult from './interfaces/file-upload-result.interface';

@Injectable()
export class IngredientManagementEffects {
    constructor(
        private actions$: Actions,
        private ingredientManagementService: IngredientManagementService,
        private ingredientManagementParser: IngredientManagementParser,
    ) {}

    uploadFile$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.uploadFileRequest),
            switchMap(({ file }: { file: File }) =>
                this.ingredientManagementService.uploadFile(file).pipe(
                    map((result: FileUploadResult) => actions.uploadFileSuccess({ result })),
                    catchError((errorResponse: HttpErrorResponse) =>
                        of(
                            actions.uploadFileFailure({
                                errorResponse,
                                result: this.ingredientManagementParser.parseFileUploadResult(
                                    errorResponse.error,
                                ),
                            }),
                        ),
                    ),
                ),
            ),
        ),
    );

    getIngredients$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.getIngredientsRequest),
            switchMap(
                ({
                    pageSize,
                    pageIndex,
                    searchQuery,
                    sortingOptions,
                    ids,
                }: {
                    pageSize: number;
                    pageIndex: number;
                    searchQuery: string;
                    sortingOptions: SortingOptions;
                    ids: string[];
                }) =>
                    this.ingredientManagementService
                        .getIngredients({ pageSize, pageIndex, searchQuery, sortingOptions, ids })
                        .pipe(
                            map((paginatedIngredients: PaginatedResults<Ingredient>) =>
                                actions.getIngredientsSuccess({ paginatedIngredients }),
                            ),
                            catchError((errorResponse: HttpErrorResponse) =>
                                of(actions.getIngredientsFailure({ errorResponse })),
                            ),
                        ),
            ),
        ),
    );

    updateName$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.updateNameRequest),
            switchMap(({ id, name }: { id: string; name: string }) =>
                this.ingredientManagementService.updateName(id, name).pipe(
                    map(({ ingredient }) => actions.updateNameSuccess({ ingredient })),
                    catchError((errorResponse: HttpErrorResponse) =>
                        of(
                            actions.updateNameFailure({
                                errorResponse,
                            }),
                        ),
                    ),
                ),
            ),
        ),
    );

    updateNotes$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.updateNotesRequest),
            switchMap(({ id, notes }: { id: string; notes: string }) =>
                this.ingredientManagementService.updateNotes(id, notes).pipe(
                    map(() => actions.updateNotesSuccess()),
                    catchError((errorResponse: HttpErrorResponse) =>
                        of(
                            actions.updateNotesFailure({
                                errorResponse,
                            }),
                        ),
                    ),
                ),
            ),
        ),
    );

    downloadIngredient$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.downloadIngredientRequest),
            switchMap(({ id }: { id: string }) =>
                this.ingredientManagementService.downloadIngredient(id).pipe(
                    map((blob: Blob) => actions.downloadIngredientSuccess({ blob })),
                    catchError((errorResponse: HttpErrorResponse) =>
                        of(actions.downloadIngredientFailure({ errorResponse })),
                    ),
                ),
            ),
        ),
    );

    deleteIngredient$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.deleteIngredientRequest),
            switchMap(({ id }: { id: string }) =>
                this.ingredientManagementService.deleteIngredient(id).pipe(
                    map(() =>
                        actions.deleteIngredientSuccess({
                            id,
                        }),
                    ),
                    catchError((errorResponse: HttpErrorResponse) =>
                        of(
                            actions.deleteIngredientFailure({
                                id,
                                errorResponse,
                            }),
                        ),
                    ),
                ),
            ),
        ),
    );

    downloadTemplate$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.downloadTemplateRequest),
            switchMap(() =>
                this.ingredientManagementService.downloadTemplate().pipe(
                    map((blob: Blob) => actions.downloadTemplateSuccess({ blob })),
                    catchError((errorResponse: HttpErrorResponse) =>
                        of(actions.downloadTemplateFailure({ errorResponse })),
                    ),
                ),
            ),
        ),
    );

    downloadInstructions$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.downloadInstructionsRequest),
            switchMap(() =>
                this.ingredientManagementService.downloadInstructions().pipe(
                    map((blob: Blob) => actions.downloadInstructionsSuccess({ blob })),
                    catchError((errorResponse: HttpErrorResponse) =>
                        of(actions.downloadInstructionsFailure({ errorResponse })),
                    ),
                ),
            ),
        ),
    );
}
