import { Component, Input, Output, EventEmitter } from '@angular/core';
import MenuItem from '@leap-libs/main-menu/src/lib/menu-item.interface';

@Component({
    selector: 'app-feature-card',
    templateUrl: 'feature-card.view.component.html',
    styleUrls: ['feature-card.view.component.scss'],
})
export class FeatureCardViewComponent {
    @Input() item: MenuItem;
    @Input() isD3App: boolean;
    @Output() clicked: EventEmitter<string> = new EventEmitter<string>();

    constructor() {}

    onClick(path: string): void {
        this.clicked.emit(path);
    }
}
