/** third-party imports */
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';

/** custom imports */
import { reducers } from '../../index';
import { NotebooksEffects } from './notebooks.effects';
import { NotebooksFacade } from './notebooks.facade';
import { NotebooksParser } from './parsers/notebooks.parser';
import { NotebooksService } from './services/notebooks.service';

@NgModule({
    imports: [
        CommonModule,
        HttpClientModule,
        StoreModule.forRoot(reducers),
        StoreDevtoolsModule.instrument({ maxAge: 10 }),
        EffectsModule.forRoot([NotebooksEffects]),
    ],
    declarations: [],
    providers: [NotebooksParser, NotebooksService, NotebooksFacade],
})
export class NotebooksStoreModule {}
