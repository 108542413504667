import { Directive, ChangeDetectorRef, AfterContentChecked } from '@angular/core';
import { DatatableComponent } from '@swimlane/ngx-datatable';

@Directive({
    selector: '[libNgxDatatableResizeWatcher]',
})
export class NgxDatatableResizeWatcherDirective implements AfterContentChecked {
    private latestWidth: number;
    private latestHeight: number;

    constructor(private table: DatatableComponent, public changeDetectorRef: ChangeDetectorRef) {}

    ngAfterContentChecked(): void {
        if (
            this.table &&
            (this.table.element.clientWidth !== this.latestWidth ||
                this.table.element.clientHeight !== this.latestHeight)
        ) {
            this.latestWidth = this.table.element.clientWidth;
            this.latestHeight = this.table.element.clientHeight;
            this.table.recalculate();
            this.changeDetectorRef.markForCheck();
            window.dispatchEvent(new Event('resize'));
        }
    }
}
