/** third-party imports */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

/** custom imports */
import { environment } from '@environments/leap/environment';
import { MetadataParser } from '../parsers/metadata.parser';

/** Interfaces - Types */
import LabsRestApi from '../rest-api-interfaces/labs.rest.interface';
import HealthLabelsRestApi from '../rest-api-types/health-labels.rest.type';
import HealthLabel from '../interfaces/health-label.interface';
import IdentifiersRestApi from '../rest-api-types/identifiers.rest.type';
import Identifiers from '../types/identifiers.type';
import OriginsInfoPerRelationshipRestApi from '../rest-api-types/origins-info-per-relationship.rest.type';
import OriginsInfoPerRelationship from '../types/origins-info-per-relationship.type';
import StudyTypesRestApi from '../rest-api-interfaces/study-types.rest.interface';
import JournalsRestApi from '../rest-api-interfaces/journals.rest.interface';
import UnitConversionRestApi from '../rest-api-interfaces/unit-conversion.rest.interface';
import UnitConversion from '../interfaces/unit-conversion.interface';
import ResultsRestApi from '@leap-common/rest-api-interfaces/results.rest.interface';
import ProteinOriginsRestApi from '../rest-api-types/protein-origins.rest.type';
import ProteinOrigins from '../types/protein-origins.type';
import PatentsCountsRestApi from '../rest-api-types/patents-counts.rest.type';
import PatentsCounts from '../types/patents-counts.type';
import ContainingIngredientsRestApi from '../rest-api-types/containing-ingredients.rest.type';
import ContainingIngredients from '../types/containing-ingredients.type';
import PrevalencesRestApi from '../rest-api-types/prevalences.rest.type';
import Prevalences from '../types/prevalences.type';

@Injectable()
export class MetadataService {
    constructor(private http: HttpClient, private metadataParser: MetadataParser) {}

    getLabs(): Observable<string[]> {
        return this.http
            .get(`${environment.metadataServerUrl}/nodes/tags/groups?filter=UCD DMD lab`)
            .pipe(map((labs: LabsRestApi) => this.metadataParser.parseLabs(labs)));
    }

    getHealthLabels(): Observable<HealthLabel[]> {
        return this.http
            .get(`${environment.metadataServerUrl}/health-areas`)
            .pipe(
                map((healthLabels: HealthLabelsRestApi) =>
                    this.metadataParser.parseHealthLabels(healthLabels),
                ),
            );
    }

    getPrevalences(ids: string[]): Observable<Prevalences> {
        return this.http
            .post(`${environment.metadataServerUrl}/nodes/prevalence`, {
                cuis: ids,
            })
            .pipe(
                map((prevalences: PrevalencesRestApi) =>
                    this.metadataParser.parsePrevalences(prevalences),
                ),
            );
    }

    getIdentifiers(ids: string[]): Observable<Identifiers> {
        return this.http
            .post(`${environment.metadataServerUrl}/nodes/identifiers`, {
                cuis: ids,
            })
            .pipe(
                map((identifiers: IdentifiersRestApi) =>
                    this.metadataParser.parseIdentifiers(identifiers),
                ),
            );
    }

    getSourceInfoPerRelationship(
        ids: [string, string][],
    ): Observable<Record<string, OriginsInfoPerRelationship>> {
        return this.http
            .post(`${environment.metadataServerUrl}/edges?metadata=relation_source`, {
                cui_tuples: ids,
            })
            .pipe(
                map((relationshipSourcesInfoMap: OriginsInfoPerRelationshipRestApi) =>
                    this.metadataParser.parseRelationshipsOriginsInfo(relationshipSourcesInfoMap),
                ),
            );
    }

    getStudyTypes(): Observable<string[]> {
        return this.http
            .get(`${environment.metadataServerUrl}/edges?metadata=types_of_study`)
            .pipe(
                map((studyTypes: StudyTypesRestApi) =>
                    this.metadataParser.parseStudyTypes(studyTypes),
                ),
            );
    }

    getJournals(): Observable<string[]> {
        return this.http
            .get(`${environment.metadataServerUrl}/edges?metadata=journals`)
            .pipe(map((journals: JournalsRestApi) => this.metadataParser.parseJournals(journals)));
    }

    getUnitConversions(): Observable<UnitConversion[]> {
        return this.http
            .get(`${environment.metadataServerUrl}/units/conversions`)
            .pipe(
                map((unitConversions: ResultsRestApi<UnitConversionRestApi>) =>
                    this.metadataParser.parseUnitConversions(unitConversions.results),
                ),
            );
    }

    getProteinOrigins(ids: string[]): Observable<ProteinOrigins> {
        return this.http
            .post(`${environment.metadataServerUrl}/nodes/peptide-protein-source`, {
                cuis: ids,
            })
            .pipe(
                map((proteinOrigins: ProteinOriginsRestApi) =>
                    this.metadataParser.parseProteinOrigins(proteinOrigins),
                ),
            );
    }

    getPatentsCounts(ids: string[]): Observable<PatentsCounts> {
        return this.http
            .post(`${environment.metadataServerUrl}/nodes/no-of-patents`, {
                cuis: ids,
            })
            .pipe(
                map((patentsCount: PatentsCountsRestApi) =>
                    this.metadataParser.parsePatentsCounts(patentsCount),
                ),
            );
    }

    getContainingIngredients(ids: string[]): Observable<ContainingIngredients> {
        return this.http
            .post(`${environment.metadataServerUrl}/nodes/ingredients`, {
                cuis: ids,
            })
            .pipe(
                map((ingredients: ContainingIngredientsRestApi) =>
                    this.metadataParser.parseContainingIngredients(ingredients),
                ),
            );
    }

    getArticlesIngestionDate(): Observable<string> {
        return this.http
            .get(`${environment.metadataServerUrl}/latest-articles-ingestion`)
            .pipe(
                map(
                    ({ latestArticlesIngestion }: { latestArticlesIngestion: string }) =>
                        latestArticlesIngestion,
                ),
            );
    }
}
