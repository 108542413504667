import { Injectable } from '@angular/core';

/** Interfaces - Enums */
import Discovery from '../enums/discovery.enum';
import DiscoveryExecutionParamsRestApi from '../rest-api-interfaces/discovery-execution-params.rest.interface';
import DiscoveryExecutionParams from '../interfaces/discovery-execution-params.interface';

@Injectable()
export class DiscoveryParser {
    constructor() {}

    parseDiscovery(discovery: string): Discovery {
        return discovery === 'od'
            ? Discovery.open
            : discovery === 'cd'
            ? Discovery.closed
            : discovery === 'cq'
            ? Discovery.combinatorial
            : undefined;
    }

    parseDiscoveryExecutionParameters(
        parameters: DiscoveryExecutionParamsRestApi,
    ): DiscoveryExecutionParams {
        return {
            sourceId: parameters?.sourceUid,
            sourceName: parameters?.sourceName,
            targetId: parameters?.targetUid,
            targetName: parameters?.targetName,
            categories: parameters?.targetCategories,
            categoryA: parameters?.categoryA,
            categoryB: parameters?.categoryB,
        };
    }

    serializeDiscovery(discovery: Discovery): string {
        return discovery === Discovery.open
            ? 'od'
            : discovery === Discovery.closed
            ? 'cd'
            : discovery === Discovery.combinatorial
            ? 'cq'
            : undefined;
    }

    serializeDiscoveryExecutionParameters(
        parameters: DiscoveryExecutionParams,
    ): DiscoveryExecutionParamsRestApi {
        return {
            sourceUid: parameters?.sourceId,
            sourceName: parameters?.sourceName,
            targetUid: parameters?.targetId,
            targetName: parameters?.targetName,
            targetCategories: parameters?.categories,
            categoryA: parameters?.categoryA,
            categoryB: parameters?.categoryB,
        };
    }
}
