/** third-party imports */
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

/** custom imports */
import { IngredientComparisonService } from './services/ingredient-comparison.service';
import { IngredientManagementService } from '../ingredient-management/services/ingredient-management.service';
import { HttpErrorResponse } from '@angular/common/http';
import * as actions from './ingredient-comparison.actions';
import PaginatedResults from '@leap-common/interfaces/paginated-results.interface';
import Ingredient from '../ingredient-viewer/interfaces/ingredient.interface';
import Bioactivity from './interfaces/bioactivity.interface';
import MultiLineChartDataItem from '@leap-libs/charts/src/lib/interfaces/multi-line-chart-data-item.interface';
import StatisticsItem from '../report/interfaces/statistics-item.interface';

@Injectable()
export class IngredientComparisonEffects {
    constructor(
        private actions$: Actions,
        private ingredientComparisonService: IngredientComparisonService,
        private ingredientManagementService: IngredientManagementService,
    ) {}

    getIngredients$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.getIngredientsRequest),
            switchMap(({ ids }) =>
                this.ingredientManagementService.getIngredients({ ids }).pipe(
                    map((paginatedIngredients: PaginatedResults<Ingredient>) =>
                        actions.getIngredientsSuccess({ paginatedIngredients }),
                    ),
                    catchError((errorResponse: HttpErrorResponse) =>
                        of(actions.getIngredientsFailure({ errorResponse })),
                    ),
                ),
            ),
        ),
    );

    getBioactivity$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.getBioactivityRequest),
            switchMap(
                ({ ingredientIds, baselineId }: { ingredientIds: string[]; baselineId: string }) =>
                    this.ingredientComparisonService.getBioactivity(ingredientIds, baselineId).pipe(
                        map((bioactivity: Bioactivity[]) =>
                            actions.getBioactivitySuccess({ bioactivity }),
                        ),
                        catchError((errorResponse: HttpErrorResponse) =>
                            of(actions.getBioactivityFailure({ errorResponse })),
                        ),
                    ),
            ),
        ),
    );

    getAminoAcids$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.getAminoAcidsRequest),
            switchMap(({ ingredientIds }: { ingredientIds: string[] }) =>
                this.ingredientComparisonService.getAminoAcids(ingredientIds).pipe(
                    map((aminoAcids: Record<string, StatisticsItem[]>) =>
                        actions.getAminoAcidsSuccess({ aminoAcids }),
                    ),
                    catchError((errorResponse: HttpErrorResponse) =>
                        of(actions.getAminoAcidsFailure({ errorResponse })),
                    ),
                ),
            ),
        ),
    );

    getMolecularWeights$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.getMolecularWeightsRequest),
            switchMap(({ ingredientIds }: { ingredientIds: string[] }) =>
                this.ingredientComparisonService.getMolecularWeight(ingredientIds).pipe(
                    map((molecularWeights: MultiLineChartDataItem[]) =>
                        actions.getMolecularWeightsSuccess({ molecularWeights }),
                    ),
                    catchError((errorResponse: HttpErrorResponse) =>
                        of(actions.getMolecularWeightsFailure({ errorResponse })),
                    ),
                ),
            ),
        ),
    );
}
