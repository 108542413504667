/** third-party imports */
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { catchError, map, switchMap, mergeMap } from 'rxjs/operators';
import { Actions, createEffect, ofType } from '@ngrx/effects';

/** custom imports */
import { CompoundReportService } from './services/compound-report.service';
import { HttpErrorResponse } from '@angular/common/http';
import * as actions from './compound-report.actions';
import Overview from './interfaces/overview.interface';
import Effect from '../ingredient-profiler/enums/effect.enum';
import TargetsSentences from './interfaces/targets-sentences.interface';
import HealthLabel from './interfaces/health-label.interface';
import StudyTypes from './interfaces/study-types.interface';
import StudyType from './interfaces/study-type.interface';
import ExecutionFilters from '@apps/leap/src/app/shared/modules/filters/types/execution-filters.type';

@Injectable()
export class CompoundReportEffects {
    constructor(private actions$: Actions, private compoundReportService: CompoundReportService) {}

    getOverview$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.getOverviewRequest),
            switchMap(
                ({
                    compoundId,
                    ingredientName,
                    filters,
                }: {
                    compoundId: string;
                    ingredientName: string;
                    filters: ExecutionFilters;
                }) =>
                    this.compoundReportService
                        .getOverview(compoundId, ingredientName, filters)
                        .pipe(
                            map(({ overview, total }: { overview: Overview; total: number }) =>
                                actions.getOverviewSuccess({ overview, total }),
                            ),
                            catchError((errorResponse: HttpErrorResponse) =>
                                of(actions.getOverviewFailure({ errorResponse })),
                            ),
                        ),
            ),
        ),
    );

    getEffectSentences$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.getEffectSentencesRequest),
            mergeMap(
                ({
                    compoundId,
                    ingredientName,
                    effect,
                    filters,
                }: {
                    compoundId: string;
                    ingredientName: string;
                    effect: Effect;
                    filters: ExecutionFilters;
                }) =>
                    this.compoundReportService
                        .getEffectSentences(compoundId, ingredientName, effect, filters)
                        .pipe(
                            map((effectSentences: TargetsSentences) =>
                                actions.getEffectSentencesSuccess({ effectSentences }),
                            ),
                            catchError((errorResponse: HttpErrorResponse) =>
                                of(actions.getEffectSentencesFailure({ errorResponse })),
                            ),
                        ),
            ),
        ),
    );

    getHealthLabels$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.getHealthLabelsRequest),
            switchMap(
                ({
                    compoundId,
                    ingredientName,
                    filters,
                }: {
                    compoundId: string;
                    ingredientName: string;
                    filters: ExecutionFilters;
                }) =>
                    this.compoundReportService
                        .getHealthLabels(compoundId, ingredientName, filters)
                        .pipe(
                            map((healthLabels: HealthLabel[]) =>
                                actions.getHealthLabelsSuccess({ healthLabels }),
                            ),
                            catchError((errorResponse: HttpErrorResponse) =>
                                of(actions.getHealthLabelsFailure({ errorResponse })),
                            ),
                        ),
            ),
        ),
    );

    getHealthLabelSentences$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.getHealthLabelSentencesRequest),
            mergeMap(
                ({
                    compoundId,
                    ingredientName,
                    healthLabel,
                    effect,
                    filters,
                }: {
                    compoundId: string;
                    ingredientName: string;
                    healthLabel: string;
                    effect: Effect;
                    filters: ExecutionFilters;
                }) =>
                    this.compoundReportService
                        .getHealthLabelSentences(
                            compoundId,
                            ingredientName,
                            healthLabel,
                            effect,
                            filters,
                        )
                        .pipe(
                            map((sentences: TargetsSentences) =>
                                actions.getHealthLabelSentencesSuccess({ sentences }),
                            ),
                            catchError((errorResponse: HttpErrorResponse) =>
                                of(actions.getHealthLabelSentencesFailure({ errorResponse })),
                            ),
                        ),
            ),
        ),
    );

    getStudyTypes$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.getStudyTypesRequest),
            switchMap(
                ({
                    compoundId,
                    ingredientName,
                    filters,
                }: {
                    compoundId: string;
                    ingredientName: string;
                    filters: ExecutionFilters;
                }) =>
                    this.compoundReportService
                        .getStudyTypes(compoundId, ingredientName, filters)
                        .pipe(
                            map((studyTypes: StudyTypes) =>
                                actions.getStudyTypesSuccess({ studyTypes }),
                            ),
                            catchError((errorResponse: HttpErrorResponse) =>
                                of(actions.getStudyTypesFailure({ errorResponse })),
                            ),
                        ),
            ),
        ),
    );

    getStudyType$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.getStudyTypeRequest),
            mergeMap(
                ({
                    compoundId,
                    ingredientName,
                    name,
                    filters,
                }: {
                    compoundId: string;
                    ingredientName: string;
                    name: string;
                    filters: ExecutionFilters;
                }) =>
                    this.compoundReportService
                        .getStudyType(compoundId, ingredientName, name, filters)
                        .pipe(
                            map((studyType: StudyType) =>
                                actions.getStudyTypeSuccess({ studyType }),
                            ),
                            catchError((errorResponse: HttpErrorResponse) =>
                                of(actions.getStudyTypeFailure({ errorResponse })),
                            ),
                        ),
            ),
        ),
    );

    downloadReport$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.downloadRequest),
            switchMap(
                ({ compoundId, ingredientName }: { compoundId: string; ingredientName: string }) =>
                    this.compoundReportService.download(compoundId, ingredientName).pipe(
                        map((blob: Blob) => actions.downloadSuccess({ blob })),
                        catchError((errorResponse: HttpErrorResponse) =>
                            of(actions.downloadFailure({ errorResponse })),
                        ),
                    ),
            ),
        ),
    );
}
