import { Injectable } from '@angular/core';

/** Interfaces */
import PaginatedResultsRestApi from '@leap-common/rest-api-interfaces/paginated-results.rest.interface';
import PaginatedResults from '@leap-common/interfaces/paginated-results.interface';
import UserGroupRestApi from '@leap-store/core/src/lib/data/auth/rest-api-interfaces/user-group.rest.interface';
import UserGroup from '@leap-store/core/src/lib/data/auth/interfaces/user-group.interface';
import GroupTreeRestApi from '../rest-api-interfaces/group-tree.rest.interface';
import UserGroupIdsRestApi from '../rest-api-interfaces/user-group-ids.rest.interface';

@Injectable()
export class GroupsParser {
    constructor() {}

    parsePaginatedResults(
        paginatedGroups: PaginatedResultsRestApi<UserGroupRestApi>,
    ): PaginatedResults<UserGroup> {
        return {
            results: paginatedGroups.results ? this.parseGroups(paginatedGroups.results) : [],
            pageIndex: paginatedGroups.pageIndex,
            pageSize: paginatedGroups.pageSize,
            total: paginatedGroups.total,
        };
    }

    parseGroups(groups: UserGroupRestApi[]): UserGroup[] {
        return groups?.length
            ? groups.map((group: UserGroupRestApi) => this.parseGroup(group))
            : [];
    }

    parseGroup(group: UserGroupRestApi): UserGroup {
        return {
            id: group.uuid || group.id,
            name: group.name,
            label: group.label,
            participants: group.no_of_users,
            alias: group.alias,
        };
    }

    reduceNestedGroups(items: UserGroupRestApi[], acc: UserGroup[] = []): UserGroup[] {
        if (!items || !items.length) {
            return acc;
        }

        for (const item of items) {
            acc = [...acc, this.parseGroup(item)];
            acc = this.reduceNestedGroups(item.children, acc);
        }

        return acc;
    }

    parseGroupsTree(group: GroupTreeRestApi<UserGroupRestApi>): UserGroup[] {
        return this.reduceNestedGroups(group.children).filter(
            (item: UserGroup) => item.label === 'department',
        );
    }

    serializeGroup(name: string, label: string, parentId: string): UserGroupRestApi {
        return {
            name,
            labelName: label,
            parentUuid: parentId,
        };
    }

    serializeGroupsIds(ids: string[]): UserGroupIdsRestApi {
        return {
            uuids: ids,
        };
    }
}
