import { Injectable } from '@angular/core';

/** Interfaces  */
import SynonymsRestApi from '../rest-api-types/synonyms.rest.type';
import Synonyms from '../types/synonyms.type';

@Injectable()
export class SynonymsParser {
    constructor() {}

    parseSynonyms(synonyms: SynonymsRestApi): Synonyms {
        return synonyms;
    }
}
