import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { reducers } from '.';

/** pouchDB */
import { PouchDBStoreModule } from '../../../../pouchdb/pouchdb.module';

/** auth */
import { AuthStoreModule } from './auth/auth-store.module';
import { AuthEffects } from './auth/auth.effects';

/** users */
import { UsersStoreModule } from './users/users-store.module';
import { UsersEffects } from './users/users.effects';

/** roles */
import { RolesStoreModule } from './roles/roles-store.module';
import { RolesEffects } from './roles/roles.effects';

/** groups */
import { GroupsStoreModule } from './groups/groups-store.module';
import { GroupsEffects } from './groups/groups.effects';

/** notebooks */
import { NotebooksStoreModule } from './notebooks/notebooks-store.module';
import { NotebooksEffects } from './notebooks/notebooks.effects';

/** pipeline executions */
import { PipelineExecutionsStoreModule } from './pipeline-executions/pipeline-executions-store.module';
import { PipelineExecutionsEffects } from './pipeline-executions/pipeline-executions.effects';

/** projects */
import { ProjectsStoreModule } from './projects/projects-store.module';
import { ProjectsEffects } from './projects/projects.effects';

/** bookmarks */
import { BookmarksStoreModule } from './bookmarks/bookmarks-store.module';
import { BookmarksEffects } from './bookmarks/bookmarks.effects';

/** articles report */
import { ArticlesReportStoreModule } from './articles-report/articles-report-store.module';
import { ArticlesReportEffects } from './articles-report/articles-report.effects';

/** content writer */
import { ContentWriterStoreModule } from './content-writer/content-writer-store.module';
import { ContentWriterEffects } from './content-writer/content-writer.effects';

/** tags */
import { TagsStoreModule } from './tags/tags-store.module';
import { TagsEffects } from './tags/tags.effects';

/** files */
import { FilesStoreModule } from './files/files-store.module';
import { FilesEffects } from './files/files.effects';

/** notebook-servers */
import { NotebookServersStoreModule } from './notebook-servers/notebook-servers-store.module';
import { NotebookServersEffects } from './notebook-servers/notebook-servers.effects';

/** discovery */
import { OpenDiscoveryStoreModule } from './discovery/open/open-discovery-store.module';
import { OpenDiscoveryEffects } from './discovery/open/open-discovery.effects';
import { ClosedDiscoveryStoreModule } from './discovery/closed/closed-discovery-store.module';
import { ClosedDiscoveryEffects } from './discovery/closed/closed-discovery.effects';
import { CombinatorialDiscoveryStoreModule } from './discovery/combinatorial/combinatorial-discovery-store.module';
import { CombinatorialDiscoveryEffects } from './discovery/combinatorial/combinatorial-discovery.effects';
import { CategoriesStoreModule } from './discovery/categories/categories-store.module';
import { CategoriesEffects } from './discovery/categories/categories.effects';
import { SuggestionsStoreModule } from './discovery/suggestions/suggestions-store.module';
import { SuggestionsEffects } from './discovery/suggestions/suggestions.effects';
import { SynonymsStoreModule } from './discovery/synonyms/synonyms-store.module';
import { SynonymsEffects } from './discovery/synonyms/synonyms.effects';
import { DefinitionsStoreModule } from './discovery/definitions/definitions-store.module';
import { DefinitionsEffects } from './discovery/definitions/definitions.effects';
import { TermsStoreModule } from './discovery/terms/terms-store.module';
import { TermsEffects } from './discovery/terms/terms.effects';
import { DatabasesStoreModule } from './discovery/databases/databases-store.module';
import { DatabasesEffects } from './discovery/databases/databases.effects';
import { ConcentrationsStoreModule } from './discovery/concentrations/concentrations-store.module';
import { ConcentrationsEffects } from './discovery/concentrations/concentrations.effects';
import { PatentsStoreModule } from './discovery/patents/patents-store.module';
import { PatentsEffects } from './discovery/patents/patents.effects';

/** notifications */
import { NotificationsStoreModule } from './notifications/notifications-store.module';
import { NotificationsEffects } from './notifications/notifications.effects';

/** metadata */
import { MetadataStoreModule } from './metadata/metadata-store.module';
import { MetadataEffects } from './metadata/metadata.effects';

/** metapaths */
import { MetapathsStoreModule } from './metapaths/metapaths-store.module';
import { MetapathsEffects } from './metapaths/metapaths.effects';

/** paths */
import { PathsStoreModule } from './paths/paths-store.module';
import { PathsEffects } from './paths/paths.effects';

/** explainability */
import { ExplainabilityStoreModule } from './explainability/explainability-store.module';
import { ExplainabilityEffects } from './explainability/explainability.effects';

/** GPT */
import { GPTStoreModule } from './gpt/gpt-store.module';
import { GPTEffects } from './gpt/gpt.effects';

/** conversational search */
import { ConversationalSearchStoreModule } from './conversational-search/conversational-search-store.module';
import { ConversationalSearchEffects } from './conversational-search/conversational-search.effects';

/** articles */
import { ArticlesStoreModule } from './articles/articles-store.module';
import { ArticlesEffects } from './articles/articles.effects';

/** report */
import { ReportStoreModule } from './report/report-store.module';
import { ReportEffects } from './report/report.effects';

/** compound report */
import { CompoundReportStoreModule } from './compound-report/compound-report-store.module';
import { CompoundReportEffects } from './compound-report/compound-report.effects';

/** ingredient profiler */
import { IngredientProfilerStoreModule } from './ingredient-profiler/ingredient-profiler-store.module';
import { IngredientProfilerEffects } from './ingredient-profiler/ingredient-profiler.effects';

/** cow milk profiler */
import { CowMilkProfilerStoreModule } from './cow-milk-profiler/cow-milk-profiler-store.module';
import { CowMilkProfilerEffects } from './cow-milk-profiler/cow-milk-profiler.effects';

/** dairy profiler */
import { DairyProfilerStoreModule } from './dairy-profiler/dairy-profiler-store.module';
import { DairyProfilerEffects } from './dairy-profiler/dairy-profiler.effects';

/** ingredient viewer */
import { IngredientViewerStoreModule } from './ingredient-viewer/ingredient-viewer-store.module';
import { IngredientViewerEffects } from './ingredient-viewer/ingredient-viewer.effects';

/** ingredient comparison */
import { IngredientComparisonStoreModule } from './ingredient-comparison/ingredient-comparison-store.module';
import { IngredientComparisonEffects } from './ingredient-comparison/ingredient-comparison.effects';

/** ingredient management */
import { IngredientManagementStoreModule } from './ingredient-management/ingredient-management-store.module';
import { IngredientManagementEffects } from './ingredient-management/ingredient-management.effects';

@NgModule({
    imports: [
        CommonModule,
        PouchDBStoreModule,
        AuthStoreModule,
        UsersStoreModule,
        RolesStoreModule,
        GroupsStoreModule,
        NotebooksStoreModule,
        NotebookServersStoreModule,
        ProjectsStoreModule,
        BookmarksStoreModule,
        ArticlesReportStoreModule,
        ContentWriterStoreModule,
        TagsStoreModule,
        OpenDiscoveryStoreModule,
        ClosedDiscoveryStoreModule,
        CombinatorialDiscoveryStoreModule,
        CategoriesStoreModule,
        SuggestionsStoreModule,
        SynonymsStoreModule,
        DefinitionsStoreModule,
        TermsStoreModule,
        DatabasesStoreModule,
        ConcentrationsStoreModule,
        PatentsStoreModule,
        NotificationsStoreModule,
        PipelineExecutionsStoreModule,
        FilesStoreModule,
        MetadataStoreModule,
        MetapathsStoreModule,
        PathsStoreModule,
        ExplainabilityStoreModule,
        GPTStoreModule,
        ConversationalSearchStoreModule,
        ArticlesStoreModule,
        ReportStoreModule,
        CompoundReportStoreModule,
        IngredientProfilerStoreModule,
        CowMilkProfilerStoreModule,
        DairyProfilerStoreModule,
        IngredientViewerStoreModule,
        IngredientComparisonStoreModule,
        IngredientManagementStoreModule,
        StoreModule.forRoot(reducers, {}),
        StoreDevtoolsModule.instrument(),
        EffectsModule.forRoot([
            AuthEffects,
            UsersEffects,
            RolesEffects,
            GroupsEffects,
            NotebooksEffects,
            NotebookServersEffects,
            ProjectsEffects,
            BookmarksEffects,
            ArticlesReportEffects,
            ContentWriterEffects,
            TagsEffects,
            OpenDiscoveryEffects,
            ClosedDiscoveryEffects,
            CombinatorialDiscoveryEffects,
            CategoriesEffects,
            SuggestionsEffects,
            SynonymsEffects,
            DefinitionsEffects,
            TermsEffects,
            DatabasesEffects,
            ConcentrationsEffects,
            PatentsEffects,
            NotificationsEffects,
            PipelineExecutionsEffects,
            FilesEffects,
            MetadataEffects,
            MetapathsEffects,
            PathsEffects,
            ExplainabilityEffects,
            GPTEffects,
            ConversationalSearchEffects,
            ArticlesEffects,
            ReportEffects,
            CompoundReportEffects,
            IngredientProfilerEffects,
            CowMilkProfilerEffects,
            DairyProfilerEffects,
            IngredientViewerEffects,
            IngredientComparisonEffects,
            IngredientManagementEffects,
        ]),
    ],
    declarations: [],
})
export class DataStoreModule {}
