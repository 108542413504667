import { createAction, props } from '@ngrx/store';
import { HttpErrorResponse } from '@angular/common/http';
import { MetapathsActionTypes } from './metapaths-action-types.enum';
import PaginatedMetapaths from './interfaces/paginated-metapaths.interface';

export const getMetapathsRequest = createAction(
    MetapathsActionTypes.GET_METAPATHS_REQUEST,
    props<{
        parentInsightId: string;
        sourceId: string;
        targetId: string;
        sourceCategories: string[];
        targetCategories: string[];
        sourceSubcategories: string[];
        targetSubcategories: string[];
        pageSize: number;
        pageIndex: number;
    }>(),
);

export const getMetapathsSuccess = createAction(
    MetapathsActionTypes.GET_METAPATHS_SUCCESS,
    props<{
        paginatedMetapaths: PaginatedMetapaths;
        parentInsightId: string;
    }>(),
);

export const getMetapathsFailure = createAction(
    MetapathsActionTypes.GET_METAPATHS_FAILURE,
    props<{ errorResponse: HttpErrorResponse }>(),
);

export const clearNextError = createAction(MetapathsActionTypes.CLEAR_NEXT_ERROR);
