import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

/** Services */
import { AuthService } from '../services/auth.service';
import { RedirectService } from '../../../layout/services/redirect.service';

@Injectable()
/** The name'NonAuthGuard' was chosen to indicate that it applies to users who are not authenticated */
export class NonAuthGuard implements CanActivate {
    constructor(private authService: AuthService, private redirectService: RedirectService) {}

    /**
     * Activates the protected route based on the authService.isUserAuthenticated().
     * If the user is not authenticated, allow them to redirect to /auth/ route they requested.
     * If the user is already authenticated, they will be redirected to the landing page.
     */
    canActivate(): Observable<boolean> {
        return this.authService.isUserAuthenticated().pipe(
            map((isUserAuthenticated: boolean) => {
                if (isUserAuthenticated) {
                    this.redirectService.redirectBasedOnTermsOfUseAcceptance();
                    return false;
                }
                return true;
            }),
        );
    }
}
