import { Component, Input } from '@angular/core';
import { BETA_TEXT } from '@leap-common/constants/common';
import Header from '@leap-common/interfaces/header.interface';

@Component({
    selector: 'app-chat-header',
    templateUrl: 'chat-header.view.component.html',
    styleUrls: ['chat-header.view.component.scss'],
})
export class ChatHeaderViewComponent {
    @Input() header: Header;

    readonly betaText: string = BETA_TEXT;

    constructor() {}
}
