/** third-party imports */
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { Actions, createEffect, ofType } from '@ngrx/effects';

/** custom imports */
import { DairyProfilerService } from './services/dairy-profiler.service';
import { HttpErrorResponse } from '@angular/common/http';
import * as actions from './dairy-profiler.actions';
import PaginatedInsights from '../ingredient-profiler/interfaces/paginated-insights.interface';
import Overview from '../ingredient-profiler/interfaces/overview.interface';
import HealthLabelStatistics from '../ingredient-profiler/interfaces/health-label-statistics.interface';
import HealthLabelSummaries from '../ingredient-profiler/interfaces/health-label-summaries.interface';
import HealthLabelTopCompounds from '../ingredient-profiler/interfaces/health-label-top-compounds.interface';
import RelationshipGroup from '../ingredient-profiler/enums/relationship-group.enum';

@Injectable()
export class DairyProfilerEffects {
    constructor(private actions$: Actions, private dairyProfilerService: DairyProfilerService) {}

    getInsights$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.getInsightsRequest),
            mergeMap(({ compoundId, filters, pageSize, pageIndex, preferences, sortingOptions }) =>
                this.dairyProfilerService
                    .getInsights(
                        compoundId,
                        filters,
                        pageSize,
                        pageIndex,
                        preferences,
                        sortingOptions,
                    )
                    .pipe(
                        map((paginatedInsights: PaginatedInsights) =>
                            actions.getInsightsSuccess({
                                compoundId,
                                paginatedInsights,
                            }),
                        ),
                        catchError((errorResponse: HttpErrorResponse) =>
                            of(actions.getInsightsFailure({ errorResponse })),
                        ),
                    ),
            ),
        ),
    );

    getTargetInsights$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.getTargetInsightsRequest),
            mergeMap(({ targetId, filters, pageSize, pageIndex, preferences }) =>
                this.dairyProfilerService
                    .getTargetInsights(targetId, filters, pageSize, pageIndex, preferences)
                    .pipe(
                        map((paginatedInsights: PaginatedInsights) =>
                            actions.getTargetInsightsSuccess({
                                paginatedInsights,
                            }),
                        ),
                        catchError((errorResponse: HttpErrorResponse) =>
                            of(actions.getTargetInsightsFailure({ errorResponse })),
                        ),
                    ),
            ),
        ),
    );

    downloadInsights$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.downloadInsightsRequest),
            switchMap(({ filters, preferences }) =>
                this.dairyProfilerService.downloadInsights(filters, preferences).pipe(
                    map((blob: Blob) => actions.downloadInsightsSuccess({ blob })),
                    catchError((errorResponse: HttpErrorResponse) =>
                        of(actions.downloadInsightsFailure({ errorResponse })),
                    ),
                ),
            ),
        ),
    );

    getOverview$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.getOverviewRequest),
            switchMap(({ countBy, filters, preferences }) =>
                this.dairyProfilerService.getOverview(countBy, filters, preferences).pipe(
                    map((overview: Overview) => actions.getOverviewSuccess({ overview })),
                    catchError((errorResponse: HttpErrorResponse) =>
                        of(actions.getOverviewFailure({ errorResponse })),
                    ),
                ),
            ),
        ),
    );

    getHealthLabelsStatistics$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.getHealthLabelsStatisticsRequest),
            switchMap(({ filters, preferences }) =>
                this.dairyProfilerService.getHealthLabelsStatistics(filters, preferences).pipe(
                    map(
                        ({
                            healthLabelsStatistics,
                            relationshipsPerGroup,
                        }: {
                            healthLabelsStatistics: HealthLabelStatistics[];
                            relationshipsPerGroup: Record<RelationshipGroup, string[]>;
                        }) =>
                            actions.getHealthLabelsStatisticsSuccess({
                                healthLabelsStatistics,
                                relationshipsPerGroup,
                            }),
                    ),
                    catchError((errorResponse: HttpErrorResponse) =>
                        of(actions.getHealthLabelsStatisticsFailure({ errorResponse })),
                    ),
                ),
            ),
        ),
    );

    getHealthLabelSummaries$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.getHealthLabelSummariesRequest),
            mergeMap(({ healthLabel, filters, preferences }) =>
                this.dairyProfilerService
                    .getHealthLabelSummaries(healthLabel, filters, preferences)
                    .pipe(
                        map((healthLabelSummaries: HealthLabelSummaries) =>
                            actions.getHealthLabelSummariesSuccess({ healthLabelSummaries }),
                        ),
                        catchError((errorResponse: HttpErrorResponse) =>
                            of(actions.getHealthLabelSummariesFailure({ errorResponse })),
                        ),
                    ),
            ),
        ),
    );

    getHealthLabelTopCompounds$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.getHealthLabelTopCompoundsRequest),
            mergeMap(({ healthLabel, filters, preferences }) =>
                this.dairyProfilerService
                    .getHealthLabelTopCompounds(healthLabel, filters, preferences)
                    .pipe(
                        map((healthLabelTopCompounds: HealthLabelTopCompounds) =>
                            actions.getHealthLabelTopCompoundsSuccess({ healthLabelTopCompounds }),
                        ),
                        catchError((errorResponse: HttpErrorResponse) =>
                            of(actions.getHealthLabelTopCompoundsFailure({ errorResponse })),
                        ),
                    ),
            ),
        ),
    );

    getSuggestions$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.getSearchSuggestionsRequest),
            switchMap(({ query, activeSearch }) =>
                this.dairyProfilerService.getSearchSuggestions(query, activeSearch).pipe(
                    map((suggestionIds: string[]) =>
                        actions.getSearchSuggestionsSuccess({ suggestionIds }),
                    ),
                    catchError((errorResponse: HttpErrorResponse) =>
                        of(actions.getSearchSuggestionsFailure({ errorResponse })),
                    ),
                ),
            ),
        ),
    );
}
