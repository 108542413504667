/** third-party imports */
import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { of } from 'rxjs';
import { switchMap, map, catchError } from 'rxjs/operators';

/** custom imports */
import { ConcentrationsService } from './services/concentrations.service';
import { BookmarksService } from '@leap-store/core/src/lib/data/bookmarks/services/bookmarks.service';
import * as actions from './concentrations.actions';
import Concentration from './interfaces/concentration.interface';

@Injectable()
export class ConcentrationsEffects {
    constructor(
        private actions$: Actions,
        private concentrationsService: ConcentrationsService,
        private bookmarksService: BookmarksService,
    ) {}

    getConcentrations$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.getConcentrationsRequest),
            switchMap(({ sourceId, targetId, bookmarkIds }) =>
                (bookmarkIds
                    ? this.bookmarksService.getConcentrations(bookmarkIds, sourceId, targetId)
                    : this.concentrationsService.getConcentrations(sourceId, targetId)
                ).pipe(
                    map((concentrations: Concentration[]) =>
                        actions.getConcentrationsSuccess({
                            concentrations,
                        }),
                    ),
                    catchError((errorResponse: HttpErrorResponse) =>
                        of(actions.getConcentrationsFailure({ errorResponse })),
                    ),
                ),
            ),
        ),
    );

    getConcentrationDetails$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.getConcentrationDetailsRequest),
            switchMap(({ sourceId, targetId, sourceName }) =>
                this.concentrationsService
                    .getConcentrationDetails(sourceId, targetId, sourceName)
                    .pipe(
                        map((concentrationDetails: Concentration[]) =>
                            actions.getConcentrationDetailsSuccess({
                                concentrationDetails,
                            }),
                        ),
                        catchError((errorResponse: HttpErrorResponse) =>
                            of(actions.getConcentrationDetailsFailure({ errorResponse })),
                        ),
                    ),
            ),
        ),
    );
}
